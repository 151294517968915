import React, { useEffect, useState, useRef, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from "gsap/all";
import Image from '../../../../Images/SlidersPage/crm-benefits.png';

const Center = css`
    display : flex;
    justify-content : center;
    align-items : center;
    box-sizing : border-box;


@media screen and (max-width : 768px){

}
`;


const BenefitsSection = styled.div`
    width : 100%;
    height : auto;
    font-family : "Merriweather";
    overflow : hidden;
    margin : 20px 0;
    ${Center};
    flex-direction : column;
`;

const InnerSection = styled.div`
    width : 80%;
    height : auto;
    ${Center};

@media screen and (max-width : 768px){
    width : 100%;
    flex-direction : column-reverse;
}
`;


const Heading = styled.div`
    width : 100%;
    padding : 20px 0;
    ${Center};
`;



const Points = styled.div`
    width : 100%;
    ${Center};
    padding : 20px;

@media screen and (max-width : 768px){
    padding : 5px ;
}
`;


const PointsDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex !important;
    justify-content : space-around;
    align-items : center;
    flex-direction : column;  


p{
    text-align : center;
    padding : 10px 0;
}

@media screen and (max-width : 768px){
    padding : 5px ;

p{
    font-size : 14px;
    padding : 5px 0;
}
}
`;


const ImageDiv = styled.div`
    width : 100%;
    height : 100%;


@media screen and (max-width : 768px){
    padding : 5px ;
}
`;

const ImageWrapper = styled.div`
    width : 100%;
    height : auto;
    ${Center};

img{
    width : 70%;
    height:  auto;
}


@media screen and (max-width : 768px){
    padding : 5px ;
}
`;

const Benefits = () => {
    const HeadingRef = useRef(null);
    const PointsRef = useRef(null);
    const PointsDivRef = useRef(null);
    const ImageRef = useRef(null);

    // useEffect(() => {

    // }, [])
    return <Fragment>
        <BenefitsSection>
                <Heading ref={HeadingRef} >
                    <h2 data-aos="fade-up">Customer Benefits</h2>
                </Heading>
            <InnerSection>
                <Points ref={PointsRef} data-aos="fade-right">
                    <PointsDiv ref={PointsDivRef}>
                        <p className = 'benefits-p'>1. Enhanced ability to target profitable customers.</p>
                        <p className = 'benefits-p'>2. Integrated assistance across channels.</p>
                        <p className = 'benefits-p'>3. Enhanced sales force efficiency and effectiveness.</p>
                        <p className = 'benefits-p'>4. Improved pricing and Customized products and services.</p>
                        <p className = 'benefits-p'>5. Improved customer service efficiency and effectiveness.</p>
                        <p className = 'benefits-p'>6. Individualized marketing messages also called campaigns.</p>
                        <p className = 'benefits-p'>7. Connect customers and all channels on a single platform.</p>
                    </PointsDiv>
                </Points>
                <ImageDiv data-aos="fade-left">
                    <ImageWrapper>
                        <img ref={ImageRef} src={Image} />
                    </ImageWrapper>
                </ImageDiv>
            </InnerSection>
        </BenefitsSection>

    </Fragment>
}

export default Benefits;