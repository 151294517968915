import React, { useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import './LmvLogo.css';

const Section = styled.section`
  width: 100%;
  height: 80vh;
  padding: 50px;
  overflow: hidden;
  box-sizing: border-box;

@media screen and (max-width: 768px) {
  height : 50vh;
}
`;

const SVG = styled.svg`
  margin-top : 20px;
`

const Path = styled.path``;

export default function LMV() {



  return (
      <Section>
        <SVG
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 658 443"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,443.000000) scale(0.100000,-0.100000)"
            stroke="#000"
            fill="#fff"
          >
            <Path className="fill" d="M6519 4384 c-18 -20 -59 -57 -89 -84 l-55 -47 -80 13 c-123 21 -366
29 -467 15 -255 -34 -423 -116 -589 -287 -215 -223 -365 -585 -417 -1007 l-17
-138 -50 -17 c-73 -25 -110 -55 -139 -114 -50 -99 -29 -210 53 -280 109 -93
270 -67 346 56 26 42 30 59 30 115 -1 93 -43 165 -122 210 -14 7 -10 26 32
152 145 441 294 694 488 830 148 103 271 121 470 68 20 -5 37 -14 37 -19 0 -5
-27 -40 -60 -78 l-59 -69 61 -7 c33 -3 197 -6 364 -6 l302 0 8 88 c12 133 11
619 -2 632 -7 7 -20 -1 -45 -26z"
            />
            <Path className="fill" d="M10 2445 l0 -1075 1295 0 c712 0 1295 2 1295 5 0 34 -252 339 -298
362 -9 4 -361 8 -782 8 -421 0 -831 1 -912 3 l-148 2 0 885 0 885 -225 0 -225
0 0 -1075z"
            />
            <Path className="logoPathBig" d="M5835 3490 c-18 -35 -973 -1723 -986 -1743 l-17 -28 -72 128 c-40 70
-199 351 -353 623 -154 272 -347 613 -429 758 l-149 262 -195 0 c-107 0 -194
-2 -194 -5 0 -8 284 -515 605 -1080 172 -302 367 -647 433 -765 l120 -215 231
-3 c230 -2 230 -2 244 20 8 13 104 183 214 378 110 195 324 573 475 840 151
267 318 562 370 655 l94 170 -45 2 c-107 6 -343 8 -346 3z"
            />
            <Path className="logoPathBig" d="M894 3476 c-38 -17 -77 -66 -101 -127 -16 -40 -18 -100 -21 -656 l-3
-613 156 0 155 0 0 493 c0 448 2 495 18 527 29 60 31 60 435 60 l367 0 0 -540
0 -540 155 0 155 0 0 538 0 539 309 7 c327 8 452 2 488 -20 12 -8 27 -31 32
-51 7 -25 11 -324 11 -875 l0 -838 150 0 150 0 -2 963 -3 962 -23 50 c-26 56
-63 100 -104 121 -39 21 -2277 20 -2324 0z"
            />
            <Path className="logoPathMedium" d="M10 865 l0 -325 3095 0 3095 0 0 325 0 325 -3095 0 -3095 0 0 -325z
m165 35 c0 -106 -3 -144 -12 -147 -10 -4 -13 30 -13 147 0 117 3 151 13 148 9
-4 12 -42 12 -148z m395 135 c0 -11 -12 -15 -50 -15 l-50 0 0 -135 c0 -113 -2
-135 -15 -135 -13 0 -15 22 -15 135 l0 135 -50 0 c-38 0 -50 4 -50 15 0 12 19
15 115 15 96 0 115 -3 115 -15z m557 -9 c15 -13 25 -28 21 -32 -5 -4 -16 0
-26 9 -44 40 -146 28 -158 -19 -9 -35 8 -50 84 -68 94 -24 112 -38 112 -85 0
-58 -26 -76 -111 -76 -61 0 -71 3 -96 27 -37 37 -24 45 22 13 60 -40 149 -28
161 22 9 35 -20 60 -85 74 -101 20 -132 50 -111 105 21 55 136 74 187 30z
m573 -126 c0 -93 -4 -150 -10 -150 -6 0 -10 57 -10 150 0 93 4 150 10 150 6 0
10 -57 10 -150z m2389 139 c53 -19 75 -83 47 -138 -19 -36 -55 -51 -126 -51
l-60 0 0 -51 c0 -36 -4 -49 -12 -47 -9 3 -14 42 -16 138 -1 74 0 140 2 147 7
16 119 18 165 2z m1061 -124 l0 -134 82 -3 c54 -2 83 -7 86 -15 3 -10 -20 -13
-92 -13 l-96 0 0 150 c0 93 4 150 10 150 6 0 10 -52 10 -135z m790 -21 c0 -92
-4 -144 -10 -144 -6 0 -13 7 -16 15 -5 13 -8 13 -27 0 -34 -23 -91 -19 -125
10 -75 63 -33 185 62 185 23 0 52 -7 64 -16 22 -15 22 -15 22 39 0 29 3 57 6
60 18 18 24 -17 24 -149z m-3430 131 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15
0 8 7 15 15 15 8 0 15 -7 15 -15z m-250 -35 c0 -28 3 -30 35 -30 19 0 35 -4
35 -10 0 -5 -16 -10 -35 -10 l-35 0 0 -74 c0 -79 11 -100 49 -94 11 2 21 -1
23 -7 2 -5 -11 -11 -28 -13 -51 -5 -64 17 -64 109 0 72 -2 79 -20 79 -11 0
-20 5 -20 10 0 6 9 10 20 10 16 0 20 7 20 30 0 17 5 30 10 30 6 0 10 -13 10
-30z m2420 0 c0 -28 3 -30 35 -30 19 0 35 -4 35 -10 0 -5 -16 -10 -35 -10
l-36 0 3 -82 3 -83 33 0 c19 0 32 -5 32 -12 0 -17 -62 -17 -78 -1 -7 7 -12 44
-12 95 0 82 0 83 -25 83 -14 0 -25 5 -25 10 0 6 11 10 25 10 21 0 25 5 25 30
0 17 5 30 10 30 6 0 10 -13 10 -30z m830 0 c0 -28 3 -30 35 -30 19 0 35 -4 35
-10 0 -5 -16 -10 -35 -10 l-35 0 0 -74 c0 -79 11 -100 49 -94 11 2 21 -1 23
-7 5 -18 -53 -17 -73 1 -16 14 -19 31 -19 96 0 71 -2 78 -20 78 -11 0 -20 5
-20 10 0 6 9 10 20 10 16 0 20 7 20 30 0 17 5 30 10 30 6 0 10 -13 10 -30z
m-4019 -59 c24 -24 29 -38 29 -76 0 -38 -5 -52 -29 -76 -79 -79 -211 11 -171
116 16 43 48 65 98 65 35 0 49 -6 73 -29z m399 -50 c0 -71 2 -82 22 -95 32
-23 65 -20 93 9 22 21 25 33 25 95 0 40 4 70 10 70 6 0 10 -42 10 -105 0 -63
-4 -105 -10 -105 -5 0 -10 7 -10 16 0 14 -2 14 -22 0 -33 -24 -82 -20 -115 8
-25 22 -28 31 -31 105 -4 69 -2 81 12 81 13 0 16 -13 16 -79z m620 -27 c0 -80
-3 -105 -12 -102 -9 3 -14 36 -16 106 -2 86 0 102 13 102 12 0 15 -17 15 -106z
m321 96 c24 -13 49 -61 49 -95 0 -92 -116 -140 -181 -76 -24 24 -29 38 -29 76
0 64 39 105 100 105 23 0 50 -5 61 -10z m219 -6 c0 -14 2 -14 22 0 35 25 94
20 123 -9 22 -22 25 -32 25 -105 0 -47 -4 -80 -10 -80 -6 0 -10 32 -10 75 0
85 -18 115 -69 115 -53 0 -70 -26 -73 -109 -2 -49 -7 -76 -15 -79 -10 -3 -13
22 -13 102 0 64 4 106 10 106 6 0 10 -7 10 -16z m464 10 c9 -3 16 -13 16 -20
0 -12 -4 -12 -20 -4 -30 16 -91 8 -104 -13 -15 -24 10 -43 70 -52 54 -8 82
-41 64 -74 -22 -42 -86 -54 -140 -26 -32 17 -42 42 -12 30 64 -27 72 -29 99
-19 20 7 28 16 28 34 0 21 -7 26 -53 36 -29 7 -59 17 -67 24 -20 16 -19 54 1
74 16 16 87 22 118 10z m837 -79 c19 -47 36 -85 39 -85 3 0 21 38 39 85 19 47
40 85 47 85 15 0 15 0 -37 -120 -20 -47 -42 -85 -49 -85 -8 0 -33 46 -56 103
-29 68 -39 102 -30 102 7 0 28 -38 47 -85z"
            />
            <Path className="logoPathSmallFill"  d="M3950 950 l0 -70 68 0 c79 0 105 18 105 70 0 52 -26 70 -105 70 l-68 0 0 -70z"/>
            <Path
               
              className="logoPathSmallFill"
              d="M5772 917 c-49 -52 -12 -147 58 -147 73 0 111 104 54 149 -36 28 -85
27 -112 -2z"
            />
            <Path
               
              className="logoPathSmallFill"
              d="M1362 917 c-33 -35 -30 -99 4 -126 57 -45 134 -8 134 64 0 74 -89
114 -138 62z"
            />
            <Path
               
              className="logoPathSmallFill"
              d="M2715 915 c-48 -47 -25 -128 40 -141 67 -12 116 46 94 113 -19 58
-90 73 -134 28z"
            />
            <Path
               
              className="logoPathSmall"
              d="M40 225 l0 -115 83 0 c99 0 127 14 127 64 0 17 -5 37 -11 43 -9 9 -9
19 1 42 10 26 10 35 -4 56 -16 24 -21 25 -106 25 l-90 0 0 -115z m178 66 c5
-32 -21 -51 -70 -51 -21 0 -38 -4 -38 -10 0 -5 22 -10 48 -10 53 0 72 -12 72
-47 0 -31 -28 -43 -99 -43 l-61 0 0 95 0 96 73 -3 c68 -3 72 -4 75 -27z"
            />
            <Path
               
              className="logoPathSmall"
              d="M360 225 l0 -115 100 0 c124 0 134 18 13 22 l-88 3 -3 93 -3 92 85 0
c47 0 88 5 91 10 4 6 -30 10 -94 10 l-101 0 0 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M651 295 c29 -41 49 -56 49 -38 0 16 -54 83 -67 83 -10 0 -4 -14 18
-45z"
            />
            <Path
               
              className="logoPathSmall"
              d="M766 278 c-41 -55 -46 -67 -46 -115 0 -29 5 -53 10 -53 6 0 10 22 10
49 0 42 6 56 50 115 31 41 45 66 36 66 -8 0 -35 -28 -60 -62z"
            />
            <Path
               
              className="logoPathSmall"
              d="M904 327 c-2 -7 -3 -58 -2 -113 l3 -99 115 0 115 0 0 110 0 110 -113
3 c-87 2 -114 0 -118 -11z m214 -99 l-3 -93 -95 0 -95 0 -3 93 -3 92 101 0
101 0 -3 -92z"
            />
            <Path
               
              className="logoPathSmall"
              d="M1270 335 c0 -12 184 -220 197 -223 10 -3 13 21 13 112 0 141 -18
157 -22 20 l-3 -96 -83 96 c-72 84 -102 110 -102 91z"
            />
            <Path
               
              className="logoPathSmall"
              d="M1600 225 c0 -70 4 -115 10 -115 6 0 10 42 10 105 l0 105 95 0 95 0
0 -95 0 -95 -75 0 c-45 0 -75 -4 -75 -10 0 -7 30 -10 83 -8 l82 3 3 113 3 112
-116 0 -115 0 0 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2060 330 c0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -43 10 -110
10 -67 0 -110 -4 -110 -10z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2350 225 c0 -70 4 -115 10 -115 6 0 10 45 10 115 0 70 -4 115 -10
115 -6 0 -10 -45 -10 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2550 290 l0 -50 -70 0 c-40 0 -70 -4 -70 -10 0 -6 30 -10 70 -10
l70 0 0 -55 c0 -42 3 -55 15 -55 12 0 15 19 15 115 0 96 -3 115 -15 115 -11 0
-15 -12 -15 -50z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2700 225 l0 -115 95 0 c57 0 95 4 95 10 0 6 -35 10 -85 10 l-85 0 0
95 0 95 85 0 c50 0 85 4 85 10 0 6 -38 10 -95 10 l-95 0 0 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M3110 330 c0 -6 43 -10 110 -10 67 0 110 4 110 10 0 6 -43 10 -110
10 -67 0 -110 -4 -110 -10z"
            />
            <Path
               
              className="logoPathSmall"
              d="M3400 225 l0 -115 95 0 c57 0 95 4 95 10 0 6 -35 10 -85 10 l-85 0 0
95 0 95 85 0 c50 0 85 4 85 10 0 6 -38 10 -95 10 l-95 0 0 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M3684 327 c-2 -7 -3 -58 -2 -113 l3 -99 103 -3 c63 -2 102 1 102 7 0
6 -40 11 -92 13 l-93 3 -3 93 -3 92 90 0 c49 0 93 5 96 10 4 6 -30 10 -95 10
-74 0 -102 -3 -106 -13z"
            />
            <Path
               
              className="logoPathSmall"
              d="M4000 225 c0 -70 4 -115 10 -115 6 0 10 45 10 115 0 70 -4 115 -10
115 -6 0 -10 -45 -10 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M4208 293 l-3 -48 -72 -3 c-96 -4 -94 -22 2 -22 l75 0 0 -55 c0 -30
5 -55 10 -55 6 0 10 45 10 115 0 66 -4 115 -9 115 -6 0 -11 -21 -13 -47z"
            />
            <Path
               
              className="logoPathSmall"
              d="M4408 270 c34 -38 78 -90 98 -115 58 -69 64 -63 64 70 0 141 -18 156
-22 18 l-3 -97 -83 97 c-46 53 -90 97 -99 97 -9 0 9 -28 45 -70z"
            />
            <Path
               
              className="logoPathSmall"
              d="M4686 324 c-3 -9 -6 -55 -6 -104 0 -63 4 -91 14 -99 9 -8 50 -11 117
-9 l104 3 3 113 3 112 -115 0 c-95 0 -115 -3 -120 -16z m214 -99 l0 -95 -95 0
-95 0 0 95 0 95 95 0 95 0 0 -95z"
            />
            <Path
               
              className="logoPathSmall"
              d="M5040 225 l0 -115 95 0 c57 0 95 4 95 10 0 6 -33 10 -80 10 l-80 0 0
105 c0 87 -3 105 -15 105 -12 0 -15 -19 -15 -115z"
            />
            <Path
               
              className="logoPathSmall"
              d="M5304 327 c-2 -7 -3 -58 -2 -113 l3 -99 115 0 115 0 0 110 0 110
-113 3 c-87 2 -114 0 -118 -11z m214 -99 l-3 -93 -95 0 -95 0 -3 93 -3 92 101
0 101 0 -3 -92z"
            />
            <Path
               
              className="logoPathSmall"
              d="M5657 333 c-4 -3 -7 -55 -7 -115 0 -89 3 -108 15 -108 12 0 15 18 15
105 l0 105 90 0 c73 0 90 -3 90 -15 0 -8 5 -15 10 -15 6 0 10 11 10 25 l0 25
-108 0 c-60 0 -112 -3 -115 -7z"
            />
            <Path
               
              className="logoPathSmall"
              d="M5970 333 c1 -15 69 -87 75 -81 10 9 -47 88 -62 88 -7 0 -13 -3 -13
-7z"
            />
            <Path
               
              className="logoPathSmall"
              d="M6110 274 c-44 -59 -50 -73 -50 -115 0 -37 4 -49 15 -49 11 0 15 12
15 51 0 45 5 57 45 109 25 32 45 61 45 64 0 20 -26 -3 -70 -60z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2150 195 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"
            />
            <Path
               
              className="logoPathSmall"
              d="M3200 195 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"
            />
            <Path
               
              className="logoPathSmall"
              d="M1260 191 c0 -47 4 -81 10 -81 6 0 10 31 10 74 0 41 -4 78 -10 81 -6
4 -10 -23 -10 -74z"
            />
            <Path
               
              className="logoPathSmall"
              d="M4350 191 c0 -47 4 -81 10 -81 6 0 10 31 10 74 0 41 -4 78 -10 81 -6
4 -10 -23 -10 -74z"
            />
            <Path
               
              className="logoPathSmall"
              d="M420 230 c0 -5 25 -10 55 -10 30 0 55 5 55 10 0 6 -25 10 -55 10 -30
0 -55 -4 -55 -10z"
            />
            <Path
               
              className="logoPathSmall"
              d="M2760 230 c0 -5 25 -10 55 -10 30 0 55 5 55 10 0 6 -25 10 -55 10
-30 0 -55 -4 -55 -10z"
            />
            <Path
               
              className="logoPathSmall"
              d="M3460 230 c0 -5 25 -10 55 -10 30 0 55 5 55 10 0 6 -25 10 -55 10
-30 0 -55 -4 -55 -10z"
            />
            <Path
               
              className="logoPathSmall"
              d="M5760 230 c0 -5 23 -10 50 -10 l50 0 0 -45 0 -45 -70 0 c-42 0 -70
-4 -70 -10 0 -7 29 -10 78 -8 l77 3 3 63 3 62 -61 0 c-33 0 -60 -4 -60 -10z"
            />
          </g>
        </SVG>
      </Section>
  );
}
