import React, { useEffect, useState, useRef, Fragment } from 'react';
import Image1 from '../../../../Images/SlidersPage/features-1.png';
import Image2 from '../../../../Images/SlidersPage/features-4.png';
import styled, { css } from 'styled-components';
import { gsap } from "gsap/all";
import { HiBadgeCheck } from 'react-icons/hi';

const Center = css`
    display : flex;
    justify-content : center;
    align-items : center;
    box-sizing : border-box;
    overflow : hidden;

@media screen and (max-width : 768px){

}
`;


const FavouriteSection = styled.div`
    width : 100%;
    height : auto;
    line-height : 30px;
    ${Center};

@media screen and (max-width : 768px){

}
`;
const Wrapper = styled.div`
    width : 80%;
    ${Center};
    flex-direction : column;
    padding : 0 10px;

@media screen and (max-width : 768px){
    width : 100%;
}
`;
const Heading = styled.div`
    width : 100%;
    ${Center};
    flex-direction : column;
    text-align : center;

h2{
    padding : 15px 0;
}

p{
    width : 95%;
    padding : 10px 0;
    font-weight : bold;

}

@media screen and (max-width : 768px){

h2{
    font-size : 18px;
}

p{
    width : 100%;
}
}
`;
const Content = styled.div`
    width : 100%;
    padding : 10px;
    ${Center};

@media screen and (max-width : 768px){

}
`;
const ContentWrapper = styled.div`
    width : 100%;
    line-height : 30px;

@media screen and (max-width : 768px){

}
`;
const ContentDiv = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
    padding : 10px 0;

@media screen and (max-width : 768px){
    flex-direction : ${props => props.flex};
}
`;
const ContentDivWrapper = styled.div`
    width : 100%;
    ${Center};
    flex-direction : column;
    

@media screen and (max-width : 768px){

}
`;
const ContentHead = styled.div`
    width : 100%;
    padding : 10px;

h3{
    text-align : center;
}

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentMain = styled.div`
    width : 100%;
    padding : 10px;

@media screen and (max-width : 768px){

}
`;
const Image = styled.div`
    width : 100%;
    height : auto;
    ${Center};

@media screen and (max-width : 768px){

}
`;
const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    ${Center};

img{
    width : 90%;
    height : auto;
}


@media screen and (max-width : 768px){

}
`;


const Icon = styled(HiBadgeCheck)`
    font-size : 20px;
    position : relative;
    bottom : -5px;
    margin-right : 5px;
`;

const Favourite = () => {

    return <Fragment>
        <FavouriteSection>
            <Wrapper>   
                <Heading >
                    <h2 data-aos="fade-up">Build everlasting customer relationships with the world’s favorite CRM</h2>
                    <p data-aos="fade-up">Our CRM empowers a global network to convert more leads, engage with customers, and grow their revenue. Transform your business with the world’s favorite customer relationship management software</p>
                </Heading>
                <Content>
                    <ContentWrapper>
                        <ContentDiv flex='column' data-aos="zoom-in">
                            <Image>
                                <ImageDiv>
                                    <img src={Image1} />
                                </ImageDiv>
                            </Image>
                            <ContentDivWrapper>
                                <ContentHead>
                                    <h3>Connect with your customers wherever they are</h3>
                                </ContentHead>
                                <ContentMain>
                                    <p><Icon/>Reach customers across every channel: telephone, email, live chat, and social media.</p>
                                    <p><Icon/>Get real-time notifications when customers interact with your business.</p>
                                    <p><Icon/>Measure the effectiveness of your customer communication and find the best time and channel to reach out to your customers.</p>
                                </ContentMain>
                            </ContentDivWrapper>
                        </ContentDiv>

                        <ContentDiv flex='column-reverse'  data-aos="zoom-in">
                            <ContentDivWrapper>
                                <ContentHead>
                                    <h3>Save more time with automation</h3>
                                </ContentHead>
                                <ContentMain>
                                    <p><Icon/>Automate every aspect of your business and cut out time-intensive, repetitive tasks.</p>
                                    <p><Icon/>Streamline your lead nurturing process and make the most of every incoming lead.</p>
                                    <p><Icon/>Trigger instant actions, stay on top of activities, and follow up better with workflows.</p>
                                </ContentMain>
                            </ContentDivWrapper>
                            <Image>
                                <ImageDiv>
                                    <img src={Image2} />
                                </ImageDiv>
                            </Image>
                        </ContentDiv>
                    </ContentWrapper>
                </Content>
            </Wrapper>
        </FavouriteSection>
    </Fragment>
}

export default Favourite;
