import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Bg from '../Images/hero-bg.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";

const Section = styled.section`
    width : 100%;
    height : auto;
    background : linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%), url(${Bg});
    position : relative;
    overflow : hidden;
    color : #fff;
    padding-bottom : 150px;
`;

const InnerSection = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
`;

const Svg = styled.div`
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    bottom: -150px;
`
const Heading = styled.h1`
    text-align : center;
    padding : 30px 0;
    font-size : 2rem;
`;

const ContentMainDiv  = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : space-between;

@media screen and (max-width : 768px) {
    flex-direction : column;
}
`;

const ContentDiv = styled.div`
    width : 100%;
    height : auto;
    padding : 10px;
    display : flex;
    flex-direction : column;
    justify-content : flex-start;
    align-items : center;

h2{
    text-align : center;
    padding : 10px 0;
}

p{
    text-align : center;
    padding : 10px 0;
    line-height : 30px;
}
`
const ContentNumber = styled.div`
    width : 50px;
    height : 50px;
    border-radius : 50%;
    background: black;
    display : flex;
    margin : 0 auto;
    justify-content : center;
    align-items : center;

p{
    color : #fff;
    font-size : 1rem;
}
`;

const WhyUs = () => {
    const animate = useRef(null);

    useEffect(() => {
        AOS.init({duration : 1000})
    }, []);

    return(
        <Fragment>
            <Section>
                    <Heading>Why LMV IT</Heading>
                <InnerSection>
                    <ContentMainDiv>
                        <ContentDiv data-aos="fade-up">
                            <ContentNumber ><p>01</p></ContentNumber>
                            <h2 >Expertise</h2>
                            <p >LMVIT Solutions  is a website design company in Hyderabad with a team that has a great deal of industrial experience in website design. Our experts bring a vibrant reality to you and to the audience. We always try to stay out of the crowd with our unique services.</p>
                        </ContentDiv>
                        <ContentDiv  data-aos="fade-up">
                            <ContentNumber><p>02</p></ContentNumber>
                            <h2>Support</h2>
                            <p>We guarantee you to give the best design which will provide you an engaging audience. We have professionals to every project who design a website that is fully personalized and user- friendly.</p>
                        </ContentDiv>
                        <ContentDiv  data-aos="fade-up" >
                            <ContentNumber><p>03</p></ContentNumber>
                            <h2>Implementation</h2>
                            <p>We have served plenty of companies with our creative ideas in web designing. Our services are affordable and reasonable. We mean what we offer you.</p>
                        </ContentDiv>  
                    </ContentMainDiv>
                </InnerSection>
                <Svg>
                <svg width="100%" height="355px" viewBox="0 0 1920 355" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                        <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
                    </g>
                    </g>
                </svg>
                </Svg>
            </Section>
        </Fragment>
    )

}

export default WhyUs;
