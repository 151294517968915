import React, {useRef, useEffect, useCallback} from 'react';
import { gsap, Power2 } from "gsap/all";
import styled from 'styled-components';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);




const WorkSection = styled.section`
    width : 100%;
    height : auto;
    display : flex;
    padding : 50px 0;
    justify-content : center;
    align-items : center;
    position : relative;

@media screen and (max-width: 768px){
    height : auto;
    display : flex;
}
`;

const SvgBg = styled.svg`
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%; 
    height: 100%;
    z-index : -20;
`

const PathBg = styled.path``;

const InnerSection = styled.div`
    width : 100%;
    height : auto;


@media screen and (max-width: 768px){
    padding : 15px 0;
}
`;


const Heading = styled.h1`
    text-align : center;
    font-family : "Merriweather";
    letter-spacing : 3px;
    margin-bottom : 10px;
    color : rgba(255, 255, 255)
`;

const Steps = styled.div`
    width : 100%;
    height : auto;
    padding : 15px;
    display : flex;
    justify-content : center;
    flex-wrap : wrap;
    overflow : hidden;


@media screen and (max-width: 768px){
    padding : 0px;
    margin-top : 20px;
}
`;  

const Step = styled.div`
    width : 300px;
    height : 150px;
    margin: 10px;
    padding : 10px;
    cursor : pointer;
    display : flex;
    justify-content : center;

@media screen and (max-width: 768px){
    width : 80%;
    justify-content : center;    
}
`;

const StepNum = styled.div`
    width : 30%;
    height : 100%;
    position : relative;

`

const Traingle = styled.div`
    width: 0; 
    height: 0;  
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-left:35px solid ${props => props.primary};
`;

const Number = styled.h3`
    position: absolute;
    border-radius : 50%;
    font-family : "Merriweather";
    padding : 5px;
    top : 18px;
    color : ${props => props.primary};
    left : -15px;
    background : rgba(255, 255, 255);
    box-shadow : 0 0 5px rgba(0, 0, 0)
`;

const Text = styled.div`
    width : 100%;    
`;
const Title = styled.h4`
    color : rgba(255, 255, 255);    
`;
const Paragraph = styled.p`
    font-size : 0.9rem;
    padding : 10px 0;
    color : rgba(255, 255, 255);
`;

const HowWeWork = (props) => {
    const WorkSectionRef = useRef(null);
    const PathRef = useRef(null);
    const StepsRef = useRef(null);

    useEffect(() => {
        const t1 = gsap.timeline();
        t1.to(PathRef.current, 0.8, {attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" }, ease: Power2.easeIn})
        .to(PathRef.current, 0.4, {attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" }, ease: Power2.easeOut})
        .from(StepsRef.current, {opacity: 0, ease: "ease-in"})

        ScrollTrigger.create({
            animation : t1, 
            trigger : '#work-section',
            start : "top 80%",
            toggleActions : "restart none none reset",
        });
    }, []);


    return(
        <WorkSection ref={WorkSectionRef} id='work-section'>
            <SvgBg viewBox="0 0 100 100" preserveAspectRatio="none">
                <PathBg ref={PathRef} id="How-we-work-bg" className="path" stroke="#000" stroke-width="2px" d="M 0 100 V 100 Q 50 100 100 100 V 100 z"/>
            </SvgBg>
            <InnerSection>
                <Heading>How we work</Heading>
                <Steps ref= {StepsRef} id="How-we-work-steps">
                    <Step>
                        <StepNum>
                            <Traingle primary="#3f3697"/>
                            <Number primary="#3f3697">01</Number>
                        </StepNum>
                        <Text>
                            <Title>Requirements Gathering</Title>
                            <Paragraph>Project Manager Prepares a requirement document</Paragraph>
                        </Text>
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#a0937d"/>
                            <Number primary="#a0937d">02</Number>
                        </StepNum>
                        <Text>
                            <Title>Analysis & Study</Title>
                            <Paragraph>System Analyst Study the technical aspects</Paragraph>
                        </Text>     
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#cf0000"/>
                            <Number primary="#cf0000">03</Number>
                        </StepNum>
                        <Text>
                            <Title>Meeting Online/Offline</Title>
                            <Paragraph>Project Manager meets with the client</Paragraph>
                        </Text>
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#aa2ee6"/>
                            <Number primary="#aa2ee6">04</Number>
                        </StepNum>
                        <Text>
                            <Title>Final Agreements</Title>
                            <Paragraph>Closures of project requirement documents</Paragraph>
                        </Text>
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#02475e"/>
                            <Number primary="#02475e">05</Number>
                        </StepNum>
                        <Text>
                            <Title>Work Begins</Title>
                            <Paragraph>Happy beginning of the project starts with the team</Paragraph> 
                        </Text>
                    </Step>
                    <Step>  
                        <StepNum>
                            <Traingle primary="#150e56"/>
                            <Number primary="#150e56">06</Number>
                        </StepNum>
                        <Text>
                            <Title>Development</Title>
                            <Paragraph>Developing the project within given time</Paragraph>
                        </Text>
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#2f5d62"/>
                            <Number primary="#2f5d62">07</Number>
                        </StepNum>
                        <Text>
                            <Title>Testing, Debugging and Testing</Title>
                            <Paragraph>Once completed send to QA for test and debug</Paragraph>
                        </Text>
                    </Step>
                    <Step>
                        <StepNum>
                            <Traingle primary="#864000"/>
                            <Number primary="#864000">08</Number>
                        </StepNum>
                        <Text>
                            <Title>Live on Production</Title>
                            <Paragraph>Get ready for launch. Brand your business</Paragraph>
                        </Text>
                    </Step>
                </Steps>
            </InnerSection>
        </WorkSection>
    )
}

export default HowWeWork;