import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { BiAlignRight } from 'react-icons/bi';
import LMVLogo from '../../Images/PNG/logo.png';

const Nav = styled.nav`
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  z-index: 100;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
`;

const NavLink = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
`;

const Logo = styled.div`
  ${NavLink}
  width : 150px;
  height: 75px;
  padding: 0 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 120px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const MenuBars = styled(BiAlignRight)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    width: 40px;
    color: #fff;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
`;

const ServicesDiv = styled.div`
  position: relative;
  width: 100%;
`;

const SubMenuDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  left: -5px;
  display: ${(props) => (props.open === true ? 'initial' : 'none')};
  animation: ${(props) => (props.open === true ? 'open' : 'close')} 0.3s ease;

  @keyframes open {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes clpse {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const SubMenuLinks = styled(Button)`
  width: 100%;
  height: 50px;
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = (props) => {
  const [open, setOpen] = useState(false);

  let history = useHistory();

  useEffect(() => {}, []);

  const onClickLogoHandler = () => {
    history.push('/');
  };
  return (
    <Nav>
      <Logo onClick={onClickLogoHandler}>
        <img src={LMVLogo} />
      </Logo>
      <MenuBars onClick={props.toggle} />
      <NavBtn>
        <Button to="/" primary="true">
          Home
        </Button>
        <ServicesDiv>
          <Button to="" primary="true" onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
            Services
          </Button>
          <SubMenuDiv open={open} onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
            <SubMenuLinks to="uiuxdesigning" primary="true">
              UI UX Designing
            </SubMenuLinks>
            <SubMenuLinks to="appdevelopment" primary="true">
              App Development
            </SubMenuLinks>
            <SubMenuLinks to="webdevelopment" primary="true">
              Web Development
            </SubMenuLinks>
            <SubMenuLinks to="digitalmarketing" primary="true">
              Digital Marketing
            </SubMenuLinks>
          </SubMenuDiv>
        </ServicesDiv>
        <Button to="about" primary="true">
          About Us
        </Button>
        <Button to="contact" primary="true">
          Let's Talk
        </Button>
      </NavBtn>
    </Nav>
  );
};

export default Navbar;
