import React, { Fragment } from 'react';
import styled from 'styled-components';


const Section = styled.section`
    width : 100%;
    height : auto;
    padding : 20px 10%;

@media screen and (max-width : 768px){
    padding : 20px 0;
}
`;

const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 15px 0;

h1{
    font-size : 2rem;
}
`;

const OurProcessDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-wrap : wrap;
`;

const Process = styled.div`
    width : 350px;
    height : auto;
    padding : 10px;
    margin : 10px;
    cursor : pointer;
    border-radius : 10px;
    box-shadow : 0 0 10px #000;
    transition : all 0.2s ease;
    background: rgba( 144, 135, 221, 0.60 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.0px );
    -webkit-backdrop-filter: blur( 4.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );

:hover{
    color : #9087DD;
    background : black; 
}

@media screen and (max-width: 768px) {
    padding : 15px 0;       
}
`;

const ProcessHeading = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 0;
    text-align : center;
`;

const ProcessText = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 0;
    text-align : center;
    line-height : 30px;
`;

const OurProcess = () => {


    return <Section>
        <Heading>
            <h1>OUR PROCESS</h1>
        </Heading>
        <OurProcessDiv>
            <Process data-aos="fade-up">
                <ProcessHeading>
                    <h4>PLANNING</h4>
                </ProcessHeading>
                <ProcessText>
                    <p>Understanding what you want out of your site and how do you plant to implement it.</p>
                </ProcessText>
            </Process>
            <Process data-aos="fade-up">
                <ProcessHeading>
                    <h4>DEVELOPMENT</h4>
                </ProcessHeading>
                <ProcessText>
                    <p>We develop content management systems for clients who need more than just the basics.</p>
                </ProcessText>
            </Process>
            <Process data-aos="fade-up">
                <ProcessHeading>
                    <h4>REVIEW and TEST</h4>
                </ProcessHeading>
                <ProcessText>
                    <p>Once the site is ready, it should be checked and tested to ensure an error free working.</p>
                </ProcessText>
            </Process >
            <Process data-aos="fade-up">
                <ProcessHeading>
                    <h4>LAUNCH</h4>
                </ProcessHeading>
                <ProcessText>
                    <p>After successful testing the product is delivered / deployed to the customer for their use.</p>
                </ProcessText>
            </Process>
            <Process data-aos="fade-up">
                <ProcessHeading>
                    <h4>MAINTENANCE</h4>
                </ProcessHeading>
                <ProcessText>
                    <p>It is an important step which makes sure that your site works with efficiency all the time.</p>
                </ProcessText>
            </Process>
        </OurProcessDiv>
    </Section>
}


export default OurProcess;