import React, { useEffect, Fragment} from 'react';
import Bg1 from '../../Images/PNG/Digital.png';
import WhyUs from '../WhyLmvIt';
import styled from 'styled-components';
import TimeToEnter from './DigitalMarketing/TimeToEnter';
import OurServices from './DigitalMarketing/OurServices';
import Footer from '../Footer';

// background : #b83e6c #D35C7D

const DigitalSection = styled.section`
    width: 100%;
    height: 100vh;
    box-sizing : border-box;
    overflow: hidden;
    background-image : url(${Bg1});
    background-position: center;
    object-fit : cover;
    padding : 0 10%;
    display : flex;
    justify-content : center;
    align-item : center;

@media screen and (max-width: 768px) {
    object-fit : cover;
    flex-direction : column;
    padding : 0 ;
}
`;

const BgImage1 = styled.img`
    width : 100%;
    height : 100%;
    position : absolute;
    z-index: -20;
`;



const UiUxDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px ) {

}

`;

const ImgDiv = styled.div`
    width : 100%;
    height : 100%;
    justify-content : center;
    align-items : center;
    display : flex;
    position : relative;

svg{
    width : 100%;
    height : auto;
    filter: drop-shadow(0px 0px 10px #000);
    transform : scale(1.5)
}

@media screen and (max-width : 768px ) {
    display : initial;
}
`;

const Heading = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content: center;    

`;

const HeadingDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    font-style: italic;
    justify-content : center;
    padding-top : 60px;
    align-item : center;
    flex-direction : column;
    text-align : center;


h1{
    font-size : 2rem;
    letter-spacing : 3px;
}

h5{
    text-align : center;
    margin-bottom : 15px;
}

p{
    font-size : 1.1rem;
    padding : 10px 0;
    font-weight : bold;
    line-height : 30px;
}

@media screen and (max-width : 768px ) {

h1{
    font-size : 1.5rem;
    padding : 10px 0;
    letter-spacing : 5px;
}

p{
    font-size : 0.9rem;
    padding : 10px 10px ;  
}
}
`;

const Img = styled.img`
    width : 100%;
    height : auto;
    height : auto;
    filter: drop-shadow(15px 15px 10px #000);
    transform : skew(-5deg) scale(1.5);

@media screen and (max-width : 768px ) {
    transform : skew(-5deg) scale(1.5);    
}
`;



const DigitalMarketing = () => {
    
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])


        return (
            <Fragment>
                <DigitalSection>
                    <BgImage1 src={Bg1} />
                    <Heading>
                        <HeadingDiv>
                            <h1 data-aos="fade-right">Digital Marketing</h1>
                            {/* <h5 data-aos="fade-right">Andriod Apps, IOS Apps, Web Based Apps....!</h5> */}
                            <p data-aos="fade-right">Do you want to accelerate your business growth and increase your business ROI? Well then, it’s time to contact LMV IT Solutions !</p>
                        </HeadingDiv>
                    </Heading>
                    <UiUxDiv >
                        <ImgDiv data-aos="zoom-in-left">
                            {/* <Img src={Image} /> */}
                            <Logo />
                        </ImgDiv>  
                    </UiUxDiv>
                </DigitalSection>
                <OurServices />
                <TimeToEnter />
                <WhyUs />
                <Footer />
            </Fragment>
            
        )
}

export default DigitalMarketing;


const Logo = () => {

    return <svg  width="100%" height="100%" version="1.1" viewBox="0 0 3508 2480">
     <defs>
       <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="1757.64" y1="827.369" x2="1771.03" y2="827.369">
        <stop offset="0" stopOpacity="1" stopColor="#796393"/>
        <stop offset="0.470588" stopOpacity="1" stopColor="#584073"/>
        <stop offset="1" stopOpacity="1" stopColor="#361D52"/>
       </linearGradient>
       <clipPath id="id1">
        <path d="M678 2128l0 -68c-28,11 -44,22 -44,34 0,12 16,23 44,34z"/>
       </clipPath>
       <linearGradient id="id2" gradientUnits="userSpaceOnUse" x1="1057.89" y1="1425.78" x2="1083.66" y2="1425.78">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="1036.41" y1="1391.19" x2="1077.98" y2="1391.19">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id4" gradientUnits="userSpaceOnUse" x1="1066.89" y1="1370.32" x2="1148.17" y2="1370.32">
        <stop offset="0" stopOpacity="1" stopColor="#FCEE99"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#FDCC87"/>
        <stop offset="1" stopOpacity="1" stopColor="#FEA975"/>
       </linearGradient>
       <linearGradient id="id5" gradientUnits="userSpaceOnUse" x1="1117.4" y1="1369.85" x2="1153.65" y2="1369.85">
        <stop offset="0" stopOpacity="1" stopColor="#FCEE99"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#FDCC87"/>
        <stop offset="1" stopOpacity="1" stopColor="#FEA975"/>
       </linearGradient>
       <linearGradient id="id6" gradientUnits="userSpaceOnUse" x1="1116.65" y1="1372.03" x2="1136.58" y2="1372.03">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id7" gradientUnits="userSpaceOnUse" x1="1203.58" y1="1633.05" x2="833.724" y2="1425.98">
        <stop offset="0" stopOpacity="1" stopColor="#60CDF9"/>
        <stop offset="0.521569" stopOpacity="1" stopColor="#5B94EA"/>
        <stop offset="1" stopOpacity="1" stopColor="#565ADB"/>
       </linearGradient>
       <linearGradient id="id8" gradientUnits="userSpaceOnUse" x1="1182.75" y1="1905.22" x2="762.912" y2="1848.48">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.239216" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id9" gradientUnits="userSpaceOnUse" x1="1007.96" y1="1916.93" x2="671.026" y2="1840.93">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.301961" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <radialGradient id="id10" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3392.32 -0 -0 -1809.26 -6.84352E+06 1.46457E+06)" cx="2017.95" cy="809.037" r="3.51627" fx="2017.95" fy="809.037">
        <stop offset="0" stopOpacity="1" stopColor="#D9D9D9"/>
        <stop offset="0.470588" stopOpacity="1" stopColor="#BFC7D4"/>
        <stop offset="1" stopOpacity="1" stopColor="#A6B6D0"/>
       </radialGradient>
       <linearGradient id="id11" gradientUnits="userSpaceOnUse" x1="1204.26" y1="1536.85" x2="831.964" y2="1522.18">
        <stop offset="0" stopOpacity="1" stopColor="#52AED4"/>
        <stop offset="0.521569" stopOpacity="1" stopColor="#4E7EC7"/>
        <stop offset="1" stopOpacity="1" stopColor="#494DBA"/>
       </linearGradient>
       <linearGradient id="id12" gradientUnits="userSpaceOnUse" x1="1115.34" y1="1592.2" x2="697.622" y2="1544.93">
        <stop offset="0" stopOpacity="1" stopColor="#60CDF9"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#5B94EA"/>
        <stop offset="1" stopOpacity="1" stopColor="#565ADB"/>
       </linearGradient>
       <linearGradient id="id13" gradientUnits="userSpaceOnUse" x1="793.642" y1="1799.33" x2="913.993" y2="1208.66">
        <stop offset="0" stopOpacity="1" stopColor="#60CDF9"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#5B94EA"/>
        <stop offset="1" stopOpacity="1" stopColor="#565ADB"/>
       </linearGradient>
       <linearGradient id="id14" gradientUnits="userSpaceOnUse" x1="682.538" y1="1889.02" x2="2592.54" y2="791.425">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.129412" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id15" gradientUnits="userSpaceOnUse" x1="910.774" y1="900.505" x2="2507.78" y2="1712.23">
        <stop offset="0" stopOpacity="1" stopColor="#60CDF9"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#5B94EA"/>
        <stop offset="1" stopOpacity="1" stopColor="#565ADB"/>
       </linearGradient>
       <linearGradient id="id16" gradientUnits="userSpaceOnUse" x1="1306.01" y1="1585.88" x2="1645.59" y2="1585.88">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id17" gradientUnits="userSpaceOnUse" x1="1306.01" y1="1650.73" x2="1987.79" y2="1650.73">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id18" gradientUnits="userSpaceOnUse" x1="1427.3" y1="1737.64" x2="1685.81" y2="1737.64">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id19" gradientUnits="userSpaceOnUse" x1="1427.3" y1="1786.2" x2="2015.15" y2="1786.2">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id20" gradientUnits="userSpaceOnUse" x1="1306.01" y1="1753.49" x2="1401.21" y2="1753.49">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id21" gradientUnits="userSpaceOnUse" x1="1427.3" y1="596.341" x2="1685.81" y2="596.341">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id22" gradientUnits="userSpaceOnUse" x1="1427.3" y1="644.89" x2="2015.15" y2="644.89">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id23" gradientUnits="userSpaceOnUse" x1="1306.01" y1="612.193" x2="1401.21" y2="612.193">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id24" gradientUnits="userSpaceOnUse" x1="1304.85" y1="1113.26" x2="2056.3" y2="1113.26">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id25" gradientUnits="userSpaceOnUse" x1="1935.69" y1="1456.19" x2="2014.93" y2="1456.19">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id26" gradientUnits="userSpaceOnUse" x1="1337.35" y1="1460.57" x2="1409.07" y2="1460.57">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id27" gradientUnits="userSpaceOnUse" x1="1437.76" y1="1456.57" x2="1513.85" y2="1456.57">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id28" gradientUnits="userSpaceOnUse" x1="1598.34" y1="1223.51" x2="1918.2" y2="1223.51">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id29" gradientUnits="userSpaceOnUse" x1="1394.71" y1="1139.14" x2="1661.18" y2="1139.14">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id30" gradientUnits="userSpaceOnUse" x1="1388.97" y1="1139.14" x2="1664.25" y2="1139.14">
        <stop offset="0" stopOpacity="1" stopColor="#E17988"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#BE5372"/>
        <stop offset="1" stopOpacity="1" stopColor="#9B2D5B"/>
       </linearGradient>
       <linearGradient id="id31" gradientUnits="userSpaceOnUse" x1="1396.18" y1="1061.63" x2="1765.46" y2="1061.63">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id32" gradientUnits="userSpaceOnUse" x1="1492.3" y1="986.802" x2="1766.79" y2="986.802">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id33" gradientUnits="userSpaceOnUse" x1="1393.91" y1="1061.63" x2="1766.5" y2="1061.63">
        <stop offset="0" stopOpacity="1" stopColor="#E6E6E6"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#CBD4E2"/>
        <stop offset="1" stopOpacity="1" stopColor="#B0C1DD"/>
       </linearGradient>
       <linearGradient id="id34" gradientUnits="userSpaceOnUse" x1="1492.3" y1="986.802" x2="1766.79" y2="986.802">
        <stop offset="0" stopOpacity="1" stopColor="#50535F"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#373E4B"/>
        <stop offset="1" stopOpacity="1" stopColor="#1D2936"/>
       </linearGradient>
       <linearGradient id="id35" gradientUnits="userSpaceOnUse" x1="1540.82" y1="711.699" x2="2129.68" y2="711.699">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id36" gradientUnits="userSpaceOnUse" x1="1492.3" y1="986.803" x2="1766.79" y2="986.803">
        <stop offset="0" stopOpacity="1" stopColor="#50535F"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#373E4B"/>
        <stop offset="1" stopOpacity="1" stopColor="#1D2936"/>
       </linearGradient>
       <linearGradient id="id37" gradientUnits="userSpaceOnUse" x1="1492.3" y1="986.802" x2="1766.79" y2="986.802">
        <stop offset="0" stopOpacity="1" stopColor="#484B56"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#323944"/>
        <stop offset="1" stopOpacity="1" stopColor="#1B2632"/>
       </linearGradient>
       <linearGradient id="id38" gradientUnits="userSpaceOnUse" x1="1534.18" y1="261.839" x2="1884.38" y2="2350.91">
        <stop offset="0" stopOpacity="1" stopColor="#57B9E1"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#5386D4"/>
        <stop offset="1" stopOpacity="1" stopColor="#4E52C6"/>
       </linearGradient>
       <linearGradient id="id39" gradientUnits="userSpaceOnUse" x1="1537.53" y1="711.698" x2="2188.32" y2="711.698">
        <stop offset="0" stopOpacity="1" stopColor="#E17988"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#BE5372"/>
        <stop offset="1" stopOpacity="1" stopColor="#9B2D5B"/>
       </linearGradient>
       <linearGradient id="id40" gradientUnits="userSpaceOnUse" x1="1662.03" y1="561.588" x2="2190.96" y2="561.588">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id41" gradientUnits="userSpaceOnUse" x1="1691.4" y1="557.223" x2="2167.73" y2="557.223">
        <stop offset="0" stopOpacity="1" stopColor="#767AA4"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#616587"/>
        <stop offset="1" stopOpacity="1" stopColor="#4B4F69"/>
       </linearGradient>
       <linearGradient id="id42" gradientUnits="userSpaceOnUse" x1="1672.91" y1="579.302" x2="2155.2" y2="579.302">
        <stop offset="0" stopOpacity="1" stopColor="#595B68"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#3D4452"/>
        <stop offset="1" stopOpacity="1" stopColor="#202D3B"/>
       </linearGradient>
       <linearGradient id="id43" gradientUnits="userSpaceOnUse" x1="1823.79" y1="596.945" x2="1974.12" y2="596.945">
        <stop offset="0" stopOpacity="1" stopColor="white"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#E1EBFA"/>
        <stop offset="1" stopOpacity="1" stopColor="#B83E6C"/>
       </linearGradient>
       <linearGradient id="id44" gradientUnits="userSpaceOnUse" x1="1885.21" y1="559.411" x2="1970.72" y2="559.411">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id45" gradientUnits="userSpaceOnUse" x1="3188.47" y1="1246.61" x2="2429.13" y2="2206.63">
       <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id46" gradientUnits="userSpaceOnUse" x1="2193.94" y1="2235.97" x2="2886.49" y2="1752.54">
        <stop offset="0" stopOpacity="1" stopColor="#e8728a"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#e8728a"/>
        <stop offset="1" stopOpacity="1" stopColor="#b83e6c"/>
       </linearGradient>
       <linearGradient id="id47" gradientUnits="userSpaceOnUse" x1="2787.71" y1="1463.61" x2="2003.71" y2="2139.09">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id48" gradientUnits="userSpaceOnUse" x1="2019.6" y1="1946.42" x2="2435" y2="1946.42">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id49" gradientUnits="userSpaceOnUse" x1="2916.87" y1="995.978" x2="2021.51" y2="1823.51">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id50" gradientUnits="userSpaceOnUse" x1="2132.79" y1="1529.78" x2="2548.19" y2="1529.78">
        <stop offset="0" stopOpacity="1" stopColor="#FA8696"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#D35C7D"/>
        <stop offset="1" stopOpacity="1" stopColor="#AC3164"/>
       </linearGradient>
       <linearGradient id="id51" gradientUnits="userSpaceOnUse" x1="1212.02" y1="1873.45" x2="1289.16" y2="2089.46">
        <stop offset="0" stopOpacity="1" stopColor="#00DCAC"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#01AB98"/>
        <stop offset="1" stopOpacity="1" stopColor="#027984"/>
       </linearGradient>
       <linearGradient id="id52" gradientUnits="userSpaceOnUse" x1="1349.17" y1="1956.7" x2="1273.19" y2="2158.81">
        <stop offset="0" stopOpacity="1" stopColor="#00DCAC"/>
        <stop offset="0.478431" stopOpacity="1" stopColor="#01AB98"/>
        <stop offset="1" stopOpacity="1" stopColor="#027984"/>
       </linearGradient>
       <linearGradient id="id53" gradientUnits="userSpaceOnUse" x1="1157.28" y1="2019.53" x2="1235.18" y2="2129.89">
        <stop offset="0" stopOpacity="1" stopColor="#00DCAC"/>
        <stop offset="0.458824" stopOpacity="1" stopColor="#01AB98"/>
        <stop offset="1" stopOpacity="1" stopColor="#027984"/>
       </linearGradient>
     </defs>
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path fill="#CCD3E4" d="M936 2177c0,-1 0,0 1,-1 1,0 2,0 3,0 1,0 3,0 5,0l-9 1zm1032 -63l-575 0c-33,0 -67,-13 -92,-32l-11 -8c-5,-4 -13,-12 -17,-18 -11,-14 -20,-27 -28,-44l-7 -20c0,-2 -1,-6 -2,-7 -12,0 -25,2 -37,2l-101 7c-20,3 -43,3 -64,6 -17,2 -38,3 -55,5 -35,5 -71,8 -106,13 -45,6 -91,13 -135,24 -11,3 -21,5 -32,8 -4,2 -9,3 -15,5 -5,1 -9,4 -15,5l0 68c3,0 11,3 14,4 13,4 27,8 42,12 66,16 149,27 218,35 5,1 10,1 16,2l16 2c11,0 22,2 33,3l100 8c10,1 23,3 34,3 5,0 12,1 17,1 5,1 11,1 17,1 22,2 44,3 67,4l17 2c3,0 6,0 9,0 3,0 5,1 8,1l52 2c3,1 6,0 9,0 4,0 5,1 8,1 3,0 7,0 10,0 14,1 29,2 43,2l26 1c4,0 5,0 9,1l87 2c8,1 18,0 27,0 3,0 4,1 8,1 6,0 12,0 18,0 7,0 11,1 18,1 24,0 47,0 71,0 13,0 23,1 36,1l3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 2 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 2 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 3 0 151 -3c13,0 24,0 37,0 7,0 12,-1 18,-1l19 -1c6,0 14,1 19,0 6,-1 13,0 19,-1 12,-1 25,0 37,-1 4,0 5,-1 9,-1 18,0 37,-1 55,-2 7,0 19,-1 28,-1 4,0 5,-1 9,-1l18 0c4,0 5,-1 9,-1 7,0 12,-1 19,-1 3,0 4,-1 8,-1 12,0 34,-2 45,-2l108 -8c13,0 40,-3 53,-5 3,0 7,0 9,0 20,-3 40,-4 61,-7 12,-1 23,-2 35,-3 22,-3 46,-6 68,-9 45,-7 88,-14 131,-24 20,-4 104,-24 104,-50l0 -3c0,-15 -30,-27 -42,-32 -80,-32 -261,-50 -345,-59l-54 -5c-10,-1 -33,-2 -42,-3 -9,-2 -18,-1 -28,-2 -6,-1 -14,-1 -20,-2 -3,0 -5,0 -8,0 -11,-2 -24,-2 -35,-3 -2,0 -5,0 -7,0 -4,0 -10,0 -14,-1l-71 -4c-4,0 -3,0 -7,0 -2,-1 -5,0 -7,0l-15 -1c-3,0 -3,-1 -6,-1l-36 -1c-10,0 -20,-1 -29,-2 -5,0 -10,1 -15,0 -6,-1 -52,-2 -58,-2 0,15 -12,44 -19,56 -1,2 -2,4 -3,6l-12 16c-6,7 -12,13 -18,19 -2,2 -3,3 -5,4l-28 19c-19,12 -47,20 -73,20z"/>
      <path fill="#565064" d="M945 2176c-2,0 -4,0 -5,0 -1,0 -2,0 -3,0 -1,1 -1,0 -1,1l9 -1z"/>
      <path fill="url(#id0)" d="M1771 827c0,4 -3,7 -7,7 -3,0 -6,-3 -6,-7 0,-3 3,-6 6,-6 4,0 7,3 7,6z"/>
      <g clipPath="url(#id1)">
       <image x="633" y="2055" width="45.5652" height="83.4812" xlinkHref="Digital Marketing _Images\Digital Marketing _ImgID1.png"/>
      </g>
      <polygon fill="#FDA893" fillRule="nonzero" points="897,1466 931,1466 942,1386 891,1386 "/>
      <path fill="#FDA893" fillRule="nonzero" d="M964 1384c0,0 0,1 0,1 -1,9 -1,19 -7,26 -4,5 -11,8 -18,9 -16,2 -32,-7 -41,-19 -10,-12 -13,-28 -13,-43 -1,-12 1,-26 10,-36 14,-16 46,-18 60,-1 7,7 9,16 10,26 1,12 0,25 -1,37z"/>
      <path fill="#D78F7D" fillRule="nonzero" d="M930 1420c-7,0 -13,-3 -18,-6 -4,-2 -7,-5 -9,-8 7,8 17,13 28,14 0,0 -1,0 -1,0z"/>
      <path fill="#D78F7D" fillRule="nonzero" d="M931 1420c-11,-1 -21,-6 -28,-14 -2,-2 -4,-4 -5,-7 4,5 9,10 15,13 5,4 11,6 18,8 0,0 0,0 0,0z"/>
      <path fill="#333333" fillRule="nonzero" d="M958 1293c-5,0 -9,3 -13,6 -4,3 -8,6 -13,8 -7,2 -14,1 -22,-1 -6,-1 -13,-1 -19,1 -7,2 -11,10 -14,15 -2,4 -20,-5 -16,30 1,7 13,26 16,29 3,4 12,13 16,13 7,-2 6,-17 7,-24 2,-6 2,-14 7,-19 3,-5 10,-8 16,-9 7,0 13,1 20,1 6,1 13,1 19,-2 10,-4 1,-48 -4,-48z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M891 1371c-1,-3 -1,-7 1,-10 1,-2 4,-4 7,-4 2,0 5,1 7,3 2,3 2,6 2,9 0,2 0,4 -1,6 -1,4 -5,6 -9,4 -3,-1 -5,-5 -7,-8z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M921 1438l96 96 -24 4 63 -105 17 8 -53 111c-4,7 -12,10 -19,7 -2,-1 -3,-1 -3,-2l-2 -1 -103 -88 28 -30z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M1055 1435c2,-6 6,-12 11,-16 2,6 4,11 5,17 1,3 2,7 0,9 -2,2 -5,2 -7,1 -5,-3 -7,-7 -9,-11z"/>
      <path fill="url(#id2)" d="M1076 1404l-9 2 -9 3c4,0 6,8 7,14 1,6 2,12 4,18l0 2c1,3 5,6 9,6 5,-1 7,-6 6,-10l0 -2c-2,-6 -4,-12 -6,-18 -2,-5 -5,-13 -2,-15z"/>
      <path fill="url(#id3)" d="M1078 1407l-28 8c-2,1 -4,0 -5,-2l-9 -33c0,-2 1,-4 3,-5l28 -8 11 40z"/>
      <path fill="url(#id4)" d="M1067 1367c0,0 41,-21 53,-48l28 103c0,0 -43,-22 -70,-15l-11 -40z"/>
      <path fill="url(#id5)" d="M1149 1366c8,28 9,53 1,55 -8,3 -20,-19 -28,-47 -8,-29 -8,-54 -1,-56 8,-2 21,20 28,48z"/>
      <path fill="url(#id6)" d="M1128 1384l-4 1 -7 -26 4 -1c7,-2 15,2 16,9 2,7 -2,15 -9,17z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M1067 1431c0,-1 0,-2 1,-3 -1,0 -3,-5 -3,-6 -1,-1 -1,-3 0,-5 3,-3 9,-6 13,-4 1,1 2,2 3,3 2,3 4,7 5,11 1,4 0,8 -2,11 -2,3 -7,5 -11,4 -1,0 -3,-1 -3,-3 -1,-1 0,-3 2,-4 -2,0 -4,-2 -5,-4z"/>
      <path fill="url(#id7)" d="M985 1552c-2,0 -5,-2 -8,-5 -13,-11 -26,-22 -40,-33 -7,-6 -15,-12 -23,-19 -7,-6 -15,-13 -17,-23 0,-1 0,-1 0,-2 0,-2 0,-5 1,-7 2,-6 5,-11 9,-15 4,-6 9,-11 16,-12 1,0 2,0 3,0 3,0 6,1 9,2 1,1 1,1 2,2 1,7 4,16 7,23 2,8 4,15 6,22 2,7 3,15 4,22 1,8 1,16 3,24 2,-8 3,-16 3,-24 1,-8 1,-16 -1,-24 -3,-8 -6,-15 -9,-22 -3,-6 -7,-14 -12,-20 4,2 6,4 9,7 21,22 40,45 60,68 0,0 0,0 0,0 2,2 4,5 4,8 0,3 -2,5 -3,7 -4,6 -9,12 -15,16 -1,1 -2,2 -4,3 -1,1 -3,2 -4,2z"/>
      <polygon fill="#FDA893" fillRule="nonzero" points="833,1664 817,1875 703,2049 703,2049 703,2049 718,2061 760,2011 760,2011 857,1896 860,1893 861,1886 907,1675 "/>
      <path fill="#FDA893" fillRule="nonzero" d="M1021 2104l6 0 -7 -64 0 -2 -6 -63 -5 -64 -6 -64 0 -1 -2 -4 -15 -47c-5,-16 -10,-31 -15,-47 -10,-31 -20,-63 -30,-94l-68 28c14,29 29,58 43,88 8,15 15,30 22,44l21 44 12 59 12 63 12 60 0 0 1 3 12 63 0 0 0 0 13 -2z"/>
      <path fill="#333333" fillRule="nonzero" d="M1006 2103c0,0 1,3 1,8 1,4 5,8 8,8l42 0c6,0 9,-9 5,-14 -4,-6 -14,-12 -36,-8l-20 6z"/>
      <path fill="#333333" fillRule="nonzero" d="M703 2049c0,0 -2,3 -5,7 -2,4 -2,8 0,11l29 31c4,4 13,0 14,-7 1,-8 -2,-19 -20,-32l-18 -10z"/>
      <path fill="url(#id8)" d="M1015 1874c-1,-18 -4,-36 -10,-54l0 0 -37 -130 -96 8 63 139 4 12c3,9 6,18 8,27l52 207 28 -6 -12 -203z"/>
      <path fill="url(#id9)" d="M915 1688l-97 3 -11 150 0 23c0,5 -2,11 -5,16l-91 149 23 20 122 -134c11,-12 18,-27 21,-42l5 -29 33 -156z"/>
      <path fill="url(#id10)" d="M938 1441c0,-1 -1,-1 -1,-1 0,-1 0,-1 0,-1 0,0 1,1 1,2z"/>
      <path fill="url(#id11)" d="M957 1531c-2,-8 -2,-16 -3,-24 -1,-7 -2,-15 -4,-22 -2,-7 -4,-14 -6,-22 -3,-7 -6,-16 -7,-23 0,0 1,0 1,1 5,6 9,14 12,20 3,7 6,14 9,22 2,8 2,16 1,24 0,8 -1,16 -3,24z"/>
      <path fill="url(#id12)" d="M894 1422c0,0 7,17 41,12 0,0 22,47 22,66 -1,19 1,73 1,73 0,0 6,86 13,116 0,3 -1,5 -4,6 -18,7 -91,31 -149,-1 -2,-1 -3,-3 -2,-6l12 -111 17 -117c0,0 22,-32 49,-38z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M735 1636c-2,2 -3,5 -4,8 -1,3 -1,6 -2,9 0,3 -1,7 -3,10 -1,3 -2,5 -4,6 -1,1 -2,1 -2,1 -2,0 -3,-1 -3,-3 0,-1 1,-2 1,-4 1,-1 0,-2 -1,-3 -3,4 -6,9 -9,13 -2,2 -3,3 -5,5 0,1 -1,2 -2,2 -1,0 -2,0 -3,-1 0,-1 0,-2 1,-3 2,-3 5,-7 7,-10 1,-1 3,-3 1,-3 -4,3 -7,7 -10,11 -2,2 -3,3 -5,3 -1,1 -2,0 -2,-1 -1,-1 0,-2 0,-2 4,-5 8,-10 11,-14 1,-1 1,-1 1,-1 0,-1 0,-2 -1,-2 0,0 -1,1 -1,2 -2,1 -3,2 -4,3 -2,2 -4,4 -7,6 -1,0 -1,1 -2,1 -1,0 -2,0 -2,-1 -1,0 -1,-1 -1,-2 1,-1 1,-2 2,-3 4,-4 7,-8 11,-11 1,-1 1,-1 1,-1 0,-1 0,-2 0,-2 -5,2 -9,4 -14,5 0,0 -1,0 -2,-1 0,0 0,0 0,-1 0,0 1,-1 1,-1 2,-2 5,-3 8,-4 3,-1 5,-3 7,-5 3,-1 4,-3 6,-4 4,-3 9,-5 13,-7 3,0 5,-3 7,-5 4,2 8,6 11,10z"/>
      <path fill="#FDA893" fillRule="nonzero" d="M902 1469l-89 86c-8,7 -15,15 -22,23l-21 23 -42 45 -14 -11 38 -51 18 -25 10 -12c1,-2 3,-4 5,-6l5 -6 82 -94 30 28z"/>
      <path fill="url(#id13)" d="M855 1450c6,-8 14,-16 25,-17 8,-1 16,4 22,10 6,4 11,9 12,16 1,9 -6,17 -12,24 -22,21 -45,40 -68,60 -2,2 -5,4 -8,4 -3,0 -5,-2 -8,-3 -5,-5 -11,-10 -15,-15 -6,-7 -6,-9 0,-16 11,-14 22,-27 33,-40 6,-8 12,-15 19,-23z"/>
      <path fill="url(#id14)" d="M1962 2115l-563 0c-44,0 -84,-18 -114,-46 -24,-22 -41,-51 -49,-84 -2,-12 -4,-24 -4,-37l0 -1400c0,-46 19,-88 49,-118 30,-30 72,-49 118,-49l563 0c48,0 90,20 121,52 28,30 46,70 46,115l0 176 -31 -25 0 -157c0,-31 -12,-59 -32,-80 -21,-22 -51,-36 -84,-36l-114 0c-6,24 -28,41 -54,41l-39 0 -138 -79c0,28 -1,54 -3,79l-87 0c-26,0 -48,-17 -54,-41l-114 0c-31,0 -59,12 -80,32 -23,21 -36,51 -36,84l0 1415c0,32 13,61 34,82 21,21 50,34 82,34l603 0c36,0 68,-16 89,-41 17,-21 27,-47 27,-75l0 -1145c10,-5 20,-9 31,-13l0 390 0 554 0 205c0,9 -1,18 -3,26 -5,38 -24,71 -50,95 -30,28 -70,46 -114,46z"/>
      <path fill="url(#id15)" d="M1982 2073l-603 0c-32,0 -61,-13 -82,-34 -21,-21 -34,-50 -34,-82l0 -1415c0,-33 13,-63 36,-84 21,-20 49,-32 80,-32l114 0c6,24 28,41 54,41l87 0c-4,64 -11,121 -20,169l-180 0c-4,0 -7,3 -7,7l0 4c0,3 3,6 7,6l177 0c-3,15 -6,29 -9,43l-263 0c-10,0 -18,4 -24,10 -6,6 -10,14 -10,24l0 767c0,9 4,18 10,24 6,6 14,10 24,10l683 0c10,0 18,-4 25,-10 6,-7 9,-15 9,-24l0 -666c14,-6 28,-13 42,-19l0 1145c0,28 -10,54 -27,75 -21,25 -53,41 -89,41zm116 -1374l-168 -142 -23 -14c0,0 0,0 0,0l-132 -76 39 0c26,0 48,-17 54,-41l114 0c33,0 63,14 84,36 20,21 32,49 32,80l0 157z"/>
      <path fill="url(#id16)" d="M1631 1567l-310 0c-8,0 -15,6 -15,15l0 8c0,8 7,15 15,15l310 0c8,0 15,-7 15,-15l0 -8c0,-9 -7,-15 -15,-15z"/>
      <path fill="url(#id17)" d="M1969 1632l-644 0c-10,0 -19,8 -19,19 0,10 9,19 19,19l644 0c10,0 19,-9 19,-19 0,-11 -9,-19 -19,-19z"/>
      <path fill="url(#id18)" d="M1661 1713l-209 0c-14,0 -25,11 -25,25 0,13 11,25 25,25l209 0c14,0 25,-12 25,-25 0,-14 -11,-25 -25,-25z"/>
      <path fill="url(#id19)" d="M2007 1778l-571 0c-5,0 -9,3 -9,8 0,5 4,9 9,9l571 0c4,0 8,-4 8,-9 0,-5 -4,-8 -8,-8z"/>
      <path fill="url(#id20)" d="M1401 1753c0,27 -21,48 -47,48 -27,0 -48,-21 -48,-48 0,-26 21,-47 48,-47 26,0 47,21 47,47z"/>
      <path fill="url(#id21)" d="M1661 571l-209 0c-14,0 -25,12 -25,25 0,14 11,25 25,25l209 0c14,0 25,-11 25,-25 0,-13 -11,-25 -25,-25z"/>
      <path fill="url(#id22)" d="M1611 653l-177 0c-4,0 -7,-3 -7,-6l0 -4c0,-4 3,-7 7,-7l180 0c-1,6 -2,12 -3,17z"/>
      <path fill="url(#id23)" d="M1401 612c0,26 -21,48 -47,48 -27,0 -48,-22 -48,-48 0,-26 21,-47 48,-47 26,0 47,21 47,47z"/>
      <path fill="url(#id24)" d="M2022 1531l-683 0c-10,0 -18,-4 -24,-10 -6,-6 -10,-15 -10,-24l0 -767c0,-10 4,-18 10,-24 6,-6 14,-10 24,-10l263 0c-29,118 -63,176 -63,176l0 0c0,0 0,0 -1,1l-142 236c-4,5 -1,15 8,28l-14 23c-7,9 30,54 67,80 4,3 8,5 12,8 -59,14 -96,34 -96,56 0,44 140,80 313,82 4,0 8,0 12,0 167,0 301,-33 301,-76 1,-25 -46,-48 -119,-63l-183 -129 10 -13 0 0 60 -73 0 0c0,0 83,-101 289,-201l0 666c0,9 -3,17 -9,24 -7,6 -15,10 -25,10zm-416 -301l27 -34 46 32c-2,0 -3,0 -4,0 -24,0 -47,1 -69,2z"/>
      <path fill="url(#id25)" d="M1984 1493l-8 -35 35 -36 -27 71zm-45 -46l70 -26 -35 35 -35 -9zm75 -30c-1,-1 -2,-1 -3,0l-74 28c-1,0 -1,1 -1,2 0,1 0,3 2,3l35 8 8 36c1,1 2,2 3,2l0 0c1,0 2,-1 3,-2l28 -74c0,-1 0,-2 -1,-3z"/>
      <path fill="url(#id26)" d="M1413 1450c11,-24 -14,-44 -40,-26 -26,-18 -50,2 -40,26 2,4 4,8 7,11l33 34 0 0 0 0 33 -34c3,-3 6,-7 7,-11z"/>
      <path fill="url(#id27)" d="M1489 1484l15 7 -5 -13 1 -1c6,-6 9,-14 9,-23 0,-18 -15,-33 -33,-33 -18,0 -33,15 -33,33 0,19 15,33 33,33 4,0 8,-1 12,-2l1 -1zm17 13c-1,0 -1,0 -2,-1l-15 -6c-4,2 -9,2 -13,2 -21,0 -38,-17 -38,-38 0,-21 17,-38 38,-38 21,0 38,17 38,38 0,10 -3,18 -9,25l5 12c0,2 0,3 -1,5 -1,0 -2,1 -3,1z"/>
      <path fill="#D1D5D7" d="M1999 1310c0,44 -141,78 -313,76 -173,-2 -313,-38 -313,-82 1,-44 141,-78 314,-76 173,2 313,38 312,82z"/>
      <path fill="url(#id28)" d="M1867 1355c-5,0 -9,-1 -13,-4l-175 -123 -46 -32 64 -78 183 129 32 22c10,7 12,21 5,30l-33 47c-4,6 -11,9 -17,9z"/>
      <path fill="#AFBDC1" d="M1456 1048c6,-13 13,-26 20,-38 1,-3 1,-5 -1,-8 -1,-2 -3,-3 -6,-3l-38 -1c-3,0 -5,2 -7,4l-24 41c-1,2 -1,5 0,7l19 34c1,2 4,3 6,3 3,0 5,-1 7,-3 8,-12 16,-24 24,-36z"/>
      <path fill="url(#id29)" d="M1539 1280c-13,0 -42,-14 -70,-32 -4,-3 -8,-5 -12,-8 -37,-26 -74,-71 -67,-80l14 -23c13,19 39,43 72,66 4,3 8,6 12,8 -2,10 -7,22 -16,38 28,16 55,31 65,31 0,0 0,-1 1,-1l18 -33c5,2 9,3 12,4l-22 28c-1,1 -4,2 -7,2z"/>
      <path fill="url(#id30)" d="M1537 1280c-10,0 -37,-15 -65,-31 9,-16 14,-28 16,-38 25,17 49,29 68,35l-18 33c-1,0 -1,1 -1,1z"/>
      <path fill="url(#id31)" d="M1580 1251c-3,0 -7,0 -12,-1 -3,-1 -7,-2 -12,-4 -19,-6 -43,-18 -68,-35 -4,-2 -8,-5 -12,-8 -33,-23 -59,-47 -72,-66 -9,-13 -12,-23 -8,-28l142 -236c0,0 0,1 0,1l-46 75c10,49 90,118 157,145l-59 85c-21,29 -49,41 -78,41 -4,0 -8,-1 -12,-1 34,20 64,32 80,32 6,0 10,-1 13,-4 -3,3 -7,4 -13,4z"/>
      <path fill="url(#id32)" d="M1707 1105l59 -73c0,0 0,0 0,0 0,0 0,0 1,0l0 0 -60 73 0 0zm60 -73l0 0zm-118 62c-67,-27 -147,-96 -157,-145l46 -75c-4,17 37,62 97,103 24,17 47,31 68,41 0,0 0,0 0,0l-54 76zm54 -76l0 0z"/>
      <path fill="#BCC0C2" fillRule="nonzero" d="M1593 1247c0,0 0,0 0,0l13 -17 0 0 -13 17c0,0 0,0 0,0z"/>
      <path fill="url(#id33)" d="M1580 1251c-16,0 -46,-12 -80,-32 4,0 8,1 12,1 29,0 57,-12 78,-41l59 -85c18,7 35,12 50,12 3,0 5,0 8,-1l-10 13 -64 78 -27 34 -13 17c0,0 0,0 0,0 -3,3 -7,4 -13,4z"/>
      <path fill="url(#id34)" d="M1699 1106c-15,0 -32,-5 -50,-12l54 -76c22,11 41,18 53,18 4,0 8,-2 10,-4l-59 73 0 0c-3,1 -5,1 -8,1z"/>
      <path fill="url(#id35)" d="M1766 1032l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 -1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0zm-63 -14c-21,-10 -44,-24 -68,-41 -60,-41 -101,-86 -97,-103 0,0 0,-1 0,-1 1,-1 1,-1 1,-1 0,0 34,-58 63,-176 3,-14 6,-28 9,-43 1,-5 2,-11 3,-17 9,-48 16,-105 20,-169 2,-25 3,-51 3,-79l138 79 132 76c-59,-9 -98,-14 -98,-14 0,0 133,148 52,263l-158 226zm-164 -146l0 0z"/>
      <path fill="url(#id36)" d="M1766 1032l0 0c0,0 0,0 0,0 0,0 0,0 0,0zm-63 -14c0,0 0,0 0,0l0 0c0,0 0,0 0,0l0 0z"/>
      <path fill="url(#id37)" d="M1756 1036c-12,0 -31,-7 -53,-18l0 0c22,11 41,18 53,18 4,0 8,-2 10,-4l0 0c-2,2 -6,4 -10,4z"/>
      <path fill="url(#id38)" d="M1930 557l-23 -14c0,0 0,0 0,0l23 14z"/>
      <path fill="url(#id39)" d="M1756 1036c-12,0 -31,-7 -53,-18l0 0 158 -226c81,-115 -52,-263 -52,-263 0,0 39,5 98,14l23 14 258 218c-8,3 -16,6 -24,9 -286,112 -397,248 -397,248l0 0c-1,0 -1,0 -1,0 0,0 0,0 0,0 -2,2 -6,4 -10,4z"/>
      <path fill="url(#id40)" d="M1644 363c-35,49 64,178 220,287 156,110 311,159 345,110 35,-49 -64,-178 -220,-287 -156,-110 -311,-159 -345,-110z"/>
      <path fill="url(#id41)" d="M1676 379c-28,39 64,150 204,248 141,99 276,147 304,109 27,-39 -65,-150 -205,-249 -141,-98 -276,-147 -303,-108z"/>
      <path fill="url(#id42)" d="M1673 410c14,52 95,139 207,217 113,79 222,126 275,122 -14,-52 -95,-139 -207,-218 -113,-79 -222,-125 -275,-121z"/>
      <path fill="url(#id43)" d="M1936 667l38 -76 -46 -32 -45 -32 -59 61c17,14 35,28 55,42 19,13 38,26 57,37z"/>
      <path fill="url(#id44)" d="M1882 527c-5,8 11,29 36,47 25,18 50,25 56,17 5,-7 -11,-28 -36,-46 -25,-18 -50,-26 -56,-18z"/>
      <path fill="url(#id45)" d="M2824 2118l-298 0c-40,0 -73,-33 -73,-73l0 -298c0,-40 33,-73 73,-73l298 0c40,0 73,33 73,73l0 298c0,40 -33,73 -73,73z"/>
      <path fill="url(#id46)" d="M2795 2118l-269 0c-40,0 -73,-33 -73,-73l0 -269c0,-41 33,-73 73,-73l269 0c41,0 73,32 73,73l0 269c0,40 -32,73 -73,73z"/>
      <path fill="white" fillRule="nonzero" d="M2794 1976c0,16 -3,25 -5,31 -6,14 -17,25 -31,31 -6,2 -15,5 -31,5 -17,1 -22,1 -66,1 -43,0 -49,0 -66,-1 -16,0 -24,-3 -30,-5 -7,-3 -14,-7 -19,-12 -5,-6 -9,-12 -12,-19 -2,-6 -5,-15 -6,-31 0,-17 -1,-22 -1,-66 0,-43 1,-48 1,-66 1,-16 4,-24 6,-30 3,-7 7,-14 12,-19 6,-5 12,-9 19,-12 6,-2 14,-5 30,-6 18,0 23,-1 66,-1 44,0 49,1 66,1 16,1 25,4 31,6 7,3 13,7 19,12 5,6 9,12 12,19 2,6 5,14 5,30 1,18 1,23 1,66 0,44 0,49 -1,66zm30 -133c-1,-18 -4,-29 -8,-40 -4,-11 -10,-21 -19,-29 -8,-8 -18,-15 -29,-19 -10,-4 -22,-6 -39,-7 -18,-1 -23,-1 -68,-1 -44,0 -50,0 -67,1 -17,0 -29,3 -40,7 -11,4 -21,11 -29,19 -8,8 -15,18 -19,29 -4,11 -6,22 -7,40 -1,17 -1,23 -1,67 0,45 0,50 1,68 0,17 3,29 7,39 4,11 11,21 19,29 8,9 18,15 29,19 11,4 22,7 40,8 17,1 23,1 67,1 45,0 50,0 68,-1 17,-1 29,-4 39,-8 22,-8 40,-26 48,-48 4,-10 7,-22 8,-39 0,-18 1,-23 1,-68 0,-44 0,-50 -1,-67z"/>
      <path fill="white" fillRule="nonzero" d="M2661 1965c-30,0 -54,-25 -54,-55 0,-30 24,-54 54,-54 30,0 55,24 55,54 0,30 -25,55 -55,55zm0 -139c-46,0 -84,38 -84,84 0,47 38,84 84,84 47,0 84,-37 84,-84 0,-46 -37,-84 -84,-84z"/>
      <path fill="white" fillRule="nonzero" d="M2768 1823c0,11 -9,20 -19,20 -11,0 -20,-9 -20,-20 0,-11 9,-20 20,-20 10,0 19,9 19,20z"/>
      <path fill="url(#id47)" d="M2095 2154l298 0c40,0 73,-33 73,-73l0 -298c0,-40 -33,-73 -73,-73l-298 0c-40,0 -73,33 -73,73l0 298c0,40 33,73 73,73z"/>
      <path fill="url(#id48)" d="M2093 2154l269 0c40,0 73,-33 73,-73l0 -269c0,-41 -33,-73 -73,-73l-269 0c-41,0 -73,32 -73,73l0 269c0,40 32,73 73,73z"/>
      <path fill="white" fillRule="nonzero" d="M2372 1864c-10,5 -22,8 -34,9 12,-7 22,-19 26,-33 -11,7 -24,12 -38,15 -11,-12 -26,-19 -43,-19 -33,0 -60,27 -60,60 0,4 0,9 1,13 -49,-2 -93,-26 -123,-62 -5,9 -8,19 -8,30 0,21 11,39 27,50 -10,0 -19,-3 -27,-8 0,1 0,1 0,1 0,29 20,53 48,59 -5,1 -11,2 -16,2 -4,0 -8,-1 -11,-1 7,23 29,41 55,41 -20,16 -46,26 -74,26 -5,0 -9,0 -14,-1 27,17 58,27 92,27 110,0 170,-91 170,-170 0,-3 0,-5 -1,-8 12,-8 22,-19 30,-31z"/>
      <path fill="url(#id49)" d="M2504 1737l-298 0c-40,0 -73,-32 -73,-73l0 -297c0,-41 33,-73 73,-73l298 0c40,0 73,32 73,73l0 297c0,41 -33,73 -73,73z"/>
      <path fill="url(#id50)" d="M2475 1737l-269 0c-40,0 -73,-32 -73,-73l0 -269c0,-40 33,-73 73,-73l269 0c40,0 73,33 73,73l0 269c0,41 -33,73 -73,73z"/>
      <path fill="white" fillRule="nonzero" d="M2382 1417l31 0 0 -54c-5,-1 -24,-3 -45,-3 -45,0 -76,29 -76,80l0 48 -49 0 0 61 49 0 0 153 61 0 0 -153 48 0 7 -61 -55 0 0 -41c0,-18 5,-30 29,-30z"/>
      <path fill="url(#id51)" d="M1300 2087c0,0 -4,-164 -61,-176 -56,-12 -64,102 0,215l61 -39z"/>
      <path fill="white" fillRule="nonzero" d="M1270 2106c-27,-110 -38,-151 -38,-151l2 -1c0,1 11,41 38,152l-2 0z"/>
      <path fill="url(#id52)" d="M1320 2126c0,0 86,-83 54,-123 -32,-41 -113,29 -135,123l81 0z"/>
      <path fill="white" fillRule="nonzero" d="M1287 2115l-1 -1c21,-49 63,-89 63,-89l1 1c0,1 -42,40 -63,89z"/>
      <path fill="url(#id53)" d="M1247 2126c0,0 -22,-94 -66,-86 -44,8 0,86 0,86l66 0z"/>
      <polygon fill="white" fillRule="nonzero" points="1203,2114 1188,2059 1190,2058 1205,2113 "/>
     </g>
    </svg>
}