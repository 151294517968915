import React, {useEffect, useState, Fragment} from 'react';
import Bg1 from '../../Images/PNG/Artboard.png';
import styled from 'styled-components';
import { gsap } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import WhyUs from '../WhyLmvIt';
import UXdesign from './Ui_Ux/UXdesign';
import UIdesign from './Ui_Ux/UIdesign';
import VWdesign from './Ui_Ux/VWdesign';
import UiUx from './Ui_Ux/Logo';
import Steps from './Ui_Ux/Steps';
import Footer from '../Footer';
gsap.registerPlugin(ScrollTrigger);

// background : #77acf1;

const UiUxSection = styled.section`
    width: 100%;
    height: 100vh;
    box-sizing : border-box;
    overflow: hidden;
    background-image : url(${Bg1});
    background-position: center;
    object-fit : cover;
    padding : 0 10%;
    display : flex;
    justify-content : center;
    align-item : center;

@media screen and (max-width: 768px) {
    object-fit : cover;
    flex-direction : column;
    padding : 0 ;
}
`;

const BgImage1 = styled.img`
    width : 100%;
    height : 100%;
    position : absolute;
    z-index: -20;
`;



const UiUxDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px ) {

}

`;

const ImgDiv = styled.div`
    width : 100%;
    height : 100%;
    justify-content : center;
    align-items : center;
    display : flex;
    position : relative;

svg{
    width : 100%;
    height : auto;
    filter: drop-shadow(0px 0px 10px #000);
    transform : scale(1.5)
}

@media screen and (max-width : 768px ) {
    display : initial;
}
`;

const Heading = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content: center;

`;

const HeadingDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    font-style: italic;
    justify-content : center;
    align-item : center;
    flex-direction : column;
    text-align : center;


h1{
    font-size : 2rem;
    letter-spacing : 3px;
}

h5{
    text-align : center;
    margin-bottom : 15px;
}

p{
    font-size : 1.1rem;
    padding : 10px 0;
    font-weight : bold;
    line-height : 30px;
}

@media screen and (max-width : 768px ) {

h1{
    font-size : 1.5rem;
    padding : 10px 0;
    letter-spacing : 5px;
}

p{
    font-size : 0.9rem;
    padding : 10px 10px ;  
}
}
`;


const UI_UX = () => {
    
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])

        return <Fragment>
                <UiUxSection className="section" id='section-1'>
                    <BgImage1 src={Bg1} />
                    <Heading>
                        <HeadingDiv>
                        <h1 data-aos="fade-right">UI &amp; UX DESIGN </h1>
                        <h5 data-aos="fade-right" >Giving purpose to every pixel.....!</h5>
                        <p data-aos="fade-right">As your User Experience And Technology Partner, we builds for you software solutions, be it websites, web applications or mobile applications, that are fluid and flawless.</p>
                        </HeadingDiv>
                    </Heading>
                    <UiUxDiv >
                        <ImgDiv data-aos="zoom-in-left" >
                            <UiUx  />
                        </ImgDiv>
                    </UiUxDiv>
                </UiUxSection>
                <Steps />
                <UXdesign />
                <UIdesign /> 
                <VWdesign />   
                <WhyUs />
                <Footer />
            </Fragment>
}


export default UI_UX;