import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../Images/PNG/logo.png';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap/all';
import FormComponent from './Form/Form';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const FooterSection = styled.section`
  width: 100%;
  height: auto;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const InnerSection = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContactFormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding: 25px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  background: #000;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FirstDiv = styled.div`
  width: 100%;
  height: auto;
`;
const SecondDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;
const ThirdDiv = styled.div`
  width: 100%;
  height: auto;
`;

const LogoDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoImg = styled.img`
  width: 80%;
  height: auto;
`;

const ContactUs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #fff;
    padding: 5px 0;
  }

  i {
    color: rgba(173, 202, 37, 255);
    padding-right: 5px;
  }
`;

const Information = styled.div`
  width: 100%;
  height: auto;
`;

const Projects = styled.div`
  width: 100%;
  height: auto;
`;

const HeadTitle = styled.h3`
  text-align: center;
  color: rgba(173, 202, 37, 255);
  padding: 10px 0;
`;
const UL = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;
const LI = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  padding: 15px 0;
  color: rgba(255, 255, 255);
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.05);
    color: rgba(173, 202, 37, 255);
  }
`;

const Links = styled(Link)`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
`;

const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const FormText = styled.h2`
  color: rgba(173, 202, 37, 255);
  padding: 10px 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  padding: 10px 20px;
`;

const Copyright = styled.div`
  width: 100%;
  height: auto;
  text-align: center;

  @media screen and (max-width: 768px) {
    p {
      padding: 10px 0;
      transform: scale(0.8);
    }
  }
`;
const ContactUsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = () => {
  const FormTextRef = useRef(null);
  const ParagraphRef = useRef(null);
  const FooterSectionRef = useRef(null);

  useEffect(() => {
    const t1 = gsap.timeline();
    t1.from(FormTextRef.current, 0.4, { opacity: 0, ease: 'ease-in-out', repeat: Infinity }).from(ParagraphRef.current, 0.4, { scale: 5, opacity: 0, ease: 'ease-in-out' });

    ScrollTrigger.create({
      animation: t1,
      trigger: FooterSectionRef.current,
      start: 'top 60%',
      toggleActions: 'restart none none reset',
    });
  }, []);

  return (
    <FooterSection ref={FooterSectionRef} id="footer-section">
      <InnerSection>
        <ContactFormContainer>
          <Text>
            <TextWrapper>
              <FormText ref={FormTextRef}>
                Let's Talk &nbsp;&nbsp;&nbsp;<i className="fas fa-phone-alt"></i>
              </FormText>
              <Paragraph ref={ParagraphRef}>We will be happy to collaborate with you in contributing to your growth story</Paragraph>
            </TextWrapper>
          </Text>
          <FormComponent />
        </ContactFormContainer>
        <FooterContainer>
          <FirstDiv>
            <LogoDiv>
              <LogoImg src={Logo} />
            </LogoDiv>
          </FirstDiv>
          <SecondDiv>
            <Information>
              <HeadTitle>Information</HeadTitle>
              <UL>
                <LI>
                  <Links to="/">Home</Links>
                </LI>
                <LI>
                  <Links to="/about">About Us</Links>
                </LI>
                <LI>
                  <Links to="/contact">Contact</Links>
                </LI>
              </UL>
            </Information>
            <Projects>
              <HeadTitle>Services</HeadTitle>
              <UL>
                <LI>
                  <Links to="uiuxdevelopment">UI/UX Development</Links>
                </LI>
                <LI>
                  <Links to="appdevelopment">App Development</Links>
                </LI>
                <LI>
                  <Links to="webdevelopment">Web Development</Links>
                </LI>
                <LI>
                  <Links to="digitalmarketing">Digital Marketing</Links>
                </LI>
              </UL>
            </Projects>
          </SecondDiv>
          <ThirdDiv>
            <ContactUs>
              <HeadTitle>Contact Us</HeadTitle>
              <ContactUsDiv>
                <div>
                  <i className="fas fa-location-arrow" style={{ textAlign: 'center' }}></i>
                </div>
                <p style={{ lineHeight: '28px', transform: 'scale(0.8)' }}>1-10-446/752 Plot No: A1, BLOCK-A, Kundan Towers, Mayuri Marg, Begumpet, Hyderabad, Telangana,500016.</p>
              </ContactUsDiv>
              <ContactUsDiv>
                <div>
                  <i className="fas fa-phone-alt" style={{ textAlign: 'center' }}></i>
                </div>
                <p style={{ width: '70%', lineHeight: '28px', transform: 'scale(0.8)' }}>+91 6309531081</p>
              </ContactUsDiv>
              <ContactUsDiv>
                <div>
                  <i className="fas fa-envelope" style={{ width: '30%', textAlign: 'center' }}></i>
                </div>
                <p style={{ width: '70%', lineHeight: '28px', transform: 'scale(0.8)' }}> lmvitservices@gmail.com</p>
              </ContactUsDiv>
            </ContactUs>
          </ThirdDiv>
        </FooterContainer>
        <Copyright>
          <p>
            <i className="far fa-copyright"></i> Copyrights. All rights reserved by Lmv it solutions | <Link to={'/privacy-policy'}>Privacy Policy</Link>
          </p>
        </Copyright>
      </InnerSection>
    </FooterSection>
  );
};

export default Footer;
