import React from 'react';
import styled from 'styled-components';
import One from '../../../Images/PNG/Digital_Marketing/1.jpg';
import Two from '../../../Images/PNG/Digital_Marketing/2.jpg';
import Three from '../../../Images/PNG/Digital_Marketing/3.jpg';


const Section = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 10%;
    background : linear-gradient(to right, #b83e6c 20%, #D35C7D 100%);

@media screen and (max-width : 768px){
    padding : 10px;
}
`;

const InnerSection = styled.div`
    width : 100%;
    height : 100%;
`

const Content = styled.div`

`;

const ContentDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-contnet : center;
    align-item : center;
    flex-direction : column;
    margin : 15px auto;


@media screen and (max-width : 768px){
    padding : 10px;
}
`;

const ContentImage = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-item : center;

img{
    width : 100px;
    height : 100px;
}
`;

const ContentHead = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 0;
    text-align : center;
    font-style : italic;

`;
const ContentText = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 0;
    font-style : italic;
    text-align: justify;
    text-justify: inter-word;

p{
    line-height : 30px;

}
`;

const TimeToEnter = () => {


    return <Section>
        <InnerSection>
            <Content>
                <ContentDiv data-aos="fade-left">
                    <ContentImage>
                        <img src={One} />
                    </ContentImage>
                    <ContentHead>
                        <h4>It’s Time to Enter into the Online World</h4>
                    </ContentHead>
                    <ContentText>
                        <p>The internet has become a common thing in everyone’s life and so, it acts as the best medium to reach your target audience. LMVIT, the Best Digital Marketing Company in Hyderabad promotes your business with advanced and proven marketing strategies. We let your target audience know about your business’s products and services and help you grow digitally.</p>
                    </ContentText>
                </ContentDiv>
                
                <ContentDiv data-aos="fade-right">
                    <ContentImage>
                        <img src={Two} />
                    </ContentImage>
                    <ContentHead>
                        <h4>We Strategize, Market and Reach Your Target Audience</h4>
                    </ContentHead>
                    <ContentText>
                        <p>Strategic planning, promotion of your products and services with eye-catching creatives will enable you to reach your target audience. It’s our sole responsibility to applicate effective digital strategies and make your business stand in the minds of your targeted audience.</p>
                    </ContentText>
                </ContentDiv>
                <ContentDiv data-aos="fade-left">
                    <ContentImage>
                        <img src={Three} />
                    </ContentImage>
                    <ContentHead>
                        <h4>Generate Leads and thus, Conversions</h4>
                    </ContentHead>
                    <ContentText>
                        <p>Reach your business goals in no time with our advanced strategical planning and implementation through digital marketing services in Hyderabad. Our digital marketing executives and the managing team will help you get leads and conversions through effectively optimized campaigns and thus, enhance your business ROI.</p>
                    </ContentText>
                </ContentDiv>
            </Content>
        </InnerSection>
    </Section>
}

export default TimeToEnter;