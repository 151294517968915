import React, { Fragment, useEffect }from 'react';
import Footer from '../Footer';
import ContactUs from '../ContactUs';


const ContactUsPage = () => {
    
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])

        return (
            <Fragment>
                <ContactUs/>
                <Footer />
            </Fragment>
        )
}   

export default ContactUsPage;