import React, { Fragment } from 'react';
import styled from 'styled-components';
import Image from '../../../Images/PNG/Web/WhyUs.png';


const Section = styled.section`
    width : 100%;
    height : auto;
    padding : 20px 10%;
    background : #9087DD;

@media screen and (max-width : 768px){
    padding : 20px;
}
`;

const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 15px 0;

h1{
    font-size : 1.5rem;
}
`;

const OurProcessDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    flex-direction : column;
}
`;

const Process = styled.div`
    width : 100%;
    height : auto;
    

@media screen and (max-width: 768px) {
}
`;

const ProcessText = styled.div`
    width : 100%;
    height : auto;
    line-height : 30px;
    font-weight : bold;
    display : flex;
    justify-content : center;
    align-items : center;

p{
    font-size : 14px;
    padding : 10px 0;
}
img{
    width : 100%;
    height : auto;
    padding : 10px;
}
`;
const I = styled.i`
    margin-right : 5px;
`;

const WhyToChooseUs = () => {


    return <Section>
        <Heading>
            <h1>WHY TO CHOOSE US</h1>
        </Heading>
        <OurProcessDiv>
            <Process>
                <ProcessText data-aos="fade-right">
                    <div>
                        <p><I className="fas fa-bookmark"></I>The Websites we make are optimized</p>
                        <p><I className="fas fa-bookmark"></I>Our Agile Methodology of development is proven and effective</p>
                        <p><I className="fas fa-bookmark"></I>Delivering services and solutions right for your business</p>
                        <p><I className="fas fa-bookmark"></I>Strong focus on business requirements and ROI</p>
                        <p><I className="fas fa-bookmark"></I>We’re quick to response to the clients need</p>
                    </div>
                </ProcessText>
            </Process>
            <Process>
                <ProcessText>
                    <img data-aos="fade-in" src={Image} />
                </ProcessText>
            </Process>
            <Process>
                <ProcessText data-aos="fade-left">
                    <div>
                        <p><I className="fas fa-bookmark"></I>No compromise on quality of website</p>
                        <p><I className="fas fa-bookmark"></I>No worrying as we have an expert web development team</p>
                        <p><I className="fas fa-bookmark"></I>Our web developers are experienced and certified</p>
                        <p><I className="fas fa-bookmark"></I>Extensive project management experience</p>
                        <p><I className="fas fa-bookmark"></I>We build responsive websites that auto adapt to device screens</p>
                    </div>
                </ProcessText>
            </Process>
        </OurProcessDiv>
    </Section>
}


export default WhyToChooseUs;