import React, { Fragment } from 'react';


const StudyTap = () => {

  return <Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
      viewBox="0 0 1040 835">
      <defs>
        <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="568.139" y1="825.492" x2="568.139" y2="742.045">
          <stop offset="0" stop-opacity="1" stop-color="#F5F5FA" />
          <stop offset="1" stop-opacity="1" stop-color="#E6E3F2" />
          <stop offset="1" stop-opacity="1" stop-color="#E6E3F2" />
        </linearGradient>
        <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.962" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id2" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.711" x2="-172.125" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.969" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id4" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.711" x2="-172.123" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id5" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.962" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id6" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.711" x2="-172.125" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id7" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.969" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id8" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.711" x2="-172.123" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id9" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.962" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id10" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.711" x2="-172.125" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id11" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.969" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id12" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.711" x2="-172.123" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id13" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.962" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id14" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.711" x2="-172.125" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id15" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.969" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id16" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.711" x2="-172.123" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id17" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.962" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id18" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.711" x2="-172.125" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id19" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.711" x2="-171.969" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id20" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.243" x2="690.567" y2="120.677">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id21" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.709" x2="-171.962" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id22" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.709" x2="-172.125" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id23" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.709" x2="-171.969" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id24" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.242" x2="690.567" y2="120.677">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id25" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.709" x2="-171.962" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id26" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.709" x2="-172.125" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id27" gradientUnits="userSpaceOnUse" x1="1853.29" y1="373.709" x2="-172.109" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id28" gradientUnits="userSpaceOnUse" x1="664.278" y1="476.224" x2="690.645" y2="120.718">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id29" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.242" x2="690.567" y2="120.676">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id30" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.709" x2="-171.962" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id31" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.709" x2="-172.125" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id32" gradientUnits="userSpaceOnUse" x1="1852.92" y1="373.702" x2="-171.616" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id33" gradientUnits="userSpaceOnUse" x1="664.241" y1="476.24" x2="690.611" y2="120.677">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id34" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.24" x2="690.567" y2="120.677">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id35" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.708" x2="-171.962" y2="373.708">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id36" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.708" x2="-172.125" y2="373.708">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id37" gradientUnits="userSpaceOnUse" x1="1829.53" y1="372.164" x2="-140.216" y2="372.164">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id38" gradientUnits="userSpaceOnUse" x1="664.222" y1="476.239" x2="690.594" y2="120.676">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id39" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.239" x2="690.567" y2="120.676">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id40" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.68" x2="-171.962" y2="373.68">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id41" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.68" x2="-172.125" y2="373.68">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id42" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.68" x2="-171.969" y2="373.68">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id43" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.68" x2="-172.123" y2="373.68">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id44" gradientUnits="userSpaceOnUse" x1="1853.19" y1="373.708" x2="-171.962" y2="373.708">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id45" gradientUnits="userSpaceOnUse" x1="1853.3" y1="373.708" x2="-172.125" y2="373.708">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id46" gradientUnits="userSpaceOnUse" x1="1852.98" y1="373.711" x2="-171.689" y2="373.711">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id47" gradientUnits="userSpaceOnUse" x1="664.226" y1="476.239" x2="690.597" y2="120.675">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id48" gradientUnits="userSpaceOnUse" x1="664.192" y1="476.239" x2="690.567" y2="120.675">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id49" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.118" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id50" gradientUnits="userSpaceOnUse" x1="1852.93" y1="373.804" x2="-171.991" y2="373.804">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id51" gradientUnits="userSpaceOnUse" x1="568.14" y1="825.496" x2="568.14" y2="742.043">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id52" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.116" x2="528.488" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id53" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.118" x2="528.488" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id54" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.117" x2="528.488" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id55" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.118" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id56" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.118" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id57" gradientUnits="userSpaceOnUse" x1="1853.44" y1="373.665" x2="-172.157" y2="373.665">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id58" gradientUnits="userSpaceOnUse" x1="568.14" y1="825.491" x2="568.14" y2="742.045">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id59" gradientUnits="userSpaceOnUse" x1="566.585" y1="862.116" x2="528.489" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id60" gradientUnits="userSpaceOnUse" x1="566.585" y1="862.118" x2="528.489" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id61" gradientUnits="userSpaceOnUse" x1="566.585" y1="862.117" x2="528.489" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id62" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.118" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id63" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.706" x2="-172.119" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id64" gradientUnits="userSpaceOnUse" x1="1853.41" y1="373.728" x2="-172.144" y2="373.728">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id65" gradientUnits="userSpaceOnUse" x1="568.15" y1="825.502" x2="568.15" y2="742.039">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id66" gradientUnits="userSpaceOnUse" x1="566.586" y1="862.116" x2="528.491" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id67" gradientUnits="userSpaceOnUse" x1="566.586" y1="862.118" x2="528.491" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id68" gradientUnits="userSpaceOnUse" x1="566.586" y1="862.117" x2="528.491" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id69" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.702" x2="-172.119" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id70" gradientUnits="userSpaceOnUse" x1="1853.34" y1="373.706" x2="-172.122" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id71" gradientUnits="userSpaceOnUse" x1="1853.34" y1="373.746" x2="-172.122" y2="373.746">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id72" gradientUnits="userSpaceOnUse" x1="568.107" y1="825.492" x2="568.107" y2="742.045">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id73" gradientUnits="userSpaceOnUse" x1="566.59" y1="862.116" x2="528.494" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id74" gradientUnits="userSpaceOnUse" x1="566.59" y1="862.118" x2="528.494" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id75" gradientUnits="userSpaceOnUse" x1="566.59" y1="862.117" x2="528.494" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id76" gradientUnits="userSpaceOnUse" x1="1853.34" y1="373.702" x2="-172.122" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id77" gradientUnits="userSpaceOnUse" x1="1853.09" y1="373.706" x2="-172.067" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id78" gradientUnits="userSpaceOnUse" x1="1853.09" y1="373.725" x2="-172.067" y2="373.725">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id79" gradientUnits="userSpaceOnUse" x1="568.329" y1="825.431" x2="568.329" y2="742.083">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id80" gradientUnits="userSpaceOnUse" x1="566.534" y1="862.116" x2="528.444" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id81" gradientUnits="userSpaceOnUse" x1="566.534" y1="862.118" x2="528.444" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id82" gradientUnits="userSpaceOnUse" x1="566.534" y1="862.117" x2="528.444" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id83" gradientUnits="userSpaceOnUse" x1="1853.09" y1="373.702" x2="-172.067" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id84" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.115" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id85" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.703" x2="-172.115" y2="373.703">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id86" gradientUnits="userSpaceOnUse" x1="568.142" y1="825.492" x2="568.142" y2="742.045">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id87" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.701" x2="-172.115" y2="373.701">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id88" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.707" x2="-172.115" y2="373.707">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id89" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.115" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id90" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.115" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id91" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.703" x2="-172.115" y2="373.703">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id92" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.699" x2="-172.114" y2="373.699">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id93" gradientUnits="userSpaceOnUse" x1="568.133" y1="825.492" x2="568.133" y2="742.045">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id94" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.701" x2="-172.115" y2="373.701">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id95" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.707" x2="-172.115" y2="373.707">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id96" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.115" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id97" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.706" x2="-172.115" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id98" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.703" x2="-172.115" y2="373.703">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id99" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.709" x2="-172.115" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id100" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.701" x2="-172.115" y2="373.701">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id101" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.707" x2="-172.115" y2="373.707">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id102" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.702" x2="-172.115" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id103" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.114" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id104" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.703" x2="-172.114" y2="373.703">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id105" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.709" x2="-172.114" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id106" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.701" x2="-172.114" y2="373.701">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id107" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.707" x2="-172.114" y2="373.707">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id108" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.114" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id109" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.706" x2="-172.118" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id110" gradientUnits="userSpaceOnUse" x1="1852.23" y1="374.201" x2="-171.723" y2="374.201">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id111" gradientUnits="userSpaceOnUse" x1="568.14" y1="825.494" x2="568.14" y2="742.044">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id112" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.116" x2="528.488" y2="442.087">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id113" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.118" x2="528.488" y2="442.08">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id114" gradientUnits="userSpaceOnUse" x1="566.584" y1="862.117" x2="528.488" y2="442.085">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
          <stop offset="1" stop-opacity="1" stop-color="#F3F4FA" />
        </linearGradient>
        <linearGradient id="id115" gradientUnits="userSpaceOnUse" x1="1853.31" y1="373.702" x2="-172.118" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id116" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.706" x2="-172.115" y2="373.706">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id117" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.703" x2="-172.115" y2="373.703">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id118" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.709" x2="-172.115" y2="373.709">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id119" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.701" x2="-172.115" y2="373.701">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id120" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.707" x2="-172.115" y2="373.707">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
        <linearGradient id="id121" gradientUnits="userSpaceOnUse" x1="1853.32" y1="373.702" x2="-172.115" y2="373.702">
          <stop offset="0" stop-opacity="1" stop-color="#FAF9FC" />
          <stop offset="1" stop-opacity="1" stop-color="#FEFEFE" />
        </linearGradient>
      </defs>
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_715252320">
          <path fill="url(#id0)" d="M568 785c-70,0 -137,-1 -197,-2l4 -4 -4 -5 -5 5 4 4c-12,0 -23,-1 -35,-1l3 -3 -4 -5 -4 5 3 3c-11,0 -22,-1 -33,-1l2 -2 -5 -5 -4 5 2 2c-11,-1 -21,-1 -30,-2l0 0 -4 -5 -5 5c-10,-1 -20,-1 -29,-2l-3 -3 -2 3c-12,-1 -24,-2 -34,-2l0 -1 -1 1c-54,-5 -86,-10 -86,-15 0,-2 3,-4 10,-6l3 3 5 -4 -1 0c12,-3 30,-5 53,-7 2,5 4,9 6,14l426 0c21,4 42,6 63,6 22,0 43,-2 64,-6l147 0c3,-6 7,-13 11,-19 91,5 148,11 148,19 0,14 -210,25 -468,25zm-417 -36l-4 4 4 4 4 -4 -4 -4z" />
          <path fill="#9D9E9E" fill-opacity="0.168627" d="M140 553c-43,-94 -70,-202 -57,-280 31,-188 173,-273 317,-273 39,0 79,6 116,18 79,26 141,82 178,155 -9,7 -18,15 -28,25 -59,59 -134,78 -197,100 -50,-48 -113,-82 -170,-82 -86,0 -159,76 -159,296 0,14 0,27 0,41z" />
          <path fill="#9D9E9E" fill-opacity="0.168627" d="M666 766c-21,0 -42,-2 -63,-6l127 0c-21,4 -42,6 -64,6zm271 -147c7,-35 6,-71 -7,-107 -26,-71 -65,-91 -109,-91 -28,0 -58,8 -88,16 -30,8 -61,16 -90,16 -41,0 -78,-16 -108,-70 -17,-31 -39,-60 -66,-85 63,-22 138,-41 197,-100 10,-10 19,-18 28,-25 39,-32 75,-45 107,-45 97,0 162,125 181,243l-2 3 3 3c2,11 3,21 4,32l-3 -3 -4 4 4 5 3 -4c1,12 1,22 1,33 0,1 0,2 0,3l-4 -5 -4 5 4 4 4 -4c0,11 0,23 -2,34l-2 -2 -4 4 4 5 2 -2c-7,49 -24,94 -49,133zm21 -140l-4 4 4 5 5 -5 -5 -4zm0 -37l-4 5 4 4 5 -4 -5 -5zm0 -36l-4 4 4 5 5 -5 -5 -4zm0 -37l-4 5 4 4 5 -4 -5 -5zm0 -36l-4 4 4 4 5 -4 -5 -4zm0 -37l-4 4 4 5 5 -5 -5 -4z" />
          <path fill="#9D9E9E" fill-opacity="0.278431" d="M877 760l-700 0c-2,-5 -4,-9 -6,-14 -24,-55 -30,-121 -31,-193 0,-14 0,-27 0,-41 0,-220 73,-296 159,-296 57,0 120,34 170,82 27,25 49,54 66,85 30,54 67,70 108,70 29,0 60,-8 90,-16 30,-8 60,-16 88,-16 44,0 83,20 109,91 13,36 14,72 7,107 -9,44 -29,86 -49,122 -4,6 -8,13 -11,19zm-506 -11l-5 4 5 4 4 -4 -4 -4zm-37 0l-4 4 4 4 4 -4 -4 -4zm-37 0l-4 4 4 4 5 -4 -5 -4zm-36 0l-5 4 5 4 4 -4 -4 -4zm-37 0l-4 4 4 4 5 -4 -5 -4zm-36 0l-5 4 5 4 4 -4 -4 -4zm183 -26l-5 4 5 5 4 -5 -4 -4zm-37 0l-4 4 4 5 4 -5 -4 -4zm-37 0l-4 4 4 5 5 -5 -5 -4zm-36 0l-5 4 5 5 4 -5 -4 -4zm-37 0l-4 4 4 5 5 -5 -5 -4zm-36 0l-5 4 5 5 4 -5 -4 -4zm183 -26l-5 4 5 5 4 -5 -4 -4zm-37 0l-4 4 4 5 4 -5 -4 -4zm-37 0l-4 4 4 5 5 -5 -5 -4zm-36 0l-5 4 5 5 4 -5 -4 -4zm-37 0l-4 4 4 5 5 -5 -5 -4zm-36 0l-5 4 5 5 4 -5 -4 -4z" />
          <polygon fill="url(#id1)" points="1036,158 1031,154 1036,149 1040,154 " />
          <polygon fill="url(#id2)" points="1010,158 1005,154 1010,149 1014,154 " />
          <polygon fill="url(#id3)" points="984,158 980,154 984,149 988,154 " />
          <polygon fill="url(#id4)" points="958,158 954,154 958,149 963,154 " />
          <polygon fill="url(#id5)" points="1036,195 1031,190 1036,186 1040,190 " />
          <polygon fill="url(#id6)" points="1010,195 1005,190 1010,186 1014,190 " />
          <polygon fill="url(#id7)" points="984,195 980,190 984,186 988,190 " />
          <polygon fill="url(#id8)" points="958,195 954,190 958,186 963,190 " />
          <polygon fill="url(#id9)" points="1036,231 1031,227 1036,223 1040,227 " />
          <polygon fill="url(#id10)" points="1010,231 1005,227 1010,223 1014,227 " />
          <polygon fill="url(#id11)" points="984,231 980,227 984,223 988,227 " />
          <polygon fill="url(#id12)" points="958,231 954,227 958,223 963,227 " />
          <polygon fill="url(#id13)" points="1036,268 1031,264 1036,259 1040,264 " />
          <polygon fill="url(#id14)" points="1010,268 1005,264 1010,259 1014,264 " />
          <polygon fill="url(#id15)" points="984,268 980,264 984,259 988,264 " />
          <polygon fill="url(#id16)" points="958,268 954,264 958,259 963,264 " />
          <polygon fill="url(#id17)" points="1036,305 1031,300 1036,296 1040,300 " />
          <polygon fill="url(#id18)" points="1010,305 1005,300 1010,296 1014,300 " />
          <polygon fill="url(#id19)" points="984,305 980,300 984,296 988,300 " />
          <polygon fill="url(#id20)" points="958,305 954,300 958,296 963,300 " />
          <polygon fill="url(#id21)" points="1036,341 1031,337 1036,333 1040,337 " />
          <polygon fill="url(#id22)" points="1010,341 1005,337 1010,333 1014,337 " />
          <polygon fill="url(#id23)" points="984,341 980,337 984,333 988,337 " />
          <polygon fill="url(#id24)" points="958,341 954,337 958,333 963,337 " />
          <polygon fill="url(#id25)" points="1036,378 1031,374 1036,369 1040,374 " />
          <polygon fill="url(#id26)" points="1010,378 1005,374 1010,369 1014,374 " />
          <path fill="url(#id27)" d="M984 378l-1 -1c0,-2 0,-4 -1,-6l2 -2 4 5 -4 4z" />
          <path fill="url(#id28)" d="M983 377l-3 -3 2 -3c1,2 1,4 1,6z" />
          <polygon fill="url(#id29)" points="958,378 954,374 958,369 963,374 " />
          <polygon fill="url(#id30)" points="1036,415 1031,410 1036,406 1040,410 " />
          <polygon fill="url(#id31)" points="1010,415 1005,410 1010,406 1014,410 " />
          <path fill="url(#id32)" d="M987 411c0,0 0,-1 0,-2l1 1 -1 1z" />
          <path fill="url(#id33)" d="M984 415l-4 -5 4 -4 3 3c0,1 0,2 0,2l-3 4z" />
          <polygon fill="url(#id34)" points="958,415 954,410 958,406 963,410 " />
          <polygon fill="url(#id35)" points="1036,451 1031,447 1036,442 1040,447 " />
          <polygon fill="url(#id36)" points="1010,451 1005,447 1010,442 1014,447 " />
          <path fill="url(#id37)" d="M988 447c0,0 0,0 0,0l0 0 0 0z" />
          <path fill="url(#id38)" d="M984 451l-4 -4 4 -5 4 5c0,0 0,0 0,0l-4 4z" />
          <polygon fill="url(#id39)" points="958,451 954,447 958,442 963,447 " />
          <polygon fill="url(#id40)" points="1036,121 1031,117 1036,113 1040,117 " />
          <polygon fill="url(#id41)" points="1010,121 1005,117 1010,113 1014,117 " />
          <polygon fill="url(#id42)" points="984,121 980,117 984,113 988,117 " />
          <polygon fill="url(#id43)" points="958,121 954,117 958,113 963,117 " />
          <polygon fill="url(#id44)" points="1036,488 1031,483 1036,479 1040,483 " />
          <polygon fill="url(#id45)" points="1010,488 1005,483 1010,479 1014,483 " />
          <path fill="url(#id46)" d="M986 486c0,-1 0,-3 0,-5l2 2 -2 3z" />
          <path fill="url(#id47)" d="M984 488l-4 -5 4 -4 2 2c0,2 0,4 0,5l-2 2z" />
          <polygon fill="url(#id48)" points="958,488 954,483 958,479 963,483 " />
          <polygon fill="url(#id49)" points="334,809 330,805 334,800 338,805 " />
          <path fill="url(#id50)" d="M334 783l-1 -1c1,0 1,0 2,0l-1 1z" />
          <path fill="url(#id51)" d="M335 782c-1,0 -1,0 -2,0l-3 -3 4 -5 4 5 -3 3z" />
          <polygon fill="url(#id52)" points="334,757 330,753 334,749 338,753 " />
          <polygon fill="url(#id53)" points="334,732 330,727 334,723 338,727 " />
          <polygon fill="url(#id54)" points="334,706 330,701 334,697 338,701 " />
          <polygon fill="url(#id55)" points="334,835 330,830 334,826 338,830 " />
          <polygon fill="url(#id56)" points="297,809 293,805 297,800 302,805 " />
          <path fill="url(#id57)" d="M297 783l-2 -2c1,0 3,0 5,0l-3 2z" />
          <path fill="url(#id58)" d="M300 781c-2,0 -4,0 -5,0l-2 -2 4 -5 5 5 -2 2z" />
          <polygon fill="url(#id59)" points="297,757 293,753 297,749 302,753 " />
          <polygon fill="url(#id60)" points="297,732 293,727 297,723 302,727 " />
          <polygon fill="url(#id61)" points="297,706 293,701 297,697 302,701 " />
          <polygon fill="url(#id62)" points="297,835 293,830 297,826 302,830 " />
          <polygon fill="url(#id63)" points="261,809 256,805 261,800 265,805 " />
          <path fill="url(#id64)" d="M261 783l-5 -4 0 0c3,0 6,0 9,0l-4 4z" />
          <path fill="url(#id65)" d="M265 779c-3,0 -6,0 -9,0l5 -5 4 5 0 0z" />
          <polygon fill="url(#id66)" points="261,757 256,753 261,749 265,753 " />
          <polygon fill="url(#id67)" points="261,732 256,727 261,723 265,727 " />
          <polygon fill="url(#id68)" points="261,706 256,701 261,697 265,701 " />
          <polygon fill="url(#id69)" points="261,835 256,830 261,826 265,830 " />
          <polygon fill="url(#id70)" points="224,809 220,805 224,800 229,805 " />
          <path fill="url(#id71)" d="M224 783l-4 -4 2 -2c1,0 3,0 5,0l2 2 -5 4z" />
          <path fill="url(#id72)" d="M227 777c-2,0 -4,0 -5,0l2 -3 3 3z" />
          <polygon fill="url(#id73)" points="224,757 220,753 224,749 229,753 " />
          <polygon fill="url(#id74)" points="224,732 220,727 224,723 229,727 " />
          <polygon fill="url(#id75)" points="224,706 220,701 224,697 229,701 " />
          <polygon fill="url(#id76)" points="224,835 220,830 224,826 229,830 " />
          <polygon fill="url(#id77)" points="188,809 183,805 188,800 192,805 " />
          <path fill="url(#id78)" d="M188 783l-5 -4 4 -4c1,0 1,0 1,0l4 4 -4 4z" />
          <path fill="url(#id79)" d="M188 775c0,0 0,0 -1,0l1 -1 0 1z" />
          <polygon fill="url(#id80)" points="188,757 183,753 188,749 192,753 " />
          <polygon fill="url(#id81)" points="188,732 183,727 188,723 192,727 " />
          <polygon fill="url(#id82)" points="188,706 183,701 188,697 192,701 " />
          <polygon fill="url(#id83)" points="188,835 183,830 188,826 192,830 " />
          <polygon fill="url(#id84)" points="151,809 147,805 151,800 155,805 " />
          <polygon fill="url(#id85)" points="151,783 147,779 151,774 155,779 " />
          <polygon fill="url(#id86)" points="151,757 147,753 151,749 155,753 " />
          <polygon fill="url(#id87)" points="151,732 147,727 151,723 155,727 " />
          <polygon fill="url(#id88)" points="151,706 147,701 151,697 155,701 " />
          <polygon fill="url(#id89)" points="151,835 147,830 151,826 155,830 " />
          <polygon fill="url(#id90)" points="114,809 110,805 114,800 119,805 " />
          <polygon fill="url(#id91)" points="114,783 110,779 114,774 119,779 " />
          <path fill="url(#id92)" d="M111 754l-1 -1 4 -4 4 4c-2,0 -5,1 -7,1z" />
          <path fill="url(#id93)" d="M114 757l-3 -3c2,0 5,-1 7,-1l1 0 -5 4z" />
          <polygon fill="url(#id94)" points="114,732 110,727 114,723 119,727 " />
          <polygon fill="url(#id95)" points="114,706 110,701 114,697 119,701 " />
          <polygon fill="url(#id96)" points="114,835 110,830 114,826 119,830 " />
          <polygon fill="url(#id97)" points="78,809 73,805 78,800 82,805 " />
          <polygon fill="url(#id98)" points="78,783 73,779 78,774 82,779 " />
          <polygon fill="url(#id99)" points="78,757 73,753 78,749 82,753 " />
          <polygon fill="url(#id100)" points="78,732 73,727 78,723 82,727 " />
          <polygon fill="url(#id101)" points="78,706 73,701 78,697 82,701 " />
          <polygon fill="url(#id102)" points="78,835 73,830 78,826 82,830 " />
          <polygon fill="url(#id103)" points="41,809 37,805 41,800 45,805 " />
          <polygon fill="url(#id104)" points="41,783 37,779 41,774 45,779 " />
          <polygon fill="url(#id105)" points="41,757 37,753 41,749 45,753 " />
          <polygon fill="url(#id106)" points="41,732 37,727 41,723 45,727 " />
          <polygon fill="url(#id107)" points="41,706 37,701 41,697 45,701 " />
          <polygon fill="url(#id108)" points="41,835 37,830 41,826 45,830 " />
          <polygon fill="url(#id109)" points="371,809 366,805 371,800 375,805 " />
          <path fill="url(#id110)" d="M371 783l-1 0c1,0 1,0 1,0l0 0z" />
          <path fill="url(#id111)" d="M371 783c0,0 0,0 -1,0l-4 -4 5 -5 4 5 -4 4z" />
          <polygon fill="url(#id112)" points="371,757 366,753 371,749 375,753 " />
          <polygon fill="url(#id113)" points="371,732 366,727 371,723 375,727 " />
          <polygon fill="url(#id114)" points="371,706 366,701 371,697 375,701 " />
          <polygon fill="url(#id115)" points="371,835 366,830 371,826 375,830 " />
          <polygon fill="url(#id116)" points="4,809 0,805 4,800 9,805 " />
          <polygon fill="url(#id117)" points="4,783 0,779 4,774 9,779 " />
          <polygon fill="url(#id118)" points="4,757 0,753 4,749 9,753 " />
          <polygon fill="url(#id119)" points="4,732 0,727 4,723 9,727 " />
          <polygon fill="url(#id120)" points="4,706 0,701 4,697 9,701 " />
          <polygon fill="url(#id121)" points="4,835 0,830 4,826 9,830 " />
          <path fill="#433C68" fill-rule="nonzero" d="M600 300c-79,-10 -155,-11 -234,0l0 -58 234 0 0 58z" />
          <polygon fill="#433C68" fill-rule="nonzero" points="483,279 310,225 483,164 668,221 " />
          <polygon fill="#433C68" fill-rule="nonzero" points="629,315 636,315 636,226 629,226 " />
          <path fill="#433C68" fill-rule="nonzero" d="M643 322c0,6 -5,10 -11,10 -5,0 -10,-4 -10,-10 0,-5 5,-10 10,-10 6,0 11,5 11,10z" />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="837,172 726,173 825,238 828,207 846,197 759,178 835,187 840,181 849,172 " />
          <path fill="#FEFEFE" d="M876 203l-1 3c-2,-1 -4,-1 -5,-2l0 -3c2,1 4,1 6,2z" />
          <polygon fill="#FEFEFE" points="861,199 866,200 865,203 860,202 " />
          <polygon fill="#FEFEFE" points="851,197 857,198 856,201 851,200 " />
          <polygon fill="#FEFEFE" points="898,213 899,210 904,212 903,215 " />
          <path fill="#FEFEFE" d="M890 207c1,0 3,1 5,2l-1 3c-2,-1 -4,-2 -5,-2l1 -3z" />
          <path fill="#FEFEFE" d="M885 205l-1 4c-1,-1 -3,-2 -5,-2l1 -3c2,0 4,1 5,1z" />
          <path fill="#FEFEFE" d="M926 223c1,1 3,2 5,3l-2 3c-2,-1 -3,-2 -5,-3l2 -3z" />
          <path fill="#FEFEFE" d="M922 221l-2 3c-1,-1 -3,-2 -4,-3l1 -3c2,1 3,2 5,3z" />
          <path fill="#FEFEFE" d="M907 217l1 -3c2,1 4,1 5,2l-1 3c-2,-1 -3,-1 -5,-2z" />
          <path fill="#FEFEFE" d="M945 246l3 -2c1,2 2,3 2,5l-3 1c0,-1 -1,-3 -2,-4z" />
          <path fill="#FEFEFE" d="M939 238l3 -2c1,1 2,3 3,4l-2 2c-1,-1 -2,-3 -4,-4z" />
          <polygon fill="#FEFEFE" points="932,231 934,229 939,233 936,235 " />
          <path fill="#FEFEFE" d="M950 274c-1,2 -2,4 -2,5l-3 -1c0,-2 1,-3 2,-5l3 1z" />
          <path fill="#FEFEFE" d="M949 264l3 0 0 3c0,1 0,2 -1,3l-3 -1c1,-1 1,-2 1,-3l0 -2z" />
          <path fill="#FEFEFE" d="M949 257l0 -3 3 0 0 3c0,1 1,2 1,3l-4 0c0,-1 0,-2 0,-3z" />
          <path fill="#FEFEFE" d="M933 299c-2,1 -3,3 -4,4l-3 -3c2,-1 3,-2 4,-3l3 2z" />
          <polygon fill="#FEFEFE" points="940,292 936,296 933,294 937,290 " />
          <polygon fill="#FEFEFE" points="943,282 945,283 944,286 942,288 940,286 941,284 " />
          <polygon fill="#FEFEFE" points="909,317 904,320 903,317 907,315 " />
          <polygon fill="#FEFEFE" points="917,312 913,315 911,312 915,309 " />
          <path fill="#FEFEFE" d="M925 306c-1,1 -3,2 -4,3l-2 -2c1,-2 3,-3 4,-4l2 3z" />
          <polygon fill="#FEFEFE" points="881,329 883,332 878,334 876,331 " />
          <path fill="#FEFEFE" d="M892 327c-2,1 -4,2 -5,3l-2 -3c2,-1 4,-2 5,-3l2 3z" />
          <path fill="#FEFEFE" d="M900 322c-1,1 -3,2 -4,3l-2 -3c2,-1 3,-1 5,-2l1 2z" />
          <polygon fill="#FEFEFE" points="854,340 856,344 850,346 849,342 " />
          <polygon fill="#FEFEFE" points="863,337 865,340 860,342 858,339 " />
          <polygon fill="#FEFEFE" points="872,333 874,336 869,338 867,335 " />
          <polygon fill="#FEFEFE" points="827,350 828,354 822,355 821,352 " />
          <polygon fill="#FEFEFE" points="836,347 837,350 832,352 831,349 " />
          <polygon fill="#FEFEFE" points="845,344 846,347 841,349 840,346 " />
          <polygon fill="#FEFEFE" points="798,359 799,362 794,364 793,360 " />
          <polygon fill="#FEFEFE" points="808,356 809,359 803,361 803,358 " />
          <polygon fill="#FEFEFE" points="817,353 818,357 813,358 812,355 " />
          <polygon fill="#FEFEFE" points="764,367 770,366 770,369 765,371 " />
          <polygon fill="#FEFEFE" points="779,364 780,367 775,368 774,365 " />
          <polygon fill="#FEFEFE" points="789,362 790,365 784,366 783,363 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="219,189 234,197 219,205 210,221 202,205 186,197 202,189 210,173 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="256,216 264,221 256,225 251,234 246,225 237,221 246,216 251,207 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="674,118 658,126 674,135 682,150 690,135 706,126 690,118 682,102 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="637,145 628,150 637,155 641,164 646,155 655,150 646,145 641,136 " />
          <path fill="#918EBE" fill-rule="nonzero" d="M836 407c0,36 -28,65 -64,65 -36,0 -65,-29 -65,-65 0,-36 29,-65 65,-65 36,0 64,29 64,65z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M824 407c0,29 -23,52 -52,52 -29,0 -52,-23 -52,-52 0,-29 23,-52 52,-52 29,0 52,23 52,52z" />
          <path fill="#A7A3D1" fill-rule="nonzero" d="M811 407c0,22 -17,39 -39,39 -22,0 -39,-17 -39,-39 0,-22 17,-39 39,-39 22,0 39,17 39,39z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M796 407c0,13 -11,24 -24,24 -14,0 -24,-11 -24,-24 0,-13 10,-24 24,-24 13,0 24,11 24,24z" />
          <path fill="#918EBE" fill-rule="nonzero" d="M782 407c0,5 -5,10 -10,10 -6,0 -10,-5 -10,-10 0,-5 4,-10 10,-10 5,0 10,5 10,10z" />
          <polygon fill="#6258A1" fill-rule="nonzero" points="856,326 855,311 839,327 841,338 784,395 779,389 773,408 792,402 786,396 842,341 853,342 870,325 " />
          <polygon fill="#918EBE" fill-rule="nonzero" points="222,287 183,312 222,339 261,312 " />
          <polygon fill="#FDD743" fill-rule="nonzero" points="196,345 246,345 246,296 196,296 " />
          <polygon fill="#252422" fill-rule="nonzero" points="284,318 284,318 " />
          <polygon fill="#252422" fill-rule="nonzero" points="179,318 179,318 " />
          <path fill="#918EBE" fill-rule="nonzero" d="M222 344l-43 -29 0 50c0,3 3,6 6,6l73 0c3,0 6,-3 6,-6l0 -50 -42 29z" />
          <path fill="#3E4054" fill-rule="nonzero" d="M340 506c0,13 -10,23 -23,23 -13,0 -24,-10 -24,-23 0,-13 11,-24 24,-24 13,0 23,11 23,24z" />
          <path fill="#D9EEF5" fill-rule="nonzero" d="M314 513l0 1 0 0 0 1 0 0 -1 0c0,1 -1,1 -1,1 -1,0 -1,0 -2,0 0,0 -1,1 -2,1 -1,0 -3,-1 -4,-1 -1,-1 -2,-1 -3,-2 0,-1 -1,-2 -2,-3 0,-2 0,-3 0,-5 0,-2 0,-3 1,-5 0,-1 1,-2 2,-3 0,-1 1,-2 3,-2 1,-1 2,-1 4,-1 0,0 1,0 1,0 1,0 1,0 2,0 0,1 1,1 1,1 0,0 1,0 1,1l0 0 0 0 0 1 0 1 0 0 0 1 0 0 0 0 -1 0c0,0 -1,0 -1,-1 0,0 -1,0 -1,0 -1,0 -2,-1 -2,-1 -1,0 -2,1 -3,1 0,0 -1,1 -1,1 -1,1 -1,2 -1,3 0,1 -1,2 -1,3 0,1 1,2 1,3 0,1 0,2 1,2 0,1 1,1 2,2 0,0 1,0 2,0 1,0 1,0 2,0 0,-1 1,-1 1,-1 1,0 1,0 1,0l1 -1 0 0 0 1 0 0c0,0 0,1 0,1z" />
          <path fill="#D9EEF5" fill-rule="nonzero" d="M328 515l0 1 -1 0 -1 0c0,0 0,0 -1,0 0,0 -1,0 -1,0l-1 0 0 0 -1 0 0 0 0 -1 -6 -18c0,-1 0,-1 -1,-1l1 -1 0 0c1,0 1,0 2,0 0,0 1,0 1,0l1 0 0 1 0 0 5 16 0 0 5 -16 0 0 1 -1 0 0c1,0 1,0 1,0 1,0 1,0 2,0l0 0 0 1c0,0 0,0 0,1l-6 18z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M205 407c-21,0 -40,10 -52,27l5 4c10,-15 28,-25 47,-25 20,0 37,10 47,25l5 -4c-11,-17 -30,-27 -52,-27z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M205 418c-18,0 -34,9 -43,24l4 4c8,-13 23,-22 39,-22 17,0 31,9 39,22l5 -4c-9,-15 -25,-24 -44,-24z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M205 431c-14,0 -27,8 -34,20l5 5c5,-11 16,-19 29,-19 13,0 25,8 30,19l5 -5c-7,-12 -20,-20 -35,-20z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M205 445c-11,0 -20,7 -23,17l6 6c1,-9 8,-16 17,-16 9,0 17,7 18,16l6 -6c-3,-10 -13,-17 -24,-17z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M205 461c-4,0 -8,4 -8,9 0,5 4,8 8,8 5,0 9,-3 9,-8 0,-5 -4,-9 -9,-9z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M614 490l-301 0c-11,0 -21,-10 -21,-22l0 -28c0,-12 10,-22 21,-22l301 0 0 72z" />
          <path fill="#918EBE" fill-rule="nonzero" d="M625 490l-320 0c-6,0 -10,-5 -10,-11l0 -47c0,-6 4,-11 10,-11l320 0 0 -7 -320 0c-9,0 -17,8 -17,18l0 47c0,10 8,18 17,18l320 0 0 -7z" />
          <polygon fill="#918EBE" fill-rule="nonzero" points="341,485 326,465 311,485 311,432 341,432 " />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M670 407l-357 0c-11,0 -20,-9 -20,-20 0,-11 9,-20 20,-20l357 0 0 40z" />
          <path fill="#F9B236" fill-rule="nonzero" d="M683 407l-376 0c-6,0 -11,-5 -11,-11l0 -16c0,-6 5,-11 11,-11l376 0 0 -6 -376 0c-10,0 -19,8 -19,17l0 16c0,10 9,18 19,18l376 0 0 -7z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M593 358l-238 0c-12,0 -22,-10 -22,-22l0 -9c0,-12 10,-22 22,-22l238 0 0 53z" />
          <path fill="#A6C2DA" fill-rule="nonzero" d="M602 358l-258 0c-5,0 -8,-4 -8,-9l0 -34c0,-5 3,-8 8,-8l258 0 0 -5 -258 0c-8,0 -13,6 -13,13l0 34c0,8 5,14 13,14l258 0 0 -5z" />
          <polygon fill="#A6C2DA" fill-rule="nonzero" points="335,360 456,360 456,305 335,305 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="373,354 361,339 349,354 349,315 373,315 " />
          <polygon fill="#918EBE" fill-rule="nonzero" points="305,432 352,432 352,431 305,431 " />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M302 693l346 0c12,0 21,-10 21,-21l0 -32c0,-12 -9,-21 -21,-21l-346 0 0 74z" />
          <path fill="#433C68" fill-rule="nonzero" d="M289 693l366 0c6,0 11,-5 11,-11l0 -49c0,-7 -5,-12 -11,-12l-366 0 0 -7 366 0c10,0 18,8 18,19l0 49c0,10 -8,18 -18,18l-366 0 0 -7z" />
          <polygon fill="#433C68" fill-rule="nonzero" points="497,696 667,696 667,619 497,619 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="614,688 631,667 648,688 648,633 614,633 " />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M747 604l-434 0c-12,0 -22,-10 -22,-22l0 -58c0,-12 10,-21 22,-21l434 0 0 101z" />
          <path fill="#6258A1" fill-rule="nonzero" d="M763 604l-454 0c-8,0 -14,-7 -14,-16l0 -66c0,-9 6,-16 14,-16l454 0 0 -9 -454 0c-13,0 -23,11 -23,25l0 66c0,14 10,25 23,25l454 0 0 -9z" />
          <polygon fill="#918EBE" fill-rule="nonzero" points="360,597 339,568 318,597 318,522 360,522 " />
          <polygon fill="#918EBE" fill-rule="nonzero" points="309,522 376,522 376,520 309,520 " />
          <path fill="#E6E8F5" fill-rule="nonzero" d="M276 762l513 0c12,0 21,-10 21,-22l0 -13c0,-12 -9,-22 -21,-22l-513 0 0 57z" />
          <path fill="#918EBE" fill-rule="nonzero" d="M258 762l531 0c9,0 17,-7 17,-16l0 -22c0,-9 -8,-16 -17,-16l-531 0 0 -9 531 0c15,0 27,11 27,25l0 22c0,14 -12,25 -27,25l-531 0 0 -9z" />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,530 732,530 732,529 383,529 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,537 732,537 732,536 383,536 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,545 732,545 732,543 383,543 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,552 732,552 732,550 383,550 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,559 732,559 732,557 383,557 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,566 732,566 732,565 383,565 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,574 732,574 732,572 383,572 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,581 732,581 732,579 383,579 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,523 732,523 732,521 383,521 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="383,588 732,588 732,586 383,586 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,718 799,718 799,717 282,717 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,723 799,723 799,722 282,722 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,728 799,728 799,727 282,727 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,734 799,734 799,732 282,732 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,739 799,739 799,737 282,737 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,744 799,744 799,742 282,742 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,749 799,749 799,747 282,747 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,754 799,754 799,753 282,753 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,713 799,713 799,712 282,712 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="282,759 799,759 799,758 282,758 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,438 611,438 611,437 355,437 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,444 611,444 611,442 355,442 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,450 611,450 611,448 355,448 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,456 611,456 611,454 355,454 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,462 611,462 611,460 355,460 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,467 611,467 611,466 355,466 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,473 611,473 611,472 355,472 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,479 611,479 611,478 355,478 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,432 611,432 611,431 355,431 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="355,485 611,485 611,484 355,484 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,376 667,376 667,375 302,375 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,379 667,379 667,379 302,379 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,383 667,383 667,382 302,382 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,386 667,386 667,385 302,385 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,389 667,389 667,389 302,389 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,393 667,393 667,392 302,392 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,396 667,396 667,395 302,395 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,399 667,399 667,399 302,399 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,373 667,373 667,372 302,372 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="302,403 667,403 667,402 302,402 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,632 496,632 496,630 309,630 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,639 496,639 496,637 309,637 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,646 496,646 496,644 309,644 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,653 496,653 496,651 309,651 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,660 496,660 496,659 309,659 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,668 496,668 496,666 309,666 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,675 496,675 496,673 309,673 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,682 496,682 496,680 309,680 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,624 496,624 496,623 309,623 " />
          <polygon fill="#EFF0F9" fill-rule="nonzero" points="309,689 496,689 496,687 309,687 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,314 593,314 593,313 456,313 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,319 593,319 593,318 456,318 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,324 593,324 593,323 456,323 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,330 593,330 593,328 456,328 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,335 593,335 593,333 456,333 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,340 593,340 593,339 456,339 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,345 593,345 593,344 456,344 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,350 593,350 593,349 456,349 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,309 593,309 593,308 456,308 " />
          <polygon fill="#E6E8F5" fill-rule="nonzero" points="456,355 593,355 593,354 456,354 " />
          <path fill="#625F61" fill-rule="nonzero" d="M816 481c-1,-3 -1,-7 1,-11 1,-3 5,-5 8,-5 1,0 2,0 3,1 0,0 1,1 1,1 2,2 5,4 8,5 3,2 7,2 11,3 2,1 4,2 6,4 1,2 1,5 0,8 0,3 0,5 -1,8 0,2 0,2 0,5 -1,2 -1,4 -3,6 0,1 -1,5 -2,5 -1,1 0,-3 -1,-4 -1,0 -1,-1 -1,-2 -1,-4 -1,-9 -4,-13 -2,-2 -4,-3 -7,-3 -2,0 -5,2 -7,1 -5,0 -10,-5 -12,-9z" />
          <polygon fill="#ED7378" fill-rule="nonzero" points="832,521 847,521 847,501 832,501 " />
          <path fill="#ED7378" fill-rule="nonzero" d="M824 489c0,0 0,20 5,23 6,3 14,-2 17,-4 2,-2 4,-7 4,-7 0,0 2,-3 2,-5 0,-2 0,-5 -3,-4 -3,2 -1,1 -1,1 0,0 1,-3 -2,-5 -2,-3 -21,-9 -22,1z" />
          <path fill="#ED7378" fill-rule="nonzero" d="M859 770l0 7c1,0 9,-1 9,-1l-1 -6 -8 0z" />
          <path fill="#433C68" fill-rule="nonzero" d="M857 774c0,0 -18,10 -18,10 -1,0 -1,0 -2,1 -2,0 -3,1 -3,3 0,0 0,1 0,1l35 -1c0,0 1,-11 -1,-12 -2,0 -8,2 -11,-2z" />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="847,780 848,783 847,783 846,780 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="845,781 846,784 845,784 844,782 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="843,782 844,785 843,785 842,783 " />
          <path fill="#ED7378" fill-rule="nonzero" d="M816 770l0 6c0,0 8,0 8,0l0 -6 -8 0z" />
          <path fill="#433C68" fill-rule="nonzero" d="M814 774c0,0 -19,10 -19,10 0,0 -1,0 -2,0 -1,1 -2,2 -2,3 0,1 0,1 0,1l35 0c0,0 0,-11 -2,-12 -1,0 -8,2 -10,-2z" />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="803,780 805,783 804,783 802,780 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="801,781 803,783 802,784 800,781 " />
          <polygon fill="#FEFEFE" fill-rule="nonzero" points="799,782 801,784 800,785 798,782 " />
          <polygon fill="#433C68" fill-rule="nonzero" points="816,618 813,771 825,771 847,618 " />
          <polygon fill="#433C68" fill-rule="nonzero" points="833,621 855,771 869,771 868,618 832,618 " />
          <path fill="#ED7378" fill-rule="nonzero" d="M772 524c0,0 -2,-2 -2,-5 0,-2 -2,-8 -3,-6 -1,1 1,4 -1,3 -2,-1 -5,-8 -6,-7 -1,1 -3,5 -1,9 3,5 5,5 6,7 2,1 4,4 4,4l3 -5z" />
          <path fill="#918EBE" fill-rule="nonzero" d="M814 524l-17 14c-1,1 -2,1 -2,0l-24 -16 -4 6 30 29 1 0 18 -15 -2 -18z" />
          <path fill="#A7A3D1" fill-rule="nonzero" d="M814 524l10 -4 8 -3c5,3 10,2 15,-1l8 3 17 7 -4 92 -13 0 0 0 -32 0 0 0 -7 0 -2 -94z" />
          <polygon fill="#F6F8F9" fill-rule="nonzero" points="810,580 839,580 839,530 810,530 " />
          <path fill="#ED7378" fill-rule="nonzero" d="M844 562c0,0 -2,0 -2,-2 0,-1 -2,-4 -3,-3 0,1 -6,-2 -6,1 -1,3 0,7 1,8 2,1 8,4 8,4l2 -8z" />
          <path fill="#918EBE" fill-rule="nonzero" d="M872 526c0,0 13,33 12,43 0,9 -41,3 -41,3 0,0 -3,-4 -1,-7 1,-3 3,-4 3,-4l24 1c0,0 -15,-44 3,-36z" />
          <path fill="#433C68" fill-rule="nonzero" d="M221 655c0,0 -25,37 -22,46 4,9 18,22 18,22l10 -3c0,0 -9,-16 -9,-19 1,-3 10,-21 10,-21l-7 -25z" />
          <path fill="#433C68" fill-rule="nonzero" d="M284 655c0,0 24,37 21,46 -4,9 -17,22 -17,22l-10 -4c0,0 8,-17 8,-19 1,-3 -10,-20 -10,-20l8 -25z" />
          <path fill="#433C68" fill-rule="nonzero" d="M284 655c-1,-3 -25,-9 -25,-9 0,0 -4,5 -4,5 -1,0 -2,5 -3,9 0,-4 -2,-9 -2,-9 -1,0 -4,-4 -4,-4 0,0 -24,5 -26,8 -2,4 3,82 5,84 3,1 15,4 18,6 3,2 8,1 9,0 2,1 5,-1 8,-2 4,-2 17,-5 19,-7 3,-1 7,-77 5,-81z" />
          <path fill="#F9B236" fill-rule="nonzero" d="M210 720c0,0 -13,-6 -19,-3 -7,3 -12,14 -5,21 7,7 62,28 67,29 4,1 16,1 16,1l3 -10c0,0 -42,-29 -62,-38z" />
          <path fill="#F9B236" fill-rule="nonzero" d="M291 720c0,0 13,-6 20,-3 6,3 11,14 4,21 -7,7 -62,28 -66,29 -5,1 -17,1 -17,1l-3 -10c0,0 42,-29 62,-38z" />
          <path fill="#252422" fill-rule="nonzero" d="M211 752c0,0 -3,-2 -5,-2 -2,0 -3,3 -4,6 -1,3 -2,14 0,15 1,1 6,0 6,0l3 -19z" />
          <path fill="#433C68" fill-rule="nonzero" d="M232 767c0,0 -14,0 -17,1 -3,1 -7,5 -9,2 -1,-2 0,-20 5,-18 2,1 6,3 9,4 3,2 9,2 9,2l3 9z" />
          <path fill="#252422" fill-rule="nonzero" d="M290 752c0,0 3,-2 5,-2 2,0 3,3 4,6 1,3 1,14 0,15 -1,1 -6,0 -6,0l-3 -19z" />
          <path fill="#433C68" fill-rule="nonzero" d="M269 767c0,0 14,0 17,1 3,1 7,5 8,2 2,-2 1,-20 -4,-18 -2,1 -6,3 -9,4 -3,2 -9,2 -9,2l-3 9z" />
          <path fill="#F6F8F9" fill-rule="nonzero" d="M288 736l-75 0c-2,0 -3,-1 -3,-2l-3 -41c0,-2 1,-3 3,-3l80 0c2,0 3,1 3,3l-2 41c0,1 -2,2 -3,2z" />
          <path fill="#ED7378" fill-rule="nonzero" d="M269 636c0,-1 0,-1 -1,-2l0 0c-1,0 -2,1 -2,1 0,0 0,-1 0,-1 0,-7 -14,-7 -14,-6 0,-1 -13,-1 -13,6 0,0 0,1 0,1 -1,0 -2,-1 -2,-1l-1 0c0,1 0,1 0,2 -1,1 -1,3 0,4 0,2 1,3 2,4 0,0 1,1 1,1 0,2 1,4 1,4 1,3 7,10 12,10l0 0c6,0 12,-7 13,-10 0,0 0,-2 0,-4 1,0 1,-1 2,-1 1,-1 2,-2 2,-4 0,-1 0,-3 0,-4z" />
          <path fill="#252422" fill-rule="nonzero" d="M269 635c0,-2 0,-3 0,-4 0,-2 0,-3 0,-4 0,-1 -1,-2 -1,-3 -1,-2 -2,-4 -3,-5 -2,-2 -6,-3 -9,-3 -5,-1 -10,0 -16,-2 -1,0 -2,-1 -4,-2 0,-1 -1,-2 -2,-1 -1,0 -1,1 -1,2 0,2 1,4 1,6 0,1 0,2 1,3 0,1 0,2 1,3 0,0 -1,-1 -2,-1 0,1 1,3 2,4 0,0 1,1 0,1l0 0 0 1c1,1 2,3 3,5 0,0 0,1 0,1 1,0 2,-1 2,-1 1,-2 3,-4 5,-4 1,0 2,0 3,0 2,0 3,0 5,0 1,0 2,-1 3,0 2,0 3,0 4,1 1,1 3,2 4,3l0 1c1,0 2,-1 2,-1 0,-1 1,-1 2,0z" />
          <path fill="#F9B236" fill-rule="nonzero" d="M764 268c0,10 -6,20 -15,25 1,-4 2,-7 2,-11l0 -15 13 0 0 1zm-88 0l0 -1 13 0 0 15c0,4 1,7 2,11 -9,-5 -15,-15 -15,-25zm75 -5l0 -8 -62 0 0 8 -17 0 0 5c0,14 9,26 22,30 4,7 12,12 20,13l0 8 -4 0c-4,0 -7,4 -7,8l-9 0 0 4 52 0 0 -4 -9 0c0,-4 -3,-8 -7,-8l-5 0 0 -8c9,-1 16,-6 21,-13 13,-4 22,-16 22,-30l0 -5 -17 0z" />
          <path fill="#FEFEFE" fill-rule="nonzero" d="M720 259l-6 13 -14 2 10 10 -3 13 13 -6 12 6 -2 -13 10 -10 -14 -2 -6 -13zm0 9l2 6 1 1 2 1 6 1 -4 4 -1 1 0 2 1 6 -5 -3 -2 -1 -2 1 -5 3 1 -6 0 -2 -1 -1 -5 -4 6 -1 2 -1 1 -1 3 -6z" />
        </g>
      </g>
    </svg>
  </Fragment>
}

export default StudyTap;