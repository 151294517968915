import React, { Fragment } from 'react';
import styled from 'styled-components';


const Section = styled.section`
    width : 100%;
    height : auto;
    padding : 20px 10%;

`;

const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 15px 0;

h1{
    font-size : 1.5rem;
}
`;

const OurProcessDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;

@media screen and (max-width : 768px) {
    flex-direction : column;
}
`;

const Process = styled.div`
    width : 100%;
    height : auto;
    padding : 15px;
    display : flex;
    justify-content : center;
    align-items : center;
    margin : 0 10px;
    background: rgba( 144, 135, 221, 0.20 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.0px );
    -webkit-backdrop-filter: blur( 4.0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    

@media screen and (max-width: 768px) {
    padding :  0;       
}
`;


const ProcessText = styled.div`
    width : 100%;
    height : auto;
    padding : 10px 0;
    line-height : 30px;
    text-align : center;

p{
    font-weight : bold;
    padding : 10px 0;
    position : relative;
    display : flex;
    justify-content : center;
}
`;

const WhatWeOffer = () => {


    return <Section>
        <Heading>
            <h1>WHAT WE OFFER</h1>
        </Heading>
        <OurProcessDiv>
            <Process>
                <ProcessText data-aos="zoom-in-left">
                    <p>1. WEB ENABLEMENT OF ANY LEGACY APPLICATIONS</p>
                    <p>2. CUSTOMIZED WEB PORTAL SOLUTIONS</p>
                    <p>3. COMMUNITY SITE DEVELOPMENT</p>
                    <p>4. CUSTOM SOCIAL NETWORK DEVELOPMENT</p>
                    <p>5. E COMMERCE SOLUTIONS</p>
                </ProcessText>
            </Process>
            <Process>
                <ProcessText data-aos="zoom-in-right">
                    <p>6. INTERNET AND INTRANET SOLUTIONS</p>
                    <p>7. SPECIFIC CUSTOM APPLICATIONS AS PER CLIENT DEMAND</p>
                    <p>8. CORPORATE WEB BASED SOLUTIONS</p>
                    <p>9. BUSINESS APPLICATIONS</p>
                    <p>10. PAYMENT PROCESSOR INTEGRATIONS</p>
                </ProcessText>
            </Process>
        </OurProcessDiv>
    </Section>
}


export default WhatWeOffer;