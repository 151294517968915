


const Svg = () => {

    return <svg width="100%" height="100%" version="1.1" viewBox="0 0 1450 918">
     <defs>
        <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="235.54" y1="832.733" x2="587.05" y2="832.733">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.458824" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.560784" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.619608" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1="704.421" y1="786.034" x2="414.236" y2="618.763">
         <stop offset="0" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="0.368627" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="0.541176" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="1" stop-opacity="1" stop-color="#EAEAF9"/>
        </linearGradient>
        <linearGradient id="id2" gradientUnits="userSpaceOnUse" x1="464.622" y1="766.97" x2="464.622" y2="647.042">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="531.31" y1="671.782" x2="574.821" y2="671.782">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id4" gradientUnits="userSpaceOnUse" x1="531.307" y1="663.862" x2="574.818" y2="663.862">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id5" gradientUnits="userSpaceOnUse" x1="531.316" y1="669.12" x2="574.833" y2="669.12">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id6" gradientUnits="userSpaceOnUse" x1="575.635" y1="861.055" x2="813.979" y2="861.055">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.431373" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.54902" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id7" gradientUnits="userSpaceOnUse" x1="870.988" y1="816.686" x2="857.406" y2="976.621">
         <stop offset="0" stop-opacity="1" stop-color="#450D95"/>
         <stop offset="0.788235" stop-opacity="1" stop-color="#450D95"/>
         <stop offset="0.890196" stop-opacity="1" stop-color="#6732EF"/>
         <stop offset="1" stop-opacity="1" stop-color="#6732EF"/>
        </linearGradient>
        <linearGradient id="id8" gradientUnits="userSpaceOnUse" x1="791.613" y1="814.805" x2="714.913" y2="814.805">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id9" gradientUnits="userSpaceOnUse" x1="1305.09" y1="309.553" x2="1305.09" y2="488.286">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id10" gradientUnits="userSpaceOnUse" x1="1351.43" y1="525.547" x2="1351.43" y2="412.527">
         <stop offset="0" stop-opacity="1" stop-color="#6732EF"/>
         <stop offset="0.129412" stop-opacity="1" stop-color="#6732EF"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#450D95"/>
         <stop offset="1" stop-opacity="1" stop-color="#450D95"/>
        </linearGradient>
        <linearGradient id="id11" gradientUnits="userSpaceOnUse" x1="1393.09" y1="547.374" x2="1393.09" y2="469.864">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id12" gradientUnits="userSpaceOnUse" x1="1169.41" y1="669.249" x2="1197.07" y2="669.249">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id13" gradientUnits="userSpaceOnUse" x1="1169.41" y1="659.729" x2="1169.42" y2="659.729">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id14" gradientUnits="userSpaceOnUse" x1="1211.94" y1="705.645" x2="1211.96" y2="705.645">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id15" gradientUnits="userSpaceOnUse" x1="1211.94" y1="711.292" x2="1235.83" y2="711.292">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id16" gradientUnits="userSpaceOnUse" x1="1211.96" y1="711.198" x2="1235.77" y2="711.198">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id17" gradientUnits="userSpaceOnUse" x1="-72.1595" y1="411.679" x2="167.761" y2="411.679">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id18" gradientUnits="userSpaceOnUse" x1="290.967" y1="478.72" x2="260.104" y2="495.649">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.478431" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id19" gradientUnits="userSpaceOnUse" x1="1178.8" y1="333.681" x2="124.19" y2="333.681">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.478431" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.541176" stop-opacity="1" stop-color="#ABB8ED"/>
         <stop offset="0.568627" stop-opacity="1" stop-color="#6D86E0"/>
         <stop offset="1" stop-opacity="1" stop-color="#6D86E0"/>
        </linearGradient>
        <linearGradient id="id20" gradientUnits="userSpaceOnUse" x1="978.316" y1="799.45" x2="1073" y2="799.45">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id21" gradientUnits="userSpaceOnUse" x1="1061.56" y1="710.981" x2="991.683" y2="710.981">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#ABB8ED"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#6D86E0"/>
         <stop offset="1" stop-opacity="1" stop-color="#6D86E0"/>
        </linearGradient>
        <linearGradient id="id22" gradientUnits="userSpaceOnUse" x1="995.232" y1="781.632" x2="995.232" y2="839.279">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id23" gradientUnits="userSpaceOnUse" x1="1001.54" y1="781.857" x2="1001.54" y2="839.277">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id24" gradientUnits="userSpaceOnUse" x1="1008.01" y1="781.731" x2="1008.01" y2="839.355">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id25" gradientUnits="userSpaceOnUse" x1="446.804" y1="603.67" x2="437.8" y2="603.67">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id26" gradientUnits="userSpaceOnUse" x1="809.407" y1="623.969" x2="932.491" y2="623.969">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id27" gradientUnits="userSpaceOnUse" x1="491.299" y1="444.307" x2="385.591" y2="383.279">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id28" gradientUnits="userSpaceOnUse" x1="612.112" y1="374.993" x2="523.537" y2="323.857">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id29" gradientUnits="userSpaceOnUse" x1="556.102" y1="406.699" x2="488.081" y2="367.43">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id30" gradientUnits="userSpaceOnUse" x1="522.897" y1="503.539" x2="621.664" y2="503.539">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id31" gradientUnits="userSpaceOnUse" x1="525.219" y1="503.594" x2="613.349" y2="503.594">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id32" gradientUnits="userSpaceOnUse" x1="528.169" y1="499.294" x2="626.865" y2="499.294">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id33" gradientUnits="userSpaceOnUse" x1="52.7172" y1="576.373" x2="125.444" y2="576.373">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id34" gradientUnits="userSpaceOnUse" x1="116.955" y1="509.815" x2="63.2876" y2="509.815">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#ABB8ED"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#6D86E0"/>
         <stop offset="1" stop-opacity="1" stop-color="#6D86E0"/>
        </linearGradient>
        <linearGradient id="id35" gradientUnits="userSpaceOnUse" x1="117.199" y1="510.342" x2="63.0431" y2="510.342">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#ABB8ED"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#6D86E0"/>
         <stop offset="1" stop-opacity="1" stop-color="#6D86E0"/>
        </linearGradient>
        <linearGradient id="id36" gradientUnits="userSpaceOnUse" x1="107.506" y1="615.275" x2="107.506" y2="584.122">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id37" gradientUnits="userSpaceOnUse" x1="335.528" y1="131.573" x2="413.815" y2="131.573">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id38" gradientUnits="userSpaceOnUse" x1="348.643" y1="116.024" x2="363.716" y2="116.024">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id39" gradientUnits="userSpaceOnUse" x1="348.893" y1="140.357" x2="363.968" y2="140.357">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id40" gradientUnits="userSpaceOnUse" x1="578.521" y1="42.0536" x2="655.225" y2="173.613">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.0588235" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.439216" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id41" gradientUnits="userSpaceOnUse" x1="635.661" y1="271.05" x2="518.902" y2="116.071">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.54902" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id42" gradientUnits="userSpaceOnUse" x1="593.078" y1="117.621" x2="663.791" y2="117.621">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.560784" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id43" gradientUnits="userSpaceOnUse" x1="649.162" y1="207.898" x2="649.162" y2="293.311">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.560784" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id44" gradientUnits="userSpaceOnUse" x1="730.991" y1="411.297" x2="730.991" y2="322.992">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.290196" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.560784" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id45" gradientUnits="userSpaceOnUse" x1="704.534" y1="82.2491" x2="900.164" y2="421.094">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.239216" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.34902" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.45098" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id46" gradientUnits="userSpaceOnUse" x1="704.534" y1="82.2491" x2="900.164" y2="421.094">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.239216" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.34902" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.45098" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id47" gradientUnits="userSpaceOnUse" x1="1012.69" y1="552.561" x2="1012.69" y2="450.854">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.509804" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id48" gradientUnits="userSpaceOnUse" x1="912.891" y1="508.125" x2="905.384" y2="385.232">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.509804" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id49" gradientUnits="userSpaceOnUse" x1="912.891" y1="508.125" x2="905.384" y2="385.232">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.509804" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id50" gradientUnits="userSpaceOnUse" x1="865.847" y1="167.723" x2="1006.78" y2="411.818">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.341176" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.388235" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.431373" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id51" gradientUnits="userSpaceOnUse" x1="1001.01" y1="274.081" x2="1117.38" y2="475.629">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.45098" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.501961" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="0.560784" stop-opacity="1" stop-color="#BEBEED"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
        <linearGradient id="id52" gradientUnits="userSpaceOnUse" x1="108.073" y1="558.972" x2="404.93" y2="558.972">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id53" gradientUnits="userSpaceOnUse" x1="135.8" y1="534.727" x2="406.001" y2="534.727">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id54" gradientUnits="userSpaceOnUse" x1="330.087" y1="234.854" x2="417.701" y2="234.854">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id55" gradientUnits="userSpaceOnUse" x1="968.366" y1="790.527" x2="1225.21" y2="790.527">
         <stop offset="0" stop-opacity="1" stop-color="#FFD33F"/>
         <stop offset="1" stop-opacity="1" stop-color="#FF7364"/>
        </linearGradient>
        <linearGradient id="id56" gradientUnits="userSpaceOnUse" x1="1304.92" y1="301.742" x2="1304.92" y2="336.581">
         <stop offset="0" stop-opacity="1" stop-color="#EAEAF9"/>
         <stop offset="0.631373" stop-opacity="1" stop-color="#D4D4F3"/>
         <stop offset="1" stop-opacity="1" stop-color="#BEBEED"/>
        </linearGradient>
     </defs>
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_692460616">
       <path fill="#96A1E1" fill-rule="nonzero" d="M487 901l-39 0 -197 -115c-3,-2 -3,-5 0,-7l129 -74c1,-1 3,-1 5,-1 1,0 3,0 4,1l214 123c3,1 3,5 0,6l-116 67z"/>
       <path fill="url(#id0)" d="M463 887c-6,4 -14,4 -20,0l-207 -122 0 0 0 11c0,3 1,6 4,7l200 115c8,5 18,5 26,0l114 -65c4,-3 7,-7 7,-11l0 -9 -124 74z"/>
       <path fill="#F7F7F9" fill-rule="nonzero" d="M463 887l122 -70c3,-1 3,-5 0,-7l-211 -121c-3,-2 -6,-2 -9,0l-128 73c-2,2 -2,5 0,7l206 118c6,4 14,4 20,0z"/>
       <path fill="#7777D8" fill-rule="nonzero" d="M534 799c2,1 5,1 7,0 2,-1 2,-3 0,-4 -2,-1 -5,-1 -7,0 -2,1 -2,3 0,4z"/>
       <path fill="#7777D8" fill-rule="nonzero" d="M544 804c2,1 5,1 7,0 2,-1 2,-3 0,-4 -2,-1 -5,-1 -7,0 -2,1 -2,3 0,4z"/>
       <path fill="#7777D8" fill-rule="nonzero" d="M553 810c2,1 5,1 7,0 2,-2 2,-3 0,-4 -2,-1 -5,-1 -7,0 -2,1 -2,3 0,4z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M329 808l40 23c4,2 12,2 17,-1l15 -8c5,-3 6,-8 1,-10l-40 -23c-4,-3 -12,-3 -17,0l-15 9c-5,3 -5,7 -1,10z"/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="363,720 349,712 363,704 377,712 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="379,730 366,722 379,714 393,722 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="396,739 382,731 396,723 409,731 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="412,748 398,741 412,733 426,741 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="428,758 415,750 428,742 442,750 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="445,767 431,759 445,752 458,759 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="461,777 447,769 461,761 475,769 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="477,786 464,778 477,770 491,778 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="494,796 480,788 493,780 507,788 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="510,805 496,797 510,789 523,797 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="526,814 512,807 526,799 540,807 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="542,824 529,816 542,808 556,816 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="346,730 332,722 346,714 359,722 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="362,740 348,732 362,724 376,732 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="378,749 365,741 378,733 392,741 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="395,758 381,751 395,743 408,751 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="411,768 397,760 411,752 425,760 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="427,777 414,769 427,762 441,769 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="444,787 430,779 444,771 457,779 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="460,796 446,788 460,780 474,788 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="476,806 463,798 476,790 490,798 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="493,815 479,807 492,799 506,807 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="509,825 495,817 509,809 522,817 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="525,834 511,826 525,818 539,826 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="328,741 314,733 328,725 341,733 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="344,750 330,742 344,734 358,742 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="360,759 347,752 360,744 374,752 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="377,769 363,761 377,753 390,761 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="393,778 379,770 393,763 407,770 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="409,788 396,780 409,772 423,780 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="426,797 412,789 426,781 439,789 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="442,807 428,799 442,791 456,799 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="458,816 445,808 458,800 472,808 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="475,825 461,818 474,810 488,818 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="491,835 477,827 491,819 504,827 "/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="507,844 493,836 507,829 521,836 "/>
       <path fill="white" fill-rule="nonzero" d="M337 836l-7 -4c-1,-1 -2,-2 -2,-4 0,-1 1,-1 2,-1l7 4c1,1 2,2 2,4 0,1 -1,2 -2,1z"/>
       <polygon fill="#757575" fill-rule="nonzero" points="373,526 373,526 373,526 "/>
       <path fill="url(#id1)" d="M605 652c-1,-7 -5,-13 -11,-16l-202 -117c-3,-1 -6,-1 -9,0l-10 6c3,-1 6,-1 8,0l202 117c6,3 10,9 11,16 1,1 1,3 1,4l0 134c0,5 -2,8 -6,10l10 -6c4,-1 6,-5 6,-10l0 -134c0,-1 0,-3 0,-4z"/>
       <path fill="#F7F7F9" fill-rule="nonzero" d="M373 526l0 0 0 -1c3,-1 6,-1 8,0l202 117c7,4 12,12 12,20l0 134c0,5 -2,8 -6,10 -3,2 -7,2 -11,0l-198 -113c-7,-4 -11,-11 -11,-20l0 -140c0,-3 2,-6 4,-7z"/>
       <path fill="#001066" fill-rule="nonzero" d="M581 647l-202 -116c-2,-1 -5,0 -5,3l0 139c0,7 4,13 9,16l193 110c6,3 12,-1 12,-7l0 -131c0,-6 -2,-11 -7,-14z"/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,749 569,743 569,745 579,751 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,713 550,714 572,728 572,726 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,716 550,718 565,727 565,725 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,733 567,726 567,728 579,735 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,719 550,721 579,738 579,736 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,740 555,726 555,727 579,741 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,729 550,730 579,747 579,746 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,732 550,734 568,744 568,743 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,735 550,737 578,754 578,752 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,756 555,742 555,744 579,758 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,742 550,744 579,761 579,760 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,746 550,747 569,759 569,757 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,763 570,758 570,760 579,765 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,752 550,754 565,763 565,761 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="579,769 566,762 566,764 579,771 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,762 550,764 571,777 571,775 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="550,766 550,767 578,784 578,783 "/>
       <path fill="url(#id2)" d="M388 678l23 -34c3,-4 9,-3 11,2l5 15c1,4 6,5 8,2l25 -26c4,-4 11,-2 13,3l18 52c3,10 15,13 22,7l3 -2c3,-3 8,-1 9,3l16 65 -153 -87z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M539 666c0,-7 5,-9 10,-6l0 -17c-14,-8 -25,-2 -25,14l15 9 0 0z"/>
       <path fill="#4A5EA5" fill-rule="nonzero" d="M539 666l-15 -9c0,9 3,18 7,26l11 -7c-1,-3 -3,-7 -3,-10z"/>
       <path fill="url(#id3)" d="M575 687c0,-16 -12,-36 -26,-44l0 0 0 17c6,3 10,11 10,17 0,7 -4,9 -10,6 -2,-1 -5,-4 -7,-7l-11 7c5,8 11,15 18,19 14,8 26,1 26,-15l0 0z"/>
       <path fill="url(#id4)" d="M464 611l0 1c-2,-1 -3,0 -3,2l-1 -1c0,-2 2,-3 4,-2z"/>
       <path fill="url(#id5)" d="M464 623c3,2 6,0 6,-4 0,-4 -3,-9 -6,-11l0 -4c5,3 9,11 9,17 0,6 -4,8 -9,5l0 -3 0 0z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M457 612c0,4 3,9 7,11l0 3c-6,-3 -10,-10 -10,-16l3 2z"/>
       <polygon fill="#FF7364" fill-rule="nonzero" points="393,610 393,615 389,612 389,608 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="394,601 394,605 389,603 390,598 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="394,591 394,596 390,594 390,589 "/>
       <polygon fill="#FF7364" fill-rule="nonzero" points="394,582 394,587 390,584 390,580 "/>
       <polygon fill="#203084" fill-rule="nonzero" points="394,577 390,575 390,570 394,573 "/>
       <polygon fill="#203084" fill-rule="nonzero" points="394,568 390,566 390,561 394,563 "/>
       <polygon fill="#203084" fill-rule="nonzero" points="400,618 396,616 396,611 400,614 "/>
       <polygon fill="#FF7364" fill-rule="nonzero" points="400,604 400,609 396,607 396,602 "/>
       <polygon fill="#FFD33F" fill-rule="nonzero" points="400,595 400,600 396,597 396,593 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="400,586 400,590 396,588 396,583 "/>
       <polygon fill="#203084" fill-rule="nonzero" points="400,581 396,579 396,574 400,576 "/>
       <polygon fill="#001066" fill-rule="nonzero" points="400,572 396,569 396,565 400,567 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="406,617 406,622 402,620 402,615 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="406,608 406,613 402,610 402,606 "/>
       <polygon fill="#203084" fill-rule="nonzero" points="406,603 402,601 402,596 406,599 "/>
       <polygon fill="#FF7364" fill-rule="nonzero" points="406,590 406,594 402,592 402,587 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="406,580 406,585 402,582 402,578 "/>
       <polygon fill="#001066" fill-rule="nonzero" points="406,575 402,573 402,568 406,571 "/>
       <polygon fill="#FFD33F" fill-rule="nonzero" points="412,621 412,626 408,623 408,619 "/>
       <polygon fill="#FF7364" fill-rule="nonzero" points="412,612 412,616 408,614 408,609 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="412,602 412,607 408,605 408,600 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="412,593 412,598 408,595 408,591 "/>
       <polygon fill="#5A3B72" fill-rule="nonzero" points="412,584 412,588 408,586 408,581 "/>
       <polygon fill="#FFD33F" fill-rule="nonzero" points="412,574 412,579 408,577 408,572 "/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M715 918l-21 0 0 0c2,-1 4,-1 6,-2l168 -99c3,-1 4,-3 4,-5l0 -8 0 -1c0,-1 -1,-2 -2,-3l-70 -40c2,-1 4,-2 6,-2 3,0 5,1 6,2l82 47c3,2 3,6 -1,8l-178 103zm-24 -8l0 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c-2,0 -4,-1 -6,-2 2,1 4,2 6,2zm-6 -2c0,0 0,0 0,0 0,0 0,0 0,0zm14 0c0,0 0,0 0,0 0,0 0,0 0,0zm0 0c0,0 0,0 0,0 0,0 0,0 0,0zm1 0c0,-1 0,-1 0,-1 0,0 0,0 0,1z"/>
       <path fill="url(#id6)" d="M691 918c-2,0 -5,-1 -6,-2l-86 -49c-2,-2 -2,-3 -2,-4l0 -5 0 -3c0,1 1,2 2,3l86 50c0,0 0,0 0,0 0,0 0,0 0,0 2,1 4,2 6,2 0,0 0,0 0,0 0,0 0,0 0,0 3,0 6,-1 8,-2 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 1,0 1,0 0,-1 0,-1 0,-1l168 -98c3,-1 4,-3 4,-5l0 3 0 2 0 0 0 3c0,2 -1,4 -4,5l-168 99c-2,1 -4,1 -6,2 -1,0 -2,0 -3,0zm-94 -63l0 0zm88 53l0 0zm6 2l0 0zm9 -3l0 0z"/>
       <polygon fill="url(#id7)" points="872,803 872,804 "/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M599 858l86 50c4,2 10,2 15,-1l168 -98c3,-1 4,-3 4,-5l0 0 0 -1c0,-1 -1,-2 -2,-3l-86 -49c-4,-3 -10,-2 -15,0l-169 99c-2,1 -3,3 -3,5 0,1 1,2 2,3z"/>
       <path fill="#001066" fill-rule="nonzero" d="M687 904l-81 -47c-4,-3 -3,-6 1,-9l160 -93c5,-3 11,-3 14,-1l82 48c3,2 3,5 -1,8l-161 93c-4,3 -10,3 -14,1z"/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="706,877 706,877 707,877 707,877 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="704,878 704,878 705,878 705,878 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="703,880 702,880 702,879 703,879 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="700,881 700,880 700,880 700,881 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="697,882 697,882 698,882 698,882 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="695,883 695,883 696,883 696,883 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="694,885 693,885 693,884 694,884 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="692,886 691,886 691,885 692,885 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="689,887 689,887 690,887 690,887 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="687,888 687,888 688,888 688,888 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="702,875 694,871 695,870 703,875 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="700,876 692,872 693,871 701,876 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="698,878 690,873 691,872 699,877 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="696,879 681,870 682,870 697,878 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="688,879 681,874 682,874 690,878 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="686,880 679,875 680,875 687,879 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="684,881 677,877 678,876 685,881 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="693,870 677,861 678,860 694,869 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="691,871 676,863 677,862 692,871 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="689,872 673,863 674,862 690,872 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="679,869 670,864 672,864 680,868 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="679,874 667,866 668,866 681,873 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="677,875 665,867 666,867 679,874 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="675,876 663,869 664,868 677,875 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="678,880 670,875 671,875 679,879 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="669,875 660,870 661,869 670,874 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="676,881 658,871 659,871 677,881 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="674,882 656,872 657,872 675,882 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="715,883 709,879 710,879 717,883 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="713,884 707,881 708,880 715,884 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="713,889 703,883 704,882 714,888 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="709,891 701,886 702,886 710,890 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="719,885 717,884 718,883 720,885 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="717,886 715,885 716,884 718,886 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="703,895 699,893 700,892 704,894 "/>
       <polygon fill="#4754A4" fill-rule="nonzero" points="701,896 697,894 698,893 702,895 "/>
       <path fill="url(#id8)" d="M781 825c-13,8 -33,8 -46,0 -12,-7 -12,-19 0,-26l1 0 -11 -6 -1 0c-18,10 -18,28 0,39 19,10 49,10 67,0l1 -1 -11 -6 0 0z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M724 793l11 6c13,-7 33,-7 45,0l11 -6c-18,-11 -48,-11 -67,0z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M780 799l0 0c13,7 13,19 0,26l11 7c18,-11 18,-29 0,-39l-11 6z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M1412 552c-8,0 -15,-2 -21,-5l-106 -61c-12,-7 -10,-20 3,-28 8,-4 18,-7 27,-7 8,0 15,2 21,5l106 62c12,7 10,19 -3,27 -8,5 -18,7 -27,7z"/>
       <path fill="url(#id9)" d="M1323 382c-1,-3 1,-7 4,-10 1,-1 3,-2 4,-3 1,-1 2,-1 3,-2l0 -47c0,3 -2,6 -5,9 -1,1 -2,2 -4,3 0,0 -1,0 -2,1l0 0c-1,0 -1,0 -2,1 0,0 0,0 -1,0 0,0 -1,0 -1,0 0,1 -1,1 -1,1l-1 0c-2,1 -5,1 -8,1 -2,1 -4,1 -6,1 -2,-1 -5,-1 -7,-1l0 0c-1,0 -2,-1 -3,-1l0 0c-1,0 -2,0 -2,-1l-1 0c-1,0 -1,0 -2,-1l-1 0c-1,0 -1,-1 -2,-1 -2,-1 -3,-2 -4,-3 -3,-2 -4,-5 -5,-9l0 154c0,0 0,0 0,0 1,5 3,9 9,12 6,3 15,5 24,4 5,0 10,-1 14,-3 0,0 0,-2 0,-5l0 -100z"/>
       <path fill="url(#id10)" d="M1365 448c-1,-2 0,-3 0,-4 1,-3 4,-6 8,-9 2,-1 4,-2 7,-3l0 -50 0 0c-1,4 -4,7 -8,10 -1,1 -2,1 -2,1l-1 1c-1,0 -1,0 -1,0 -1,0 -1,0 -1,1 -1,0 -1,0 -2,0 0,0 0,0 -1,0l0 0c-2,1 -4,1 -6,1 -1,1 -2,1 -2,1 -3,0 -5,0 -7,0 -2,0 -4,0 -6,-1l-1 0c-1,0 -1,0 -2,0l-1 -1c-1,0 -1,0 -2,0l-1 0c0,-1 -1,-1 -2,-1l0 0 0 0c-1,-1 -2,-1 -3,-2 -5,-3 -7,-6 -8,-10 0,0 0,80 0,100 0,3 0,5 0,5l0 15c0,4 3,8 8,11 7,4 16,5 25,4 3,0 6,0 9,-1l0 -68z"/>
       <path fill="#6732EF" fill-rule="nonzero" d="M1327 372c-3,3 -5,7 -4,10 1,4 3,7 8,10 1,1 2,1 3,2l0 0 0 0c1,0 2,0 2,1l1 0c1,0 1,0 2,0l1 1c1,0 1,0 2,0l1 0c2,1 4,1 6,1 2,0 4,0 7,0 0,0 1,0 2,-1 2,0 4,0 6,-1l0 0c1,0 1,0 1,0 1,0 1,0 2,0 0,-1 0,-1 1,-1 0,0 0,0 1,0l1 -1c0,0 1,0 2,-1 4,-3 7,-6 8,-10 1,-5 -2,-10 -8,-13 -11,-6 -27,-7 -38,-2 -1,1 -2,1 -3,2 -1,1 -3,2 -4,3z"/>
       <path fill="url(#id11)" d="M1422 447l0 0c0,3 -2,6 -5,9 -1,1 -2,2 -4,3 0,0 -1,1 -2,1l0 0c-1,0 -1,1 -2,1l0 0 0 0c-1,0 -2,1 -2,1 -1,0 -1,0 -1,0 -1,0 -1,0 -2,0 0,0 0,1 -1,1 0,0 -1,0 -1,0 -1,0 -2,0 -3,0 -2,1 -4,1 -6,1 0,0 0,0 -1,0l0 0c-1,0 -2,0 -3,0l-1 0c-1,-1 -2,-1 -3,-1 -1,0 -2,0 -3,-1l-1 0c0,0 -1,0 -2,0l-1 -1c-1,0 -1,0 -2,0l-1 -1c-1,0 -1,-1 -2,-1 -3,-2 -5,-3 -6,-5 -2,-2 -2,-4 -2,-6l0 77c-1,0 -1,0 0,1 0,4 2,8 8,11 7,4 16,6 26,5 5,-1 10,-3 14,-5 6,-3 8,-7 9,-11l0 -79 0 0z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M1365 444c0,1 -1,2 0,4 0,2 0,4 2,6 1,2 3,3 6,5 1,0 1,1 2,1l1 1c1,0 1,0 2,0l1 1c1,0 2,0 2,0l1 0c1,1 2,1 3,1 1,0 2,0 3,1l1 0c1,0 2,0 3,0l0 0c1,0 1,0 1,0 2,0 4,0 6,-1 1,0 2,0 3,0 0,0 1,0 1,0 1,0 1,-1 1,-1 1,0 1,0 2,0 0,0 0,0 1,0 0,0 1,-1 2,-1l0 0 0 0c1,0 1,-1 2,-1l0 0c1,0 2,-1 2,-1 2,-1 3,-2 4,-3 3,-3 5,-6 5,-9 0,-4 -3,-9 -9,-12 -9,-5 -22,-6 -33,-3 -3,1 -5,2 -7,3 -4,3 -7,6 -8,9z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M1262 775l-37 0 -91 -54 127 0 38 22c4,3 4,8 0,10l-37 22z"/>
       <path fill="#DCDCF9" fill-rule="nonzero" d="M1226 765l0 0c0,-1 0,-1 -1,-1 0,0 -1,0 -1,0l-92 -54c-1,0 -2,-1 -2,-3l0 12c0,1 1,2 2,3l92 53c1,1 3,1 5,1l0 -11c-1,0 -2,0 -3,0z"/>
       <path fill="#C0C0F9" fill-rule="nonzero" d="M1274 741l-39 22c-1,1 -1,1 -2,1l0 0c0,0 0,0 -1,0 -1,1 -2,1 -3,1l0 11c2,0 3,0 5,-1l40 -23c2,-1 2,-2 2,-3l0 -11c0,1 -1,2 -2,3z"/>
       <path fill="white" fill-rule="nonzero" d="M1267 739l-33 19c-2,2 -5,2 -7,1l-37 -22c5,1 11,0 17,-2l-3 -2c-7,1 -14,1 -18,-1 -3,-2 -4,-6 -1,-10l-3 -2c-3,3 -5,7 -4,10l-37 -21c-2,-1 -2,-3 0,-4l8 -4 -5 -3 -11 6c-2,1 -3,2 -3,3 0,2 1,3 2,3l92 54c0,0 1,0 1,0 1,0 1,0 1,1l0 0c1,0 2,0 3,0 1,0 2,0 3,-1 1,0 1,0 1,0l0 0c1,0 1,0 2,-1l39 -22c1,-1 2,-2 2,-3 1,-1 0,-3 -1,-4l-3 -1 -4 2c1,1 1,3 -1,4z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1151 709c-2,1 -4,1 -6,0 -1,-1 -1,-2 0,-3 2,-1 4,-1 6,0 1,1 1,2 0,3zm-10 -4c-2,1 -2,3 0,4l37 21c-1,-3 1,-7 4,-10l-33 -19 -8 4z"/>
       <polygon fill="#3CB779" fill-rule="nonzero" points="1243,738 1243,738 "/>
       <path fill="#3CB779" fill-rule="nonzero" d="M1233 738c1,0 1,1 1,1 0,0 0,-1 -1,-1z"/>
       <polygon fill="#3CB779" fill-rule="nonzero" points="1235,739 1236,739 "/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1232 757c-2,0 -4,0 -6,0 -1,-1 -1,-3 0,-4 2,-1 4,-1 6,0 1,1 1,3 0,4zm-25 -22c-6,2 -12,3 -17,2l37 22c2,1 5,1 7,-1l33 -19c2,-1 2,-3 1,-4l-4 2c0,1 0,1 -1,1 0,1 -1,1 -1,1l-19 11c-1,0 -3,1 -5,1 -1,0 -3,0 -5,-1l-26 -15z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1186 732c4,2 11,2 18,1l-19 -11c-3,4 -2,8 1,10z"/>
       <path fill="white" fill-rule="nonzero" d="M1264 737l-2 2c0,0 1,0 1,-1 1,0 1,0 1,-1z"/>
       <path fill="white" fill-rule="nonzero" d="M1145 706c-1,1 -1,2 0,3 2,1 4,1 6,0 1,-1 1,-2 0,-3 -2,-1 -4,-1 -6,0z"/>
       <path fill="white" fill-rule="nonzero" d="M1232 753c-2,-1 -4,-1 -6,0 -1,1 -1,3 0,4 2,0 4,0 6,0 1,-1 1,-3 0,-4z"/>
       <path fill="#DCDCF9" fill-rule="nonzero" d="M1182 720l3 2 19 11 3 2 26 15c2,1 4,1 5,1l0 -12c-1,0 -1,0 -2,0l-1 0c0,0 0,0 -1,0 0,0 0,-1 -1,-1l-7 -4 0 0c-2,1 -3,1 -5,1l0 -11c-1,0 -2,-1 -3,-1l0 0c0,0 -1,0 -1,0 0,0 -1,0 -1,-1l-92 -53c-1,-1 -2,-2 -2,-3l0 12c0,1 1,2 2,3l15 9 0 4c0,1 1,2 2,2l3 2 5 3 33 19z"/>
       <path fill="#C0C0F9" fill-rule="nonzero" d="M1283 715l-39 23c0,0 -1,0 -1,0l0 0c-1,1 -1,1 -2,1 -1,0 -2,0 -3,0l0 12c2,0 4,-1 5,-1l19 -11 2 -2 4 -2 4 -2 12 -7c1,-1 2,-2 2,-3l0 -11c-1,1 -1,2 -3,3z"/>
       <path fill="white" fill-rule="nonzero" d="M1284 709l-16 -9 0 5 9 5c2,1 1,3 -1,4l-33 19c-2,1 -5,1 -7,0l-4 -3 -6 4 7 4c1,0 1,1 1,1 1,0 1,0 1,0l1 0c1,0 1,0 2,0 1,0 2,0 3,0 1,0 1,0 2,-1l0 0c0,0 1,0 1,0l39 -23c2,-1 2,-2 3,-3 0,-1 0,-2 -2,-3z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1268 707c0,1 0,2 0,2 1,0 3,0 5,0 1,1 1,3 0,4 -2,1 -4,1 -6,0 -1,-1 -1,-2 -1,-3l-29 17c2,0 3,0 4,1 2,1 2,2 0,3 -1,1 -4,1 -5,0 -1,-1 -2,-2 -1,-2l-3 1 4 3c2,1 5,1 7,0l33 -19c2,-1 3,-3 1,-4l-9 -5 0 2 0 0z"/>
       <polygon fill="#3CB779" fill-rule="nonzero" points="1225,723 1225,723 "/>
       <path fill="white" fill-rule="nonzero" d="M1267 713c2,1 4,1 6,0 1,-1 1,-3 0,-4 -2,0 -4,0 -5,0 -1,1 -1,1 -2,1 0,1 0,2 1,3z"/>
       <path fill="white" fill-rule="nonzero" d="M1236 731c1,1 4,1 5,0 2,-1 2,-2 0,-3 -1,-1 -2,-1 -4,-1l-2 2c-1,0 0,1 1,2z"/>
       <path fill="#C0C0F9" fill-rule="nonzero" d="M1266 699l-39 23c-1,0 -1,0 -2,1l0 0c0,0 -1,0 -1,0 -1,0 -2,0 -3,1l0 11c2,0 3,0 5,-1l0 0 6 -4 3 -1 2 -2 29 -17c1,0 1,0 2,-1 0,0 0,-1 0,-2l0 -2 0 -9c0,1 -1,2 -2,3z"/>
       <path fill="white" fill-rule="nonzero" d="M1133 663l33 -19c2,-1 5,-1 7,0l39 22c-8,-1 -21,1 -30,7 -9,5 -13,11 -13,16l-36 -22c-2,-1 -2,-2 0,-4zm126 35l-33 19c-2,1 -5,1 -7,0l-37 -21c8,0 18,-2 27,-7 10,-6 14,-13 11,-18l39 23c2,1 2,3 0,4zm-47 -27c6,4 3,11 -6,16 -9,6 -22,7 -28,4 -6,-4 -3,-11 6,-16 10,-6 22,-7 28,-4zm-88 -2l92 53c0,1 1,1 1,1 0,0 1,0 1,0l0 0c1,0 2,1 3,1 1,-1 2,-1 3,-1 0,0 1,0 1,0l0 0c1,-1 1,-1 2,-1l39 -23c1,-1 2,-2 2,-3 1,-1 0,-2 -1,-3l-93 -53c-2,-2 -7,-2 -10,0l-39 23c-2,1 -3,2 -3,3 0,1 1,2 2,3z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1137 664c2,0 4,0 6,0 1,1 1,3 0,4 -2,1 -4,1 -6,0 -1,-1 -2,-3 0,-4zm31 -17c1,-1 4,-1 5,0 2,1 2,2 0,3 -1,1 -4,1 -5,0 -2,-1 -2,-2 0,-3zm-35 20l36 22c0,-5 4,-11 13,-16 9,-6 22,-8 30,-7l-39 -22c-2,-1 -5,-1 -7,0l-33 19c-2,2 -2,3 0,4z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1250 697c-2,-1 -2,-2 0,-3 1,-1 4,-1 5,0 2,1 2,2 0,3 -1,1 -4,1 -5,0zm-26 18c-2,1 -4,1 -6,0 -1,-1 -1,-2 0,-3 2,-1 4,-1 6,0 1,1 1,2 0,3zm-15 -26c-9,5 -19,7 -27,7l37 21c2,1 5,1 7,0l33 -19c2,-1 2,-3 0,-4l-39 -23c3,5 -1,12 -11,18z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1178 691c6,3 19,2 28,-4 9,-5 12,-12 6,-16 -6,-3 -18,-2 -28,4 -9,5 -12,12 -6,16z"/>
       <path fill="white" fill-rule="nonzero" d="M1168 650c1,1 4,1 5,0 2,-1 2,-2 0,-3 -1,-1 -4,-1 -5,0 -2,1 -2,2 0,3z"/>
       <path fill="white" fill-rule="nonzero" d="M1255 694c-1,-1 -4,-1 -5,0 -2,1 -2,2 0,3 1,1 4,1 5,0 2,-1 2,-2 0,-3z"/>
       <path fill="white" fill-rule="nonzero" d="M1137 668c2,1 4,1 6,0 1,-1 1,-3 0,-4 -2,0 -4,0 -6,0 -2,1 -1,3 0,4z"/>
       <path fill="#319A60" fill-rule="nonzero" d="M1224 712c-2,-1 -4,-1 -6,0 -1,1 -1,2 0,3 2,1 4,1 6,0 1,-1 1,-2 0,-3z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M1194 663c-3,-2 -7,-3 -11,-2 -2,0 -5,1 -6,2 -3,1 -4,3 -4,4 0,2 1,4 4,5 1,1 4,2 6,2 4,1 8,0 11,-1 2,-2 3,-4 3,-5 0,-2 -1,-4 -3,-5z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M1190 655c-3,-2 -7,-2 -11,-2 -2,0 -4,1 -6,2 -2,1 -4,3 -4,5 0,2 2,3 4,5 2,1 4,1 6,2 4,0 8,-1 11,-2 2,-2 3,-3 3,-5 0,-2 -1,-4 -3,-5z"/>
       <path fill="url(#id12)" d="M1194 673c-3,1 -7,2 -11,1 -2,0 -5,-1 -6,-2 -2,0 -3,-1 -3,-3 1,1 3,2 5,2 4,0 8,0 11,-2 2,-1 3,-3 3,-5l0 0 0 -4c0,2 -1,3 -3,5 -3,1 -7,2 -11,2 -2,-1 -4,-1 -6,-2 -2,-2 -4,-3 -4,-5 0,1 0,4 0,4 1,2 2,4 4,5l0 0c0,2 0,3 0,3 0,2 1,4 4,5 1,1 4,2 6,2 4,0 8,0 11,-2 2,-1 3,-3 3,-5l0 0 0 -4c0,1 -1,3 -3,5z"/>
       <path fill="url(#id13)" d="M1169 660l0 0c0,-1 0,-1 0,0z"/>
       <path fill="url(#id14)" d="M1212 706l0 0c0,-1 0,-1 0,0z"/>
       <path fill="url(#id15)" d="M1232 711c-3,1 -7,2 -10,2 -3,-1 -5,-1 -7,-2 -2,-2 -3,-3 -3,-5 0,1 0,4 0,4 0,2 1,4 3,5 2,1 4,2 7,2 3,0 7,0 10,-2 3,-1 4,-3 4,-5l0 0 0 -4c0,2 -1,3 -4,5z"/>
       <path fill="url(#id16)" d="M1232 701c-3,-2 -7,-2 -10,-2 -3,0 -5,1 -7,2 -2,1 -3,3 -3,5 0,1 1,3 3,5 2,1 4,1 7,2 3,0 7,-1 10,-2 3,-2 4,-3 4,-5 0,-2 -1,-4 -4,-5z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M282 500c-1,0 -1,0 -2,0l0 0 -117 -68c-1,-1 -2,-1 -2,-2 -17,9 -39,14 -60,14 -6,0 -12,0 -17,-1 -17,-2 -33,-7 -46,-14 -16,-9 -24,-22 -25,-34 0,0 0,-5 0,-12l0 0 0 0c0,-1 0,-2 0,-2 0,-1 0,-2 0,-3 0,-3 1,-7 2,-10 3,-8 9,-16 20,-23 1,0 2,-1 3,-2 15,-8 34,-13 54,-14 2,0 5,0 7,0 4,0 7,0 11,0 19,1 37,6 53,15 9,6 16,12 20,19 1,2 2,4 2,6 2,4 3,8 3,12 0,1 -1,2 -1,2l1 0c0,6 0,9 0,9 0,1 0,2 0,3 -1,7 -3,13 -8,19l111 64c2,1 4,3 4,6 0,6 -4,12 -9,15 -1,1 -3,1 -4,1z"/>
       <path fill="url(#id17)" d="M175 382l-9 0c-4,5 -10,9 -16,13 -34,20 -90,20 -124,0 -7,-4 -12,-8 -16,-13l-10 0c0,7 0,12 0,12 1,13 10,25 26,34 13,7 29,12 45,14 28,3 57,-1 79,-14 16,-9 24,-21 25,-34 0,-1 0,-2 0,-2 0,0 0,-4 0,-10z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M0 380c0,0 0,1 0,2 0,1 1,1 1,2 0,1 0,2 0,3 1,2 2,3 2,5 1,1 1,2 2,2 4,7 11,14 21,20 34,20 90,20 124,0 16,-9 24,-22 25,-34 0,-4 -1,-8 -2,-12 -1,-2 -1,-4 -2,-6 -5,-7 -11,-13 -21,-19 -15,-9 -34,-14 -53,-15 -6,0 -12,0 -18,0 -20,1 -39,6 -54,14 -1,1 -2,2 -3,3 -10,6 -17,14 -20,22 -1,3 -2,7 -2,10 0,1 0,2 0,3z"/>
       <path fill="#001066" fill-rule="nonzero" d="M11 380c0,0 0,1 0,1 0,1 1,2 1,2 0,1 0,2 0,3 1,1 1,3 2,4 0,1 1,1 1,2 4,6 10,12 18,17 30,17 77,17 106,0 14,-8 21,-19 22,-29 0,-4 -1,-7 -2,-11 -1,-1 -1,-3 -2,-4 -4,-6 -10,-12 -18,-17 -13,-7 -29,-11 -45,-12 -5,-1 -10,-1 -15,-1 -17,1 -33,5 -46,13 -1,0 -2,1 -3,2 -9,5 -14,12 -17,19 -1,3 -2,6 -2,9 0,0 0,1 0,2z"/>
       <path fill="white" fill-rule="nonzero" d="M95 354c-5,2 -13,2 -17,-1 -5,-2 -5,-7 -1,-9 5,-3 13,-3 18,0 4,2 4,7 0,10z"/>
       <path fill="white" fill-rule="nonzero" d="M113 354c-2,1 -5,1 -7,0 -2,-1 -2,-3 0,-4 2,-1 5,-1 7,0 2,1 2,3 0,4z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M265 493c0,-5 4,-12 8,-15 2,-1 4,-1 6,0l-116 -68c-2,-1 -4,-1 -7,0 -5,3 -8,10 -8,15 0,3 1,5 2,6l117 68c-1,-1 -2,-3 -2,-6z"/>
       <path fill="url(#id18)" d="M279 478c-2,-1 -4,-1 -6,0 -4,3 -8,10 -8,15 0,3 1,5 2,6l1 0c1,1 3,1 5,-1 5,-3 9,-9 9,-15 0,-3 -1,-5 -3,-5z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M289 264c-1,2 -3,4 -6,5 -3,1 -6,1 -9,0 -3,-1 -5,-3 -5,-5 -1,-2 -2,-4 -4,-5l-4 -2c0,0 0,0 -1,0 0,0 -1,0 -2,0l-24 14c-2,1 -4,1 -6,0l-24 -13 0 0c0,-1 -1,-1 -1,-1l0 2c1,0 1,0 1,1l24 13c2,2 4,1 6,0l25 -14 0 0c1,0 2,0 3,0l3 2c2,1 3,3 4,5 0,2 2,4 5,5 3,1 7,1 10,0 3,-2 5,-4 5,-6l0 -2c0,1 0,1 0,1z"/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="170,236 170,237 170,236 "/>
       <path fill="#9898ED" fill-rule="nonzero" d="M285 213c-1,1 -4,2 -6,2 -3,0 -7,1 -9,2l-3 2c-1,1 -1,2 0,2l3 -2c2,-1 6,-1 9,-2 2,0 5,-1 6,-2 2,-1 2,-2 2,-3l0 -3c0,1 0,1 0,2 0,0 -1,1 -2,2z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M221 241c-4,-2 -9,-2 -13,0 -2,1 -3,3 -3,4 -1,2 -2,4 -4,5l-3 2 -1 0 0 0 -1 0 0 0 0 0c0,0 -1,0 -1,0 0,0 0,0 -1,0l-22 -13c-1,0 -1,-1 -2,-1l0 -1 0 1c0,2 1,2 2,3l22 13c1,0 1,1 1,1 1,0 2,-1 3,-1l3 -2c2,-1 3,-3 4,-5 0,-1 1,-3 3,-4 4,-1 9,-1 13,0 2,1 3,3 4,4 0,-2 -1,-4 -4,-6z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M292 239l0 -2c0,0 0,1 -1,1l0 0 -24 14c0,0 0,1 1,1l0 1 23 -14c0,0 1,0 1,-1l0 0z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M223 223c-2,-1 -4,-2 -6,-2 -3,0 -7,-1 -9,-2l-3 -2 0 0 -1 -1 0 0 0 1c0,1 0,2 1,2l3 2c2,1 6,2 9,2 2,0 4,1 6,2 1,1 2,2 2,3 1,-2 0,-4 -2,-5z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M268 253c-1,0 -1,-1 -1,-1l24 -14 0 0c1,0 1,-1 1,-1 0,-1 -1,-1 -1,-2l-24 -14c-1,0 -1,-1 0,-2l3 -2c2,-1 6,-2 9,-2 2,0 5,-1 6,-2 1,-1 2,-2 2,-2 0,-1 0,-1 0,-2 0,-1 0,-2 -2,-3 -4,-3 -11,-3 -15,-1 -1,1 -2,2 -3,4 0,1 -1,3 -3,5l-4 2 0 0c-1,0 -3,0 -4,-1l-15 -9 -1 0 0 0 -6 -4c-2,-1 -5,-1 -8,0l-7 5 -14 8 -1 1 0 0 1 1 0 0 3 2c2,1 6,2 9,2 2,0 4,1 6,2 2,1 3,3 2,5 0,2 -2,3 -5,4 -3,1 -6,1 -9,0 -3,-1 -5,-3 -5,-5 -1,-1 -2,-3 -4,-4l-4 -2c0,-1 -1,-1 -2,-1l-24 14c-1,1 -2,1 -2,2l0 1c0,0 0,0 0,1 1,0 1,1 2,1l22 13c1,0 1,0 1,0l1 0 0 0 0 0 0 0 1 0 0 0c0,0 0,0 1,0l3 -2c2,-1 3,-3 4,-5 0,-1 1,-3 3,-4 4,-2 9,-2 13,0 3,2 4,4 4,6 -1,1 -2,2 -3,3 -2,1 -4,1 -6,2 -3,0 -6,0 -9,2l-3 2 0 0c0,0 -1,0 -1,1 0,0 1,0 1,1l0 0 24 13c2,1 4,1 6,0l24 -14c1,0 2,0 2,0 1,0 1,0 1,0l4 2c2,1 3,3 4,5 0,2 2,4 5,5 3,1 6,1 9,0 3,-1 5,-3 6,-5 0,0 0,0 0,-1 0,-1 -1,-3 -3,-4 -2,-1 -4,-1 -6,-1 -3,-1 -6,-1 -9,-2l-3 -2 0 -1 0 0z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M326 629c0,0 0,1 -1,1l-25 15c0,0 1,1 1,1l1 1 23 -14c1,0 2,-1 1,-2l0 -2z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M253 614c-1,-1 -4,-2 -6,-2 -3,0 -7,-1 -9,-2l-4 -2c0,-1 0,-1 0,-1l0 2c0,1 0,1 0,1l4 3c2,1 6,2 9,2 2,0 5,1 6,2 2,1 3,2 3,3 1,-2 0,-5 -3,-6z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M251 633c-3,-1 -9,-1 -13,0 -2,1 -4,3 -4,4 0,2 -1,4 -4,6l-3 2c-1,0 -2,0 -2,0 -1,0 -1,0 -2,0l-24 -14c-1,-1 -1,-2 -2,-3l0 3c1,1 1,2 2,3l24 14c1,0 2,0 3,0l1 0 3 -2c3,-2 4,-4 4,-6 0,-1 2,-3 4,-4 4,-1 10,-1 13,0 2,1 4,2 4,4 1,-2 0,-5 -4,-7z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M318 662c-3,1 -7,1 -10,0 -4,-1 -6,-3 -6,-4 0,-2 -1,-4 -4,-6l-4 -2c0,0 -1,0 -2,0l-27 15c-2,1 -4,1 -6,0l-25 -14c-1,0 -1,-1 -1,-1l0 3c0,0 0,1 1,1l25 14c2,1 4,1 6,0l27 -15c1,0 2,0 2,0l4 2c3,2 4,4 4,6 0,1 2,3 6,4 3,1 7,1 10,0 4,-1 5,-3 5,-5l0 -3c0,2 -2,4 -5,5z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M320 604c-2,1 -5,2 -8,2 -3,0 -6,1 -8,2l-4 2c-1,1 -1,1 -1,2l2 1 3 -2c2,-1 5,-2 8,-2 3,0 6,-1 8,-2 1,-2 2,-3 2,-4l0 -3c0,1 -1,2 -2,4z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M301 646c0,0 -1,-1 -1,-1l25 -15c1,0 1,-1 1,-1 1,-1 0,-1 -1,-2l-24 -14 -2 -1c0,-1 0,-1 1,-2l4 -2c2,-1 5,-2 8,-2 3,0 6,-1 8,-2 1,-2 2,-3 2,-4 0,-2 -1,-3 -2,-4 -5,-3 -12,-3 -16,-1 -2,1 -3,3 -3,4 -1,2 -2,4 -4,5l-4 2c-1,1 -2,1 -3,1l-18 -11 -1 0 0 0 -6 -4c-2,-1 -5,-1 -8,0l-8 5 -15 8c0,1 0,1 0,2l0 0 4 3c2,1 6,2 9,2 2,0 5,1 6,2 3,1 4,4 3,6 -1,1 -2,2 -5,3 -3,2 -7,2 -10,1 -4,-2 -6,-3 -6,-5 0,-2 -1,-4 -4,-5l-3 -2c-1,-1 -2,-1 -2,-1 -1,0 -1,0 -2,0l-25 15c-1,0 -2,1 -2,2 1,1 1,2 2,3l24 14c1,0 1,0 2,0 0,0 1,0 2,0l3 -2c3,-2 4,-4 4,-6 0,-1 2,-3 4,-4 4,-1 10,-1 13,0 4,2 5,5 4,7 0,1 -1,2 -3,3 -1,1 -4,1 -6,1 -3,1 -7,1 -9,2l-3 3c-1,0 -1,0 -1,1 0,0 0,1 1,1l25 14c2,1 4,1 6,0l27 -15c1,0 2,0 2,0l4 2c3,2 4,4 4,6 0,1 2,3 6,4 3,1 7,1 10,0 3,-1 5,-3 5,-5 0,-2 -1,-3 -3,-5 -2,-1 -4,-1 -6,-1 -3,-1 -7,-1 -9,-2l-3 -2 -1 -1 0 0z"/>
       <polygon fill="#9898ED" fill-rule="nonzero" points="935,100 935,101 "/>
       <path fill="#9898ED" fill-rule="nonzero" d="M786 29l-6 -3c-3,-2 -9,-3 -13,-4 -5,0 -8,-1 -11,-3 -2,-2 -4,-3 -4,-5l0 3c0,3 1,5 4,7 3,2 6,3 11,3 4,0 10,1 13,3l4 3 3 -2c0,-1 -1,-1 -1,-2z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M942 61l0 -4c0,1 0,2 -1,2l-40 24c0,0 0,1 1,1l2 2 37 -22c1,-1 2,-2 1,-3z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M829 64c-6,-3 -14,-3 -20,0 -4,1 -6,4 -6,6 -1,3 -2,6 -6,8l-6 3c-1,1 -3,1 -4,1l-37 -22c-2,-1 -3,-2 -3,-4l0 5c0,2 1,3 3,4l37 21c2,1 3,0 4,0l6 -3c4,-2 5,-5 6,-8 0,-3 2,-5 6,-6 6,-3 14,-3 20,0 3,1 5,3 5,5 2,-4 0,-8 -5,-10z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M926 109c-5,1 -9,1 -14,0 -5,-2 -8,-4 -9,-8 -1,-2 -2,-5 -6,-7l-6 -4c-1,0 -2,0 -3,0l-39 22c-2,2 -6,2 -9,0l-38 -22c-1,0 -1,-1 -1,-1l0 4c0,1 0,2 1,2l38 22c3,2 7,1 9,0l39 -22c1,0 2,0 3,0l6 3c4,2 5,5 6,8 1,3 4,6 9,8 5,1 9,1 14,0 6,-2 9,-6 9,-9l0 -4c0,3 -3,6 -9,8z"/>
       <path fill="#9898ED" fill-rule="nonzero" d="M932 19c-3,2 -7,3 -11,3 -5,0 -10,1 -13,3l-6 4c-1,0 -1,1 -1,2l3 2 4 -3c3,-2 9,-3 13,-3 4,0 8,-1 11,-4 3,-1 4,-3 4,-6l0 -4c0,2 -2,4 -4,6z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M902 84c-1,0 -1,-1 -1,-1l40 -24c1,0 1,-1 1,-2 0,-1 0,-2 -1,-3l-37 -21 -3 -2c0,-1 0,-2 1,-2l6 -4c3,-2 8,-3 13,-3 4,0 8,-1 11,-3 2,-2 4,-4 4,-6 0,-2 -2,-4 -4,-6 -6,-4 -17,-5 -24,-1 -3,2 -4,4 -5,6 0,2 -2,5 -5,8l-6 3c-1,0 -3,1 -5,0l-27 -15 -1 -1 0 0 -9 -5c-4,-3 -9,-3 -12,0l-13 7 -26 15c-1,0 -2,-1 -3,-1l-6 -3c-3,-2 -5,-5 -6,-8 0,-2 -2,-4 -4,-6 -7,-4 -18,-3 -24,1 -3,2 -4,4 -4,7 0,2 2,3 4,5 3,2 6,3 11,3 4,1 10,2 13,4l6 3c0,1 1,1 1,2l-3 2 -34 19c-2,1 -3,3 -3,4 0,2 1,3 3,4l37 22c1,0 3,0 4,-1l6 -3c4,-2 5,-5 6,-8 0,-2 2,-5 6,-6 6,-3 14,-3 20,0 5,2 7,6 5,10 0,1 -2,3 -4,4 -2,1 -6,2 -9,2 -5,1 -10,2 -14,4l-5 3c-1,1 -1,1 -1,2 0,0 0,1 1,1l38 22c3,2 7,2 9,0l39 -22c1,0 2,0 3,0l6 4c4,2 5,5 6,7 1,4 4,6 9,8 5,1 9,1 14,0 6,-2 9,-5 9,-8l0 -1c0,-2 -1,-4 -5,-6 -2,-2 -6,-3 -9,-3 -5,0 -10,-1 -14,-3l-3 -2 -2 -2 0 0z"/>
       <path fill="#001066" fill-rule="nonzero" d="M1225 472l-550 -319c-36,-21 -80,-25 -119,-11l-2 -2 -15 9c-3,1 -6,3 -8,4l-232 135 0 0 -56 33c-31,18 -31,62 0,80l539 313c49,28 110,28 159,0l289 -169c24,-18 22,-57 -5,-73z"/>
       <path fill="url(#id19)" d="M1227 469l-551 -318c-37,-22 -82,-25 -122,-10l-3 -2 -309 179c-23,13 -29,41 -17,62 4,-7 10,-13 17,-17l93 -54 0 0 196 -113c45,-26 100,-26 145,0l551 318c7,4 12,10 16,16 11,-20 6,-48 -16,-61z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M1049 877l-21 0 -37 -26 44 -38c2,-1 4,-2 7,-2 2,0 5,1 7,2l45 27c4,2 4,7 0,9l-45 28z"/>
       <path fill="url(#id20)" d="M1073 722l-95 0 0 128c0,1 1,3 2,3l40 23c3,2 8,2 12,0l39 -23c1,0 2,-2 2,-3l0 0 0 -128z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M1020 748l-40 -23c-2,-2 -2,-5 0,-6l40 -23c3,-2 7,-2 11,0l40 23c2,1 2,4 0,6l-39 23c-4,2 -9,2 -12,0z"/>
       <path fill="#001066" fill-rule="nonzero" d="M1022 739l-29 -17c-2,-1 -2,-3 0,-4l29 -17c2,-1 5,-1 8,0l29 17c1,1 1,3 0,4l-29 17c-2,1 -6,1 -8,0z"/>
       <path fill="url(#id21)" d="M993 720l29 -16c2,-2 5,-2 8,0l29 17c0,0 1,0 1,0 0,-1 0,-2 -1,-3l-29 -17c-3,-1 -6,-1 -8,0l-29 17c-1,1 -1,2 -1,3 0,0 1,0 1,-1z"/>
       <path fill="#36399F" fill-rule="nonzero" d="M1015 846l-23 -14c-2,-1 -3,-3 -3,-5l0 -79 -1 -1c-1,-1 -3,1 -3,2l0 80c0,2 1,4 3,5l24 14c2,1 4,0 5,-2 -1,0 -1,0 -2,0z"/>
       <path fill="#001066" fill-rule="nonzero" d="M1017 768c0,-2 -2,-5 -4,-6l-24 -14 0 79c0,2 1,4 3,5l23 14c1,0 1,0 2,0 0,0 0,-1 0,-1l0 -77z"/>
       <path fill="url(#id22)" d="M995 825c-1,-1 -1,-2 -1,-3l0 -63c0,-1 0,-2 1,-1 1,0 2,2 2,3l0 63c0,1 -1,1 -2,1z"/>
       <path fill="url(#id23)" d="M1002 828c-1,0 -2,-2 -2,-3l0 -41c0,-1 1,-2 2,-1 0,0 1,2 1,3l0 41c0,1 -1,2 -1,1z"/>
       <path fill="url(#id24)" d="M1008 770c-1,0 -1,0 -1,1l0 58c0,1 0,3 1,3 1,1 2,0 2,-1l0 -58c0,-1 -1,-2 -2,-3z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M996 858l7 4c1,1 1,0 1,-1 0,-1 0,-3 -1,-3l-7 -4c-1,-1 -2,0 -2,1 0,1 1,3 2,3z"/>
       <path fill="url(#id25)" d="M438 611c3,2 6,0 6,-4 0,-4 -3,-8 -6,-10l0 -4c5,3 9,10 9,16 0,6 -4,8 -9,5l0 -3 0 0z"/>
       <path fill="#4A5EA5" fill-rule="nonzero" d="M438 594l0 3c-3,-2 -6,0 -6,3l-3 -1c0,-6 4,-8 9,-5z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M938 581c-41,-24 -107,-24 -148,0 -41,23 -41,62 0,86 41,24 108,24 149,0 41,-23 40,-62 -1,-86zm-153 89c-44,-26 -44,-67 -1,-92 44,-26 116,-26 160,0 44,25 44,67 0,92 -44,26 -115,26 -159,0z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M910 597c-25,-15 -67,-15 -92,0 -26,15 -25,39 0,54 26,15 67,15 93,0 25,-15 25,-39 -1,-54zm-94 55c-28,-15 -28,-41 -1,-56 27,-16 71,-16 98,0 27,15 27,41 0,56 -27,16 -70,16 -97,0z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M950 664c-5,-3 -13,-3 -18,0l-1 0c-37,21 -97,21 -134,-1 -38,-22 -38,-57 -1,-78 37,-22 97,-22 135,-1l0 0 1 1 0 0c5,2 13,2 17,-1 5,-2 5,-7 1,-10l0 0 -1 0c0,0 0,-1 -1,-1 -47,-26 -123,-26 -170,1 -48,27 -47,72 0,100 48,27 124,28 171,1 1,0 1,0 1,-1 5,-3 5,-7 0,-10z"/>
       <path fill="url(#id26)" d="M917 593c-29,-17 -76,-17 -106,0l0 0c-3,2 -3,5 0,6 3,2 8,2 12,0l0 0c23,-13 60,-13 83,1 23,13 23,35 0,48 -23,14 -60,14 -84,0 -3,-2 -8,-2 -11,0 -3,2 -3,5 0,7 30,17 77,17 107,0 29,-17 29,-45 -1,-62z"/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="312,379 469,470 470,469 313,378 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="344,360 501,451 502,450 345,359 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="377,341 534,432 535,431 379,340 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="409,322 566,413 567,412 411,321 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="437,306 593,397 595,396 438,306 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="464,290 621,381 622,380 466,290 "/>
       <path fill="url(#id27)" d="M491 444c-3,2 -7,2 -9,1l-97 -56c-2,-2 -2,-4 1,-6 2,-1 7,-2 9,0l97 56c2,1 2,4 -1,5z"/>
       <path fill="url(#id28)" d="M612 375c-3,2 -7,2 -9,0l-80 -46c-2,-1 -2,-4 1,-5 2,-2 6,-2 9,-1l80 47c2,1 2,3 -1,5z"/>
       <path fill="url(#id29)" d="M556 407c-3,1 -7,1 -9,0l-60 -34c-2,-2 -2,-4 1,-6 3,-1 7,-1 9,0l60 34c2,2 2,4 -1,6z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M451 458c-2,2 -6,2 -9,1l-6 -4c-2,-1 -2,-4 1,-5 3,-2 7,-2 9,-1l6 4c2,1 2,4 -1,5z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M511 417c-2,2 -6,2 -9,1l-33 -19c-2,-2 -2,-4 1,-6 2,-1 6,-2 9,0l33 19c2,1 2,4 -1,5z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M670 530c-1,0 -2,0 -3,0 -1,-1 -1,-1 0,-2 1,0 2,0 3,0 0,1 0,1 0,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M677 534c-1,1 -2,1 -3,0 -1,0 -1,-1 0,-1 1,-1 2,-1 3,0 1,0 1,1 0,1z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M684 538c0,1 -2,1 -2,0 -1,0 -1,-1 0,-1 0,-1 2,-1 2,0 1,0 1,1 0,1z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M692 543c-1,0 -2,0 -3,0 -1,-1 -1,-1 0,-2 1,0 2,0 3,0 1,1 1,1 0,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M699 547c-1,1 -2,1 -3,0 0,0 0,-1 0,-2 1,0 2,0 3,0 1,1 1,2 0,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M707 551c-1,1 -3,1 -3,0 -1,0 -1,-1 0,-1 0,-1 2,-1 3,0 0,0 0,1 0,1z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M714 556c-1,0 -2,0 -3,0 -1,-1 -1,-2 0,-2 1,0 2,0 3,0 1,0 1,1 0,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M721 560c0,0 -2,0 -3,0 0,-1 0,-1 0,-2 1,0 3,0 3,0 1,1 1,1 0,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M729 564c-1,1 -2,1 -3,0 -1,0 -1,-1 0,-1 1,-1 2,-1 3,0 0,0 0,1 0,1z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M736 569c-1,0 -2,0 -3,0 -1,-1 -1,-2 0,-2 1,-1 2,-1 3,0 1,0 1,1 0,2z"/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="712,507 684,523 681,521 708,505 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="719,511 691,527 688,525 715,509 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="726,515 698,531 695,529 722,513 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="755,506 705,535 702,533 752,504 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="758,519 730,535 727,533 754,517 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="765,523 737,539 734,537 761,521 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="772,528 744,544 741,541 768,525 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="768,470 712,503 715,505 772,472 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="775,479 722,509 719,507 771,477 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="782,478 726,511 729,513 786,480 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="789,482 761,499 764,501 793,485 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="802,490 758,515 762,517 806,492 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="809,494 765,519 768,521 813,496 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="816,498 772,523 775,525 820,500 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="795,524 768,540 764,538 792,522 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="824,503 796,519 799,522 828,505 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="831,507 771,542 775,544 835,509 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="838,511 778,546 782,548 842,513 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="656,536 633,549 637,551 659,538 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="663,540 640,553 644,555 666,542 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="677,548 642,567 646,569 680,550 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="655,575 659,577 685,561 682,559 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="622,555 625,557 633,553 630,551 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="629,559 632,561 640,557 637,555 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="678,588 681,590 693,583 689,581 "/>
       <polygon fill="#474AB6" fill-rule="nonzero" points="684,592 688,594 700,587 696,585 "/>
       <path fill="url(#id30)" d="M610 479l-1 -1 0 0c-24,-13 -62,-13 -85,1l14 8c16,-9 42,-9 58,0 16,9 16,24 0,33 -16,10 -43,10 -59,0l0 0 -14 8 0 0c24,14 63,14 87,1 23,-14 23,-37 0,-50z"/>
       <path fill="url(#id31)" d="M538 487l0 0c-16,9 -16,24 -1,33l-14 8c-23,-13 -23,-36 1,-49l14 8z"/>
       <path fill="url(#id32)" d="M634 467c16,9 42,9 58,0 16,-10 16,-25 0,-34l0 0 14 -8 0 0c24,14 24,36 0,50 -23,14 -62,14 -86,0l0 0 14 -9 0 1z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M706 425l-14 8c-16,-9 -41,-9 -57,0l-15 -8c24,-14 62,-14 86,0z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M635 433l-1 0c-16,9 -16,24 0,34l-14 8c-23,-14 -23,-36 0,-50l15 8z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M107 636l-16 0 -28 -20 33 -29c2,-1 4,-1 5,-1 2,0 4,0 6,1l35 20c3,2 3,6 0,8l-35 21z"/>
       <path fill="url(#id33)" d="M125 517l-72 0 0 98c0,1 0,2 1,3l31 17c2,2 6,2 9,0l30 -17c1,-1 1,-2 1,-3l0 0 0 -98z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M85 537l-31 -18c-2,-1 -2,-4 0,-5l31 -17c2,-2 5,-2 8,0l31 18c2,1 2,3 0,4l-30 18c-3,1 -7,1 -9,0z"/>
       <path fill="#212D60" fill-rule="nonzero" d="M86 531l-22 -12c-1,-1 -1,-3 0,-4l23 -13c1,-1 4,-1 5,0l23 13c1,1 1,3 0,4l-22 12c-2,1 -5,1 -7,0z"/>
       <path fill="url(#id34)" d="M64 517l23 -13c1,-1 4,-1 5,0l23 13 1 1c0,-1 0,-2 -1,-3l-23 -13c-1,-1 -4,-1 -5,0l-23 13c-1,1 -1,2 0,3l0 -1z"/>
       <path fill="url(#id35)" d="M106 543c0,-1 0,-3 2,-4 1,0 1,0 1,2l0 36 -3 2 0 -36z"/>
       <path fill="url(#id36)" d="M109 577l0 32c0,2 0,3 -1,4 -2,1 -2,0 -2,-1l0 -33 3 -2z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M98 512c-4,-2 -9,-2 -13,-1l0 2c3,-1 7,-1 10,1 3,2 3,4 0,6 -3,2 -8,2 -11,0l-3 2c5,2 12,2 17,0 4,-3 4,-7 0,-10z"/>
       <path fill="#96A1E1" fill-rule="nonzero" d="M394 196l-18 0 -30 -22 36 -31c2,-1 4,-2 6,-2 2,0 4,1 6,2l37 22c3,2 3,6 0,8l-37 23z"/>
       <path fill="url(#id37)" d="M414 67l-78 0 0 107c0,1 0,2 1,2l33 19c3,2 7,2 10,0l32 -19c1,0 2,-1 2,-2l0 0 0 -107z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M370 89l-33 -19c-2,-1 -2,-4 0,-5l33 -19c3,-2 6,-2 9,0l33 19c2,1 2,4 0,5l-32 19c-3,2 -7,2 -10,0z"/>
       <path fill="#36399F" fill-rule="nonzero" d="M366 167l-20 -11c-2,-1 -2,-3 -2,-5l0 -66 -1 -1c-1,0 -3,1 -3,2l0 67c0,2 1,4 3,5l20 11c2,1 4,0 4,-2 0,0 -1,0 -1,0z"/>
       <path fill="#232F6D" fill-rule="nonzero" d="M367 102c0,-2 -1,-4 -3,-5l-20 -12 0 66c0,2 0,4 2,5l20 11c0,0 1,0 1,0l0 0 0 -65z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M351 114c0,-2 2,-3 4,-2l0 -6c-5,-3 -9,-1 -9,5l5 3 0 0z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M351 114l-5 -3c0,3 1,6 3,9l3 -3c0,-1 -1,-2 -1,-3z"/>
       <path fill="url(#id38)" d="M364 121c0,-5 -4,-12 -9,-15l0 6c2,1 3,4 3,6 0,2 -1,3 -3,2 -1,-1 -2,-2 -3,-3l-3 3c1,3 3,5 6,6 5,3 9,1 9,-5l0 0z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M352 138c0,-2 1,-3 3,-2l0 -6c-5,-2 -9,0 -9,5l6 3 0 0z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M352 138l-6 -3c0,3 1,6 3,9l4 -2c-1,-1 -1,-2 -1,-4z"/>
       <path fill="url(#id39)" d="M364 146c0,-6 -4,-13 -9,-16l0 6c2,1 4,4 4,6 0,2 -2,3 -4,2 -1,0 -2,-1 -2,-2l-4 2c1,3 4,5 6,7 5,3 9,0 9,-5l0 0z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M374 113c0,1 0,1 -1,1 0,-1 -1,-2 -1,-2 0,-1 1,-1 1,-1 1,0 1,1 1,2z"/>
       <path fill="#FD256F" fill-rule="nonzero" d="M374 120c0,0 0,1 -1,0 0,0 -1,-1 -1,-2 0,0 1,-1 1,0 1,0 1,1 1,2z"/>
       <path fill="#232F6D" fill-rule="nonzero" d="M374 126c0,1 0,1 -1,1 0,0 -1,-1 -1,-2 0,-1 1,-1 1,-1 1,1 1,2 1,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M374 133c0,1 0,1 -1,1 0,-1 -1,-1 -1,-2 0,-1 1,-1 1,-1 1,1 1,1 1,2z"/>
       <path fill="#474AB6" fill-rule="nonzero" d="M374 140c0,1 0,1 -1,1 0,-1 -1,-2 -1,-2 0,-1 1,-1 1,-1 1,0 1,1 1,2z"/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M352 182l-3 -2c-1,0 -1,-1 -1,-1 0,-1 0,-1 1,-1l3 2c0,0 1,1 1,1 0,1 -1,1 -1,1z"/>
       <path fill="url(#id40)" d="M567 57c22,13 44,31 65,55l-2 5c8,6 30,-2 38,6l17 -41c-21,-24 -42,-42 -65,-55 -21,-12 -39,-18 -55,-16 -6,0 -12,2 -18,5l-50 29c5,-3 10,-4 15,-4 16,-2 34,4 55,16z"/>
       <path fill="url(#id41)" d="M622 224c-30,-18 -46,-50 -46,-97 0,-3 0,-6 1,-10 -4,-2 -7,-5 -11,-7 -14,-7 -24,-7 -32,1 -7,9 -11,24 -11,46 0,47 15,79 46,97 9,6 18,9 27,12 9,2 18,3 27,4l0 21c19,4 41,4 53,4l0 -55c-9,-1 -18,-2 -27,-4 -8,-3 -17,-6 -27,-12z"/>
       <path fill="#F0F0FC" fill-rule="nonzero" d="M596 266c-9,-3 -18,-6 -27,-12 -31,-18 -46,-50 -46,-97 0,-22 4,-37 11,-46 8,-8 18,-8 32,-1 4,2 7,5 11,7 5,4 9,8 14,12 8,8 16,16 23,24l16 -36 2 -5c-21,-24 -43,-42 -65,-55 -21,-12 -39,-18 -55,-16 -5,0 -10,1 -15,4 -8,3 -15,10 -20,19 -8,14 -13,34 -13,59 0,40 9,76 26,107 17,31 41,56 73,74 22,13 42,20 60,21l0 -55c-9,-1 -18,-2 -27,-4z"/>
       <polygon fill="url(#id42)" points="632,112 685,82 668,123 614,153 "/>
       <polygon fill="url(#id43)" points="623,270 676,240 676,295 623,325 "/>
       <polygon fill="url(#id44)" points="712,298 712,387 750,365 727,307 "/>
       <path fill="url(#id45)" d="M890 427l-59 -137 17 -10 0 -1c11,-7 17,-19 17,-38 0,-30 -12,-56 -33,-80 -6,-7 -13,-13 -20,-19 -10,-8 -21,-16 -33,-23l-69 -40 -54 32 54 32 15 8c13,8 25,16 35,25 7,5 13,11 18,17 1,1 1,1 2,2 3,4 7,8 10,12 0,1 0,1 0,2 3,4 6,9 9,14 0,1 0,2 1,3 0,1 1,2 1,3l-13 84c-3,2 -6,3 -10,3 -1,0 -2,1 -3,1l0 1 61 140 54 -31z"/>
       <path fill="url(#id46)" d="M723 255l-11 -6 0 -56 12 7c19,11 29,25 29,42 0,20 -10,25 -30,13zm87 17c0,-6 0,-11 -1,-17 -1,-3 -1,-6 -2,-10 -1,-2 -1,-4 -2,-7 -1,-4 -3,-8 -5,-12 -1,-1 -1,-2 -1,-3 -3,-5 -6,-10 -9,-14 0,-1 0,-1 0,-2 -4,-4 -8,-9 -12,-14 -13,-15 -31,-29 -53,-42l-15 -8 -54 -32 0 243 56 33 0 -89 15 9 23 58 21 56 65 37 -61 -141c23,-2 35,-17 35,-45z"/>
       <path fill="url(#id47)" d="M1033 448c0,-7 0,-23 2,-49l-2 -1 -42 150 41 -24 0 -58c0,-6 0,-12 1,-18z"/>
       <path fill="url(#id48)" d="M892 316c2,31 3,177 3,177l32 -20 -33 -156 -2 -1z"/>
       <path fill="url(#id49)" d="M1047 338l-38 -22 -9 30 -34 117 -1 0 -44 -198 -30 -18 -46 -26 0 243 50 29c0,0 -1,-146 -3,-177l2 1 33 156 10 44 54 31 42 -150 2 1c-2,26 -2,42 -2,49 -1,6 -1,12 -1,18l0 106 53 31 0 -243 -38 -22z"/>
       <polygon fill="url(#id50)" points="974,232 899,190 845,221 891,247 921,265 965,463 966,463 1000,346 "/>
       <polygon fill="url(#id51)" points="1063,285 1062,285 1009,316 1084,360 1085,360 1085,360 1085,603 1138,571 1138,328 "/>
       <path fill="#FFD33F" fill-rule="nonzero" d="M112 620l-7 4c-1,0 -2,0 -2,-1 0,-2 1,-3 2,-4l7 -4c1,0 2,1 2,2 0,1 -1,2 -2,3z"/>
       <path fill="url(#id52)" d="M140 639c-2,0 -4,0 -6,-2l-26 -17 1 -1 26 17c3,2 6,2 9,0l261 -157 0 15 -2 0 0 -12 -258 156c-2,1 -4,1 -5,1z"/>
       <path fill="url(#id53)" d="M420 292c-2,1 -5,1 -7,0 -1,-1 -1,-3 0,-4 2,-1 5,-1 7,0 2,1 2,3 0,4z"/>
       <path fill="url(#id54)" d="M418 290l-2 0 0 -45c0,-3 -2,-6 -5,-8l-79 -42c-1,-1 -2,-2 -2,-3 0,-2 1,-3 2,-3l18 -10 1 2 -18 9c-1,0 -1,1 -1,2 0,0 0,1 1,1l79 43c3,1 6,5 6,9l0 45z"/>
       <path fill="url(#id55)" d="M1037 918c-2,0 -4,0 -6,-1l-61 -36c-1,-1 -2,-2 -2,-3 0,-2 1,-3 2,-4l29 -17 1 2 -29 17c-1,0 -1,1 -1,2 0,1 0,1 1,2l61 35c4,2 8,2 11,0l178 -113c2,-1 3,-3 3,-5 0,-3 -1,-5 -3,-6l-214 -126c-1,-1 -2,-1 -2,0 -1,0 -2,1 -2,2l0 11 -1 0 0 -11c0,-2 0,-3 2,-4 1,-1 3,-1 4,0l214 127c2,1 3,4 3,7 0,2 -1,5 -3,6l-178 113c-2,2 -4,2 -7,2z"/>
       <path fill="url(#id56)" d="M1281 312c-4,3 -5,7 -5,10 1,4 4,7 9,10 0,1 1,1 2,1l0 1 1 0c0,0 1,0 2,1l1 0c0,0 1,0 2,0l0 1c1,0 2,0 3,0l0 0c2,1 4,1 7,1 2,0 4,0 6,0 1,0 2,-1 3,-1 2,0 3,0 5,-1l1 0c0,0 1,0 1,0 0,0 1,0 1,-1 0,0 1,0 1,0 0,0 1,0 1,0l1 -1c1,0 1,-1 2,-1 5,-3 8,-6 8,-10 1,-5 -1,-10 -8,-13 -10,-6 -27,-7 -38,-2 -1,1 -1,1 -2,2 -2,1 -3,2 -4,3z"/>
      </g>
     </g>
    </svg>
}


export default Svg;