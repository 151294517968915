import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const Button = styled(Link)`
background : ${({ primary }) => ( primary ? '#000d1a' : 'transparent')};
white-space : nowrap;
outline : none;
border : 1px solid black;
min-width : 100px;
max-width : 150px;
cursor : pointer;
position : relative;
z-index : 1000;
text-decoration : none;
transition : 0.3s;
display : flex; 
justify-content : center;
align-items : center;
padding: ${({ big }) => ( big ? '16px 40px' : '14px 24px' )};
color : ${({ primary }) => ( primary ? '#fff' : '#000dla')};
font-size : ${({ big }) => ( big ? '20px' : '14px')};

&:hover {
    transform : translateY(-2px);
}

@media screen and (max-width : 768px) {
    height : 40px;
}
`;