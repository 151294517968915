import React from 'react';
import styled from 'styled-components';
import { FaCss3Alt, FaReact, FaAngular, FaNodeJs, FaJava } from 'react-icons/fa';
import { AiFillHtml5 } from 'react-icons/ai';
import { DiMysql, DiMongodb, DiHeroku, DiRuby } from 'react-icons/di';
import Selenium from '../../Images/icons8-selenium-50.png';

const Paragraph = styled.p`
  font-size: 0.8rem;
  color: black;
  padding: 0 5px;
`;
const Icon = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    fill: ${(props) => props.fill};
  }
`;

const Icons = {
  downArrow: 'fas fa-chevron-down',
  upArrow: <i class="fas fa-chevron-up" style={{ color: '#000' }}></i>,

  // Front-end technologies
  html: (
    <Icon>
      <AiFillHtml5 fill="#dc3a1e" />
      <Paragraph>HTML</Paragraph>
    </Icon>
  ),
  css: (
    <Icon>
      <FaCss3Alt fill="#105ea9" />
      <Paragraph>Css</Paragraph>
    </Icon>
  ),
  react: (
    <Icon>
      <FaReact fill="#61dbfb" />
      <Paragraph>React</Paragraph>
    </Icon>
  ),
  angular: (
    <Icon>
      <FaAngular fill="#b62a2d" />
      <Paragraph>Angular</Paragraph>
    </Icon>
  ),
  bootstrap: (
    <i class="fab fa-bootstrap" style={{ color: '#563d7c' }}>
      &nbsp;&nbsp; Bootstrap
    </i>
  ),
  js: <i class="fab fa-js" style={{ color: '#dd921f' }}></i>,

  //Back-end technologies
  nodejs: (
    <Icon>
      <FaNodeJs fill="#409f39" />
      <Paragraph>Node</Paragraph>
    </Icon>
  ),
  mysql: (
    <Icon>
      <DiMysql fill="#000" />
      <Paragraph>Mysql</Paragraph>
    </Icon>
  ),
  mongodb: (
    <Icon>
      <DiMongodb fill="#409f39" />
      <Paragraph>MongoDB</Paragraph>
    </Icon>
  ),
  java: (
    <Icon>
      <FaJava fill="#0d8ac7" />
      <Paragraph>Java</Paragraph>
    </Icon>
  ),
  ruby: (
    <Icon>
      <DiRuby fill="#b62a2d" />
      <Paragraph>Ruby</Paragraph>
    </Icon>
  ),

  //Mobile Development
  reactNative: (
    <Icon>
      <FaReact fill="#000" />
      <Paragraph>React Native</Paragraph>
    </Icon>
  ),

  //Quality Assurance
  selenium: (
    <Icon>
      <img style={{ width: '25px', height: '25px', background: '#81b214' }} src={Selenium}></img>
      <Paragraph fill="#81b214">Selenium</Paragraph>
    </Icon>
  ),

  //Development
  heroku: (
    <Icon>
      <DiHeroku fill="#aa2ee6" />
      <Paragraph>Heroku</Paragraph>
    </Icon>
  ),
};

export default Icons;
