import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaTimes } from 'react-icons/fa';

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background: #000;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;
const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DropdownLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    color: #000d1a;
  }
`;

const HomeSideBar = styled(ProSidebar)``;

const SideBarMenu = styled(Menu)`
  background: '#000';
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const SideBarDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 0;
`;

const SideBarMenuItem = styled(MenuItem)`
  width: 100%;
  height: auto;
  text-align: center;
  background: '#000';
`;
const SideBarSubMenu = styled(SubMenu)`
  width: 100%;
  height: auto;
  text-align: center;
  background: '#000';
`;

const Dropdown = ({ isOpen, toggle }) => {
  let { path, url } = useRouteMatch();

  return (
    <DropdownContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <HomeSideBar style={{ width: '100%', background: '#000' }}>
          <SideBarMenu style={{ background: '#000' }} iconShape="square">
            <SideBarDiv>
              <SideBarMenuItem>
                <Link onClick={toggle} to={`${url}`}>
                  Home
                </Link>
              </SideBarMenuItem>
            </SideBarDiv>
            <SideBarDiv>
              <SideBarSubMenu title="Services">
                <SideBarMenuItem style={{ height: '50px', marginTop: '20px' }}>
                  <Link onClick={toggle} to="uiuxdesigning">
                    UI/UX Designing
                  </Link>
                </SideBarMenuItem>
                <SideBarMenuItem style={{ height: '50px' }}>
                  <Link onClick={toggle} to="appdevelopment">
                    App Development
                  </Link>
                </SideBarMenuItem>
                <SideBarMenuItem style={{ height: '50px' }}>
                  <Link onClick={toggle} to="webdevelopment">
                    Web Development
                  </Link>
                </SideBarMenuItem>
                <SideBarMenuItem style={{ height: '50px' }}>
                  <Link onClick={toggle} to="digitalmarketing">
                    Digital Marketing
                  </Link>
                </SideBarMenuItem>
              </SideBarSubMenu>
            </SideBarDiv>

            <SideBarDiv>
              <SideBarMenuItem>
                <Link onClick={toggle} to="contact">
                  Contact Us
                </Link>
              </SideBarMenuItem>
            </SideBarDiv>

            <SideBarDiv>
              <SideBarMenuItem>
                <Link onClick={toggle} to="about">
                  About Us
                </Link>
              </SideBarMenuItem>
            </SideBarDiv>
          </SideBarMenu>
        </HomeSideBar>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;

// function renderAll(path) {
//     return (
//       <ProSidebar className='Home_proSideBar'>
//         <Menu iconShape="square">
//           <MenuItem  >
//             <Link to={`${path}`} >Home</Link>
//           </MenuItem>
//           <SubMenu title="Employee Updation">
//             <MenuItem  >
//               <Link  to={`${path}/AddEmployee`}>Add Employee</Link>
//             </MenuItem>
//             <MenuItem >
//               <Link to={`${path}/EmployeeDetails`}>Employee Details</Link>
//             </MenuItem>
//             <MenuItem >
//               <Link to={`${path}/BranchDetails`}>Branch Details</Link>
//             </MenuItem>
//             <MenuItem >
//               <Link to={`${path}/AddDesignation`}>Designation</Link>
//             </MenuItem>
//           </SubMenu>
//         </Menu>
//       </ProSidebar>
//     )
//   }
