import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Img from '../../../Images/PNG/App/Hero3.png';
import Bg from '../../../Images/Shapes/bg5.png';

const Section = styled.section`
    width : 100%;
    height : auto;
    background : url(${Bg});
    box-sizing : border-box;
    padding : 20px 10%;

`;

const InnerSection = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;


@media screen and (max-width: 768px) {
    flex-direction : column-reverse;
}
`;

const Contnet = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    color : white ;


p{

    line-height : 30px;
}

@media screen and (max-width: 768px) {
    padding : 15px 0;
}
`;

const Gif = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width: 768px) {
    padding : 15px 0;
}

`;

const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;


img{
    width : 100%;
    height : auto;
    filter : drop-shadow(0 0 10px #000);
}
`;


const LifeCycle = () => {

    return <Fragment>
        <Section>
            <InnerSection>
                <Contnet>
                    <p data-aos="fade-up">
                    Mobile app development benefits you develop apps for small and wireless computing devices without any risk. Earlier mobile applications were shifted from PC applications to mobile devices. But as mobile applications become more technically advanced, mobile application developers are shifting towards a more modern approach, which involves writing particularly for mobile environments.
                    </p>
                </Contnet>
                <Gif>
                    <ImageDiv>
                        <img data-aos="fade-up" src={Img} />
                    </ImageDiv>
                </Gif>   
            </InnerSection>
        </Section>
    </Fragment>
}

export default LifeCycle;

// Mobile technologies have become an important platform to conduct business, generate leads and support operations. Mobile platforms are changing very rapidly, as new devices, uses and operating systems have been introduced by the market.

// With our experience building standards-based web sites, our track record for customer service, and our involvement in the Open Source community, we are able to offer Mobile Application Development services as a natural extension of our business.