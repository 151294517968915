// import UiUxLogo from '../../Images/PNG/UI UX.png';
// import AppLogo from '../../Images/PNG/APP.png';
// import WebLogo from '../../Images/PNG/website.png';
// import DigitalLogo from '../../Images/PNG/DigitalMarket.jpeg';
import UiUxColor from '../../Images/PNG/UiUxColor.png';
import Bg from '../../Images/PNG/Shape-1.png';
import AppColor from '../../Images/PNG/AppColor.png';
import WebColor from '../../Images/PNG/WebsiteColor.png';
import DigitalColor from '../../Images/PNG/DigitalColor.png';


export const Images = {
    Uiux : {
        logo : UiUxColor,
        bg : Bg,
    },

    App : {
        logo : AppColor,
        bg : Bg,
    },

    Web : {
        logo : WebColor,
        bg : Bg,
    },

    Digital : {
        logo : DigitalColor,
        bg : Bg,
    }
}