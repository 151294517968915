import React, { Fragment, useEffect }from 'react';
import styled from 'styled-components';
import Bg1 from '../../../Images/PNG/App/Bg1.png';
import Hero2 from '../../../Images/PNG/App/Hero1.png';
import One from '../../../Images/PNG/App/01.png';
import Two from '../../../Images/PNG/App/02.png';
import Three from '../../../Images/PNG/App/03.png';
import Four from '../../../Images/PNG/App/04.png';
import Five from '../../../Images/PNG/App/05.png';
import Six from '../../../Images/PNG/App/06.png';

const Section = styled.section`
    width : 100%;
    height : auto;

    background-repeat : no-repeat;
`;

const Heading = styled.h1`
    width : 100%;
    height : auto;
    padding : 20px 10%;

h1{
    text-align : center;
    font-size : 2rem;
    padding : 10px 0;
}

@media screen and (max-width : 768px){

    h1{
        font-size : 1.5rem;
    }
}
`
const ParagraphDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;   
    align-items : center;

p{  
    width : 100%;
    font-size : 1rem;
    font-weight : 500;
    line-height : 30px;
}

@media screen and (max-width : 768px){
    flex-direction : column-reverse;

p{
    font-size : 0.8rem;
    font-weight : 500;
    line-height : 30px;     
}
}
`
const ImgDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 60%;
    height : auto;
}

@media screen and (max-width : 768px){
    
img{
    width : 100%;
    padding : 10px 0;
}
}
`

const InnerSection = styled.div`
    width : 100%;
    height : 100%;

`;

const Steps = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    padding : 0 10%;
    background : url(${Bg1});
    background-attachment: fixed;
    backdrop-filter: blur(5px);

@media screen and (max-width : 768px){
    padding : 0;
}
`;
        
const StepDiv = styled.div`
    width :80%;
    height : auto;
    display : flex;
    cursor : pointer;
    background :  #fff;
    border-radius : 10px;
    padding : 10px;
    margin : 10px auto;
    transition: all 0.5s ease;
    box-shadow : 0 0 5px rgba(0, 0, 0, 0.5);

:hover {
    transform : scale(1.03);
}

@media screen and (max-width : 768px) {
    flex-direction : column;
}
`;

const StepNumber = styled.div`
    width : 40%;
    display : flex;
    justify-content : center;
    align-items : center;

h1{
    font-size : 4rem;
    font-style : "italic";
    font-family: 'Noto Sans', sans-serif;
}

@media screen and (max-width : 768px) {
    width : 100%;   

h1{
    font-size : 3rem;
    font-style : "italic";
    font-family: 'Noto Sans', sans-serif;
}
}
`;

const StepContent = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    text-align : center;

h5{
    font-size : 1.2rem;
    padding : 10px 0;
}

p{
    font-size : 1rem;
    padding : 10px 0;
}

@media screen and (max-width : 768px) {
    width : 100%;   

h1{
    font-size : 3rem;
    font-style : "italic";
    font-family: 'Noto Sans', sans-serif;
}
}
`;

const StepImage = styled.div`
    width : 60%;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 60%;
    height : 100%;
}

@media screen and (max-width : 768px) {
    width : 100%;   


img{
    width : 100%;
    height : 100%;
}
}
`;

    

const HowItHelps = () => {



    return <Fragment>
        <Section>
            <Heading>
                <h1 data-aos="fade-up">How does Mobile App helps ....!</h1>
                <ParagraphDiv >
                    <p data-aos="zoom-out">These days, business and technology progress resembles a paradox as technology is constantly growing madly and also business growth requires it to be constant too. Now one of the most prosperous and aggressively growing technologies is mobile application development. We have the answer. If your business is lacking in gaining the following mentioned advantages, then certainly, you will require a mobile app development company.</p>
                    <ImgDiv>
                        <img data-aos="zoom-out"src={Hero2} />
                    </ImgDiv>
                </ParagraphDiv>
            </Heading>
            <InnerSection>
                <Steps>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>01</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>24/7 Communication With Customers</h5>
                                <p>Mobile app development helps your capacity to communicate with your customers 24/7.</p>
                        </StepContent>
                        <StepImage>
                            <img src ={One} />
                        </StepImage>
                    </StepDiv>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>02</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>Brand’s Mobile Experience</h5>
                                <p>This is not just a mobile app that helps your business run, but also an establish a fruitful mobile experience on your brand.</p>
                        </StepContent>
                        <StepImage>
                            <img src ={Two} />
                        </StepImage>
                    </StepDiv>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>03</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>Mobile App Marketing</h5>
                                <p>The mobile app not only serves as a direct marketing channel but also gives the advantage of performing effective mobile app marketing.</p>
                        </StepContent>
                        <StepImage>
                            <img src ={Three} />
                        </StepImage>
                    </StepDiv>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>04</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>Execute Social Sharing And Connection</h5>
                                <p>Take advantage of social media connections from your mobile app.With the help of advanced online actions, user-experience and customer engagements enhance.</p>
                        </StepContent>
                        <StepImage>
                            <img src ={Four} />
                        </StepImage>
                    </StepDiv>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>05</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>Customize your work</h5>
                                <p>Plan your daily work schedules any where any time</p>
                        </StepContent>
                        <StepImage>
                            <img src ={Five} />
                        </StepImage>
                    </StepDiv>
                    <StepDiv data-aos="fade-up">
                        <StepNumber>
                                <h1>06</h1>
                        </StepNumber>
                        <StepContent>
                                <h5>Decrease The Cost To Market New Products</h5>
                                <p>With the help of a mobile app, reduce the cost to hit the market with your new products.</p>
                        </StepContent>
                        <StepImage>
                            <img src ={Six} />
                        </StepImage>
                    </StepDiv>
                </Steps>   
            </InnerSection>
        </Section>
    </Fragment>
}

export default HowItHelps;