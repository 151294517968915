import React, { useEffect, useState, useRef, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from "gsap/all";
import ImageOne from '../../../../Images/SlidersPage/services.png';
import { HiBadgeCheck } from 'react-icons/hi';

const Center = css`
    display : flex;
    justify-content : center;
    align-items : center;
    box-sizing : border-box;
    overflow : hidden;

@media screen and (max-width : 768px){

}
`;

const WhyUsSection = styled.div`
    width : 100%;
    height : auto;
    ${Center};
    flex-direction : column;
    position : relative;
`;
const Wrapper = styled.div`
    width : 100%;
    height : auto;
    ${Center};

@media screen and (max-width : 768px){
    flex-direction : column-reverse;
}
`;
const Heading = styled.div`
    width : 100%;
    ${Center};
    text-align : center;
`;
const Points = styled.div`
    width : 100%;
    ${Center};
`;
const PointsWrapper = styled.div`
    width : 100%;
    padding : 20px;
`;
const PointText = styled.div`
    line-height : 30px;
`;

const Image = styled.div`
    width : 100%;

`;

const ImageDiv = styled.div`
    width : 100%;
    ${Center};

img{
    width : 80%;
}
`;

const Icon = styled(HiBadgeCheck)`
    font-size : 20px;
    position : relative;
    bottom : -5px;
    margin-right : 5px;
`;

const Svg = styled.div`
    width : 100%;
    position : absolute;
    bottom : -60px;

@media screen and (max-width : 768px){
    bottom : -50px; 
}
`;

const WhyUs = () => {

    return <Fragment>
            <WhyUsSection>
                <Heading><h2 data-aos="fade-up">Why choose LMVIT CRM?</h2></Heading>
                <Wrapper>
                    <Points>
                        <PointsWrapper data-aos="zoom-in">
                            <PointText><p><Icon />Simple, straightforward user interface with a minimal learning curve.</p></PointText>
                            <PointText><p><Icon />Flexible, month-to-month contracts supported by transparent pricing plans.</p></PointText>
                            <PointText><p><Icon />Deployment, consultation, onboarding, and training programs to help you get started smoothly.</p></PointText>
                            <PointText><p><Icon />Migration wizard to easily import data from spreadsheets or other CRM systems.</p></PointText>
                            <PointText><p><Icon />Fully extensible developer platform that facilitates tighter integration of CRM with external systems and applications.</p></PointText>
                            <PointText><p><Icon />Sell on the go with our award-winning CRM apps for both iOS and Android.</p></PointText>
                        </PointsWrapper>
                    </Points>
                    <Image>
                        <ImageDiv data-aos="zoom-in">
                            <img src={ImageOne} />
                        </ImageDiv>
                    </Image>
                </Wrapper>
               
            </WhyUsSection>
    </Fragment>
}


export default WhyUs;

