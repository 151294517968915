import React, { useEffect, Fragment} from 'react';
import Image from '../../Images/PNG/App/Hero.png';
import Bg1 from '../../Images/PNG/AppBg.png';
import WhyUs from '../WhyLmvIt';
import styled from 'styled-components';
import LifeCycle from './App/LifeCycle';
import HowItHelps from './App/HowItHelps';
import Footer from '../Footer';

// background : #5B5A77;  #a58faa

const AppSection = styled.section`
    width: 100%;
    height: 100vh;
    box-sizing : border-box;
    overflow: hidden;
    background-image : url(${Bg1});
    background-position: center;
    object-fit : cover;
    padding : 0 10%;
    display : flex;
    justify-content : center;
    align-item : center;

@media screen and (max-width: 768px) {
    object-fit : cover;
    flex-direction : column;
    padding : 0 ;
}
`;

const BgImage1 = styled.img`
    width : 100%;
    height : 100%;
    position : absolute;
    z-index: -20;
`;



const UiUxDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px ) {

}

`;

const ImgDiv = styled.div`
    width : 100%;
    height : 100%;
    justify-content : center;
    align-items : center;
    display : flex;
    position : relative;

svg{
    width : 100%;
    height : auto;
    filter: drop-shadow(0px 0px 10px #000);
    transform : scale(1.5)
}

@media screen and (max-width : 768px ) {
    display : initial;
}
`;

const Heading = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content: center;

`;

const HeadingDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    font-style: italic;
    justify-content : center;
    padding-top : 60px;
    align-item : center;
    flex-direction : column;
    text-align : center;


h1{
    font-size : 2rem;
    letter-spacing : 3px;
}

h5{
    text-align : center;
    margin-bottom : 15px;
}

p{
    font-size : 1.1rem;
    padding : 10px 0;
    font-weight : bold;
    line-height : 30px;
}

@media screen and (max-width : 768px ) {

h1{
    font-size : 1.5rem;
    padding : 10px 0;
    letter-spacing : 5px;
}

p{
    font-size : 0.9rem;
    padding : 10px 10px ;  
}
}
`;

const Img = styled.img`
    width : 100%;
    height : auto;
    height : auto;
    filter: drop-shadow(15px 15px 10px #000);
    transform : skew(-5deg) scale(0.9);

@media screen and (max-width : 768px ) {
    transform : skew(-5deg) scale(0.95);    
}
`;



const AppDevelopment = () => {

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])

        return (
            <Fragment>
                <AppSection>
                    <BgImage1 src={Bg1} />
                    <Heading>
                        <HeadingDiv>
                            <h1 data-aos="fade-right">App Development</h1>
                            <h5 data-aos="fade-right">Andriod Apps, IOS Apps, Web Based Apps....!</h5>
                            <p data-aos="fade-right">As your User Experience And Technology Partner, we builds for you software solutions, be it websites, web applications or mobile applications, that are fluid and flawless.</p>
                        </HeadingDiv>
                    </Heading>
                    <UiUxDiv >
                        <ImgDiv data-aos="zoom-in-left">
                            <Img src={Image} />
                        </ImgDiv>  
                    </UiUxDiv>
                </AppSection>
                <HowItHelps />
                <LifeCycle />
                <WhyUs />
                <Footer />
            </Fragment>
            
        )
}

export default AppDevelopment;