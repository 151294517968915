import Vision from '../../Images/AboutUs/vision.png';
import Mission from '../../Images/AboutUs/Mission.png';
import Commitment from '../../Images/AboutUs/commitment.png';
import Team from '../../Images/AboutUs/team.png';
import WhoWeAre from '../../Images/AboutUs/whoweare.png';


const aboutUsIcons = {
    vision : Vision,
    mission : Mission,
    commitment : Commitment,
    team : Team,
    whoweare : WhoWeAre,
}

export default aboutUsIcons;