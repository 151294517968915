import React, { Fragment, useEffect }from 'react';
import Hero from '../Home Page/Hero';
import Footer from '../Footer';
import  HowWeWork from '../Home Page/HowWeWork';
import Services from '../Home Page/OurServices';
import OurTechStack from '../Home Page/OurTechStack';

const Home = () => {
    
    useEffect(() =>{
        window.scrollTo(0, 0);

    }, [])

        return (
            <Fragment >
                    <Hero />
                    <Services />
                    <HowWeWork />
                    <OurTechStack />
                    <Footer />    
            </Fragment>
        )
}   

export default Home;