import React, { useEffect, useRef, Fragment } from 'react';
import { gsap } from "gsap/all";
import styled from 'styled-components';



const Hero = styled.div`
    width : 100%;
    height : 90vh;
    display : flex;
    margin-top : 50px;


@media screen and (max-width: 768px){
    height : auto;
    flex-direction : column;
}
`;

const HeroContent = styled.div`
    width : 100%;
    height : auto;
    padding : 0 20px;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;

@media screen and (max-width: 768px){
    padding : 10px 0px;
    text-align : center;
}


` 

const HeroDiv = styled.div`
    width : 100%;
    height : auto;
    padding : 0 20px;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;


h1{
    font-size : 1.5rem;
    padding : 10px 0;
}

p{
    font-size : 1rem;
    padding : 10px 0;
    line-height : 30px;
}

@media screen and (max-width: 768px){
padding : 10px 0px;
text-align : center;

h1{
    font-size : 1.2rem;
    padding : 5px 0;
}

p{
    font-size : 0.9rem;
    padding : 5px 0;
    line-height : 30px;
}
}
`;


const HeroImage = styled.div`
    width : 150%;
    height : 100%;
    padding : 0 20px;
    display : flex;
    justify-content : center;
    align-items : center;

    svg{
        margin-top : 15px;
    }

@media screen and (max-width: 768px){
    width : 100%;
    padding : 10px 0px;
}
`;


const HeroSection = () => {
    const h1Ref = useRef(null);
    const h2Ref = useRef(null);
    const p1Ref = useRef(null);
    const p2Ref = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({delay: 2});
        tl.from(h1Ref.current, { y : 100, opacity : 0, duration : 0.5, ease : 'ease'})
        .from(p1Ref.current, { y : 100, opacity : 0,   duration : 0.5, ease : 'ease'})
        .from(h2Ref.current, { y : 100, opacity : 0,   duration : 0.5, ease : 'ease'})
        .from(p2Ref.current, { y : 100, opacity : 0,   duration : 0.5, ease : 'ease'})
    }, []);

    return <Fragment>
            <Hero >
                <HeroContent>
                    <HeroDiv>
                        <h1 ref={h1Ref} >Fishing</h1>
                        <p ref={p1Ref}>With samudra Application , you can increase your haul, sell it quickly, and do it more safely than ever before.</p>
                    </HeroDiv>
                    <HeroDiv>
                        <h1 ref={h2Ref} >Our Mission</h1>
                        <p ref={p2Ref}>To provide ocean data, information and advisory services to society, industry, the government and the scientific community through sustained ocean observations and constant improvements through systematic and focused research in information management and ocean modelling.</p>
                    </HeroDiv>
                </HeroContent>
                <HeroImage>
                    {/* <Svg /> */}
                </HeroImage>
            </Hero>
    </Fragment>
}


export default HeroSection;