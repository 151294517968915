import React, { Fragment, useEffect } from 'react';
import Footer from '../Footer';
import PrivacyPolicy from '../privacy/PrivacyPolicy';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <PrivacyPolicy />
    </Fragment>
  );
};

export default Privacy;
