import React, { useEffect } from 'react';
import styled from 'styled-components';


const Section = styled.div`
    width : 100%;
    height : auto;
    padding : 0 10%;


@media screen and (max-width : 768px){
    padding : 10px;
}
`;

const InnerSection = styled.div`
    width : 100%;
    height : 100%;
`

const Content = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-wrap : wrap;
`;

const ContentDiv = styled.div`
    width : 400px;
    height : auto;
    margin : 20px;
    padding : 10px;
    flex : 1 1 auto;

@media screen and (max-width : 768px){
    margin : 20px auto;
}
`;

const ImageDiv = styled.div`
    width : 100%;
    height : 100%;
    display: flex;
    justify-content : center;
    align-items : center;

svg{
    width : 100px;
    height : auto;
}
`;

const ContentHead = styled.div`
    width : 100%;
    height : auto;
    text-align : center;
    padding : 10px;

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentText = styled.div`
    width: fit-content;
    height : auto;
    padding : 10px;
    display : flex;
    justify-content : center;
    align-items : center;
    line-height : 30px;
    text-align: justify;
    text-justify: inter-word;

p{
    font-size : 14px;
}


@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;

const Heading = styled.div`
    width : 100%;
    height : auto;
    text-align : center;

h2{
    padding : 15px 0;
}
`

const Class = {
    color : 'red'
}

const OurServices = () => {

    
    return <Section data-aos-id="seo">
        <Heading>
            <h2>Our Services</h2>
        </Heading>
        <InnerSection>
            <Content>
                <ContentDiv data-aos="fade-up" >
                    <ImageDiv>
                        <SEO />
                    </ImageDiv>
                    <ContentHead>
                        <h4>SEO (Search engine optimization)</h4>
                    </ContentHead>
                    <ContentText>
                        <p>Our SEO strategies will raise your website’s domain authority and so, your website’s rank for the competitive search terms. We face these challenges of getting top rank in the search engines and help your target audience find you as you found us.</p>
                    </ContentText>
                </ContentDiv>
                <ContentDiv data-aos="fade-up">
                    <ImageDiv>
                        <SocialMedia />
                    </ImageDiv>
                    <ContentHead>
                        <h4>Social Media Marketing</h4>
                    </ContentHead>
                    <ContentText>
                        <p>If you are going with a digital marketing plan for your business, then social media acts as a big asset. Avail our social media marketing services to build your brand image with an extensive range of creatives and engaging content.</p>
                    </ContentText>
                </ContentDiv>
                <ContentDiv data-aos="fade-up">
                    <ImageDiv>
                        <ContentMarketing />
                    </ImageDiv>
                    <ContentHead>
                        <h4>Content marketing</h4>
                    </ContentHead>
                    <ContentText>
                        <p>Information sharing is a valuable approach in the current marketing trends. We research, create, and deliver highly valuable content with our marketing strategies that relates to your business and helps you to gain strong customer base.</p>
                    </ContentText>
                </ContentDiv>
                <ContentDiv data-aos="fade-up">
                    <ImageDiv>
                        <PPC />
                    </ImageDiv>
                    <ContentHead>
                        <h4>PPC (pay per click)</h4>
                    </ContentHead>
                    <ContentText>
                        <p>Sometimes, your business needs an immediate boost and so, pay per click comes into the picture. We provide the best PPC Services  through which you can expect highly qualified leads or sales for your business.</p>
                    </ContentText>
                </ContentDiv>
                <ContentDiv data-aos="fade-up">
                    <ImageDiv>
                        <Mail />
                    </ImageDiv>
                    <ContentHead>
                        <h4>Email marketing</h4>
                    </ContentHead>
                    <ContentText>
                        <p>If you want to retain your existing customers or clients and maintain meaningful relationships, then you need to connect with them and so, our email marketing services do that for you with responsive emails and promotional strategies.</p>
                    </ContentText>
                </ContentDiv>
            </Content>
        </InnerSection>
    </Section>
}

export default OurServices;



const Mail = () => {

    return <svg width="100%" height="100%" version="1.1" 
    viewBox="0 0 666 666"   >
     <g id="Layer_x0020_1">
      <g id="_924895384">
       <g id="Email_Marketing" >
        <path fill="#B83E6C" d="M654 666l-642 0c-7,0 -12,-5 -12,-12l0 -357c0,-6 5,-11 12,-11 2,0 4,0 6,1l315 187 315 -187c2,-1 4,-1 6,-1 7,0 12,5 12,11l0 357c0,7 -5,12 -12,12l0 0zm-630 -23l619 0 0 -325 -304 180c-2,1 -4,2 -6,2 -2,0 -4,-1 -6,-2l-303 -180 0 325z"/>
        <path fill="#B83E6C" d="M547 373c-6,0 -12,-6 -12,-12l0 -337 -404 0 0 337c0,6 -5,12 -12,12 -7,0 -12,-6 -12,-12l0 -349c0,-7 5,-12 12,-12l428 0c7,0 12,5 12,12l0 349c0,6 -5,12 -12,12l0 0z"/>
        <path fill="#B83E6C" d="M238 238l-48 0c-6,0 -12,-5 -12,-12l0 -48c0,-6 6,-11 12,-11l48 0c7,0 12,5 12,11l0 48c0,7 -5,12 -12,12l0 0zm-24 -36l24 0 0 -12 -36 0 12 12z"/>
        <path fill="#B83E6C" d="M428 309c-1,0 -2,0 -2,0l-191 -48c-5,-1 -9,-6 -9,-11l0 -95c0,-6 4,-11 9,-12l191 -47c0,-1 1,-1 2,-1 7,0 12,6 12,12l0 190c0,7 -5,12 -12,12l0 0zm-178 -68l166 41 0 -160 -166 42 0 77z"/>
        <path fill="#B83E6C" d="M476 333l-48 0c-6,0 -12,-5 -12,-12l0 -238c0,-6 6,-12 12,-12l48 0c7,0 12,6 12,12l0 238c0,7 -5,12 -12,12zm-24 -36l24 0 0 -202 -36 0 12 202z"/>
        <path fill="#B83E6C" d="M309 357l-47 0c-7,0 -12,-5 -12,-12l0 -89c0,-7 5,-12 12,-12 6,0 12,5 12,12l0 77 23 0 0 -65c0,-7 6,-12 12,-12 7,0 12,5 12,12l0 77c0,7 -5,12 -12,12l0 0z"/>
        <path fill="#B83E6C" d="M12 309c0,0 0,0 0,0 -7,0 -12,-5 -12,-12 0,-3 2,-7 5,-9l107 -79c2,-2 4,-3 7,-3 6,0 12,6 12,12 0,4 -2,7 -5,10l-107 79c-2,2 -5,2 -7,2 0,0 0,0 0,0l0 0z"/>
        <path fill="#B83E6C" d="M654 309c0,0 0,0 0,0 -2,0 -5,0 -7,-2l-107 -79c-3,-3 -5,-6 -5,-10 0,-7 6,-12 12,-12 3,0 5,1 7,3l108 79c3,2 4,6 4,9 0,7 -5,12 -12,12 0,0 0,0 0,0l0 0z"/>
       </g>
      </g>
     </g>
    </svg>
}


const PPC = () => {

    return <svg  width="577px" height="577px" version="1.1"  viewBox="0 0 577 577">
     <defs>
      <font id="FontID0" horizAdvX="666" fontVariant="normal" fillRule="nonzero" fontStyle="normal" fontWeight="400">
        <font-face 
            fontFamily="Arial">
            <font-face-src>
                <font-face-name name="Arial"/>
            </font-face-src>
        </font-face>
       <missing-glyph><path d="M0 0z"/></missing-glyph>
       <glyph unicode="₹" horizAdvX="556" d="M515.835 716.001l-48.8372 -72.0017 -101.496 0c10.3327,-11.664 18.9978,-25.1638 25.8318,-40.1676 6.83394,-14.9992 11.664,-32.3294 14.6675,-51.8316l109.834 0 -48.8372 -72.0017 -60.9966 0c-3.835,-23.6643 -11.6686,-45.4975 -23.1691,-65.4995 -11.5005,-19.9975 -26.8314,-37.1641 -45.6656,-51.6635 -18.8342,-14.4994 -41.1672,-25.6682 -66.999,-33.8335 -25.9999,-8.16983 -55.0032,-12.1684 -87.1691,-12.1684l296.999 -316.833 -129.999 0 -286.998 309 0 72.0017 71.8336 0c22.5011,0 43.3346,2.16741 62.6641,6.33412 19.1705,4.1667 36.3371,10.4963 51.3363,18.9978 14.9992,8.50153 27.4993,18.8342 37.3322,30.9981 10.001,12.1684 16.9985,26.3361 20.8335,42.6667l-268 0 48.8327 72.0017 218.668 0c-7.50189,27.9992 -23.5008,50.3322 -48.0012,66.999 -24.664,16.6668 -57.9976,25.0002 -100.333,25.0002l-119.167 0 48.8327 72.0017 428.003 0z"/>
      </font>
     </defs>
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_727248712">
       <path fill="#B83E6C" d="M433 487l0 -88c0,-7 4,-14 11,-14l98 0c6,0 10,5 10,11l0 102c0,26 -28,54 -54,54 -24,0 -33,-3 -50,-20 -10,-10 -15,-25 -15,-45zm65 90l-11 0c-12,-1 -23,-4 -34,-9 -27,-15 -45,-43 -45,-80l0 -88c0,-12 4,-21 10,-27 16,-17 31,-12 62,-12l0 -245c0,-12 -8,-26 -15,-31 -31,-26 -71,-8 -79,24 -2,11 -1,287 -1,329 0,39 -2,71 -26,98 -4,4 -6,6 -10,10 -16,15 -46,31 -72,31 -39,0 -56,-8 -84,-30 -25,-20 -37,-60 -37,-91l0 -120c-23,0 -47,-9 -63,-17 -16,-7 -33,-21 -45,-33 -15,-14 -24,-29 -33,-47 -12,-25 -15,-48 -15,-78 0,-23 8,-49 16,-65 5,-9 9,-17 15,-25 20,-27 36,-40 66,-55 26,-13 48,-16 79,-16 43,0 85,23 113,51l10 11c39,45 50,121 23,175 -8,16 -9,19 -19,32l-9 11c-3,3 -3,3 -5,6l-22 19c-23,15 -42,25 -71,30 -11,2 -16,-1 -16,4l0 120c0,79 81,114 136,81 21,-13 37,-33 42,-58 3,-15 3,-30 3,-47l0 -301c0,-20 -1,-30 6,-44 5,-11 13,-20 22,-28 51,-38 116,4 116,54l0 245c26,0 47,-5 62,10 4,4 10,14 10,22l0 104c0,34 -23,59 -48,72 -10,5 -20,7 -31,8zm-327 -265c-57,0 -106,-29 -132,-81 -13,-25 -14,-41 -14,-70 0,-35 18,-70 42,-94 3,-3 5,-6 8,-9 21,-18 57,-33 86,-33 28,0 45,1 70,14 17,8 27,16 40,29 51,50 55,138 8,193 -11,12 -23,24 -38,32 -22,11 -43,19 -70,19z"/>
       <text x="96" y="266"  fill="#B83E6C" fontWeight="normal" fontSize="260.001px" fontFamily="Arial">₹</text>
      </g>
     </g>
    </svg>
}

const SocialMedia = () => {

    return <svg width="100%" height="100%"  viewBox="0 0 430 437">
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_984455896">
       <path fill="#B83E6C" d="M14 399l0 -5 230 0c0,29 -14,29 -36,29 0,-6 1,-15 -7,-15 -9,0 -8,9 -8,15l-128 0c0,-6 1,-15 -8,-15 -8,0 -7,9 -7,15 -14,0 -36,1 -36,-24zm8 -170l0 151c-25,0 -22,-3 -22,24 0,16 17,33 33,33l192 0c16,0 33,-17 33,-33 0,-27 3,-24 -22,-24l0 -72c0,-8 -14,-8 -14,0l0 72 -186 0 0 -143 60 0c9,0 9,-15 0,-15l-67 0c-5,0 -7,3 -7,7z"/>
       <path fill="#B83E6C" d="M143 323c4,-1 9,-6 13,-6 1,5 14,23 14,27 0,7 -9,11 -14,2l-13 -23zm-69 -18c2,4 6,9 7,13 -4,1 -5,4 -9,4 -5,0 -8,-4 -7,-9 1,-4 6,-6 9,-8zm-7 -22l1 9c-8,4 -18,10 -18,22 0,7 3,12 6,16 10,11 23,7 34,0 3,2 3,3 7,5 14,7 23,1 34,-4 0,2 14,27 19,31 16,12 35,-2 35,-17 0,-9 -11,-25 -16,-34l86 -32c6,-2 10,-4 15,-6 14,-5 15,-6 2,-27l-26 -45c-8,-12 -15,-5 -15,2 0,3 6,11 7,14l25 44c-3,0 -15,5 -19,6l-87 33c-1,1 -3,1 -5,2 -1,0 -3,1 -5,2 -13,5 -25,18 -39,18 -9,0 -12,-10 -16,-15 -8,-13 -15,-22 -7,-32 3,-3 25,-16 29,-16l15 27c8,14 17,4 16,-2 -1,-4 -17,-29 -20,-34 8,-5 65,-54 78,-64 4,-4 8,-7 12,-9 2,3 3,8 9,8 5,0 10,-5 5,-14 -4,-7 -8,-19 -18,-11 -2,2 -3,3 -5,4l-66 54c-16,14 -21,20 -39,30l-16 9c-8,5 -18,12 -18,26z"/>
       <path fill="#B83E6C" d="M301 27c0,12 -3,23 7,23 15,0 -1,-28 15,-28l84 0c5,0 8,3 8,8l0 84c0,4 -3,8 -8,8l-84 0c-11,0 -8,-15 -8,-28 0,-6 0,-12 0,-18 0,-18 -14,-12 -14,-4l0 45c0,9 10,19 20,19l89 0c10,0 20,-10 20,-19l0 -90c0,-10 -10,-20 -20,-20l-89 0c-10,0 -20,10 -20,20z"/>
       <path fill="#B83E6C" d="M301 249l0 90c0,9 10,19 20,19l89 0c10,0 20,-10 20,-19 0,-12 2,-24 -7,-24 -16,0 1,29 -16,29l-84 0c-4,0 -8,-4 -8,-8l0 -84c0,-5 4,-8 8,-8l84 0c11,0 8,15 8,27 0,6 0,12 0,18 0,19 15,13 15,5l0 -45c0,-10 -10,-20 -20,-20l-89 0c-10,0 -20,10 -20,20z"/>
       <path fill="#B83E6C" d="M14 86l72 0 0 100 -72 0 0 -100zm-14 -7l0 115c0,4 3,7 7,7l86 0c5,0 7,-3 7,-7l0 -115c0,-4 -2,-7 -7,-7l-86 0c-4,0 -7,3 -7,7z"/>
       <path fill="#B83E6C" d="M179 115c-18,0 -37,-12 -45,-29 -24,-49 40,-96 80,-56 25,24 17,66 -15,80 -4,2 -14,5 -20,5zm-64 -55c0,20 2,31 15,46 1,2 2,3 3,5 10,9 27,18 42,18 21,0 30,-3 46,-15 13,-11 23,-30 23,-47 0,-13 -1,-18 -6,-30 -4,-7 -10,-16 -16,-21 -3,-2 -3,-2 -5,-4 -33,-23 -77,-12 -96,24 -3,5 -6,16 -6,24z"/>
       <path fill="#B83E6C" d="M351 295c-1,-5 4,-15 14,-15 18,-2 20,27 1,28 -8,0 -14,-6 -15,-13zm-14 -3c0,41 57,41 57,0 0,-15 -12,-27 -30,-27 -14,0 -27,13 -27,27z"/>
       <path fill="#B83E6C" d="M172 75l0 -20c4,2 14,8 19,9l0 2c-3,0 -3,0 -5,1 -2,1 0,0 -2,1l-12 7zm-14 -32l0 43c0,10 9,7 14,5l33 -17c24,-11 -1,-20 -13,-26l-20 -9c-5,-3 -14,-6 -14,4z"/>
       <path fill="#B83E6C" d="M351 56l0 9c-6,0 -14,-2 -14,7 0,8 8,7 14,7 0,15 -3,29 7,29 10,0 7,-14 7,-29 6,0 15,1 15,-7 0,-9 -9,-7 -15,-7l0 -7c0,-16 29,1 29,-15 0,-9 -11,-7 -23,-7 -10,0 -20,10 -20,20z"/>
       <path fill="#B83E6C" d="M286 208c0,13 21,5 31,3 16,-3 20,-2 20,-12 0,-11 -28,-2 -32,-1 -10,2 -19,2 -19,10z"/>
       <path fill="#B83E6C" d="M244 151c0,4 2,7 7,7 5,0 11,-10 16,-16 3,-4 12,-16 12,-20 0,-4 -2,-7 -7,-7 -5,0 -7,4 -9,7 -2,3 -4,6 -7,9 -2,3 -4,6 -7,9 -2,3 -5,7 -5,11z"/>
       <path fill="#B83E6C" d="M265 179c0,5 3,7 7,7 4,0 8,-3 11,-5l18 -14c3,-2 7,-4 7,-9 0,-5 -3,-7 -7,-7 -5,0 -16,9 -20,12 -3,3 -6,5 -9,7 -3,2 -7,4 -7,9z"/>
       <path fill="#B83E6C" d="M29 136c0,5 3,7 7,7l29 0c9,0 9,-14 0,-14l-29 0c-4,0 -7,3 -7,7z"/>
       <path fill="#B83E6C" d="M29 165c0,4 3,7 7,7l29 0c9,0 9,-14 0,-14l-29 0c-4,0 -7,3 -7,7z"/>
       <path fill="#B83E6C" d="M43 108c0,9 11,7 22,7 9,0 9,-15 0,-15 -11,0 -22,-2 -22,8z"/>
      </g>
     </g>
    </svg>
}


const SEO = () => {

    return <svg xmlns="http://www.w3.org/2000/svg"  width="622px" height="622px" version="1.1" viewBox="0 0 883 883"
     enableBackground="new 0 0 512 512">
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_1153443712">
       <g>
        <path fill="#B83E6C" d="M213 181l405 0c36,0 66,30 66,66l0 177c0,7 6,13 13,13 7,0 13,-6 13,-13l0 -177c0,-50 -41,-92 -92,-92l-405 0c-7,0 -12,6 -12,13 0,7 5,13 12,13l0 0z"/>
        <path fill="#B83E6C" d="M528 580l-244 0c-3,0 -7,2 -10,5l-73 86 -73 -86c-3,-3 -6,-5 -10,-5l-26 0c-36,0 -66,-30 -66,-66l0 -267c0,-36 30,-66 66,-66l61 0c8,0 13,-6 13,-13 0,-7 -5,-13 -13,-13l-61 0c-51,0 -92,42 -92,92l0 267c0,51 41,92 92,92l20 0 79 93c3,3 6,5 10,5 4,0 8,-2 10,-5l79 -93 238 0c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l0 0z"/>
        <path fill="#B83E6C" d="M382 771l-118 0c-7,0 -13,6 -13,13 0,7 6,13 13,13l118 0c16,0 29,13 29,30 0,16 -13,30 -29,30l-326 0c-17,0 -30,-14 -30,-30 0,-17 13,-30 30,-30l11 0c4,0 8,-2 11,-5l22 -31 22 31c3,3 7,5 11,5l71 0c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l-64 0 -29 -40c-3,-3 -7,-5 -11,-5 -4,0 -8,2 -10,5l-30 40 -4 0c-31,0 -56,25 -56,56 0,31 25,56 56,56l326 0c30,0 55,-25 55,-56 0,-31 -25,-56 -55,-56l0 0z"/>
        <path fill="#B83E6C" d="M501 111l49 0c7,0 13,-5 13,-12 0,-8 -6,-13 -13,-13l-49 0c-16,0 -30,-14 -30,-30 0,-17 14,-30 30,-30l326 0c16,0 30,13 30,30 0,16 -14,30 -30,30l-11 0c-5,0 -8,2 -11,5l-22 31 -23 -31c-2,-3 -6,-5 -10,-5l-140 0c-8,0 -13,5 -13,13 0,7 5,12 13,12l133 0 29 41c3,3 6,5 11,5 4,0 8,-2 10,-5l29 -41 5 0c31,0 56,-25 56,-55 0,-31 -25,-56 -56,-56l-326 0c-31,0 -56,25 -56,56 0,30 25,55 56,55l0 0z"/>
        <path fill="#B83E6C" d="M189 438c-7,0 -13,6 -13,13 0,7 6,13 13,13l69 0c7,0 13,-6 13,-13l0 -69c0,-7 -6,-13 -13,-13l-56 0 0 -43 56 0c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l-69 0c-7,0 -13,6 -13,13l0 69c0,7 6,13 13,13l56 0 0 43 -56 0z"/>
        <path fill="#B83E6C" d="M388 326c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l-69 0c-7,0 -13,6 -13,13l0 138c0,7 6,13 13,13l69 0c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l-56 0 0 -43 56 0c7,0 13,-6 13,-13 0,-7 -6,-13 -13,-13l-56 0 0 -43 56 0z"/>
        <path fill="#B83E6C" d="M448 300c-7,0 -13,6 -13,13l0 138c0,7 6,13 13,13l69 0c7,0 13,-6 13,-13l0 -138c0,-7 -6,-13 -13,-13l-69 0zm56 138l-43 0 0 -112 43 0 0 112z"/>
        <path fill="#B83E6C" d="M883 595c0,-6 -5,-12 -11,-13l-18 -2 -9 -21 11 -15c4,-5 3,-12 -1,-16l-37 -37c-4,-4 -11,-5 -17,-1l-14 10 -21 -8 -3 -18c-1,-6 -6,-11 -12,-11l0 0 -52 0c-6,0 -12,5 -13,11l-3 18 -20 9 -15 -11c-5,-4 -12,-3 -16,1l-37 37c-4,4 -5,11 -1,17l10 14 -8 21 -18 3c-6,1 -11,6 -11,12l0 52c0,6 5,12 11,13l18 3 8 20 -10 15c-4,5 -3,12 1,16l37 37c4,4 11,5 16,1l15 -10 21 8 2 18c1,6 7,11 13,11l0 0 52 0c6,0 12,-5 13,-11l2 -18 21 -8 15 10c5,4 12,3 16,-1l37 -37c4,-4 5,-11 1,-16l-11 -15 9 -21 18 -2c6,-1 11,-7 11,-13l0 -52zm-40 43c-5,1 -8,4 -10,8l-14 34c-2,4 -1,9 1,12l9 12 -21 21 -12 -9c-3,-2 -8,-3 -12,-1l-34 14c-4,2 -7,6 -8,10l-2 14 -30 0 -2 -14c-1,-4 -4,-8 -8,-10l-34 -14c-1,-1 -3,-1 -5,-1 -2,0 -5,1 -7,3l-12 8 -21 -21 9 -12c2,-3 3,-8 1,-12l-14 -34c-2,-4 -5,-7 -10,-8l-14 -2 0 -30 14 -2c5,-1 8,-4 10,-8l14 -34c2,-4 1,-9 -1,-12l-9 -12 21 -21 12 9c3,2 8,3 12,1l34 -14c4,-2 7,-5 8,-10l2 -14 30 0 2 14c1,5 4,8 8,10l34 14c4,2 9,1 12,-1l12 -9 21 21 -9 12c-2,3 -3,8 -1,12l14 34c2,4 5,7 10,8l14 2 0 30 -14 2z"/>
        <path fill="#B83E6C" d="M780 608c-7,0 -13,6 -13,13 0,23 -19,42 -42,42 -23,0 -42,-19 -42,-42 0,-23 19,-42 42,-42 6,0 12,1 17,4 7,3 14,0 17,-7 3,-6 0,-14 -6,-17 -9,-4 -18,-6 -28,-6 -37,0 -68,31 -68,68 0,37 31,68 68,68 37,0 68,-31 68,-68 0,-7 -6,-13 -13,-13l0 0z"/>
       </g>
      </g>
     </g>
    </svg>
    
}


const ContentMarketing = () => {

  return  <svg xmlns="http://www.w3.org/2000/svg" width="441px" height="441px" version="1.1" viewBox="0 0 441 441">
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1153441888">
   <path fill="#B83E6C" d="M59 402l0 -5 304 0c17,0 31,-15 34,-30 1,-6 0,-117 0,-126 0,-8 4,-9 13,-18 4,-5 12,-13 16,-16l0 182c0,14 2,37 -24,37l-319 0c-14,0 -24,-10 -24,-24zm190 -70c1,-4 2,-6 3,-10 3,1 5,4 6,7 -3,0 -5,2 -9,3zm10 -26c0,-4 4,-10 5,-15l19 19c2,2 5,4 5,7 -15,4 -9,9 -21,-3 -1,-1 -8,-7 -8,-8zm43 3l-9 -10 87 -88c3,1 4,2 6,4 2,2 3,3 4,5l-88 89zm-20 -21l-10 -10 88 -88 10 9c-2,5 -40,41 -44,45 -6,6 -40,41 -44,44zm89 -109l20 -20c3,2 5,4 8,7l15 15c3,3 6,5 8,8l-20 21 -31 -31zm-143 166c0,16 20,4 30,0 8,-3 15,-6 23,-9 28,-11 20,-4 39,-23 5,-5 58,-60 62,-62 0,14 2,105 -1,115 -2,9 -10,16 -23,16l-319 0c-14,0 -24,-10 -24,-24l0 -284 367 0 0 71c0,3 -18,20 -21,23l-99 99c-5,5 -7,5 -10,13 -4,11 -8,21 -12,32 -2,4 -12,30 -12,33zm169 -204l0 -23 29 0 0 54c-4,-3 -11,-11 -15,-15 -2,-2 -14,-12 -14,-16zm0 -82l5 0c14,0 24,10 24,24l0 20 -29 0 0 -44zm-382 -20c0,-14 10,-24 24,-24l319 0c28,0 24,27 24,44l-367 0 0 -20zm-15 -5l0 329c0,17 17,34 34,34l10 0 0 10c0,17 17,34 34,34l329 0c17,0 34,-17 34,-34l0 -329c0,-17 -17,-34 -34,-34l-10 0 0 -10c0,-17 -17,-34 -34,-34l-329 0c-17,0 -34,17 -34,34z"/>
   <path fill="#B83E6C" d="M59 118l103 0 0 58 -103 0 0 -58zm-15 -8l0 74c0,4 3,7 7,7l118 0c4,0 7,-3 7,-7l0 -74c0,-4 -3,-7 -7,-7l-118 0c-4,0 -7,3 -7,7z"/>
   <polygon fill="#B83E6C" points="74,235 265,235 265,220 74,220 "/>
   <polygon fill="#B83E6C" points="44,265 235,265 235,250 44,250 "/>
   <polygon fill="#B83E6C" points="44,294 206,294 206,279 44,279 "/>
   <polygon fill="#B83E6C" points="44,323 206,323 206,309 44,309 "/>
   <polygon fill="#B83E6C" points="44,353 206,353 206,338 44,338 "/>
   <polygon fill="#B83E6C" points="206,154 338,154 338,140 206,140 "/>
   <polygon fill="#B83E6C" points="235,125 353,125 353,110 235,110 "/>
   <polygon fill="#B83E6C" points="206,184 309,184 309,169 206,169 "/>
   <polygon fill="#B83E6C" points="44,44 59,44 59,29 44,29 "/>
   <polygon fill="#B83E6C" points="74,44 88,44 88,29 74,29 "/>
   <polygon fill="#B83E6C" points="103,44 118,44 118,29 103,29 "/>
   <polygon fill="#B83E6C" points="206,125 220,125 220,110 206,110 "/>
   <polygon fill="#B83E6C" points="44,235 59,235 59,220 44,220 "/>
  </g>
 </g>
</svg>
}