import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Icons from '../Data/OurTechStackData';

const TechSection = styled.div`
  width: 100%;
  height: auto;
  background: #red;
`;

const InnerSection = styled.div`
  width: 100%;
  height: auto;
`;

const Heading = styled.h1`
  text-align: center;
  padding: 15px 0;
  font-family: 'Merriweather';
  font-weight: bold;
  letter-spacing: 5px;
  margin: 40px 0;
`;

const DropDownSection = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropDownDiv = styled.div`
  width: 80%;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Div = styled.div`
  width: 80%;
  height: auto;
  margin: 10px auto;
  line-height: 50px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const IconTitle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #000;
  border-radius: 5px;
  user-select: none;
`;
const FrontEndIcons = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const IconDiv = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  animation: ${(props) => props.primary} 0.5s ease forwards;

  @keyframes open {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: 50px;
      opacity: 1;
    }
  }

  @keyframes close {
    from {
      height: 50px;
      opacity: 1;
    }

    to {
      height: 0;
      opacity: 0;
    }
  }
`;
const BackEndIcons = styled.div``;
const MobileDevelopmentIcons = styled.div``;
const QualityAssuranceIcons = styled.div``;
const DevelopmentIcons = styled.div``;

const TechTitle = styled.h5`
  color: #000;
`;
const Arrow = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UpDownIcon = styled.div``;
const Icon = styled.i`
  display: flex;
  animation: ${(props) => props.primary} 0.5 ease forwards;

  @keyframes up {
    from {
      transform: rotateX(0);
    }
    to {
      transform: rotateX(180deg);
    }
  }

  @keyframes down {
    from {
      transform: rotateX(180deg);
    }
    to {
      transform: rotateX(0);
    }
  }
`;

const OurTechStack = () => {
  const [FrontEndOpen, setFrontEndOpen] = useState(true);
  const [BackEndOpen, setBackEndOpen] = useState(true);
  const [MobileOpen, setMobileOpen] = useState(true);
  const [QualityOpen, setQualityOpen] = useState(true);
  const [DeploymentOpen, setDeploymentOpen] = useState(true);

  useEffect(() => {}, []);

  const closefunction = (set) => {
    setTimeout(() => {
      set(true);
    }, 5000);
  };

  return (
    <TechSection>
      <InnerSection>
        <Heading>OUR TECH STACK </Heading>
        <DropDownSection>
          <DropDownDiv>
            <Div>
              <IconTitle
                onClick={() => {
                  closefunction(setFrontEndOpen);
                  setFrontEndOpen((FrontEndOpen) => !FrontEndOpen);
                }}
              >
                <TechTitle>Front-end Technology</TechTitle>
                <UpDownIcon>
                  <Icon className={Icons.downArrow} primary={FrontEndOpen ? 'down' : 'up'}></Icon>
                </UpDownIcon>
              </IconTitle>
              <IconDiv primary={FrontEndOpen ? 'close' : 'open'}>
                <FrontEndIcons>
                  <Arrow> {Icons.html} </Arrow>
                  <Arrow> {Icons.css} </Arrow>
                  <Arrow> {Icons.react} </Arrow>
                  <Arrow> {Icons.angular} </Arrow>
                </FrontEndIcons>
              </IconDiv>
            </Div>
            <Div>
              <IconTitle
                onClick={() => {
                  setBackEndOpen((BackEndOpen) => !BackEndOpen);
                  closefunction(setBackEndOpen);
                }}
              >
                <TechTitle>Back-end technologies</TechTitle>
                <UpDownIcon>
                  <Icon className={Icons.downArrow} primary={BackEndOpen ? 'down' : 'up'}></Icon>
                </UpDownIcon>
              </IconTitle>
              <IconDiv primary={BackEndOpen ? 'close' : 'open'}>
                <FrontEndIcons>
                  <Arrow> {Icons.nodejs} </Arrow>
                  <Arrow> {Icons.mysql} </Arrow>
                  <Arrow> {Icons.mongodb} </Arrow>
                  <Arrow> {Icons.ruby} </Arrow>
                </FrontEndIcons>
              </IconDiv>
            </Div>
            <Div>
              <IconTitle
                onClick={() => {
                  setMobileOpen((MobileOpen) => !MobileOpen);
                  closefunction(setMobileOpen);
                }}
              >
                <TechTitle>Mobile Development</TechTitle>
                <UpDownIcon>
                  <Icon className={Icons.downArrow} primary={MobileOpen ? 'down' : 'up'}></Icon>
                </UpDownIcon>
              </IconTitle>
              <IconDiv primary={MobileOpen ? 'close' : 'open'}>
                <FrontEndIcons>
                  <Arrow>{Icons.reactNative}</Arrow>
                  <Arrow></Arrow>
                  <Arrow></Arrow>
                </FrontEndIcons>
              </IconDiv>
            </Div>
            <Div>
              <IconTitle
                onClick={() => {
                  setQualityOpen((QualityOpen) => !QualityOpen);
                  closefunction(setQualityOpen);
                }}
              >
                <TechTitle>Quality Assurance</TechTitle>
                <UpDownIcon>
                  <Icon className={Icons.downArrow} primary={QualityOpen ? 'down' : 'up'}></Icon>
                </UpDownIcon>
              </IconTitle>
              <IconDiv primary={QualityOpen ? 'close' : 'open'}>
                <FrontEndIcons>
                  <Arrow> {Icons.selenium} </Arrow>
                  <Arrow></Arrow>
                  <Arrow></Arrow>
                </FrontEndIcons>
              </IconDiv>
            </Div>
            <Div>
              <IconTitle
                onClick={() => {
                  setDeploymentOpen((DeploymentOpen) => !DeploymentOpen);
                  closefunction(setDeploymentOpen);
                }}
              >
                <TechTitle>Deployment</TechTitle>
                <UpDownIcon>
                  <Icon className={Icons.downArrow} primary={DeploymentOpen ? 'down' : 'up'}></Icon>
                </UpDownIcon>
              </IconTitle>
              <IconDiv primary={DeploymentOpen ? 'close' : 'open'}>
                <FrontEndIcons>
                  <Arrow> {Icons.heroku} </Arrow>
                  <Arrow></Arrow>
                  <Arrow></Arrow>
                </FrontEndIcons>
              </IconDiv>
            </Div>
          </DropDownDiv>
        </DropDownSection>
      </InnerSection>
    </TechSection>
  );
};

export default OurTechStack;
