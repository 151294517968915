import React, {Fragment, useState, useEffect, useRef} from 'react';
import {gsap} from "gsap/all";
import styled from 'styled-components';
import Hero from './Hero';
import What from './What';
import AOS from 'aos';
import 'aos/dist/aos.css';

const PayRoll = styled.section `
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 75px 50px;
    position : relative;
    z-index : -100;
    background: black ;

@media screen and (max-width: 768px){
    padding : 35px 20px;
}
`;

const InnerSection = styled.div `
    width : 100%;
    height : 100%;
    background : rgba(255, 255, 255);
    border-radius : 20px;
    position : relative;
    overflow : hidden;


@media screen and (max-width: 768px){

}
`;

const Svg = styled.div `
    width : 100%;
    position : absolute;
    bottom : -100px;

@media screen and (max-width : 768px){
    bottom : -10px; 
}
`;

const SideBar = styled.div `
    width : 25%;
    height : 100%;
    position: absolute;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    box-shadow : 5px 5px 10px #000;
    z-index: -50;
    left : -100%;
    animation : sideBar 0.5s ease 1.2s both;


@media screen and (max-width: 768px){

}

@keyframes sideBar {
    0%{
       left : -100%;
    }

    100%{
        left : 0;
    }
}
`;

const Blob1_L = '300px';
const Blob1_M = '200px';
const BlobMarker1 = styled.div `
    width : ${Blob1_L};
    height : auto;
    position : absolute;
    opacity : 0.5;
    left : -100px;
    top : -100px;
    z-index : 100;
    animation : Blob1 0.5s ease 1.4s both;

@media screen and (max-width: 768px){
    width : ${Blob1_M} !important;  
    left : -50px !important;
    top : -50px !important;
}

@keyframes Blob1 {
    0%{
       width : 0;
    }

    100%{
        width : ${Blob1_L};
    }
}
`;

const BlobMarker2 = styled.div `       
    height : auto;
    position : absolute;
    opacity : 0.5;
    right : -100px;
    z-index : 100;
    animation : Blob2 0.5s ease 1.6s forwards;

@media screen and (max-width: 768px){
    width : 200px !important;
    right : -50px !important;
    bottom : 10% !important;
}


@keyframes Blob2 {
    0%{
       width : 0;
    }

    100%{
        width : 300px;
    }
}
`;

const PayRollComponent = () => {
    const PayRollRef = useRef(null);
    const InnerSectionRef = useRef(null);

    useEffect(() => {
        AOS.init({duration: 1000, anchorPlacement: 'center-80%'});
        const tl = gsap.timeline();
        tl.from(PayRollRef.current, {
            padding: 0,
            duration: 0.5,
            ease: 'ease',
            delay: 0.5
        })
    }, [])

    // useEffect(() => {

    // }, [])

    return <Fragment>
        <PayRoll ref={PayRollRef}>
            <BlobMarker1>
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill="#FF0066"
                        d="M42.9,-75.8C55.6,-67,65.8,-55.4,74.8,-42.3C83.8,-29.2,91.5,-14.6,92.3,0.5C93.1,15.5,86.9,31.1,78.4,45C69.9,59,59.1,71.4,45.7,79.8C32.2,88.2,16.1,92.5,-0.1,92.7C-16.3,92.8,-32.6,88.7,-45.8,80.2C-59.1,71.7,-69.4,58.9,-77.5,44.8C-85.5,30.7,-91.4,15.3,-92.1,-0.4C-92.7,-16.1,-88.2,-32.2,-79.9,-45.9C-71.6,-59.6,-59.5,-70.8,-45.5,-78.8C-31.6,-86.9,-15.8,-91.7,-0.3,-91.1C15.1,-90.5,30.3,-84.6,42.9,-75.8Z"
                        transform="translate(100 100)"/>
                </svg>
            </BlobMarker1>
            <BlobMarker2>
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill="#FF0066"
                        d="M45.2,-77.9C57.3,-71.3,64.9,-56.6,71.8,-42.3C78.7,-27.9,84.9,-14,86.2,0.7C87.5,15.4,83.8,30.9,76.6,44.7C69.4,58.5,58.6,70.6,45.2,78.8C31.9,87,15.9,91.3,0.7,90.1C-14.5,88.9,-29.1,82.2,-41.5,73.5C-53.9,64.7,-64.2,53.9,-72.1,41.3C-80,28.7,-85.5,14.3,-86.4,-0.5C-87.3,-15.4,-83.8,-30.9,-76.2,-44.1C-68.7,-57.3,-57.3,-68.3,-43.9,-74.1C-30.5,-80,-15.3,-80.6,0.6,-81.7C16.6,-82.8,33.1,-84.4,45.2,-77.9Z"
                        transform="translate(100 100)"/>
                </svg>
            </BlobMarker2>
            <InnerSection ref={InnerSectionRef}>
                <Hero />
                <What />
                <Svg>
                    <svg id="visual" viewBox="0 0 900 500" width="100%" height="100%" version="1.1">
                        <path
                            d="M0 372L21.5 382.3C43 392.7 86 413.3 128.8 416.3C171.7 419.3 214.3 404.7 257.2 396.2C300 387.7 343 385.3 385.8 393.2C428.7 401 471.3 419 514.2 424.3C557 429.7 600 422.3 642.8 419.5C685.7 416.7 728.3 418.3 771.2 419.2C814 420 857 420 878.5 420L900 420L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#6557ff"></path>
                        <path
                            d="M0 420L21.5 423C43 426 86 432 128.8 428.3C171.7 424.7 214.3 411.3 257.2 405.2C300 399 343 400 385.8 405C428.7 410 471.3 419 514.2 429.2C557 439.3 600 450.7 642.8 456.2C685.7 461.7 728.3 461.3 771.2 463.7C814 466 857 471 878.5 473.5L900 476L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#5647f8"></path>
                        <path
                            d="M0 489L21.5 484C43 479 86 469 128.8 464.3C171.7 459.7 214.3 460.3 257.2 467.8C300 475.3 343 489.7 385.8 488.7C428.7 487.7 471.3 471.3 514.2 466.8C557 462.3 600 469.7 642.8 475.7C685.7 481.7 728.3 486.3 771.2 486.5C814 486.7 857 482.3 878.5 480.2L900 478L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#4535f0"></path>
                        <path
                            d="M0 481L21.5 483.3C43 485.7 86 490.3 128.8 493C171.7 495.7 214.3 496.3 257.2 495.7C300 495 343 493 385.8 494.2C428.7 495.3 471.3 499.7 514.2 498.7C557 497.7 600 491.3 642.8 493.7C685.7 496 728.3 507 771.2 510.5C814 514 857 510 878.5 508L900 506L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#3121e8"></path>
                        <path
                            d="M0 556L21.5 555.7C43 555.3 86 554.7 128.8 553.8C171.7 553 214.3 552 257.2 553.5C300 555 343 559 385.8 556.7C428.7 554.3 471.3 545.7 514.2 545.3C557 545 600 553 642.8 553.2C685.7 553.3 728.3 545.7 771.2 544.5C814 543.3 857 548.7 878.5 551.3L900 554L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z"
                            fill="#1300df"></path>
                    </svg>
                </Svg>
            </InnerSection>
        </PayRoll>
    </Fragment>
}

export default PayRollComponent;
