import React, { Fragment } from 'react';
import styled from 'styled-components';
import StudyTap from './Svg';
import ImageSrc from '../../../Images/SlidersPage/Payroll/Payroll.png';
import { BsForwardFill } from 'react-icons/bs';
import { Images } from '../../Data/ServicesData';

const HeroSection = styled.section`
  width: 100%;
  height: 90vh;
  margin-top: 50px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  text-align: center;

  h1 {
    font-size: 2rem;
  }

  h4 {
    padding: 15px;
  }

  p {
    padding: 15px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;

    h1 {
      padding: 5px;
    }

    h4 {
      padding: 15px;
    }

    p {
      padding: 5px;
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
  }
`;

const Hero = () => {
  return (
    <Fragment>
      <HeroSection>
        <Wrapper>
          <Content>
            <ContentDiv>
              <h1 data-aos="zoom-in" data-aos-delay="2000">
                Study Tap
              </h1>
              <h4 data-aos="zoom-in" data-aos-delay="2000">
                Restless learners change the world
              </h4>
              <p data-aos="zoom-in" data-aos-delay="2000">
                Your Study Tap learning experience is grounded in cutting edge cognitive science. With more than two dozen distinct learning features to help you achieve your goals, our approach follows these key principles.
              </p>
            </ContentDiv>
          </Content>
          <Image>
            <ImageDiv>
              <StudyTap />
            </ImageDiv>
          </Image>
        </Wrapper>
      </HeroSection>
    </Fragment>
  );
};

export default Hero;
