import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { BsCheckAll } from "react-icons/bs";
import Bg2 from '../../../Images/Shapes/bg4.png';
import Image from '../../../Images/PNG/UI_UX/UX_design.png';

const UserExperience = styled.div`
    width: 100%;
    height : auto;
    padding : 30px 0;
    position : relative;
    box-sizing : border-box;
    display : flex;
    justify-content : center;
    align-items : center;
`;

const ImageBg = styled.img`
    width : 100%;
    height : 90%;
    position : absolute;
    z-index : -1000;

@media screen and (max-width : 768px) {
    height : 100%;
}

`;
const UXInnerSection = styled.div`
    width : 90%;
    height : auto;
    padding : 20px;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px) {
    flex-direction : column-reverse;
    padding : 0px;
}
`;

const UXContent = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    padding : 0 20px;
    font-style: italic;
    text-shadow: 2px 2px 10px #000;
    color  : white;

@media screen and (max-width : 768px) {
    padding : 0 10px;
}
`;

const UXContentHeading = styled.div`
    width : 100%;
    text-align : center;
    
h1{
    padding : 20px 0;
    letter-spacing : 3px;
    font-family : "Merriweather ";

}

@media screen and (max-width : 768px) {

    h1{
        font-size : 1.5rem;
        letter-spacing : 0;
    }
}
`;
const UXContentText = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;

p{
    font-size : 1.05rem;
    width : 100%;
    font-family : "Merriweather ";
    font-weight : bold;
    line-height : 30px;
}

ul{  
    padding : 10px 0;
    list-style : none;
    display : flex;
    flex-wrap : wrap;
}

li{
    padding : 10px;
    font-family : "Merriweather";
}

@media screen and (max-width : 768px){

    p{
        font-size : 1rem;
        line-height : 30px;
    }
}
`;

const CheckIcon = styled(BsCheckAll)`
    width : 20px;
    height : 20px;
    margin-right : 5px;
    margin-bottom : -5px;
`;
const UXImage = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 100%;
    height : auto;
    -webkit-filter: drop-shadow(5px 5px 5px #000);
    filter:         drop-shadow(5px 5px 5px #000);
}
`;

const UXdesign = () => {
    return(
        <Fragment>
            <UserExperience>
                        <ImageBg  src={Bg2} />
                        <UXInnerSection >
                            <UXContent >
                                <UXContentHeading>
                                        <h1 data-aos="fade-up" data-aos-anchor-placement="center-bottom">USER EXPERIENCE DESIGN</h1>
                                </UXContentHeading>
                                <UXContentText>
                                        <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">We implement our strategies to create relevant experiences for your users by defining the journey, usability and function of every element that will constitute the designs. User experience is the core of our ideation.</p>
                                        <ul data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                                            <li><p><CheckIcon /> Problem Statement</p></li>
                                            <li> <p><CheckIcon /> User Journey</p></li>
                                            <li> <p><CheckIcon /> Content Audit</p></li>
                                            <li> <p><CheckIcon /> High Fidelity Wireframes</p></li>
                                        </ul>
                                </UXContentText>
                            </UXContent>
                            <UXImage>
                                <img  data-aos="fade-right" src={Image} />
                            </UXImage>
                        </UXInnerSection>
                    </UserExperience>           
        </Fragment>
    )
}


export default UXdesign;