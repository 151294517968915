import React, {useEffect, useRef, useState} from  'react';
import { gsap } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


const UiUx = () =>{


    useEffect(() =>{
        
    }, [])

    return <svg  xmlSpace="preserve" width="100%" height="100%" version="1.1" shapeRendering="geometricPrecision"  viewBox="0 0 3508 2480">
     <defs>
      <font id="FontID1" horizAdvX="741" fontVariant="normal" fillRule="nonzero" fontStyle="normal" fontWeight="400">
        <font-face 
            fontFamily="Montreal">
            <font-face-src>
                <font-face-name name="Montreal-Regular"/>
            </font-face-src>
        </font-face>
       <missing-glyph><path d="M0 0z"/></missing-glyph>
       <glyph unicode="U" horizAdvX="767" d="M254.014 160.005c32.0009,-37.328 75.3076,-55.992 129.997,-55.992 54.6507,0 97.9956,18.664 129.997,55.992 32.0009,37.328 47.9822,87.9929 47.9822,151.995l0 387 118.001 0 0 -391.983c0,-100.678 -27.6703,-178.17 -82.9724,-232.514 -55.3405,-54.3441 -126.356,-81.5161 -213.007,-81.5161 -86.6899,0 -157.667,27.172 -213.007,81.5161 -55.3405,54.3441 -83.0108,131.836 -83.0108,232.514l0 391.983 118.001 0 0 -387c0,-64.0018 16.0196,-114.667 48.0205,-151.995z"/>
       <glyph unicode="n" horizAdvX="645" d="M193.002 288.008l0 -288.008 -111.984 0 0 531.982 111.984 0 0 -96.9992c18.0125,32.6908 42.8467,58.3681 74.5027,77.0322 31.656,18.664 65.8414,27.9769 102.48,27.9769 60.0161,0 108.535,-18.3191 145.518,-54.9956 36.9831,-36.6765 55.4938,-89.6792 55.4938,-159.008l0 -325.988 -111.984 0 0 291.994c0,97.9956 -40.6622,147.013 -122.025,147.013 -38.6694,0 -72.3182,-12.8387 -100.985,-38.5161 -28.6667,-25.6391 -43,-63.1587 -43,-112.482z"/>
       <glyph unicode="p" horizAdvX="671" d="M365.999 539.992c71.3218,0 132.334,-24.6426 182.999,-74.0045 50.6649,-49.3236 75.9974,-114.973 75.9974,-196.988 0,-82.0143 -25.1792,-148.661 -75.4992,-200.015 -50.32,-51.3164 -108.995,-76.9938 -175.986,-76.9938 -66.9911,0 -127.161,29.3565 -180.508,87.9929l0 -273.982 -111.984 0 0 725.98 111.984 0 0 -92.9751c43.9965,67.3361 101.675,100.985 172.997,100.985zm-174.989 -274.978c0,-50.6649 15.3298,-92.1703 45.9893,-124.516 30.6596,-32.3458 67.9876,-48.4804 111.984,-48.4804 44.0348,0 82.3593,15.9813 115.012,47.9822 32.6908,32.0009 49.017,73.5063 49.017,124.516 0,50.9715 -16.0196,93.32 -48.0205,126.969 -32.0009,33.6872 -70.3254,50.5116 -114.973,50.5116 -44.6863,0 -82.3593,-16.8244 -113.019,-50.5116 -30.6596,-33.6489 -45.9893,-75.8058 -45.9893,-126.471z"/>
       <glyph unicode="g" horizAdvX="654" d="M572.989 531.982l0 -463.994c0,-93.9716 -25.4858,-164.489 -76.4956,-211.474 -51.0098,-47.0241 -116.506,-70.517 -196.489,-70.517 -80.0215,0 -152.34,24.9875 -216.993,75.001l53.0027 85.0036c51.968,-39.3209 104.472,-59.0197 157.475,-59.0197 53.0027,0 95.0063,13.6818 126.011,41.0072 31.0045,27.3637 46.4876,71.0152 46.4876,130.993l0 68.0259c-16.6711,-31.3494 -40.4706,-56.3369 -71.4751,-75.001 -31.0045,-18.664 -65.8414,-28.0152 -104.511,-28.0152 -70.6703,0 -128.655,24.6809 -173.993,74.0045 -45.3378,49.3236 -68.0259,110.681 -68.0259,183.996 0,73.353 22.6881,134.672 68.0259,183.996 45.3378,49.3619 103.169,74.0045 173.495,74.0045 70.3254,0 127.505,-28.9733 171.502,-86.9965l0 78.9867 111.984 0zm-414.977 -245.468c0,-43.6899 13.4902,-81.3628 40.4706,-113.019 27.0187,-31.656 63.3503,-47.484 109.033,-47.484 45.6444,0 82.6658,15.3298 110.988,45.9893 28.3218,30.6596 42.5018,68.8307 42.5018,114.513 0,45.6444 -14.3333,84.3138 -43,115.97 -28.6667,31.6943 -65.6881,47.5223 -110.988,47.5223 -45.3378,0 -81.5161,-16.3262 -108.535,-49.017 -26.9804,-32.6524 -40.4706,-70.8236 -40.4706,-114.475z"/>
       <glyph unicode=" " horizAdvX="273" d=""/>
       <glyph unicode="S" horizAdvX="630" d="M308.512 606.983c-34.3387,0 -62.5072,-6.97505 -84.5054,-21.0018 -21.9982,-13.9884 -32.9974,-35.1435 -32.9974,-63.4653 0,-28.3601 10.9991,-50.0134 32.9974,-64.9983 21.9982,-15.0232 68.8307,-31.1961 140.497,-48.5188 71.6667,-17.3227 125.666,-41.6587 161.997,-73.0081 36.3316,-31.3111 54.4974,-77.492 54.4974,-138.505 0,-60.9742 -22.9947,-110.489 -68.984,-148.469 -46.0277,-38.0179 -106.35,-57.0268 -181.006,-57.0268 -109.34,0 -206.339,37.6729 -290.998,113.019l74.0045 88.9894c70.6703,-61.3191 143.985,-92.017 219.982,-92.017 38.0179,0 68.1792,8.20143 90.484,24.5277 22.3432,16.3262 33.5339,37.9795 33.5339,64.9983 0,26.9804 -10.5009,47.9822 -31.5027,63.0054 -21.0018,14.9849 -57.1801,28.6667 -108.497,40.9688 -51.3548,12.3405 -90.3307,23.6845 -117.005,34.0321 -26.6738,10.3093 -50.3583,23.8378 -71.0152,40.4706 -41.3138,31.3494 -62.009,79.3316 -62.009,144.023 0,64.6534 23.5312,114.475 70.517,149.504 46.9858,34.9902 105.162,52.5045 174.491,52.5045 44.6863,0 88.9894,-7.3583 132.986,-21.9982 44.0348,-14.6783 82.0143,-35.3351 114.015,-62.009l-63.0054 -89.0277c-20.6569,18.7023 -48.6721,34.0321 -84.0072,46.0277 -35.3351,11.9956 -70.1338,17.9742 -104.472,17.9742z"/>
       <glyph unicode="i" horizAdvX="273" d="M193.002 0l-111.984 0 0 531.982 111.984 0 0 -531.982zm-105.009 637.987c-13.9884,14.0268 -21.0018,30.6979 -21.0018,50.0134 0,19.3155 7.01338,35.9867 21.0018,50.0134 13.9884,13.9884 30.6596,21.0018 50.0134,21.0018 19.3155,0 35.9867,-7.01338 49.9751,-21.0018 14.0268,-14.0268 21.0018,-30.6979 21.0018,-50.0134 0,-19.3155 -6.97505,-35.9867 -21.0018,-50.0134 -13.9884,-13.9884 -30.6596,-21.0018 -49.9751,-21.0018 -19.3538,0 -36.025,7.01338 -50.0134,21.0018z"/>
      </font>
            <font id="FontID0" horizAdvX="744" fontVariant="normal" fillRule="nonzero" fontStyle="normal" fontWeight="700">
        <font-face 
            fontFamily="Montreal">
            <font-face-src>
                <font-face-name name="Montreal Bold"/>
            </font-face-src>
        </font-face>
       <missing-glyph><path d="M0 0z"/></missing-glyph>
       <glyph unicode="R" horizAdvX="726" d="M652.985 467.004c0,-112.018 -44.3014,-184.334 -132.981,-216.985l177.014 -250.019 -192.036 0 -154.978 223.001 -107.994 0 0 -223.001 -156.013 0 0 699.011 265.003 0c108.684,0 186.173,-18.3567 232.506,-54.9935 46.3325,-36.6751 69.4796,-95.6925 69.4796,-177.014zm-297.003 -108.991c53.3456,0 89.6758,8.66099 109.029,25.983 19.3148,17.322 28.9722,44.8379 28.9722,82.5094 0,37.6715 -10.0023,63.5012 -29.9686,77.4891 -20.0046,13.9879 -55.0318,21.001 -105.005,21.001l-117 0 0 -206.982 113.973 0z"/>
       <glyph unicode="A" horizAdvX="744" d="M584.004 0l-64.9958 150.993 -293.018 0 -64.9958 -150.993 -165.977 0 301.985 699.011 150.993 0 302.023 -699.011 -166.015 0zm-211.006 490.994l-87.9896 -202.997 174.983 0 -86.9932 202.997z"/>
       <glyph unicode="T" horizAdvX="607" d="M382.004 563.999l0 -563.999 -156.013 0 0 563.999 -197.977 0 0 135.012 552.004 0 0 -135.012 -198.015 0z"/>
       <glyph unicode="S" horizAdvX="638" d="M310.991 577.987c-25.983,0 -46.4858,-6.17 -61.5084,-18.4717 -14.9843,-12.34 -22.4956,-28.6656 -22.4956,-49.0151 0,-20.3495 9.19752,-36.4835 27.5159,-48.5169 18.3184,-11.9951 60.6653,-26.1363 127.002,-42.5002 66.3371,-16.3256 117.843,-40.814 154.48,-73.4652 36.6751,-32.6895 55.0318,-80.3633 55.0318,-143.021 0,-62.6581 -23.5303,-113.513 -70.5143,-152.487 -46.984,-39.0128 -108.837,-58.5192 -185.522,-58.5192 -110.638,0 -210.317,41.0056 -298.996,123.017l93.0099 114.011c75.343,-66.0305 145.014,-99.0266 209.014,-99.0266 28.6656,0 51.1612,6.17 67.4868,18.51 16.3256,12.34 24.4884,29.0105 24.4884,50.0115 0,21.001 -8.66099,37.6715 -25.983,49.9732 -17.322,12.34 -51.6594,24.8333 -103.012,37.5182 -81.3214,19.3148 -140.837,44.493 -178.47,75.4963 -37.6715,31.0033 -56.5264,79.6735 -56.5264,146.011 0,66.3371 23.8369,117.498 71.5107,153.484 47.6738,36.0236 107.151,53.9971 178.508,53.9971 46.6391,0 93.3165,-8.0095 139.994,-23.9902 46.6774,-16.019 87.3381,-38.6679 121.982,-67.985l-78.9837 -114.011c-60.6653,45.9876 -123.323,68.9814 -188.013,68.9814z"/>
      </font>
     </defs>
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_710187392">
       <path fill="#F4F4F4" fillRule="nonzero" d="M2448 2086c383,-210 383,-550 0,-760 -383,-209 -1005,-209 -1388,0 -383,210 -383,550 0,760 383,209 1005,209 1388,0z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M2502 1832l216 -125c10,-6 10,-15 0,-21l-481 -278c-10,-5 -26,-5 -36,0l-216 125c-10,6 -10,15 0,21l480 278c10,6 26,6 37,0z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M2431 1868l-23 13c-3,2 -8,2 -11,0l-429 -247c-3,-2 -3,-5 0,-7l23 -13c3,-2 8,-2 11,0l429 248c3,2 3,4 0,6z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1696 1960l-23 14c-3,1 -8,1 -11,0l-240 -139c-3,-2 -3,-4 0,-6l23 -13c3,-2 8,-2 11,0l240 138c3,2 3,5 0,6z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1207 1677l-23 13c-3,2 -8,2 -11,0l-240 -138c-3,-2 -3,-5 0,-6l23 -14c3,-1 8,-1 11,0l240 139c3,2 3,4 0,6z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1800 1906c-85,48 -85,127 0,176 84,49 221,49 305,0 85,-49 85,-128 0,-176 -84,-49 -221,-49 -305,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1579 464l0 0c1,-1 2,-1 3,0l5 4 1 3c0,2 -1,5 -2,6 -1,1 -2,2 -3,3 -1,1 -3,2 -5,1l-12 -9c-1,-1 -2,-1 -2,-1l15 -7zm16 46l0 0c3,-10 9,-14 18,-13 -1,2 -2,4 -3,6 -3,16 5,39 20,52 5,5 10,7 15,8 -6,10 -18,11 -31,-1 -14,-12 -23,-36 -19,-52zm4 -68l0 0 0 0c1,0 1,0 1,0l10 -2c2,0 4,1 5,3l8 18c0,0 1,1 2,2l0 0c0,0 0,1 1,1l-3 9c-1,2 -3,3 -5,2 -2,-1 -4,-2 -6,-2 -1,-1 -2,-2 -3,-2 -1,-1 -1,-2 -2,-3l-7 -17c-1,-2 -3,-3 -5,-3l-10 1c0,1 -1,1 -1,1l15 -8zm16 164l0 0c0,0 1,0 1,-1l4 -10c1,-2 3,-3 4,-2 2,1 4,2 6,2 1,0 1,1 2,1l-1 1c0,1 0,2 -1,2l0 0 -15 7zm28 -152l0 0c0,0 1,-1 1,-1 1,1 1,1 2,1l13 12c0,0 0,1 1,1 0,0 0,0 0,0 0,1 1,2 1,2 0,1 0,1 0,1l0 0c0,1 0,2 0,2l-1 14c-1,2 0,5 1,6 3,3 5,6 6,9 1,1 2,2 3,3 1,0 1,0 2,0 0,0 0,0 0,0 0,0 1,1 1,1l9 0 0 0c0,0 1,0 1,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0 1,1 1,1 2,1 0,1 1,1 1,2l7 20c1,2 0,3 -2,4l-10 5c0,1 -1,2 0,3 0,3 0,6 1,9 0,2 1,5 2,6l0 1 12 12c0,0 0,0 0,0 0,0 0,0 0,1 1,0 1,0 1,0 1,2 1,4 1,5l-4 13c0,1 0,2 -1,2l-15 8c0,-1 1,-1 1,-2l3 -14c0,0 0,-1 0,-2 0,-2 -1,-3 -1,-4l-12 -12 0 0c-2,-1 -3,-4 -3,-6 0,-3 0,-6 -1,-10 0,-2 1,-4 3,-4l8 -3c2,-1 3,-3 2,-5l-7 -19c-1,-1 -1,-2 -2,-2 -1,-1 -2,-2 -3,-2l-9 0c-1,0 -2,0 -3,-1 -1,0 -2,-1 -3,-2 -2,-3 -4,-6 -6,-9 -1,-2 -2,-5 -2,-7l2 -13c0,-1 0,-2 0,-2 0,-2 -1,-3 -2,-4l-13 -12c-1,-1 -2,-1 -3,-1l15 -7zm12 143l0 0c-1,-2 0,-5 2,-6 1,-1 1,-2 2,-3 2,-2 4,-2 5,-1l7 5 4 15c1,1 0,3 -1,3l0 0 -16 8c2,-1 2,-2 2,-4l-5 -17z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.701961" d="M1579 464l0 0c1,-1 2,-1 3,0l5 4 1 3c0,2 -1,5 -2,6 -1,1 -2,2 -3,3 -1,1 -3,2 -5,1l-12 -9c-1,-1 -2,-1 -2,-1l15 -7zm16 46l0 0c3,-10 9,-14 18,-13 -1,2 -2,4 -3,6 -3,16 5,39 20,52 5,5 10,7 15,8 -6,10 -18,11 -31,-1 -14,-12 -23,-36 -19,-52zm4 -68l0 0 0 0c1,0 1,0 1,0l10 -2c2,0 4,1 5,3l8 18c0,0 1,1 2,2l0 0c0,0 0,1 1,1l-3 9c-1,2 -3,3 -5,2 -2,-1 -4,-2 -6,-2 -1,-1 -2,-2 -3,-2 -1,-1 -1,-2 -2,-3l-7 -17c-1,-2 -3,-3 -5,-3l-10 1c0,1 -1,1 -1,1l15 -8zm16 164l0 0c0,0 1,0 1,-1l4 -10c1,-2 3,-3 4,-2 2,1 4,2 6,2 1,0 1,1 2,1l-1 1c0,1 0,2 -1,2l0 0 -15 7zm28 -152l0 0c0,0 1,-1 1,-1 1,1 1,1 2,1l13 12c0,0 0,1 1,1 0,0 0,0 0,0 0,1 1,2 1,2 0,1 0,1 0,1l0 0c0,1 0,2 0,2l-1 14c-1,2 0,5 1,6 3,3 5,6 6,9 1,1 2,2 3,3 1,0 1,0 2,0 0,0 0,0 0,0 0,0 1,1 1,1l9 0 0 0c0,0 1,0 1,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0 1,1 1,1 2,1 0,1 1,1 1,2l7 20c1,2 0,3 -2,4l-10 5c0,1 -1,2 0,3 0,3 0,6 1,9 0,2 1,5 2,6l0 1 12 12c0,0 0,0 0,0 0,0 0,0 0,1 1,0 1,0 1,0 1,2 1,4 1,5l-4 13c0,1 0,2 -1,2l-15 8c0,-1 1,-1 1,-2l3 -14c0,0 0,-1 0,-2 0,-2 -1,-3 -1,-4l-12 -12 0 0c-2,-1 -3,-4 -3,-6 0,-3 0,-6 -1,-10 0,-2 1,-4 3,-4l8 -3c2,-1 3,-3 2,-5l-7 -19c-1,-1 -1,-2 -2,-2 -1,-1 -2,-2 -3,-2l-9 0c-1,0 -2,0 -3,-1 -1,0 -2,-1 -3,-2 -2,-3 -4,-6 -6,-9 -1,-2 -2,-5 -2,-7l2 -13c0,-1 0,-2 0,-2 0,-2 -1,-3 -2,-4l-13 -12c-1,-1 -2,-1 -3,-1l15 -7zm12 143l0 0c-1,-2 0,-5 2,-6 1,-1 1,-2 2,-3 2,-2 4,-2 5,-1l7 5 4 15c1,1 0,3 -1,3l0 0 -16 8c2,-1 2,-2 2,-4l-5 -17z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.050980" d="M1579 464l0 0c1,-1 2,-1 3,0l5 4 1 3c0,2 -1,5 -2,6 -1,1 -2,2 -3,3 -1,1 -3,2 -5,1l-12 -9c-1,-1 -2,-1 -2,-1l15 -7zm16 46l0 0c3,-10 9,-14 18,-13 -1,2 -2,4 -3,6 -3,16 5,39 20,52 5,5 10,7 15,8 -6,10 -18,11 -31,-1 -14,-12 -23,-36 -19,-52zm4 -68l0 0 0 0c1,0 1,0 1,0l10 -2c2,0 4,1 5,3l8 18c0,0 1,1 2,2l0 0c0,0 0,1 1,1l-3 9c-1,2 -3,3 -5,2 -2,-1 -4,-2 -6,-2 -1,-1 -2,-2 -3,-2 -1,-1 -1,-2 -2,-3l-7 -17c-1,-2 -3,-3 -5,-3l-10 1c0,1 -1,1 -1,1l15 -8zm16 164l0 0c0,0 1,0 1,-1l4 -10c1,-2 3,-3 4,-2 2,1 4,2 6,2 1,0 1,1 2,1l-1 1c0,1 0,2 -1,2l0 0 -15 7zm28 -152l0 0c0,0 1,-1 1,-1 1,1 1,1 2,1l13 12c0,0 0,1 1,1 0,0 0,0 0,0 0,1 1,2 1,2 0,1 0,1 0,1l0 0c0,1 0,2 0,2l-1 14c-1,2 0,5 1,6 3,3 5,6 6,9 1,1 2,2 3,3 1,0 1,0 2,0 0,0 0,0 0,0 0,0 1,1 1,1l9 0 0 0c0,0 1,0 1,0 0,0 0,0 1,0 0,0 0,0 0,0 0,0 0,0 0,0 1,1 1,1 2,1 0,1 1,1 1,2l7 20c1,2 0,3 -2,4l-10 5c0,1 -1,2 0,3 0,3 0,6 1,9 0,2 1,5 2,6l0 1 12 12c0,0 0,0 0,0 0,0 0,0 0,1 1,0 1,0 1,0 1,2 1,4 1,5l-4 13c0,1 0,2 -1,2l-15 8c0,-1 1,-1 1,-2l3 -14c0,0 0,-1 0,-2 0,-2 -1,-3 -1,-4l-12 -12 0 0c-2,-1 -3,-4 -3,-6 0,-3 0,-6 -1,-10 0,-2 1,-4 3,-4l8 -3c2,-1 3,-3 2,-5l-7 -19c-1,-1 -1,-2 -2,-2 -1,-1 -2,-2 -3,-2l-9 0c-1,0 -2,0 -3,-1 -1,0 -2,-1 -3,-2 -2,-3 -4,-6 -6,-9 -1,-2 -2,-5 -2,-7l2 -13c0,-1 0,-2 0,-2 0,-2 -1,-3 -2,-4l-13 -12c-1,-1 -2,-1 -3,-1l15 -7zm12 143l0 0c-1,-2 0,-5 2,-6 1,-1 1,-2 2,-3 2,-2 4,-2 5,-1l7 5 4 15c1,1 0,3 -1,3l0 0 -16 8c2,-1 2,-2 2,-4l-5 -17z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1670 563l12 12c0,1 1,2 1,4l16 -8c-1,0 -1,-1 -1,-2 0,0 0,0 -1,0 0,-1 0,-1 0,-1 0,0 0,0 0,0l-12 -12 -15 7z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1655 511c1,1 2,1 3,1l9 0c1,0 2,1 3,2l16 -8c-1,0 -1,0 -2,-1 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 -1,0 0,0 -1,0 -1,0l0 0 -9 0c0,0 -1,-1 -1,-1 0,0 0,0 0,0 -1,0 -1,0 -2,0l0 0 -15 7z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1564 471c0,0 1,0 2,1l12 9c2,1 4,0 5,-1 1,-1 2,-2 3,-3 1,-1 2,-4 2,-6l-1 -3 -5 -4c-1,-1 -2,-1 -3,0l0 0 -15 7z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1584 450c0,0 1,0 1,-1l10 -1c2,0 4,1 5,3l7 17c1,1 1,2 2,3l16 -8c-1,-1 -2,-2 -2,-2l-8 -18c-1,-2 -3,-3 -5,-3l-10 2c0,0 0,0 -1,0l0 0 -15 8z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1628 461c1,0 2,0 3,1l13 12c1,1 2,2 2,4l15 -8c0,0 0,0 0,-1 0,0 -1,-1 -1,-2 0,0 0,0 0,0 -1,0 -1,-1 -1,-1l-13 -12c-1,0 -1,0 -2,-1 0,0 -1,1 -1,1l-15 7z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1670 563l12 12c0,1 1,2 1,4l16 -8c-1,0 -1,-1 -1,-2 0,0 0,0 -1,0 0,-1 0,-1 0,-1 0,0 0,0 0,0l-12 -12 -15 7z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1655 511c1,1 2,1 3,1l9 0c1,0 2,1 3,2l16 -8c-1,0 -1,0 -2,-1 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,0 -1,0 0,0 -1,0 -1,0l0 0 -9 0c0,0 -1,-1 -1,-1 0,0 0,0 0,0 -1,0 -1,0 -2,0l0 0 -15 7z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1564 471c0,0 1,0 2,1l12 9c2,1 4,0 5,-1 1,-1 2,-2 3,-3 1,-1 2,-4 2,-6l-1 -3 -5 -4c-1,-1 -2,-1 -3,0l0 0 -15 7z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1584 450c0,0 1,0 1,-1l10 -1c2,0 4,1 5,3l7 17c1,1 1,2 2,3l16 -8c-1,-1 -2,-2 -2,-2l-8 -18c-1,-2 -3,-3 -5,-3l-10 2c0,0 0,0 -1,0l0 0 -15 8z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1628 461c1,0 2,0 3,1l13 12c1,1 2,2 2,4l15 -8c0,0 0,0 0,-1 0,0 -1,-1 -1,-2 0,0 0,0 0,0 -1,0 -1,-1 -1,-1l-13 -12c-1,0 -1,0 -2,-1 0,0 -1,1 -1,1l-15 7z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1559 486l3 -13c1,-2 3,-3 4,-1l12 9c2,1 4,0 5,-1 1,-1 2,-2 3,-3 1,-1 2,-4 2,-6l-6 -17c0,-2 1,-4 3,-5l10 -1c2,0 4,1 5,3l7 17c1,2 3,4 5,5 2,0 4,1 6,2 2,1 4,0 5,-2l3 -10c1,-2 3,-3 5,-1l13 12c1,1 2,4 2,6l-2 13c0,2 1,5 2,7 2,3 4,6 6,9 1,2 4,3 6,3l9 0c2,0 4,2 5,4l7 19c0,2 0,4 -2,5l-8 3c-2,0 -3,2 -3,4 1,4 1,7 1,10 0,2 1,4 3,6l12 12c1,2 2,4 1,6l-3 14c-1,2 -2,2 -4,1l-12 -9c-1,-1 -3,-1 -5,1 -1,1 -1,2 -2,3 -2,1 -3,4 -2,6l5 17c1,2 -1,4 -3,4l-9 2c-2,0 -4,-1 -5,-3l-8 -17c-1,-2 -3,-4 -5,-5 -2,0 -4,-1 -6,-2 -1,-1 -3,0 -4,2l-4 10c-1,2 -3,2 -4,1l-13 -12c-2,-1 -3,-4 -3,-6l2 -13c0,-2 0,-5 -2,-7 -2,-3 -4,-6 -6,-9 -1,-2 -3,-3 -5,-3l-9 0c-2,0 -4,-2 -5,-4l-7 -20c-1,-1 0,-4 2,-4l7 -3c2,0 3,-3 3,-5 0,-3 -1,-6 -1,-9 0,-2 -1,-5 -2,-6l-12 -12c-1,-2 -2,-5 -2,-7zm55 76l0 0c15,13 29,11 33,-5 4,-15 -4,-39 -19,-52 -14,-13 -29,-10 -33,5 -4,16 5,40 19,52z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.749020" d="M1559 486l3 -13c1,-2 3,-3 4,-1l12 9c2,1 4,0 5,-1 1,-1 2,-2 3,-3 1,-1 2,-4 2,-6l-6 -17c0,-2 1,-4 3,-5l10 -1c2,0 4,1 5,3l7 17c1,2 3,4 5,5 2,0 4,1 6,2 2,1 4,0 5,-2l3 -10c1,-2 3,-3 5,-1l13 12c1,1 2,4 2,6l-2 13c0,2 1,5 2,7 2,3 4,6 6,9 1,2 4,3 6,3l9 0c2,0 4,2 5,4l7 19c0,2 0,4 -2,5l-8 3c-2,0 -3,2 -3,4 1,4 1,7 1,10 0,2 1,4 3,6l12 12c1,2 2,4 1,6l-3 14c-1,2 -2,2 -4,1l-12 -9c-1,-1 -3,-1 -5,1 -1,1 -1,2 -2,3 -2,1 -3,4 -2,6l5 17c1,2 -1,4 -3,4l-9 2c-2,0 -4,-1 -5,-3l-8 -17c-1,-2 -3,-4 -5,-5 -2,0 -4,-1 -6,-2 -1,-1 -3,0 -4,2l-4 10c-1,2 -3,2 -4,1l-13 -12c-2,-1 -3,-4 -3,-6l2 -13c0,-2 0,-5 -2,-7 -2,-3 -4,-6 -6,-9 -1,-2 -3,-3 -5,-3l-9 0c-2,0 -4,-2 -5,-4l-7 -20c-1,-1 0,-4 2,-4l7 -3c2,0 3,-3 3,-5 0,-3 -1,-6 -1,-9 0,-2 -1,-5 -2,-6l-12 -12c-1,-2 -2,-5 -2,-7zm55 76l0 0c15,13 29,11 33,-5 4,-15 -4,-39 -19,-52 -14,-13 -29,-10 -33,5 -4,16 5,40 19,52z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1743 506l0 0c1,0 2,0 3,1l7 5 2 5c0,3 -1,6 -3,8 -1,1 -2,2 -4,4 -1,2 -4,3 -7,1l-16 -13c-1,0 -2,-1 -3,0l21 -11zm22 65l0 0c3,-14 12,-20 24,-18 -1,2 -2,5 -3,8 -5,21 7,53 26,71 8,7 15,11 22,12 -8,14 -26,14 -43,-1 -20,-18 -31,-50 -26,-72zm6 -94l0 0 0 0c0,0 1,0 1,0l13 -2c3,-1 6,1 7,3l11 24c0,2 1,3 2,4l0 0c1,0 2,1 2,1l-4 13c-1,2 -4,3 -7,2 -2,-1 -5,-2 -7,-3 -2,-1 -3,-2 -5,-3 -1,-1 -2,-2 -2,-3l-11 -24c-1,-3 -4,-4 -7,-4l-13 2c0,0 -1,0 -1,0l21 -10zm21 226l0 0c1,0 1,-1 2,-2l5 -14c1,-3 4,-4 6,-2 3,1 5,2 8,3 1,0 2,1 2,1l0 2c-1,1 -1,2 -2,2l0 0 -21 10zm39 -210l0 0c0,0 1,0 1,0 1,0 2,0 3,1l18 16c0,0 1,1 1,1 0,0 0,0 0,0 1,1 1,2 2,4 0,0 0,0 0,0l0 0c0,1 0,3 0,3l-2 19c-1,3 1,7 2,9 3,4 6,8 8,12 1,2 3,3 4,4 1,0 2,0 2,1 0,0 1,0 1,0 0,0 1,0 1,0l12 0 1 0c0,0 0,0 1,0 0,0 0,0 1,1 0,0 0,0 0,0 0,0 0,0 1,0 0,0 1,1 1,1 1,1 2,2 2,3l10 27c1,2 0,5 -2,6l-14 7c-1,1 -1,2 -1,4 1,4 1,8 1,12 0,3 2,7 4,9l0 0 16 17c0,0 1,0 1,1 0,0 0,0 0,0 0,0 0,1 0,1 1,2 2,5 1,6l-4 19c0,1 -1,2 -2,2l-21 11c1,-1 1,-2 2,-3l4 -18c0,-1 0,-2 0,-4 0,-2 -1,-4 -2,-5l-16 -16 0 -1c-2,-2 -4,-6 -4,-8 0,-5 0,-9 -1,-13 -1,-3 1,-6 4,-7l10 -3c3,-1 4,-4 3,-7l-10 -27c0,-1 -1,-2 -2,-2 -1,-2 -3,-3 -5,-3l-12 0c-1,0 -3,0 -4,-1 -1,-1 -3,-2 -4,-3 -2,-5 -5,-9 -8,-13 -2,-2 -3,-6 -2,-9l2 -18c0,-1 0,-2 0,-3 -1,-2 -2,-4 -3,-6l-18 -16c-2,-1 -3,-1 -4,-1l21 -10zm16 197l0 0c-1,-3 1,-6 3,-8 1,-1 2,-3 3,-4 2,-2 5,-3 7,-1l9 7 6 20c1,2 0,4 -2,5l0 0 -21 10c2,-1 3,-3 2,-5l-7 -24z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.701961" d="M1743 506l0 0c1,0 2,0 3,1l7 5 2 5c0,3 -1,6 -3,8 -1,1 -2,2 -4,4 -1,2 -4,3 -7,1l-16 -13c-1,0 -2,-1 -3,0l21 -11zm22 65l0 0c3,-14 12,-20 24,-18 -1,2 -2,5 -3,8 -5,21 7,53 26,71 8,7 15,11 22,12 -8,14 -26,14 -43,-1 -20,-18 -31,-50 -26,-72zm6 -94l0 0 0 0c0,0 1,0 1,0l13 -2c3,-1 6,1 7,3l11 24c0,2 1,3 2,4l0 0c1,0 2,1 2,1l-4 13c-1,2 -4,3 -7,2 -2,-1 -5,-2 -7,-3 -2,-1 -3,-2 -5,-3 -1,-1 -2,-2 -2,-3l-11 -24c-1,-3 -4,-4 -7,-4l-13 2c0,0 -1,0 -1,0l21 -10zm21 226l0 0c1,0 1,-1 2,-2l5 -14c1,-3 4,-4 6,-2 3,1 5,2 8,3 1,0 2,1 2,1l0 2c-1,1 -1,2 -2,2l0 0 -21 10zm39 -210l0 0c0,0 1,0 1,0 1,0 2,0 3,1l18 16c0,0 1,1 1,1 0,0 0,0 0,0 1,1 1,2 2,4 0,0 0,0 0,0l0 0c0,1 0,3 0,3l-2 19c-1,3 1,7 2,9 3,4 6,8 8,12 1,2 3,3 4,4 1,0 2,0 2,1 0,0 1,0 1,0 0,0 1,0 1,0l12 0 1 0c0,0 0,0 1,0 0,0 0,0 1,1 0,0 0,0 0,0 0,0 0,0 1,0 0,0 1,1 1,1 1,1 2,2 2,3l10 27c1,2 0,5 -2,6l-14 7c-1,1 -1,2 -1,4 1,4 1,8 1,12 0,3 2,7 4,9l0 0 16 17c0,0 1,0 1,1 0,0 0,0 0,0 0,0 0,1 0,1 1,2 2,5 1,6l-4 19c0,1 -1,2 -2,2l-21 11c1,-1 1,-2 2,-3l4 -18c0,-1 0,-2 0,-4 0,-2 -1,-4 -2,-5l-16 -16 0 -1c-2,-2 -4,-6 -4,-8 0,-5 0,-9 -1,-13 -1,-3 1,-6 4,-7l10 -3c3,-1 4,-4 3,-7l-10 -27c0,-1 -1,-2 -2,-2 -1,-2 -3,-3 -5,-3l-12 0c-1,0 -3,0 -4,-1 -1,-1 -3,-2 -4,-3 -2,-5 -5,-9 -8,-13 -2,-2 -3,-6 -2,-9l2 -18c0,-1 0,-2 0,-3 -1,-2 -2,-4 -3,-6l-18 -16c-2,-1 -3,-1 -4,-1l21 -10zm16 197l0 0c-1,-3 1,-6 3,-8 1,-1 2,-3 3,-4 2,-2 5,-3 7,-1l9 7 6 20c1,2 0,4 -2,5l0 0 -21 10c2,-1 3,-3 2,-5l-7 -24z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.050980" d="M1743 506l0 0c1,0 2,0 3,1l7 5 2 5c0,3 -1,6 -3,8 -1,1 -2,2 -4,4 -1,2 -4,3 -7,1l-16 -13c-1,0 -2,-1 -3,0l21 -11zm22 65l0 0c3,-14 12,-20 24,-18 -1,2 -2,5 -3,8 -5,21 7,53 26,71 8,7 15,11 22,12 -8,14 -26,14 -43,-1 -20,-18 -31,-50 -26,-72zm6 -94l0 0 0 0c0,0 1,0 1,0l13 -2c3,-1 6,1 7,3l11 24c0,2 1,3 2,4l0 0c1,0 2,1 2,1l-4 13c-1,2 -4,3 -7,2 -2,-1 -5,-2 -7,-3 -2,-1 -3,-2 -5,-3 -1,-1 -2,-2 -2,-3l-11 -24c-1,-3 -4,-4 -7,-4l-13 2c0,0 -1,0 -1,0l21 -10zm21 226l0 0c1,0 1,-1 2,-2l5 -14c1,-3 4,-4 6,-2 3,1 5,2 8,3 1,0 2,1 2,1l0 2c-1,1 -1,2 -2,2l0 0 -21 10zm39 -210l0 0c0,0 1,0 1,0 1,0 2,0 3,1l18 16c0,0 1,1 1,1 0,0 0,0 0,0 1,1 1,2 2,4 0,0 0,0 0,0l0 0c0,1 0,3 0,3l-2 19c-1,3 1,7 2,9 3,4 6,8 8,12 1,2 3,3 4,4 1,0 2,0 2,1 0,0 1,0 1,0 0,0 1,0 1,0l12 0 1 0c0,0 0,0 1,0 0,0 0,0 1,1 0,0 0,0 0,0 0,0 0,0 1,0 0,0 1,1 1,1 1,1 2,2 2,3l10 27c1,2 0,5 -2,6l-14 7c-1,1 -1,2 -1,4 1,4 1,8 1,12 0,3 2,7 4,9l0 0 16 17c0,0 1,0 1,1 0,0 0,0 0,0 0,0 0,1 0,1 1,2 2,5 1,6l-4 19c0,1 -1,2 -2,2l-21 11c1,-1 1,-2 2,-3l4 -18c0,-1 0,-2 0,-4 0,-2 -1,-4 -2,-5l-16 -16 0 -1c-2,-2 -4,-6 -4,-8 0,-5 0,-9 -1,-13 -1,-3 1,-6 4,-7l10 -3c3,-1 4,-4 3,-7l-10 -27c0,-1 -1,-2 -2,-2 -1,-2 -3,-3 -5,-3l-12 0c-1,0 -3,0 -4,-1 -1,-1 -3,-2 -4,-3 -2,-5 -5,-9 -8,-13 -2,-2 -3,-6 -2,-9l2 -18c0,-1 0,-2 0,-3 -1,-2 -2,-4 -3,-6l-18 -16c-2,-1 -3,-1 -4,-1l21 -10zm16 197l0 0c-1,-3 1,-6 3,-8 1,-1 2,-3 3,-4 2,-2 5,-3 7,-1l9 7 6 20c1,2 0,4 -2,5l0 0 -21 10c2,-1 3,-3 2,-5l-7 -24z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1868 644l16 16c1,1 2,3 2,5l21 -10c0,-1 0,-2 -1,-3 0,0 0,-1 0,-1 0,0 0,0 0,0 0,-1 -1,-1 -1,-1l-16 -17 -21 11z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1847 572c1,1 3,1 4,1l12 0c2,0 4,1 5,3l21 -11c0,0 -1,-1 -1,-1 -1,0 -1,0 -1,0 0,0 0,0 0,0 -1,-1 -1,-1 -1,-1 -1,0 -1,0 -1,0l-1 0 -12 0c0,0 -1,0 -1,0 0,0 -1,0 -1,0 0,-1 -1,-1 -1,-1l-1 0 -21 10z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1722 517c1,-1 2,0 3,0l16 13c3,1 6,1 7,-1 2,-2 3,-3 4,-4 2,-2 3,-5 3,-8l-2 -5 -7 -5c-1,-1 -2,-1 -3,-1l0 0 -21 11z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1750 487c0,0 1,0 1,0l13 -2c3,0 6,1 7,4l11 24c0,1 1,2 2,3l21 -10c-1,-1 -2,-2 -2,-4l-11 -24c-1,-2 -4,-4 -7,-3l-13 2c0,0 -1,0 -1,0l0 0 -21 10z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1810 503c1,0 2,0 4,1l18 16c1,2 2,4 3,6l21 -11c0,0 0,0 0,0 -1,-2 -1,-3 -2,-4 0,0 0,0 0,0 0,0 -1,-1 -1,-1l-18 -16c-1,-1 -2,-1 -3,-1 0,0 -1,0 -1,0l-21 10z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1868 644l16 16c1,1 2,3 2,5l21 -10c0,-1 0,-2 -1,-3 0,0 0,-1 0,-1 0,0 0,0 0,0 0,-1 -1,-1 -1,-1l-16 -17 -21 11z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1847 572c1,1 3,1 4,1l12 0c2,0 4,1 5,3l21 -11c0,0 -1,-1 -1,-1 -1,0 -1,0 -1,0 0,0 0,0 0,0 -1,-1 -1,-1 -1,-1 -1,0 -1,0 -1,0l-1 0 -12 0c0,0 -1,0 -1,0 0,0 -1,0 -1,0 0,-1 -1,-1 -1,-1l-1 0 -21 10z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1722 517c1,-1 2,0 3,0l16 13c3,1 6,1 7,-1 2,-2 3,-3 4,-4 2,-2 3,-5 3,-8l-2 -5 -7 -5c-1,-1 -2,-1 -3,-1l0 0 -21 11z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1750 487c0,0 1,0 1,0l13 -2c3,0 6,1 7,4l11 24c0,1 1,2 2,3l21 -10c-1,-1 -2,-2 -2,-4l-11 -24c-1,-2 -4,-4 -7,-3l-13 2c0,0 -1,0 -1,0l0 0 -21 10z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.901961" d="M1810 503c1,0 2,0 4,1l18 16c1,2 2,4 3,6l21 -11c0,0 0,0 0,0 -1,-2 -1,-3 -2,-4 0,0 0,0 0,0 0,0 -1,-1 -1,-1l-18 -16c-1,-1 -2,-1 -3,-1 0,0 -1,0 -1,0l-21 10z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1715 538l5 -19c1,-2 3,-3 5,-2l16 13c3,2 6,1 7,-1 2,-2 3,-3 4,-4 2,-2 3,-5 3,-8l-8 -24c0,-3 1,-6 4,-6l13 -2c3,0 6,1 7,4l11 24c1,2 4,5 7,6 2,1 5,2 7,3 3,1 6,0 7,-2l5 -15c1,-2 4,-3 6,-1l18 16c2,2 3,6 3,9l-2 18c-1,3 0,7 2,9 3,4 6,8 8,13 2,2 5,4 8,4l12 0c3,0 6,3 7,5l10 27c1,3 0,6 -3,7l-10 3c-3,1 -5,4 -4,7 1,4 1,8 1,13 0,2 2,6 4,8l16 17c2,2 3,6 2,9l-4 18c-1,3 -3,4 -5,2l-17 -12c-2,-2 -5,-1 -7,1 -1,1 -2,3 -3,4 -2,2 -4,5 -3,8l7 24c1,3 -1,5 -3,6l-13 2c-3,0 -6,-2 -8,-4l-10 -24c-1,-3 -4,-5 -7,-6 -3,-1 -5,-2 -8,-3 -2,-2 -5,-1 -6,2l-5 14c-1,3 -4,3 -6,2l-18 -17c-2,-2 -4,-5 -3,-8l2 -19c0,-2 -1,-6 -2,-9 -3,-4 -6,-8 -9,-12 -1,-3 -4,-5 -7,-5l-13 0c-3,0 -6,-2 -7,-5l-9 -27c-1,-2 0,-5 3,-6l10 -4c3,-1 4,-4 4,-6 -1,-5 -1,-9 -2,-13 0,-3 -1,-7 -3,-9l-16 -17c-2,-2 -3,-6 -3,-8zm76 105l0 0c20,17 41,14 46,-7 5,-22 -7,-54 -27,-72 -20,-18 -40,-15 -45,7 -5,22 6,54 26,72z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.749020" d="M1715 538l5 -19c1,-2 3,-3 5,-2l16 13c3,2 6,1 7,-1 2,-2 3,-3 4,-4 2,-2 3,-5 3,-8l-8 -24c0,-3 1,-6 4,-6l13 -2c3,0 6,1 7,4l11 24c1,2 4,5 7,6 2,1 5,2 7,3 3,1 6,0 7,-2l5 -15c1,-2 4,-3 6,-1l18 16c2,2 3,6 3,9l-2 18c-1,3 0,7 2,9 3,4 6,8 8,13 2,2 5,4 8,4l12 0c3,0 6,3 7,5l10 27c1,3 0,6 -3,7l-10 3c-3,1 -5,4 -4,7 1,4 1,8 1,13 0,2 2,6 4,8l16 17c2,2 3,6 2,9l-4 18c-1,3 -3,4 -5,2l-17 -12c-2,-2 -5,-1 -7,1 -1,1 -2,3 -3,4 -2,2 -4,5 -3,8l7 24c1,3 -1,5 -3,6l-13 2c-3,0 -6,-2 -8,-4l-10 -24c-1,-3 -4,-5 -7,-6 -3,-1 -5,-2 -8,-3 -2,-2 -5,-1 -6,2l-5 14c-1,3 -4,3 -6,2l-18 -17c-2,-2 -4,-5 -3,-8l2 -19c0,-2 -1,-6 -2,-9 -3,-4 -6,-8 -9,-12 -1,-3 -4,-5 -7,-5l-13 0c-3,0 -6,-2 -7,-5l-9 -27c-1,-2 0,-5 3,-6l10 -4c3,-1 4,-4 4,-6 -1,-5 -1,-9 -2,-13 0,-3 -1,-7 -3,-9l-16 -17c-2,-2 -3,-6 -3,-8zm76 105l0 0c20,17 41,14 46,-7 5,-22 -7,-54 -27,-72 -20,-18 -40,-15 -45,7 -5,22 6,54 26,72z"/>
       <path fill="#40398B" fillRule="nonzero" d="M2093 502l422 244c14,8 22,22 23,38l0 981c0,16 -10,34 -21,40 -12,7 -33,6 -46,-1l-420 -243c-14,-8 -25,-27 -25,-42l0 -978c0,-15 13,-26 25,-33 12,-7 29,-13 42,-6z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.400000" d="M2477 1806c13,5 29,5 40,-1 11,-6 21,-24 21,-40l0 -981c0,-6 -2,-13 -6,-21l-39 22c7,9 11,19 11,29l0 971c0,18 -12,27 -27,21z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.301961" d="M2026 539c1,-14 12,-19 25,-12l432 249c1,1 6,5 10,9l39 -22c-3,-6 -8,-11 -15,-16l-424 -245c-13,-7 -30,-1 -42,6 -11,7 -24,16 -25,31z"/>
       <path fill="#40398B" fillRule="nonzero" d="M2051 527l432 250c11,6 21,23 21,37l0 971c0,21 -15,29 -33,19l-420 -243c-14,-8 -25,-27 -25,-42l0 -978c0,-15 11,-22 25,-14z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.200000" d="M2051 527l432 250c11,6 21,23 21,37l0 971c0,21 -15,29 -33,19l-420 -243c-14,-8 -25,-27 -25,-42l0 -978c0,-15 11,-22 25,-14z"/>
       <path fill="#6c61a7" fillRule="nonzero" d="M2048 606l0 881c0,14 11,32 23,40l390 225c12,7 23,1 23,-14l0 -888c0,-11 -7,-25 -16,-34 -1,-1 -2,-2 -3,-2 -1,-1 -3,-2 -4,-3l-52 -31 -5 -2 -5 -3 -13 -7c-9,-5 -18,-2 -18,8l0 33c0,11 -8,15 -18,9l-163 -95c-10,-5 -18,-19 -18,-30l0 -37c0,-7 -5,-17 -11,-21l-78 -44 -3 -2c-16,-9 -29,-1 -29,17z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2080 1465l0 -874 78 44c6,4 11,14 11,21l0 37c0,11 8,25 18,30l163 95c5,4 10,4 13,1 3,-1 29,-19 32,-21 3,-2 4,-6 4,-10l0 -13 5 3 5 2 52 31c1,1 3,2 4,3 1,0 2,1 3,2 9,9 16,23 16,34l0 875 -381 -220c-13,-7 -23,-25 -23,-40z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2299 760l-58 -34c-4,-2 -7,-1 -7,3 0,4 3,9 7,11l58 34c4,2 6,0 6,-4 0,-3 -2,-8 -6,-10z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2210 709c-3,-2 -5,-1 -5,3 0,4 2,8 5,10 4,2 6,1 6,-3 0,-4 -2,-8 -6,-10z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2187 695c-4,-2 -6,0 -6,3 0,4 2,9 6,11 3,2 6,0 6,-4 0,-4 -3,-8 -6,-10z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2353 791c-3,-2 -6,-1 -6,3 0,4 3,9 6,11 3,1 6,0 6,-4 0,-4 -3,-8 -6,-10z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2329 777c-3,-2 -6,0 -6,4 0,3 3,8 6,10 3,2 6,0 6,-4 0,-3 -3,-8 -6,-10z"/>
       <path fill="#EBEBEB" fillRule="nonzero" d="M1997 665c5,-3 12,-3 20,2l80 46c7,4 11,14 11,21 0,7 0,34 0,37 0,11 9,24 19,30l153 90c0,-7 0,-15 0,-21 0,-5 2,-8 5,-10 4,-2 24,-14 27,-15 4,-2 9,-2 14,1l18 10 4 2 53 30c1,1 3,2 4,4 1,0 2,1 2,1 10,9 17,23 17,35l0 879 0 9c0,7 -3,12 -7,15 -4,2 -23,13 -27,16 -4,2 -10,2 -17,-2l-389 -225c-13,-7 -23,-25 -23,-40l0 -881c0,-9 3,-15 9,-18 4,-3 22,-13 27,-16z"/>
       <path fill="#F4F4F4" fillRule="nonzero" d="M2286 860c4,-2 8,-1 13,1l18 10 4 3 52 30c2,1 4,2 5,3 1,1 2,1 2,2 4,3 6,7 9,11l27 -16c-3,-4 -5,-8 -9,-10 0,-1 -1,-2 -2,-2 -1,-2 -3,-3 -4,-4l-53 -30 -4 -2 -18 -10c-5,-3 -10,-3 -14,-1 -3,1 -21,12 -26,15z"/>
       <path fill="#F4F4F4" fillRule="nonzero" d="M1969 681c6,-3 13,-3 21,2l80 46c4,1 6,5 8,9l27 -16c-2,-4 -4,-7 -8,-9l-80 -46c-8,-5 -15,-5 -20,-2 -5,3 -23,13 -28,16z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.101961" d="M2078 738c2,3 3,8 3,12l0 37c0,7 4,15 9,22l27 -16c-5,-6 -9,-15 -9,-22l0 -37c0,-4 -1,-8 -3,-12l-27 16z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1990 683l80 46c7,4 11,13 11,21 0,7 0,34 0,37 0,11 9,24 18,30l163 95c10,5 18,1 18,-9 0,-2 0,-21 0,-33 0,-11 10,-14 19,-9l18 10 4 3 52 30c2,1 4,2 5,3 1,1 2,1 2,2 10,9 17,23 17,35l0 878 0 10c0,14 -11,20 -24,13l-389 -225c-13,-7 -23,-25 -23,-40l0 -881c0,-18 13,-26 29,-16z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.701961" d="M1990 683l80 46c7,4 11,13 11,21 0,7 0,34 0,37 0,11 9,24 18,30l163 95c10,5 18,1 18,-9 0,-2 0,-21 0,-33 0,-11 10,-14 19,-9l18 10 4 3 52 30c2,1 4,2 5,3 1,1 2,1 2,2 10,9 17,23 17,35l0 878 0 10c0,14 -11,20 -24,13l-389 -225c-13,-7 -23,-25 -23,-40l0 -881c0,-18 13,-26 29,-16z"/>
       <polygon fill="#40398B" fillRule="nonzero" points="2397,1323 2248,1128 2076,1239 2116,1196 2058,1108 1960,1174 1960,1372 2397,1624 "/>
       <path fill="#40398B" fillRule="nonzero" d="M2112 1030c-14,-8 -25,-1 -25,15 0,16 11,36 25,44 14,8 26,1 26,-15 0,-16 -12,-36 -26,-44z"/>
       <path fill="#F9F9F9" fillRule="nonzero" d="M1961 1580l0 -254c57,-31 134,-26 219,23 84,49 160,131 217,225l0 248 0 10c0,14 -11,20 -24,13l-389 -225c-13,-7 -23,-25 -23,-40z"/>
       <path fill="#EBEBEB" fillRule="nonzero" d="M1969 1321l0 259c0,12 9,27 19,33l390 225c2,1 4,2 6,2 4,0 4,-5 4,-8l0 -10 0 -261c3,4 6,9 9,13l0 248 0 10c0,10 -6,16 -13,16 -3,0 -7,-1 -11,-3l-389 -225c-13,-7 -23,-25 -23,-40l0 -254c2,-2 5,-3 8,-5z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2256 1523l-185 -107c-5,-3 -9,-1 -9,5 0,5 4,12 9,15l185 107c5,2 9,0 9,-5 0,-5 -4,-12 -9,-15z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2239 1562l-151 -87c-5,-3 -9,-1 -9,4 0,6 4,12 9,15l151 88c5,2 9,0 9,-5 0,-5 -4,-12 -9,-15z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2239 1464l-151 -87c-5,-3 -9,-1 -9,5 0,5 4,12 9,15l151 87c5,3 9,1 9,-5 0,-5 -4,-12 -9,-15z"/>
       <path fill="#40398B" fillRule="nonzero" d="M2214 1660l0 8c0,11 -8,16 -18,10l-82 -47c-10,-6 -18,-20 -18,-32l0 -7c0,-11 8,-16 18,-10l82 47c10,6 18,20 18,31z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M2288 995l-218 -125c-10,-6 -18,-2 -18,9 0,11 8,24 18,30l218 125c9,6 18,2 18,-9 0,-10 -9,-24 -18,-30z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1432 883c1,0 2,0 2,-1 2,-1 3,-3 3,-6 1,-5 -3,-12 -8,-15 -3,-1 -5,-1 -7,0 -2,1 -3,3 -3,6 0,6 4,12 8,15 2,1 3,1 5,1zm-7 -20l0 0c1,0 2,1 2,1 4,2 7,8 7,12 0,2 0,3 -1,4 -1,0 -2,0 -4,-1 -4,-2 -7,-8 -7,-12 0,-2 1,-3 2,-3 0,-1 0,-1 1,-1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1324 821c1,0 2,0 3,-1 2,-1 3,-3 3,-6 0,-5 -4,-12 -8,-15l0 0c-3,-1 -5,-1 -7,0 -2,1 -3,3 -3,6 0,6 3,12 8,15 2,1 3,1 4,1zm-7 -20l0 0c1,0 2,1 3,1 4,2 7,8 7,12 0,2 -1,3 -1,4 -1,0 -3,0 -4,-1 -4,-2 -7,-8 -7,-12 0,-2 0,-3 1,-3 1,-1 1,-1 1,-1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1421 869c0,0 1,-1 1,-1 0,-1 0,-2 -1,-2l-92 -53c0,-1 -1,-1 -2,0 0,1 0,2 1,2l92 53c0,1 0,1 1,1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1564 1208c1,0 2,-1 3,-1 2,-1 3,-3 3,-6 0,-6 -4,-12 -8,-15l0 0c-3,-1 -5,-2 -7,-1 -2,2 -3,4 -3,7 0,5 3,12 8,14 2,1 3,2 4,2zm-6 -20l0 0c0,0 1,0 2,1 4,2 7,7 7,12 0,1 0,3 -1,3 -1,1 -3,1 -4,0 -4,-2 -7,-8 -7,-12 0,-2 0,-3 1,-4 1,0 1,0 2,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1434 1496c1,0 2,-1 3,-1 2,-1 3,-3 3,-6 0,-6 -4,-12 -9,-15l0 0c-2,-1 -5,-2 -7,0 -2,1 -3,3 -3,6 0,5 4,12 9,14 1,1 3,2 4,2zm-7 -20l0 0c1,0 2,0 3,1 4,2 7,7 7,12 0,1 -1,3 -2,3 -1,1 -2,1 -4,0 -3,-2 -7,-8 -7,-12 0,-2 1,-3 2,-4 0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1235 1381c1,0 1,-1 2,-1 2,-1 3,-3 3,-6 0,-6 -3,-12 -8,-15 -3,-2 -5,-2 -7,-1 -2,2 -3,4 -3,7 0,5 4,12 8,14 2,1 3,2 5,2zm-5 -19l0 0c4,2 7,7 7,12 0,1 0,3 -1,3 -1,1 -3,1 -4,0 -4,-3 -7,-8 -7,-12 0,-2 1,-3 1,-4 1,0 3,0 4,1l0 0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1561 1233c0,0 0,0 1,0 0,0 1,-1 1,-1l0 -27c0,-1 -1,-1 -2,-1 -1,0 -2,0 -2,1l0 24 -227 -132c-1,0 -1,0 -2,0 0,1 -1,1 -1,2l0 115c0,1 1,2 2,2 1,0 1,-1 1,-2l0 -113 228 132c0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1331 1287c0,0 1,-1 1,-1l23 -22c0,-1 0,-1 0,-2l-23 -49c0,0 0,0 -1,0 0,-1 -1,0 -1,0l-23 22c-1,0 -1,1 -1,2l23 49c1,0 1,0 2,0 0,1 0,1 0,1zm21 -25l0 0 -21 20 -21 -45 20 -20 22 45z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1408 1349c0,0 1,-1 1,-2l0 -53c0,-1 0,-1 -1,-2l-53 -31c-1,0 -2,0 -3,1 0,1 0,2 1,2l53 31 0 52c0,1 1,2 2,2z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1422 1364l-29 -17c-5,-2 -8,0 -8,5l0 8c0,5 3,11 8,14l29 17c5,3 9,1 9,-5l0 -7c0,-6 -4,-12 -9,-15z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1269 1276l-30 -17c-4,-3 -8,-1 -8,5l0 7c0,6 4,12 8,15l30 17c4,2 8,0 8,-5l0 -8c0,-5 -4,-11 -8,-14z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1423 1481c0,0 1,0 1,0 1,-1 0,-2 0,-3l-15 -8 0 -78c0,-1 -1,-1 -1,-1 -1,0 -2,0 -2,1l0 79c0,1 0,1 1,1l15 9c0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1254 1260c1,0 2,-1 2,-2l0 -50 51 30c1,0 2,0 2,-1 1,-1 0,-2 0,-2l-54 -31c-1,0 -1,0 -2,0 0,0 0,1 0,1l0 53c0,1 0,2 1,2z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1254 1384c0,0 1,0 1,0 0,-1 1,-1 1,-2l0 -79c0,-1 -1,-2 -2,-2 -1,0 -1,1 -1,2l0 77 -13 -8c-1,0 -2,0 -3,1 0,1 0,1 1,2l15 9c1,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1035 831c1,0 2,0 3,0 2,-2 3,-4 3,-7 0,-5 -4,-12 -9,-14 -2,-2 -5,-2 -7,-1 -1,1 -3,3 -3,6 0,6 4,12 9,15 1,1 3,1 4,1zm-4 -19l0 0c4,2 7,8 7,12 0,2 -1,3 -2,4 -1,0 -2,0 -4,-1 -3,-2 -6,-7 -6,-12 0,-1 0,-3 1,-3 1,-1 2,-1 4,0l0 0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M943 441c1,0 2,0 3,0 2,-1 3,-4 3,-7 0,-5 -4,-11 -9,-14 -2,-2 -5,-2 -7,-1 -2,1 -3,4 -3,7 0,5 4,11 9,14 1,1 3,1 4,1zm-7 -19l0 0c1,0 2,0 3,0 4,3 7,8 7,12 0,2 -1,3 -2,4 -1,1 -2,0 -4,-1 -3,-2 -7,-7 -7,-11 0,-2 1,-4 2,-4 0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M943 530c1,0 2,0 3,0 2,-2 3,-4 3,-7 0,-5 -4,-12 -9,-14 -2,-2 -5,-2 -7,-1 -2,1 -3,3 -3,6 0,6 4,12 9,15 1,1 3,1 4,1zm-7 -20l0 0c1,0 2,1 3,1 4,2 7,8 7,12 0,2 -1,3 -2,4 -1,0 -2,0 -4,-1 -3,-2 -7,-7 -7,-12 0,-1 1,-3 2,-3 0,0 1,-1 1,-1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M943 619c1,0 2,0 3,-1 2,-1 3,-3 3,-6 0,-5 -4,-12 -9,-15 -2,-1 -5,-1 -7,0 -2,1 -3,3 -3,6 0,6 4,12 9,15 1,1 3,1 4,1zm-7 -20l0 0c1,0 2,1 3,1 4,2 7,8 7,12 0,2 -1,3 -2,4 -1,0 -2,0 -4,-1 -3,-2 -7,-7 -7,-12 0,-2 1,-3 2,-3 0,-1 1,-1 1,-1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M943 708c1,0 2,0 3,-1 2,-1 3,-3 3,-6 0,-6 -4,-12 -9,-15 -2,-1 -5,-1 -7,0 -2,1 -3,3 -3,6 0,6 4,12 9,15 1,1 3,1 4,1zm-7 -20l0 0c1,0 2,0 3,1 4,2 7,8 7,12 0,2 -1,3 -2,4 -1,0 -2,0 -4,-1 -3,-2 -7,-8 -7,-12 0,-2 1,-3 2,-4 0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1024 817c1,0 1,0 1,-1 1,-1 1,-2 0,-2l-30 -17 0 -336c0,-1 0,-1 -1,-1l-46 -27c-1,0 -2,0 -2,1 -1,0 0,1 0,2l46 26 0 336c0,0 0,1 1,1l30 18c0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M993 551c1,0 1,0 2,-1 0,0 0,-1 -1,-2l-46 -26c-1,-1 -2,-1 -2,0 -1,1 0,2 0,2l47 27c0,0 0,0 0,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M993 640c1,0 1,0 2,-1 0,-1 0,-2 -1,-2l-46 -27c-1,0 -2,0 -2,1 -1,1 0,2 0,2l47 27c0,0 0,0 0,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M993 728c1,0 1,0 2,0 0,-1 0,-2 -1,-2l-46 -27c-1,0 -2,0 -2,1 -1,0 0,1 0,2l47 26c0,0 0,0 0,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1902 1511c0,0 1,0 1,-1 1,0 0,-1 0,-2l-101 -58 0 -507c0,0 0,-1 -1,-1l-68 -39c0,-1 -1,0 -2,0 0,1 0,2 1,3l67 38 0 507c0,1 0,1 1,1l101 59c0,0 1,0 1,0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1728 911c1,0 2,0 3,0 2,-2 3,-4 3,-7 0,-5 -3,-12 -8,-14 -3,-2 -5,-2 -7,-1 -2,1 -3,3 -3,6 0,6 4,12 8,15 2,1 3,1 4,1zm-4 -19l0 0c4,2 7,8 7,12 0,2 0,3 -1,4 -1,0 -3,0 -4,-1 -4,-2 -7,-7 -7,-12 0,-1 0,-3 1,-3 1,-1 3,-1 4,0l0 0z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1913 1525c1,0 2,0 3,0 2,-1 3,-4 3,-7 0,-5 -4,-11 -9,-14 -2,-2 -5,-2 -7,-1 -2,1 -3,4 -3,7 0,5 4,11 9,14 1,1 3,1 4,1zm-7 -19l0 0c1,0 2,0 3,0 3,3 7,8 7,12 0,2 -1,4 -2,4 -1,1 -2,0 -4,0 -3,-3 -7,-8 -7,-12 0,-2 1,-4 2,-4 0,0 1,0 1,0z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1470 584c4,-2 9,-2 14,1l40 23c4,2 7,9 7,14 0,5 0,23 0,25 0,7 6,17 12,20l74 44c0,-5 0,-10 0,-15 0,-3 2,-5 4,-6 2,-1 16,-10 18,-11 3,-1 6,-1 10,1l12 7 3 2 19 11c1,1 3,1 4,2 0,1 1,1 1,1 6,6 11,16 11,24l0 486 0 6c0,5 -2,8 -4,10 -3,2 -16,9 -19,11 -3,1 -7,1 -11,-1l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-6 2,-11 6,-13 3,-2 15,-8 18,-10z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1621 690c3,-1 6,-1 9,1l12 7 3 1 20 12c1,0 2,1 3,2 1,0 1,1 2,1 2,2 4,5 5,7l19 -10c-2,-3 -4,-5 -6,-8 0,0 -1,0 -1,-1 -1,-1 -3,-1 -4,-2l-19 -11 -3 -2 -12 -7c-4,-2 -7,-2 -10,-1 -2,1 -14,9 -18,11z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1452 594c3,-2 8,-2 14,2l39 22c2,2 4,4 6,6l18 -10c-1,-3 -3,-5 -5,-6l-40 -23c-5,-3 -10,-3 -14,-1 -3,2 -15,8 -18,10z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.101961" d="M1511 624c1,3 2,6 2,9l0 25c0,5 2,10 6,15l18 -11c-3,-4 -6,-10 -6,-15l0 -25c0,-2 -1,-6 -2,-8l-18 10z"/>
       <path fill="#F9F9F9" fillRule="nonzero" d="M1466 596l39 22c5,3 8,10 8,15 0,5 0,23 0,25 0,7 5,16 12,20l80 47c7,4 12,1 12,-6 0,-2 0,-14 0,-23 0,-7 7,-9 13,-5l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 6,6 11,16 11,24l0 485 0 7c0,10 -7,14 -16,9l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-13 9,-18 20,-11z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1457 597c-7,0 -7,8 -7,10l0 487c0,9 6,20 14,24l203 117c2,1 4,2 5,2 4,0 5,-5 5,-7l0 -7 0 -485c0,-7 -4,-16 -10,-21l0 -1c-1,0 -1,0 -1,0 -1,-1 -2,-2 -3,-2l-20 -12 -3 -1 -12 -7c-1,-1 -2,-1 -4,-1 -2,0 -3,1 -3,3l0 23c0,7 -4,12 -10,12 -3,0 -5,-1 -8,-3l-80 -47c-8,-4 -14,-15 -14,-23l0 -25c0,-4 -2,-9 -6,-11l-39 -23c-2,-2 -5,-2 -7,-2zm0 -4l0 0c3,0 6,1 9,3l39 22c5,3 8,10 8,15 0,5 0,23 0,25 0,7 5,16 12,20l80 47c2,1 4,2 6,2 4,0 6,-3 6,-8 0,-2 0,-14 0,-23 0,-5 3,-7 7,-7 2,0 4,1 6,2l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 6,6 11,16 11,24l0 485 0 7c0,7 -4,11 -9,11 -2,0 -4,-1 -7,-2l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-9 5,-14 11,-14z"/>
       <polygon fill="#40398B" fillRule="nonzero" points="1653,789 1469,682 1469,860 1653,966 "/>
       <polygon fill="black" fillRule="nonzero" fillOpacity="0.200000" points="1653,966 1653,895 1592,815 1515,860 1531,844 1501,799 1469,826 1469,860 "/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.200000" d="M1530 758c-9,-5 -15,-1 -15,9 0,9 6,21 15,26 8,4 15,0 15,-9 0,-10 -7,-21 -15,-26z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1638 975c-9,-5 -16,-1 -16,9 0,9 7,21 16,26 8,5 15,1 15,-9 0,-9 -7,-21 -15,-26z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1602 954l-82 -47c-3,-2 -5,-1 -5,3l0 1c0,3 2,7 5,9l82 47c3,2 5,0 5,-3l0 -1c0,-3 -2,-7 -5,-9z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1603 981l-126 -73c-2,-1 -3,0 -3,2l0 1c0,3 1,6 3,7l126 72c2,2 4,1 4,-2l0 -1c0,-2 -2,-5 -4,-6z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1649 1195c0,0 1,0 1,0 0,0 0,-1 0,-1l0 -18 5 3c0,0 0,0 1,0 0,0 0,-1 0,-1l-17 -30c-1,0 -1,-1 -2,0l-17 9c0,0 -1,1 -1,1 0,0 1,1 1,1l5 3 0 19c0,0 0,0 1,1l23 13c0,0 0,0 0,0zm0 -21l0 0c0,0 0,0 0,0 -1,0 -1,0 -1,1l0 17 -21 -12 0 -19c0,0 0,0 0,0l-5 -3 15 -8 15 26 -2 -2c0,0 -1,0 -1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1484 1100c0,0 0,0 0,0l4 -1c3,0 11,-3 12,-15 1,-7 -2,-15 -7,-18 -2,-2 -4,-2 -6,-1 -1,0 -2,1 -3,2 -1,-5 -4,-10 -8,-11 -2,-1 -3,0 -5,1 -2,1 -3,5 -3,9 1,13 9,25 12,28l3 5c1,1 1,1 1,1zm5 -34l0 0c1,0 2,1 3,1 4,3 7,11 6,16 -1,11 -8,14 -10,14l-3 1 -4 -5c-2,-3 -10,-15 -11,-27 0,-4 1,-7 2,-8 1,-1 2,-1 3,0 4,1 7,6 8,12 0,0 0,1 0,1 0,0 0,0 0,0 1,1 1,1 1,1 0,0 0,0 0,0 1,0 1,0 1,-1 1,-2 2,-4 3,-5 0,0 1,0 1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1573 1151c1,0 1,0 1,0 0,0 0,0 0,-1l0 -5c0,-6 -3,-13 -8,-16l-5 -2c-2,-2 -4,-2 -6,-1 -2,1 -3,4 -3,7l0 6c0,0 0,0 1,0l20 12c0,0 0,0 0,0zm-15 -24l0 0c1,0 2,1 3,1l4 3c4,2 7,9 7,14l0 4 -18 -11 0 -5c0,-2 1,-4 2,-5 1,0 1,-1 2,-1zm3 0l0 0 0 0 0 0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1566 1124c1,0 1,0 2,-1 2,-1 3,-3 3,-6 0,-5 -3,-11 -7,-14 -2,-1 -4,-1 -6,0 -1,1 -2,3 -2,6 0,6 3,12 7,14 1,1 2,1 3,1zm-5 -20l0 0c0,0 1,1 2,1 3,2 6,7 6,12 0,2 -1,4 -2,5 -1,0 -2,0 -3,-1 -4,-2 -7,-7 -7,-12 0,-2 1,-4 2,-4 1,-1 1,-1 2,-1z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1477 942l168 97c4,2 8,9 8,14l0 16c0,5 -4,8 -8,5l-168 -97c-4,-2 -8,-9 -8,-14l0 -17c0,-5 4,-7 8,-4z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.501961" d="M1477 942l168 97c4,2 8,9 8,14l0 16c0,5 -4,8 -8,5l-168 -97c-4,-2 -8,-9 -8,-14l0 -17c0,-5 4,-7 8,-4z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1477 995l168 97c4,2 8,9 8,14l0 16c0,6 -4,8 -8,5l-168 -97c-4,-2 -8,-9 -8,-14l0 -16c0,-6 4,-8 8,-5z"/>
       <path fill="white" fillRule="nonzero" fillOpacity="0.701961" d="M1477 995l168 97c4,2 8,9 8,14l0 16c0,6 -4,8 -8,5l-168 -97c-4,-2 -8,-9 -8,-14l0 -16c0,-6 4,-8 8,-5z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1075 355c3,-2 8,-2 14,2l39 22c5,3 8,10 8,15 0,5 0,23 0,25 0,7 5,16 12,20l74 43c0,-4 0,-10 0,-14 0,-3 1,-5 3,-6 3,-2 17,-10 18,-11 3,-1 7,-1 10,1l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 6,6 11,16 11,24l0 485 0 7c0,5 -2,8 -5,10 -3,1 -16,9 -18,10 -3,2 -7,2 -12,-1l-203 -117c-8,-5 -15,-17 -15,-27l0 -487c0,-7 2,-11 5,-13 4,-2 15,-9 19,-11z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1226 461c2,-1 5,0 9,1l12 7 3 2 19 11c2,1 3,2 4,2 0,1 1,1 1,2 2,2 4,4 6,7l18 -11c-1,-2 -3,-5 -5,-7 -1,0 -1,-1 -2,-1 -1,-1 -2,-2 -3,-2l-20 -12 -3 -2 -12 -6c-3,-2 -7,-2 -10,-1 -1,1 -14,8 -17,10z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1056 366c4,-2 9,-2 14,1l40 23c2,1 4,3 5,6l18 -11c-1,-2 -3,-4 -5,-6l-39 -22c-6,-4 -11,-4 -14,-2 -4,2 -15,9 -19,11z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.101961" d="M1115 396c1,3 2,6 2,8l0 25c0,5 3,11 6,15l19 -10c-4,-5 -6,-10 -6,-15l0 -25c0,-3 -1,-6 -3,-9l-18 11z"/>
       <path fill="#F9F9F9" fillRule="nonzero" d="M1070 367l40 23c4,3 7,9 7,14 0,5 0,23 0,25 0,8 6,17 12,21l81 47c6,3 12,1 12,-7 0,-1 0,-13 0,-22 0,-7 7,-9 13,-6l12 7 3 2 19 11c2,1 3,2 4,2 0,1 1,1 1,2 7,6 11,15 11,23l0 486 0 6c0,10 -7,14 -16,9l-203 -117c-8,-5 -15,-17 -15,-27l0 -487c0,-13 8,-18 19,-12z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1062 368c-7,0 -8,8 -8,11l0 487c0,8 7,19 14,24l203 117c2,1 4,2 5,2 5,0 5,-6 5,-8l0 -6 0 -486c0,-7 -4,-15 -9,-20l-1 -1c0,0 0,0 -1,-1 -1,0 -1,-1 -2,-2l-20 -11 -3 -2 -12 -6c-2,-1 -3,-2 -4,-2 -2,0 -3,2 -3,4l0 22c0,7 -4,12 -11,12 -2,0 -5,-1 -7,-2l-80 -47c-8,-5 -15,-15 -15,-24l0 -25c0,-3 -2,-9 -5,-11l-40 -22c-2,-2 -4,-3 -6,-3zm0 -3l0 0c2,0 5,0 8,2l40 23c4,3 7,9 7,14 0,5 0,23 0,25 0,8 6,17 12,21l81 47c2,1 4,1 5,1 4,0 7,-3 7,-8 0,-1 0,-13 0,-22 0,-5 3,-7 7,-7 2,0 4,0 6,1l12 7 3 2 19 11c2,1 3,2 4,2 0,1 1,1 1,2 7,6 11,15 11,23l0 486 0 6c0,7 -3,11 -9,11 -2,0 -4,0 -7,-2l-203 -117c-8,-5 -15,-17 -15,-27l0 -487c0,-9 4,-14 11,-14z"/>
       <polygon fill="#40398B" fillRule="nonzero" points="1257,560 1073,454 1073,667 1257,773 "/>
       <polygon fill="black" fillRule="nonzero" fillOpacity="0.200000" points="1257,773 1257,702 1194,594 1119,658 1135,632 1111,594 1073,625 1073,667 "/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.200000" d="M1134 526c-8,-5 -15,-1 -15,8 0,10 7,22 15,26 8,5 15,1 15,-8 0,-10 -7,-21 -15,-26z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1254 967c0,0 0,0 0,-1 0,0 1,0 1,0l0 -18 4 3c0,0 1,0 1,-1 1,0 1,0 0,-1l-17 -29c0,-1 -1,-1 -1,-1l-18 10c0,0 0,0 0,1 0,0 0,0 0,0l6 4 0 18c0,1 0,1 0,1l23 13c0,1 0,1 1,1zm0 -22l0 0c-1,0 -1,0 -1,1 0,0 0,0 0,0l0 18 -21 -12 0 -19c0,0 -1,-1 -1,-1l-4 -2 15 -9 15 26 -3 -1c0,-1 0,-1 0,-1z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1168 917c0,0 0,0 0,0l3 -1c3,0 12,-3 13,-15 0,-7 -3,-15 -7,-18 -2,-1 -4,-2 -6,-1 -1,0 -2,1 -3,2 -2,-5 -5,-9 -8,-11 -2,0 -4,0 -5,1 -2,1 -3,5 -3,10 1,12 9,24 11,28l4 5c0,0 0,0 1,0zm5 -34l0 0c1,0 2,1 3,2 4,3 6,10 6,16 -1,11 -9,13 -11,13l-3 1 -3 -4c-2,-4 -10,-16 -11,-28 -1,-3 0,-6 2,-8 1,0 2,0 3,0 4,1 7,7 8,13 0,0 0,0 0,0 0,0 0,0 0,0 0,1 0,1 1,1 0,0 0,0 0,0 0,0 1,0 1,-1 0,-2 1,-4 3,-4 0,-1 0,-1 1,-1z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1101 878c0,0 0,0 0,0 1,0 1,0 1,-1l0 -5c0,-6 -4,-13 -9,-16l-4 -2c-2,-2 -5,-2 -6,-1 -2,1 -4,4 -4,7l0 6c0,0 1,0 1,0l20 12c0,0 1,0 1,0zm-13 -23l0 0 4 3c4,2 8,9 8,14l0 4 -19 -11 0 -5c0,-2 1,-4 3,-5 1,-1 2,-1 4,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1093 851c1,0 2,0 2,-1 2,-1 3,-3 3,-6 0,-5 -3,-11 -7,-14 -2,-1 -4,-1 -5,0 -2,1 -3,3 -3,6 0,6 3,12 7,14 1,1 2,1 3,1zm-5 -20l0 0c1,0 1,1 2,1 4,2 6,7 6,12 0,2 0,4 -1,5 -2,0 -3,0 -4,-1 -3,-2 -6,-7 -6,-12 0,-2 0,-4 2,-4 0,-1 0,-1 1,-1z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1249 786l-121 -70c-5,-3 -9,-1 -9,4 0,5 4,11 9,14l121 70c5,3 8,1 8,-4 0,-5 -3,-11 -8,-14z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1249 822l-160 -93c-5,-3 -8,-1 -8,4 0,5 3,11 8,14l160 93c5,2 8,0 8,-5 0,-5 -3,-11 -8,-13z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1242 857c-8,-5 -15,-1 -15,9 0,10 7,22 15,27 9,5 15,1 15,-9 1,-10 -6,-22 -15,-27z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1207 837l-83 -48c-3,-1 -5,0 -5,3l0 2c0,3 3,7 5,8l83 48c2,1 4,0 4,-3l0 -2c0,-3 -2,-7 -4,-8z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1208 864l-126 -73c-2,-1 -4,0 -4,2l0 1c0,2 2,5 4,6l126 73c2,1 3,0 3,-2l0 -1c0,-3 -1,-5 -3,-6z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1470 1231c4,-2 9,-2 14,1l40 23c4,2 7,9 7,14 0,5 0,23 0,25 0,7 6,17 12,20l74 44c0,-5 0,-10 0,-15 0,-3 2,-5 4,-6 2,-1 16,-10 18,-11 3,-1 6,-1 10,1l12 7 3 2 19 11c1,1 3,1 4,2 0,1 1,1 1,1 6,6 11,16 11,24l0 485 0 7c0,5 -2,8 -4,10 -3,2 -16,9 -19,11 -3,1 -7,1 -11,-1l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-6 2,-11 6,-13 3,-2 15,-8 18,-10z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1621 1337c3,-1 6,-1 9,1l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 2,2 4,5 5,7l19 -10c-2,-3 -4,-6 -6,-8 0,0 -1,0 -1,-1 -1,-1 -3,-1 -4,-2l-19 -11 -3 -2 -12 -7c-4,-2 -7,-2 -10,-1 -2,1 -14,9 -18,11z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1452 1241c3,-2 8,-2 14,2l39 22c2,2 4,4 6,6l18 -10c-1,-3 -3,-5 -5,-6l-40 -23c-5,-3 -10,-3 -14,-1 -3,2 -15,8 -18,10z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.101961" d="M1511 1271c1,3 2,6 2,9l0 25c0,5 2,10 6,15l18 -11c-3,-4 -6,-10 -6,-15l0 -25c0,-3 -1,-6 -2,-8l-18 10z"/>
       <path fill="#F9F9F9" fillRule="nonzero" d="M1466 1243l39 22c5,3 8,10 8,15 0,5 0,23 0,25 0,7 5,16 12,20l80 47c7,4 12,1 12,-6 0,-2 0,-14 0,-23 0,-7 7,-9 13,-5l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 6,6 11,16 11,24l0 485 0 7c0,10 -7,14 -16,9l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-13 9,-18 20,-11z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1457 1244c-7,0 -7,8 -7,10l0 487c0,9 6,20 14,24l203 117c2,1 4,2 5,2 4,0 5,-5 5,-7l0 -7 0 -485c0,-7 -4,-16 -10,-21l0 -1c-1,0 -1,0 -1,0 -1,-1 -2,-2 -3,-2l-20 -12 -3 -1 -12 -7c-1,-1 -2,-1 -4,-1 -2,0 -3,1 -3,3l0 23c0,7 -4,11 -10,11 -3,0 -5,0 -8,-2l-80 -47c-8,-4 -14,-15 -14,-23l0 -25c0,-4 -2,-9 -6,-11l-39 -23c-2,-2 -5,-2 -7,-2zm0 -4l0 0c3,0 6,1 9,3l39 22c5,3 8,10 8,15 0,5 0,23 0,25 0,7 5,16 12,20l80 47c2,1 4,2 6,2 4,0 6,-3 6,-8 0,-2 0,-14 0,-23 0,-5 3,-7 7,-7 2,0 4,1 6,2l12 6 3 2 20 12c1,0 2,1 3,2 1,0 1,1 2,1 6,6 11,16 11,24l0 485 0 7c0,7 -4,11 -9,11 -2,0 -4,-1 -7,-2l-203 -118c-9,-5 -16,-17 -16,-27l0 -487c0,-9 5,-14 11,-14z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1649 1842c0,0 1,0 1,0 0,0 0,-1 0,-1l0 -18 5 3c0,0 0,0 1,0 0,0 0,-1 0,-1l-17 -30c-1,0 -1,-1 -2,0l-17 9c0,0 -1,1 -1,1 0,0 1,1 1,1l5 3 0 19c0,0 0,0 1,1l23 13c0,0 0,0 0,0zm0 -21l0 0c0,0 0,0 0,0 -1,0 -1,0 -1,1l0 17 -21 -12 0 -19c0,0 0,0 0,0l-5 -3 15 -8 15 26 -2 -2c0,0 -1,0 -1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1563 1792c0,0 0,0 0,0l4 0c3,-1 11,-4 12,-16 1,-6 -2,-14 -7,-18 -2,-1 -4,-2 -6,-1 -1,1 -2,2 -2,3 -2,-6 -5,-10 -9,-11 -2,-1 -3,-1 -5,0 -2,2 -3,5 -3,10 1,12 10,25 12,28l3 5c1,0 1,0 1,0zm5 -33l0 0c1,0 2,0 3,1 4,3 7,10 6,16 -1,11 -8,13 -10,14l-3 0 -3 -4c-3,-3 -11,-15 -12,-27 0,-4 1,-7 3,-8 0,-1 1,-1 3,-1 3,2 6,7 7,13 0,0 0,0 0,0 0,0 0,1 0,1 1,0 1,0 1,0 0,0 0,0 0,0 1,0 1,0 1,0 1,-3 2,-5 3,-5 0,0 1,0 1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1496 1754c0,0 1,0 1,0 0,-1 0,-1 0,-1l0 -5c0,-7 -4,-14 -8,-16l-5 -3c-2,-1 -4,-1 -6,0 -2,1 -3,3 -3,7l0 5c0,0 0,1 0,1l21 12c0,0 0,0 0,0zm-15 -24l0 0c1,0 1,0 2,1l5 2c4,3 7,9 7,15l0 3 -18 -11 0 -4c0,-3 1,-5 2,-6 1,0 1,0 2,0zm3 0l0 0 0 0 0 0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1489 1726c1,0 1,0 2,0 2,-1 3,-4 3,-7 0,-5 -3,-11 -7,-13 -2,-1 -4,-2 -6,-1 -2,1 -3,4 -3,7 0,5 4,11 8,13 1,1 2,1 3,1zm-6 -19l0 0c1,0 2,0 3,0 3,2 6,8 6,12 0,3 -1,4 -2,5 -1,1 -2,0 -3,0 -4,-2 -7,-8 -7,-12 0,-3 1,-4 2,-5 1,0 1,0 1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1602 1490l-75 -43c-2,-1 -5,0 -5,3 0,3 3,7 5,8l75 43c2,2 5,1 5,-2 0,-3 -3,-7 -5,-9z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1614 1519l-98 -56c-3,-2 -5,-1 -5,2 0,3 2,7 5,8l98 57c2,2 5,0 5,-3 0,-3 -3,-6 -5,-8z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1648 1579l-82 -47c-3,-2 -5,-1 -5,3l0 1c0,3 2,7 5,9l82 47c3,2 5,0 5,-3l0 -1c0,-3 -2,-7 -5,-9z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1649 1610l-176 -101c-2,-1 -4,0 -4,2l0 17c0,3 2,6 4,7l176 102c2,1 4,0 4,-2l0 -17c0,-3 -2,-6 -4,-8z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1649 1681l-176 -101c-2,-2 -4,-1 -4,2l0 17c0,3 2,6 4,7l176 102c2,1 4,0 4,-3l0 -17c0,-2 -2,-6 -4,-7z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1648 1650l-82 -48c-3,-1 -5,0 -5,3l0 2c0,3 2,7 5,8l82 48c3,1 5,0 5,-3l0 -2c0,-3 -2,-7 -5,-8z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1648 1734l-67 -39c-2,-1 -5,0 -5,3l0 24c0,3 3,7 5,9l67 38c3,2 5,1 5,-2l0 -25c0,-3 -2,-7 -5,-8z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1541 1672l-67 -39c-3,-1 -5,0 -5,3l0 24c0,3 2,7 5,9l67 38c2,2 5,1 5,-2l0 -25c0,-3 -3,-7 -5,-8z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M983 949c3,-2 8,-2 14,1l39 23c5,3 8,9 8,14 0,5 0,23 0,25 0,8 5,17 12,21l74 43c0,-4 0,-10 0,-14 0,-4 1,-6 3,-7 3,-1 16,-9 18,-10 3,-2 7,-2 10,0l12 7 3 2 20 11c1,1 2,2 3,2 0,1 1,1 1,2 7,6 11,15 11,23l0 486 0 6c0,5 -1,9 -4,10 -3,2 -16,9 -19,11 -2,2 -6,1 -11,-1l-203 -118c-8,-4 -16,-17 -16,-27l0 -487c0,-6 3,-10 6,-12 4,-2 15,-9 19,-11z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M1134 1055c2,-1 5,-1 9,1l12 7 3 1 19 12c1,0 3,1 4,2 0,0 1,1 1,1 2,2 4,5 6,7l18 -10c-1,-3 -3,-5 -6,-7 0,-1 -1,-1 -1,-2 -1,0 -2,-1 -3,-2l-20 -11 -3 -2 -12 -7c-3,-2 -7,-2 -10,0 -1,1 -14,8 -17,10z"/>
       <path fill="#EFEFEF" fillRule="nonzero" d="M964 960c4,-3 9,-2 14,1l40 23c2,1 4,3 5,6l18 -11c-1,-3 -3,-5 -5,-6l-39 -23c-6,-3 -11,-3 -14,-1 -4,2 -15,9 -19,11z"/>
       <path fill="black" fillRule="nonzero" fillOpacity="0.101961" d="M1023 990c1,2 2,5 2,8l0 25c0,5 3,10 6,15l19 -11c-4,-4 -6,-10 -6,-15l0 -25c0,-2 -1,-5 -3,-8l-18 11z"/>
       <path fill="#F9F9F9" fillRule="nonzero" d="M978 961l40 23c4,2 7,9 7,14 0,5 0,23 0,25 0,7 6,16 12,20l81 47c6,4 12,1 12,-6 0,-1 0,-14 0,-22 0,-8 6,-9 13,-6l12 7 3 1 19 12c1,0 3,1 4,2 0,0 1,1 1,1 6,6 11,16 11,24l0 485 0 7c0,10 -7,14 -16,9l-203 -118c-8,-4 -16,-17 -16,-27l0 -487c0,-12 9,-17 20,-11z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M969 962c-6,0 -7,8 -7,10l0 487c0,9 7,20 14,24l203 118c2,1 4,1 5,1 5,0 5,-5 5,-7l0 -7 0 -485c0,-7 -4,-15 -9,-21l-1 0c0,-1 -1,-1 -1,-1 -1,-1 -2,-1 -2,-2l-20 -11 -3 -2 -12 -7c-2,0 -3,-1 -4,-1 -2,0 -3,1 -3,4l0 22c0,7 -5,12 -11,12 -2,0 -5,-1 -7,-3l-80 -46c-8,-5 -15,-15 -15,-24l0 -25c0,-4 -2,-9 -5,-11l-40 -23c-2,-1 -4,-2 -7,-2zm0 -4l0 0c3,0 6,1 9,3l40 23c4,2 7,9 7,14 0,5 0,23 0,25 0,7 6,16 12,20l81 47c2,1 4,2 5,2 4,0 7,-3 7,-8 0,-1 0,-14 0,-22 0,-5 3,-8 7,-8 2,0 4,1 6,2l12 7 3 1 19 12c1,0 3,1 4,2 0,0 1,1 1,1 6,6 11,16 11,24l0 485 0 7c0,7 -3,11 -9,11 -2,0 -4,-1 -7,-2l-203 -118c-8,-4 -16,-17 -16,-27l0 -487c0,-9 5,-14 11,-14z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1162 1560c0,0 0,0 0,0 0,0 1,0 1,-1l0 -17 4 2c0,0 1,0 1,0 0,0 1,-1 0,-1l-17 -30c0,0 -1,0 -1,0l-18 9c0,1 0,1 0,1 0,1 0,1 0,1l6 3 0 19c0,0 0,1 0,1l23 13c0,0 0,0 1,0zm0 -21l0 0c-1,0 -1,0 -1,0 0,0 0,1 0,1l0 18 -21 -13 0 -18c0,-1 -1,-1 -1,-1l-4 -3 15 -8 15 26 -3 -2c0,0 0,0 0,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1076 1511c0,0 0,0 0,0l3 -1c3,-1 12,-3 13,-16 0,-6 -3,-14 -7,-17 -2,-2 -4,-2 -6,-2 -1,1 -2,2 -3,3 -2,-5 -5,-10 -8,-11 -2,-1 -4,-1 -5,0 -2,2 -4,6 -3,10 1,13 9,25 11,28l4 5c0,0 0,1 1,1zm5 -34l0 0c0,0 2,0 3,1 4,3 6,10 6,16 -1,11 -9,14 -11,14l-3 1 -3 -5c-2,-3 -10,-15 -11,-27 -1,-4 0,-7 2,-8 1,-1 2,-1 3,0 3,1 7,6 8,12 0,0 0,0 0,1 0,0 0,0 0,0 0,0 0,1 1,1 0,0 0,0 0,0 0,0 1,-1 1,-1 0,-3 1,-4 2,-5 1,0 1,0 2,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1009 1472c0,0 0,0 0,0 0,0 1,-1 1,-1l0 -5c0,-6 -4,-13 -9,-16l-4 -3c-2,-1 -5,-1 -6,0 -2,1 -4,4 -4,7l0 5c0,1 1,1 1,1l20 12c0,0 1,0 1,0zm-16 -24l0 0c1,0 2,0 3,1l4 3c4,2 8,8 8,14l0 3 -19 -10 0 -5c0,-3 1,-5 3,-5 0,-1 1,-1 1,-1zm3 0l0 0 0 0 0 0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1001 1445c1,0 2,-1 2,-1 2,-1 3,-3 3,-6 0,-6 -3,-12 -7,-14 -2,-1 -4,-1 -5,0 -2,1 -3,3 -3,6 0,5 3,11 7,14 1,0 2,1 3,1zm-5 -20l0 0c1,0 1,0 2,1 4,2 6,7 6,12 0,2 0,4 -2,4 -1,1 -2,1 -3,0 -3,-2 -6,-7 -6,-12 0,-2 0,-4 2,-5 0,0 0,0 1,0z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1114 1209l-74 -43c-3,-2 -5,-1 -5,2 0,3 2,7 5,9l74 42c3,2 5,1 5,-2 0,-3 -2,-7 -5,-8z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1126 1237l-98 -56c-3,-2 -5,-1 -5,2 0,3 2,7 5,9l98 56c3,2 5,1 5,-2 0,-3 -2,-7 -5,-9z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1161 1297l-83 -47c-3,-2 -5,0 -5,3l0 1c0,4 3,7 5,9l83 47c2,2 4,1 4,-2l0 -2c0,-3 -2,-7 -4,-9z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1161 1329l-176 -102c-2,-1 -4,0 -4,2l0 18c0,2 2,5 4,7l176 101c3,2 4,1 4,-2l0 -17c0,-3 -1,-6 -4,-7z"/>
       <path fill="#DFDFDF" fillRule="nonzero" d="M1161 1399l-176 -101c-2,-2 -4,0 -4,2l0 17c0,3 2,6 4,7l176 102c3,1 4,0 4,-2l0 -18c0,-2 -1,-5 -4,-7z"/>
       <path fill="#A196C8" fillRule="nonzero" d="M1161 1368l-83 -47c-3,-2 -5,-1 -5,2l0 2c0,3 3,7 5,9l83 47c2,2 4,0 4,-3l0 -1c0,-3 -2,-7 -4,-9z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1160 1452l-66 -38c-3,-2 -5,-1 -5,2l0 24c0,4 2,7 5,9l66 39c3,1 5,0 5,-3l0 -24c0,-3 -2,-7 -5,-9z"/>
       <path fill="#40398B" fillRule="nonzero" d="M1053 1390l-67 -38c-2,-2 -5,-1 -5,2l0 24c0,4 3,7 5,9l67 39c3,1 5,0 5,-3l0 -24c0,-3 -2,-7 -5,-9z"/>
       <g transform="matrix(0.890436 0.545627 -0.0753127 1 637.28 -583.44)">
        <text x="1754" y="1240"  fill="#A196C8" fontWeight="bold" fontSize="30.8208px" fontFamily="Montreal">START</text>
       </g>
       <g transform="matrix(0.776523 0.451197 -0.065678 1.00799 251.45 -635.833)">
        <text x="1754" y="1240"  fill="#A196C8" fontWeight="normal" fontSize="30.8196px" fontFamily="Montreal">Sign in</text>
       </g>
       <g transform="matrix(0.776523 0.451197 -0.065678 1.00799 -238.389 -915.55)">
        <text x="1754" y="1240"  fill="#A196C8" fontWeight="normal" fontSize="30.8196px" fontFamily="Montreal">Sign Up</text>
       </g>
      </g>
     </g>
    </svg>
    
}

export default UiUx;