import React from 'react';
import styled from 'styled-components';
import { Fragment } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const UpButton = styled.div`
    width : 60px;
    height : 60px;
    position : fixed;
    bottom : 30px;
    border-radius : 50%;
    left : 30px;
    cursor : pointer;
    background : #000;
    z-index : 100;
    display : flex;
    justify-content : center;
    align-items : center;
`;

const Button = styled(FaArrowUp)`
    width : 50%;
    height : 50%;
    color : #fff;
`;


const UpButtonComponent = () => {

    const onClickHandler = () => {
        window.scrollTo(0, 0);
    }


    return <Fragment>
        <UpButton>
            <Button onClick={onClickHandler} />
        </UpButton>
    </Fragment>
}

export default UpButtonComponent;

