import React, { Fragment, useEffect }from 'react';
import Footer from '../Footer';
import LMV from '../LMV Logo/LmvLogo';
import AboutUs from '../AboutUs';


const AboutUsPage = () => {

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])

        return (
            <Fragment>
                <LMV />
                <AboutUs />
                <Footer />
            </Fragment>
        )
}   

export default AboutUsPage;