import React, {useEffect, Fragment} from 'react';
import Image from '../../Images/PNG/Web/Web Develop.svg';
import Bg1 from '../../Images/PNG/WebBg.png';
import WhyUs from '../WhyLmvIt';
import styled from 'styled-components';
import OurProcess from './Web/OurProcess';
import WhyToChooseUs from './Web/WhyToChooseUs';
import WhatWeOffer from './Web/WhatWeOffer';
import Footer from '../Footer';

//  #9087DD #BFC1FD

const WebSection = styled.section`
    width: 100%;
    height: 100vh;
    box-sizing : border-box;
    overflow: hidden;
    background-image : url(${Bg1});
    background-position: center;
    object-fit : cover;
    padding : 0 10%;
    display : flex;
    justify-content : center;
    align-item : center;

@media screen and (max-width: 768px) {
    object-fit : cover;
    flex-direction : column;
    padding : 0 ;
}
`;

const BgImage1 = styled.img`
    width : 100%;
    height : 100%;
    position : absolute;
    z-index: -20;
`;



const UiUxDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px ) {

}

`;

const ImgDiv = styled.div`
    width : 100%;
    height : 100%;
    justify-content : center;
    align-items : center;
    display : flex;
    position : relative;

svg{
    width : 100%;
    height : auto;
    filter: drop-shadow(0px 0px 10px #000);
    transform : scale(1.5)
}

@media screen and (max-width : 768px ) {
    display : initial;
}
`;

const Heading = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content: center;

`;

const HeadingDiv = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    font-style: italic;
    justify-content : center;
    align-item : center;
    flex-direction : column;
    text-align : center;


h1{
    font-size : 2rem;
    letter-spacing : 3px;
}

h5{
    text-align : center;
    margin-bottom : 15px;
}

p{
    font-size : 1.1rem;
    padding : 10px 0;
    font-weight : bold;
    line-height : 30px;
}

@media screen and (max-width : 768px ) {

h1{
    font-size : 1.5rem;
    padding : 10px 0;
    letter-spacing : 5px;
}

p{
    font-size : 0.9rem;
    padding : 10px 10px ;  
}
}
`;

const Img = styled.img`
    width : 100%;
    height : 100%;
    height : auto;
    filter: drop-shadow(15px 15px 10px #000);
    transform : skew(-5deg) scale(1.5);
    margin-top : 70px;
  

@media screen and (max-width : 768px ) {
    transform : skew(-5deg) scale(1.5);
    padding-right : 20px;
    margin-top : 0px;
}
`;


const WebDevelopment = () => {
    
    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])


        return (
            <Fragment>
                <WebSection>
                    <BgImage1 src={Bg1} />
                    <Heading>
                        <HeadingDiv>
                            <h1 data-aos="fade-right">Web Development</h1>
                            {/* <h5 data-aos="fade-right">Andriod Apps, IOS Apps, Web Based Apps....!</h5> */}
                            <p data-aos="fade-right">When the limitations of the packaged CMS or E-commerce solution are standing in the way of the customers agenda we are offering our custom website development option. We have extensive experience developing sites and apps of all types, complexities and budgets.</p>
                        </HeadingDiv>
                    </Heading>
                    <UiUxDiv >
                        <ImgDiv data-aos="zoom-in-left">
                            <Img src={Image} />
                        </ImgDiv>  
                    </UiUxDiv>
                </WebSection>
                <OurProcess />
                <WhyToChooseUs />
                <WhatWeOffer />
                <WhyUs />
                <Footer />
            </Fragment>
            
        )
}

export default WebDevelopment;