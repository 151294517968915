import ImageOne from '.././../Images/JPEG/1.jpg';
import ImageTwo from '.././../Images/JPEG/2.jpg';
import ImageThree from '.././../Images/JPEG/3.jpg';
import ImageFour from '.././../Images/JPEG/4.jpg';
import ImageFive from '.././../Images/JPEG/5.jpg';
import ImageSix from '.././../Images/JPEG/6.jpg';

    
export const SliderData = [
    { 
        title : 'LMV IT SOLUTIONS',
        info : 'We builds for you software solutions, be it websites, web applications or mobile applications, that are fluid and flawless', 
        path : '/',
        image : ImageOne,
        alt : 'LMV IT SOLUTIONS'
    },
    { 
        title : 'Samudra  Application',
        path : 'samudra',
        label : 'More details',
        image : ImageThree,
        alt : 'Samudhra  Application'
    },
    { 
        title : 'CRM  Web  Solutions',
        path : 'crm',
        label : 'More details',
        image : ImageTwo,
        alt : 'CRM  Web  Solutions'
    },
    { 
        title : 'DAR Application',
        path : 'dar',
        label : 'More details',
        image : ImageFour,
        alt : 'DAR Application'
    },
    { 
        title : 'Payroll Application',
        path : 'payroll',
        label : 'More details',
        image : ImageFive,
        alt : 'Payroll'
    },
    { 
        title : 'Study Tap',
        path : 'studytap',
        label : 'More details',
        image : ImageSix,
        alt : 'Study Tap'
    }
]