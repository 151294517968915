import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import  ImageSrc1 from '../../../Images/SlidersPage/DAR/features-1 (2).png'
import ImageSrc2 from '../../../Images/SlidersPage/DAR/values-3.png';
import { BsForwardFill } from 'react-icons/bs';

const DescriptionSection = styled.section`
    width : 80%;
    height : auto;
    margin : 15px auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;


@media screen and (max-width : 768px) {
    width : 100%;
    padding : 0 10px;
    h2{
        font-size : 14px;
    }

    p{
        font-size : 12px;
    }
}
`;
const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
   justify-content : center;  
   align-items : center;
    text-align : center;

@media screen and (max-width : 768px) {
    h2{
        font-size : 14px;
    }
}
`;
const Wrapper = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;   

@media screen and (max-width : 768px){
    flex-direction : column-reverse;
    padding : 10px 0;
}
`;
const Content = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;


@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentDiv = styled.div`
    width : auto;
    height : auto;
    line-height : 30px;;

p{
    padding : 10px 0;
}
`;
const Image = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 100%;
}

@media screen and (max-width : 768px){
   
}
`;

const Icon = styled(BsForwardFill)`
    font-size : 20px;
`;

const What = () => {

    return <Fragment>
        <DescriptionSection>
            <Heading><h2 data-aos="fade-up">What is Payroll Software ?</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <p data-aos="fade-up"><Icon/> Simply put, payroll Software refers to the total amount a company is supposed to pay to its employee for a specific period. It is the responsibility of an employer to make sure their employees and staff get fair compensation for their hard work. As much as important it is, payroll can turn out to be an overwhelming task for an employer. Think about it – you have to track the number of hours and days each employee has worked. You have to deduct taxes from the final payment and distribute fair compensation to each worker.</p>
                        <p data-aos="fade-up"><Icon/> Whether you hire an accountant for the job or integrate a cloud-based online solution to automate the task, you will need the HR payroll software to issue paychecks to your employees. You need a system that tracks your employee’s working hours as well as productivity. A system that helps you understand and calculate tax deductions..</p>
                        <p data-aos="fade-up"><Icon/> Payroll processing software is a must for large-scale and medium-sized firms. There is no way you can carry out the salary disbursement process on your own. Things get super complicated when you have several departments and each department has hundreds of employees. Even if you hire a qualified and experienced HR team and a few accountants, you are going to need an automated payroll management solution to complete the payroll process.</p>
                    </ContentDiv>
                </Content>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection>
            <Heading><h2 data-aos="fade-up">Common Capabilities of Payroll Management Solution</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv style={{padding : '0 20px'}}>
                        <p data-aos="fade-up"><Icon/>Auto recording of time spent on PC and Desk Activities and Works in invisible mode</p>
                        <p data-aos="fade-up"><Icon/>White List the apps to record productive hours moreover fix the time on apps which are less productive and get the time exceed reports</p>
                        <p data-aos="fade-up"><Icon/>Total time spent on internet, blacklist the URLs like facebook, youtube etc. and record the unproductive hours</p>
                        <p data-aos="fade-up"><Icon/>Track Gmails and time spent on communication on mails</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection style={{paddingBottom : '50px'}}>
            <Heading><h2>Why Do You Need a Payroll Management System?</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv style={{padding : '0 20px'}}>
                        <p data-aos="fade-up"><Icon/>Manage the tracked files, folder, or applications as project or tasks by tagging thus identify the time spent on the project or activities.</p>
                        <p data-aos="fade-up"><Icon/>Auto logging of application (Xcode, studio, Autocad, phpstorm, Microsoft excel, keynote etc.) files and monitor the time spent.</p>
                        <p data-aos="fade-up"><Icon/>Detailed reports of the individual activities thus identify the in-efficient or bad work habits and turn to the efficient ones by pushing them via mentoring.</p>
                        <p data-aos="fade-up"><Icon/>Analyze the performance in a glance and do the appraisals by having the actual working reports of the individual</p>
                    </ContentDiv>
                </Content>
                {/* <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image> */}
            </Wrapper>
        </DescriptionSection>
    </Fragment>
}

export default What;