import React, { useEffect } from 'react';
import styled from 'styled-components';
import aboutUsIcons from './Data/AboutUsData';
import AOS from 'aos';
import "aos/dist/aos.css";


const AboutUsSecton = styled.section`
    width : 100%;
    height : auto;
`;

const Heading = styled.h1`
    text-align : center;
    font-size : 2rem;
    color : #364547;
    padding : 20px 0;
`;

const HeadingDiv = styled.div`
    width : 100%;
    height : auto;
    background : #e1e5ea;
`
const InnerSection = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    margin-top : 30px;
    flex-direction : column;
    justify-content : center;
    align-items : center;
`;

const ContentDiv = styled.div`
    width : 90%;
    height : auto;
    display : flex;
    justify-content : space-between;
    padding : 20px;

@media screen and (max-width : 768px) {
    width : 100%;
    padding : 20px; 
    flex-direction: column;
}
`;
const Content = styled.div`
    width : 100%;
    height : auto;
    display  : flex;
    justify-content: space-between;
    padding : 10px 20px;

@media screen and (max-width : 768px) {
    flex-direction : column;
}
`;
const Icons = styled.div`
    width : auto;
    height : auto;
    display : flex;
    justify-content: center;
    align-items : center;
    padding : 0 20px;

img{
    width : 100px;
    height : auto;
}
`;
const Paragraph = styled.div`
    width : 100%;
    height : auto;
    padding : 0 20px;

@media screen and (max-width : 768px){
    padding : 0;
}
`

const HeadingText = styled.h3`
    text-align : center;
    padding : 5px;
`;
const ParagraphText = styled.p`
    padding : 5px;  
    font-size : 0.9rem;
    line-height : 28px;  
`;

const AboutUs = () => {

    useEffect(() => {
        AOS.init({duration : 1000})
    });

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, [])


    return (
        <AboutUsSecton>
            <HeadingDiv>
             <Heading>About Us</Heading>   
            </HeadingDiv>
            <InnerSection>
                <ContentDiv>
                    <Content data-aos="fade-up">
                        <Icons><img src={aboutUsIcons.mission}></img></Icons>
                        <Paragraph>
                            <HeadingText>Our Mission</HeadingText>
                            <ParagraphText>LMVIT Solutions employees are driven by a passion for technology and customer satisfaction. We put our customers’ needs at the center of everything , we do and apply the expertise of our committed technologists to deliver value and the finest customer experience.</ParagraphText>
                        </Paragraph>
                    </Content>
                    <Content data-aos="fade-up">
                        <Icons><img src={aboutUsIcons.vision}></img></Icons>
                        <Paragraph>
                            <HeadingText>Our Mission</HeadingText>
                            <ParagraphText>LMVIT Solutions aims to be one among the Top Fortune companies in world by creating core competency at every level of Design and Development so as to excel in creating the best value proposition to the corporate world.</ParagraphText>
                        </Paragraph>
                    </Content>
                </ContentDiv>
                <ContentDiv>
                    <Content data-aos="fade-up">
                        <Icons><img src={aboutUsIcons.whoweare}></img></Icons>
                        <Paragraph>
                            <HeadingText>Who we are</HeadingText>
                            <ParagraphText>LMVIT Solutions is a global technology and process driven software, web and mobile app solutions company offering customer centric solutions with knowledge and experience of the entire IT lifecycle . We help businesses solve their challenges and stay ahead of the competition by leveraging the latest technologies. We deliver next-generation software solutions</ParagraphText>
                        </Paragraph>
                    </Content>
                    <Content data-aos="fade-up">
                    <Icons><img src={aboutUsIcons.commitment}></img></Icons>
                        <Paragraph>
                            <HeadingText>Our Commitment</HeadingText>
                            <ParagraphText>Promote a long term relationship with clients and deliver solutions, which are delighted to the client.
                                Continuously strive to develop the process and technology competencies.
                                Building an organization where every member is profound to the quality norms.
                                We will not rest until you are 100 percent satisfied.
                            </ParagraphText>
                        </Paragraph>
                    </Content>
                </ContentDiv>
                <ContentDiv>
                    <Content data-aos="fade-up">
                        <Icons><img src={aboutUsIcons.team}></img></Icons>
                        <Paragraph>
                            <HeadingText>Our Team</HeadingText>
                            <ParagraphText>LMVIT Solutions employees are driven by a passion for technology and customer satisfaction. We put our customers’ needs at the center of everything , we do and apply the expertise of our committed technologists to deliver value and the finest customer experience.</ParagraphText>
                        </Paragraph>
                    </Content>
                </ContentDiv>
            </InnerSection>
        </AboutUsSecton>
    )
}

export default AboutUs;