import React, { useEffect, useRef, Fragment } from 'react';
import Svg from './Svg';
import { gsap } from "gsap/all";
import styled from 'styled-components';



const Hero = styled.div`
    width : 100%;
    height : 90vh;
    display : flex;
    margin-top : 50px;


@media screen and (max-width: 768px){
    height : auto;
    flex-direction : column;
}
`;

const HeroContent = styled.div`
    width : 100%;
    height : auto;
    padding : 0 20px;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;


    h1{
        font-size : 1.5rem;
        padding : 10px 0;
    }

    p{
        font-size : 1rem;
        padding : 10px 0;
        line-height : 30px;
    }

@media screen and (max-width: 768px){
    padding : 10px 0px;
    text-align : center;

    h1{
        font-size : 1.2rem;
        padding : 5px 0;
    }

    p{
        font-size : 0.9rem;
        padding : 5px 0;
        line-height : 30px;
    }
}


@keyframes height {
    0%{
        height : 100vh;
    }

    100%{
        height : 80vh;
    }
}

`   

const HeroImage = styled.div`
    width : 150%;
    height : 100%;
    padding : 0 20px;
    display : flex;
    justify-content : center;
    align-items : center;

    svg{
        margin-top : 15px;
    }

@media screen and (max-width: 768px){
    width : 100%;
    padding : 10px 0px;
}
`;


const HeroSection = () => {
    const h1 = useRef(null);
    const p = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({delay: 2});
        tl.from(h1.current, { y : 100, opacity : 0, duration : 0.5, ease : 'ease'})
        .from(p.current, { y : 100, opacity : 0,   duration : 0.5, ease : 'ease'})
    }, []);

    return <Fragment>
            <Hero >
                <HeroContent>
                    <h1 ref={h1} >Customer Relationship Management</h1>
                    <p ref={p}>Customer relationship management (CRM) is a process in which a business or other organization administers its interactions with customers, typically using data analysis to study large amounts of information.</p>
                </HeroContent>
                <HeroImage>
                    <Svg />
                </HeroImage>
            </Hero>
    </Fragment>
}


export default HeroSection;