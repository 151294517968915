import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from '../Button'; 
import { IoMdArrowRoundForward } from 'react-icons/io';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';
import { SliderData } from '../Data/SliderData'; 
// import ImageOne from '../../Images/JPEG/1.jpg';
// import ImageTwo from '../../../Images/JPEG/2.jpg';
// import ImageThree from '../../Images/JPEG/3.jpg';
// import ImageFour from '../../Images/JPEG/4.jpg';
// import ImageFive from '../../Images/JPEG/5.jpg';
// import ImageSix from '../../Images/JPEG/6.jpg';

const HeroSection = styled.section`
    width : 100%;
    height : 100vh;
    overflow : hidden;

@media screen and (max-width: 768px) {
    padding-top : 100px;
    height : 60vh
}
`;

const HeroWrapper = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    overflow : hidden;
    position : relative;
`;

const HeroSlide = styled(motion.div)`
    z-index : 1;
    width : 100%;
    height : 100%;
`;
const HeroSlider = styled.div`
    position : absolute;
    width : 100%;
    height : 100%;
    top : 0;
    left : 0;

`;
const HeroImage = styled(motion.img)`
    width : 100%;
    height : auto;
    position : absolute;
    top : 0;
    left : 0;
    width : 100vw;
    height : auto;
    object-fit : fit;

@media screen and (max-width: 768px) {
    height : auto;
    object-fit : cover;
    left : 0;
    top : 70%;
    transform : translate(0, -70%);
}
`;
const HeroContent = styled(motion.div)`
    width : 100%;
    height : auto;
    position : relative;
    z-index : 10;
    display : flex;
    flex-direction : column;
    left : 20px;
    top : 50%;
    transform : translate(0, -50%);
    color : #000;

h1{ 
    font-size : clamp(1rem, 8vw, 2rem);
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom : 0.5rem;
    text-shadow : 0 0 10px rgba(255,255,255);
}

p{ 
    width : 400px;
    margin : 1rem;
    overflow-wrap: break-word;
    line-height : 30px;
    font-weight: 500;
    text-shadow : 0 0 20px rgba(255,255,255);
}

@media screen and (max-width: 768px) {
    top : 0;
    left : 0;
    z-index : 10;
    transform : translate(0, 0);
    justify-content : center;
    align-items : center;
    
h1{
    font-size : 1.4rem;
    font-weight: 600;     
}

p{
    width : auto;
    font-weight: 300;
    line-height : 25px;
}
}
`;
const Arrow = styled(IoMdArrowRoundForward)``;

const SliderButton = styled.div`
    position : absolute;
    bottom : 50px;
    right : 50px;
    display : flex;
    z-index : 10;

@media screen and (max-width: 768px) {
    right : 10px;
    bottom : 10px;
}
`;


const arrowbuttons = css`
    width : 50px;
    height : 50px;
    color : #fff;
    cursor : pointer;
    background : #000d1a;
    border-radius : 50px;
    padding : 10px;
    margin-right : 1rem;    
    user-select : none;
    transition : 0.3s;

    &:hover {
        background : #cd853f;
        transform : scale(1.05);
    }

@media screen and (max-width: 768px) {
    width : 35px;
    height : 35px;
}
`;


const PrevArrow = styled(IoArrowBack)`
    ${arrowbuttons}
`;

const NextArrow = styled(IoArrowForward)`
    ${arrowbuttons}
`

const Paragraph = styled(motion.p)``;



const Hero = (props) => {
    const [current, setCurrent] = useState(0);
    const [slide, setSlide] = useState(SliderData[0]);
    const length = SliderData.length;
    let timeOut = useRef(null);

    useEffect(() => {

        const nextSlide = () => {
            setCurrent(current => (current === length - 1 ? 0 : current + 1));
        }

        timeOut.current = setTimeout(nextSlide, 5000);

        return function() {
            if(timeOut.current) {
                clearTimeout(timeOut.current);
            }
        }
    }, [current, length])


    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
        setSlide(SliderData[current === length - 1 ? 0 : current + 1]);   
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
        setSlide(SliderData[current === 0 ? length - 1 : current - 1]);
    }

    const Handler = () => {
        console.log("Clicked");
    }

    if(!Array.isArray(SliderData) || SliderData.length <= 0) {
        return null;
    }


    return(
        <HeroSection>
            <HeroWrapper>
                {
                    SliderData.map((slide, index) => {
                        return(
                            <HeroSlide key={index}>
                                {   
                                    index === current && (
                                        <HeroSlider >
                                            <HeroImage src={slide.image} initial={{opacity : 0}} animate={{opacity : 1}} transition={{ ease: "easeOut", duration: 0.5 }} />
                                            <HeroContent >
                                                <motion.h1 initial={{x :-100}} animate = {{x : 0}} transition={{ ease: "easeOut", duration: 0.5 }}>{slide.title} </motion.h1>
                                                {slide.info ?  <Paragraph initial={{y :-100}} animate = {{y : 0}} transition={{ ease: "easeOut", duration: 0.5 }}>{slide.info}</Paragraph> : null}
                                                {
                                                    slide.label ? 
                                                    <Button 
                                                    style = {{marginTop : '15px', maxWidth : '160px'}}
                                                    to={slide.path} onClick={Handler}> 
                                                        {slide.label} &nbsp;&nbsp;&nbsp; <Arrow />
                                                    </Button> : null
                                                }
                                            </HeroContent>
                                        </HeroSlider>
                                    )
                                }
                                
                            </HeroSlide>
                        )
                    })
                };
                    {/* <HeroSlide>
                        <HeroSlider >
                            <HeroImage src={slide?.image} initial={{opacity : 0}} animate={{opacity : 1}} transition={{ ease: "easeOut", duration: 0.5 }} />
                            <HeroContent >
                                <motion.h1 initial={{x :-100}} animate = {{x : 0}} transition={{ ease: "easeOut", duration: 0.5 }}>{slide.title} </motion.h1>
                                <motion.p initial={{y :-100}} animate = {{y : 0}} transition={{ ease: "easeOut", duration: 0.5 }}>{slide.price}</motion.p>
                                <Button 
                                    to={slide?.path} 
                                    primary='true'
                                    css={`max-width : 160px`}> 
                                    {slide?.label}
                                <Arrow />
                                </Button>
                            </HeroContent>
                        </HeroSlider>
                    </HeroSlide> */}
                <SliderButton>
                    <PrevArrow onClick={prevSlide}/>
                    <NextArrow onClick={nextSlide}/>
                </SliderButton>
            </HeroWrapper>
        </HeroSection>
    );
}

export default Hero;