import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import  ImageSrc1 from '../../../Images/SlidersPage/DAR/features-1 (2).png'
import ImageSrc2 from '../../../Images/SlidersPage/DAR/values-3.png';
import { BsForwardFill } from 'react-icons/bs';

const PotentialSection = styled.section`
    width : 80%;
    height : auto;
    margin : 15px auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
`;
const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
`;
const Wrapper = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;   

@media screen and (max-width : 768px){
    flex-direction : column-reverse;
    padding : 10px 0;
}
`;
const Content = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;    


@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentDiv = styled.div`
    width : 80%;
    height : auto;
    line-height : 30px;
    display : flex;
    flex-direction : column;

h3{
    text-align : center;
    padding : 10px 0;
}

p{
    padding : 10px 0;
}

@media screen and (max-width : 768px){
    width : 100%;
    padding : 5px;
}
`;
const Image = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 100%;
}

@media screen and (max-width : 768px){
   
}
`;


const ServicesDiv = styled.div`
    width : 100%;
    display : flex;

@media screen and (max-width : 768px){
    flex-direction : column;
}
`;


const Icon = styled(BsForwardFill)`
    font-size : 20px;
`;

const Potential = () => {

    return <Fragment>
        <PotentialSection>
            <Heading><h2>Potential Fishing Zone (PFZ) Advisory</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <p data-aos="fade-up"><Icon/> About 7 million people living along the Indian coastline, spanning over 8100 km, are depending on fishing for their livelihood. Locating and catching fish is always a challenging task.</p>
                        <p data-aos="fade-up"><Icon/> Often, the search for fish ends up in spending considerable time and resources, thus increasing the cost leading to low profitability.</p>
                        <p data-aos="fade-up"><Icon/> A reliable and timely advisory on the potential zones of fish aggregation will benefit the fishing community to reduce the time and effort spent in searching the shoals of fish, thus improving the profitability and hence, the socio-economic status.</p>
                        <p data-aos="fade-up"><Icon/> Utilizing the remotely sensed data available from various satellites, We provides these advisories to the fishermen on a daily basis with specific references to fish landing centers.</p>
                        <p data-aos="fade-up"><Icon/> This operational service is rendered by Samudra throughout the year except during the periods of Marine Fishing ban imposed by Government of India and adverse sea state conditions such as Cyclones, High Waves, Tsunamis, etc.</p>
                    </ContentDiv>
                </Content>
                {/* <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc1} />
                    </ImageDiv>
                </Image> */}
            </Wrapper>
        </PotentialSection>


        <PotentialSection>
            <Heading><h2  data-aos="fade-up">Why Us</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Sell your catch before you reach the shore</h3>
                        <p data-aos="fade-up"><Icon/> Offload your haul, get home quicker.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Communicate quickly and easily</h3>
                        <p data-aos="fade-up"><Icon/> A smartphone plus our technology means two-way messaging between you and those on land.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Stop problems before they start</h3> 
                        <p data-aos="fade-up"><Icon/> A smartphone plus our technology means two-way messaging between you and those on land.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Get help anywhere, anytime</h3> 
                        <p data-aos="fade-up"><Icon/> Weather alerts, cyclone warnings, and even an SOS signal if and when you need it.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc1} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </PotentialSection>


        <PotentialSection style={{paddingBottom : '75px'}}>
            <Heading><h2>Our Services</h2></Heading>
            <Wrapper>
                <Content>
                    <ServicesDiv style={{padding : '0 20px'}}>
                        <p data-aos="fade-up"><Icon/>Potential Fishing Zone (PFZ) Advisory</p>
                        <p data-aos="fade-up"><Icon/>Ocean State Forecast</p>
                        <p data-aos="fade-up"><Icon/>Tsunami Early Warnings</p>
                        <p data-aos="fade-up"><Icon/>Storm Surge Warnings</p>
                    </ServicesDiv>
                </Content>
                {/* <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image> */}
            </Wrapper>
        </PotentialSection>
    </Fragment>
}

export default Potential;