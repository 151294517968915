import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { send, init } from 'emailjs-com';
import axios from 'axios';
init('user_GeXKCeE4iSChNdfLKvAtn');

const Form = styled.form`
  width: 100%;
  padding: 30px 0;
`;

const InputWrapper = styled.div`
  padding: 5px 0;
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Icon = styled.i`
  font-size: 1.5rem;
  animation: rotate 1s linear infinite;
  padding: 20px;

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FormComponent = () => {
  const [toSend, setToSend] = useState({ name: '', email: '', mobile: '', message: '' });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState({ text: 'Submit', color: '#000', load: false });

  const onSubmit = (e) => {
    e.preventDefault();
    setMessage({ ...message, load: true });
    // send(
    //   "service_warwr2s",
    //   "template_6y88wcs",
    //   toSend,
    //   "user_GeXKCeE4iSChNdfLKvAtn"
    // )

    axios
      .post('https://sheetdb.io/api/v1/ci4wn1suz6mi7', toSend)
      .then((response) => {
        response.status === 201 && setToSend({ name: '', email: '', mobile: '', message: '' });
        setMessage({ text: 'Thanks, We will contact you soon...!', color: 'green', load: false });
        setTimeout(() => {
          setMessage({ text: 'Submit', color: 'black' });
        }, 3000);
      })
      .catch((err) => {
        setMessage({ text: 'Something went wrong, Try again', color: 'red', load: false });
        setTimeout(() => {
          setMessage({ text: 'Submit', color: 'black' });
        }, 3000);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Form onSubmit={onSubmit}>
      <InputWrapper>
        <TextField id="standard-basic" label="Name" required name="name" value={toSend.name} onChange={handleChange} color="primary" style={{ width: '100%' }} />
      </InputWrapper>
      <InputWrapper>
        <TextField id="standard-basic" label="Email" required name="email" value={toSend.email} onChange={handleChange} color="primary" style={{ width: '100%' }} />
      </InputWrapper>
      <InputWrapper>
        <TextField id="standard-basic" label="Mobile" required name="mobile" value={toSend.mobile} onChange={handleChange} color="primary" style={{ width: '100%' }} />
      </InputWrapper>
      <InputWrapper>
        <TextField id="standard-basic" label="Message" name="message" value={toSend.message} onChange={handleChange} color="primary" style={{ width: '100%' }} />
      </InputWrapper>
      <ButtonDiv>
        {message.load ? (
          <Icon className="fas fa-spinner"></Icon>
        ) : (
          <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: `${message.color}`, margin: '10px auto' }}>
            {message.text}
          </Button>
        )}
      </ButtonDiv>
    </Form>
  );
};

export default FormComponent;
