import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import DarLogo from './Svg';
import { BsForwardFill} from 'react-icons/bs';

const HeroSection = styled.section`
    width : 100%;
    height : 90vh;
    margin-top : 50px;

`;
const Wrapper = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    flex-direction : column;
}
`;
const Content = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;
`;
const ContentDiv = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    line-height : 30px;

h2{
    padding : 20px;
}

p{
    padding : 20px;
}

@media screen and (max-width : 768px){
    text-align : center;
}
`;


const Image = styled.div`
    width : 150%;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    width : 120%;
}
`;

const Hero = () => {

    return <Fragment>
        <HeroSection>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <h2 data-aos="zoom-in" data-aos-delay="2000">Daily Activity Report</h2>
                        <p data-aos="zoom-in" data-aos-delay="2000">Monitor Actual Working Hours of Team and Projects. Analyze the team’s performance at a glance.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <DarLogo />
                </Image>
            </Wrapper>
        </HeroSection>
    </Fragment>
}

export default Hero;