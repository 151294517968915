import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import  ImageSrc1 from '../../../Images/SlidersPage/StudyTap/whyus.jpg'
import ImageSrc2 from '../../../Images/SlidersPage/StudyTap/transformation.png';
import { BsForwardFill } from 'react-icons/bs';

const DescriptionSection = styled.section`
    width : 100%;
    height : auto;
    margin : 15px auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;


@media screen and (max-width : 768px) {
    width : 100%;
    padding : 0 10px;
    h2{
        font-size : 14px;
    }

    p{
        font-size : 12px;
    }
}
`;
const Heading = styled.div`
    width : 80%;
    height : auto;
    display : flex;
    justify-content : center;  
    align-items : center;
    text-align : center;
    flex-direction : column;
    line-height : 30px;

h2{
    padding : 10px 0;
}
p{
    padding : 10px 0;   
}

@media screen and (max-width : 768px) {
    width : 100%;

    h2{
        font-size : 16px;
    }
    p{
        font-size : 14px;
    }
}
`;
const Wrapper = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;   

@media screen and (max-width : 768px){
    flex-direction : column-reverse;
    padding : 10px 0;
}
`;
const Content = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;


@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentDiv = styled.div`
    width : 80%;
    height : auto;
    line-height : 30px;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;

p{
    padding : 10px 0;
}

@media screen and (max-width : 768px){
    width : 100%;
    padding : 5px;
}
`;
const Image = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 100%;
}

@media screen and (max-width : 768px){
   
}
`;

const Icon = styled(BsForwardFill)`
    font-size : 20px;
`;

const What = () => {

    return <Fragment>
        <DescriptionSection>
            <Heading>
                <h2 data-aos="fade-up">Enabling transformation</h2>
                <p data-aos="fade-up">Your Study Tap learning experience is grounded in cutting edge cognitive science. With more than two dozen distinct learning features to help you achieve your goals, our approach follows these key principles:</p>
            </Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Experience</h3>
                        <p data-aos="fade-up"><Icon/> Simply put, payroll Software refers to the total amount a company is supposed to pay to its employee for a specific period. It is the responsibility of an employer to make sure their employees and staff get fair compensation for their hard work. As much as important it is, payroll can turn out to be an overwhelming task for an employer. Think about it – you have to track the number of hours and days each employee has worked. You have to deduct taxes from the final payment and distribute fair compensation to each worker.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Practice</h3>
                        <p data-aos="fade-up"><Icon/> Demonstrating your knowledge is a critical part of learning. Our courses and programs provide a space to practice with quizzes, open response assessments, virtual environments, and more.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Apply</h3>
                        <p data-aos="fade-up"><Icon/> Learning on Study Tap transforms how you think and what you can do, and translates directly into the real world—immediately apply your new capabilities in the context of your job.</p>
                    </ContentDiv>
                </Content>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection>
            <Heading><h2 data-aos="fade-up">Transformation through education</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv >
                        <p data-aos="fade-up"><Icon/>Every individual has the potential to create change, whether in their life, their community, or the world. </p>
                        <p data-aos="fade-up"><Icon/>The transformative power of education is what unlocks that potential. </p>
                        <p data-aos="fade-up"><Icon/>Yet, access to high-quality education has been a privilege of the few. Back in 2012, we realized it was a time for a seismic shift in learning. </p>
                        <p data-aos="fade-up"><Icon/>From the tried and true to the leading edge. From “for some” to “for all.” By opening the classroom through online learning.</p>
                        <p data-aos="fade-up"><Icon/>Study Tap empowers millions of learners to unlock their potential and become changemakers.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection >
            <Heading><h2  data-aos="fade-up">Why Us</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <h3 data-aos="fade-up">About Us</h3>
                        <p data-aos="fade-up"><Icon/> We are building, partnering, and promoting a software platform that brings together the best universities from all around the world to create courses for everyone, everywhere in the world.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Our Aim</h3>
                        <p data-aos="fade-up"><Icon/> we're relentlessly pursuing our vision of a world where every learner can access education to unlock their potential, without the barriers of location.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3 data-aos="fade-up">Our mission</h3> 
                        <p data-aos="fade-up"><Icon/> Increase access to high-quality education for everyone, everywhere. Enhance teaching and learning on campus and online.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc1} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection style={{paddingBottom : '50px'}}>
            <Heading><h2  data-aos="fade-up">Why Study Tap works</h2></Heading>
            <Wrapper data-aos="fade-up">
                <Content >
                    <ContentDiv>
                        <h3>Personalized learning</h3>
                        <p><Icon/> Students practice at their own pace,  in their understanding and then accelerating their learning.</p>
                    </ContentDiv>
                    <ContentDiv>
                        <h3>Trusted content</h3>
                        <p ><Icon/> Democratise access to high quality education.</p>
                    </ContentDiv>
                </Content>
                {/* <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image> */}
            </Wrapper>
        </DescriptionSection>
    </Fragment>
}

export default What;