import React from 'react';
import Footer from '../Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="privacy_policy_container" style={{ textAlign: 'justify' }}>
          <h4 className="text-center" style={{ textAlign: 'center', fontWeight: '600' }}>
            PRIVACY POLICY
          </h4>
          <p>The purpose of this Privacy Policy is to provide clarity around how we control information on this website.</p>
          <h5>Information on the Website</h5>
          <p>We only collect information about our customers, when they use any of the interactive features on our website which includes contact forms and analytics data.</p>
          <h5>Why do we collect your data in particular?</h5>
          <p>The information we collect is limited to the details we need to deliver a particular service for which the customer has asked.</p>
          <h5>How do we know our subjective information is safe and secure?</h5>
          <p>We're committed to assuring your information is kept secure, secret, and not to save for longer than required. It's managed by our information technology systems.</p>
          <h5>What's the purpose of gathering our data?</h5>
          <p>The primary purpose of collecting data on our website is for communication purposes. We'll only gain information from you that's “ fit for purpose” and doesn't constitute an irruption of privacy.</p>
          <h5>Who will have access to my particular information?</h5>
          <p>From time to time we may ask other members of our group, or third-party service providers to help us manage our information technology systems. We'll only transfer your information to a third-party service provider where we're satisfied that respectable places of protection. To cover the integrity and security of any information being reused and compliance with applicable sequestration and data protection laws.</p>
          <h5>HOW WE USE COLLECTED INFORMATION</h5>
          <p>What's done with your particular information?</p>
          <p>Lmvit Solutions may collect and use your particular information for the following purposes</p>
          <ol>
            <li>
              <p>To enrich client service Information you give helps us respond to your client service requests and support needs more efficiently, therefore, assuring that necessary information is handed to you from time to time.</p>
            </li>
            <li>
              <p>To epitomize user experience We may use information in the total to understand how our users as a group make use of the services and resources.</p>
            </li>
            <li>
              <p>To send periodic emails We may use the mail address given by you to respond to your inquiries, questions, and/ or other requests. However, you'll admit emails that may include company news, updates, If you decide to subscribe to our mailing list. However, we include detailed unsubscribe instructions at the bottom, If at any time you would like to unsubscribe from receiving further emails.</p>
            </li>
          </ol>
          <h5>OUR RESPONSIBILITY FOR WEBSITE LINKS</h5>
          <p>This Privacy Policy is limited to the particular information which we collect and use via this website. We do give links within this point to other websites, including social media spots similar to Facebook, Twitter, and LinkedIn, and spots operated by other brands within LMVIT Solutions.</p>
          <p>Still, you should use these websites in meeting with their applicable user and privacy procedures as their data practices fall outside the reach of this Privacy Policy, If you follow these links.</p>
          <h5>will you take responsibility for my information collected by other websites?</h5>
          <p>We don't take any responsibility or control over the information collected by any third-party website and we can not be responsible for the protection and privacy of any information which you may provide on similar websites.</p>
          <h5>CHANGES TO THIS PRIVACY POLICY</h5>
          <p>LMVIT Solutions has the wit to streamline this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage you to often check this page for any changes. We are helping to secure the individual information we collect. You acknowledge and agree that it's your responsibility to review this Privacy Policy periodically and become aware of the changes.</p>
          <h5>QUESTIONS OR QUERIES</h5>
          <p>If you have any questions about this privacy notice or how we handle your personal data, please mail us at privacy@lmvit.com</p>
          <h5>Changes to this Privacy Notice</h5>
          <p>
            We reserve the right to update this privacy notice at any time and will be providing you with a new privacy notice when we make any changes.
            <br /> (Last updated on Jan 5, 2022)
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
