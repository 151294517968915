import './App.css';
import React, { useState } from 'react';
import Home from './Components/Routes/HomePage';
import AboutUsPage from './Components/Routes/AboutUsPage';
import ContactUsPage from './Components/Routes/ContactUsPage';
import { useRouteMatch, Route, Redirect } from 'react-router-dom';
import WebDevelopment from './Components/Services/WebDevelopment';
import AppDevelopment from './Components/Services/AppDevelopment';
import UI_UX from './Components/Services/UI-UX';
import DigitalMarketing from './Components/Services/DigitalMarketing';
import Navbar from './Components/Nav/NavBar';
import Dropdown from './Components/Nav/Dropdown';
import CRM from './Components/Sliders Pages/CRM/CRM';
import DAR from './Components/Sliders Pages/DAR/DAR';
import Payroll from './Components/Sliders Pages/PAYROLL/Payroll';
import Samudra from './Components/Sliders Pages/SAMUDHRA/Samudra';
import StudyTap from './Components/Sliders Pages/STUDYTAP/StudyTap';
import UpButton from './Components/Nav/UpButton';
import PrivacyPolicy from './Components/Routes/PrivacyPolicy';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { path, url } = useRouteMatch();

  return (
    <div className="App">
      <Navbar toggle={toggle} />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <UpButton />
      <Route exact path={path}>
        <Home />
      </Route>
      <Route exact path={path + 'about'}>
        <AboutUsPage />
      </Route>
      <Route exact path={path + 'contact'}>
        <ContactUsPage />
      </Route>
      <Route exact path={path + 'webdevelopment'}>
        <WebDevelopment />
      </Route>
      <Route exact path={path + 'appdevelopment'}>
        <AppDevelopment />
      </Route>
      <Route exact path={path + 'uiuxdesigning'}>
        <UI_UX />
      </Route>
      <Route exact path={path + 'digitalmarketing'}>
        <DigitalMarketing />
      </Route>
      <Route exact path={path + 'crm'}>
        <CRM />
      </Route>
      <Route exact path={path + 'dar'}>
        <DAR />
      </Route>
      <Route exact path={path + 'payroll'}>
        <Payroll />
      </Route>
      <Route exact path={path + 'samudra'}>
        <Samudra />
      </Route>
      <Route exact path={path + 'studytap'}>
        <StudyTap />
      </Route>
      <Route exact path={path + 'privacy-policy'}>
        <PrivacyPolicy />
      </Route>
      {/* <Redirect from="*" to="/" /> */}
    </div>
  );
}

export default App;
