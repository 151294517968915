import React, { Fragment, useState, useEffect }from 'react';
import styled from 'styled-components';

const ContactUsSection = styled.section`
    width : 100%;
    height : auto;

`;

const InnerSection = styled.div`
    width : 100%;
    height : auto;
    padding : 20px;
`;

const Heading = styled.h1`
    text-align : center;
    font-size : 2rem;
    color : #364547;
    padding : 20px 0;
`;

const HeadingDiv = styled.div`
    width : 100%;
    height : auto;
    background : #e1e5ea;
    padding-top : 60px;
`
const ContactDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : space-between;

@media screen and (max-width: 768px) {
    flex-direction : column;
}
`;
const ContactAddress = styled.div`
    width : 100%;
    height : auto;
    padding : 20px;
`;

const Icon = styled.div`
    width : 100%;
    height : auto;
    display: flex;
    justify-content : center;
`;
const I = styled.i`
    background: #000;
    padding : 20px; 
    border-radius : 50%;
    font-size : 3rem;
    color : rgba(173,202,37,255);

@media screen and (min-width : 768px){
    font-size : 2rem;
    padding : 15px;
}

`;
const HeadingText = styled.h2`
    padding : 15px 0;
    text-align: center;

`;
const Paragraph = styled.p`
    padding : 15px 0;
    text-align : center;
    line-height : 28px;  
`;

const ContactUs = () => {


    return(
        <ContactUsSection>
            <HeadingDiv>
             <Heading className="contact-us">Contact Us</Heading>   
            </HeadingDiv>
            <InnerSection>
                <ContactDiv>
                    <ContactAddress>
                        <Icon><I className="fas fa-location-arrow"></I></Icon>
                        <HeadingText>Address</HeadingText>
                        <Paragraph>1-10-146/7/2, FLAT NO A, Block A, Kundan Towers, Mayuri Marg, Mayur Marg, Begumpet, Hyderabad, Telangana,500016.</Paragraph>
                    </ContactAddress>
                    <ContactAddress>
                        <Icon><I className="fas fa-phone-alt"></I></Icon>
                        <HeadingText>Phone Number</HeadingText>
                        <Paragraph>+91 6309531081</Paragraph>
                    </ContactAddress>
                    <ContactAddress>
                        <Icon><I className="fas fa-envelope"></I></Icon>
                        <HeadingText>Email</HeadingText>
                        <Paragraph>lmvitservices@gmail.com</Paragraph>  
                    </ContactAddress>
                </ContactDiv>
            </InnerSection> 
        </ContactUsSection>
    )
}

export default ContactUs;