import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import  ImageSrc1 from '../../../Images/SlidersPage/DAR/features-1 (2).png'
import ImageSrc2 from '../../../Images/SlidersPage/DAR/values-3.png';
import { BsForwardFill } from 'react-icons/bs';

const DescriptionSection = styled.section`
    width : 80%;
    height : auto;
    margin : 15px auto;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
`;
const Heading = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
`;
const Wrapper = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;   

@media screen and (max-width : 768px){
    flex-direction : column-reverse;
    padding : 10px 0;
}
`;
const Content = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;


@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ContentDiv = styled.div`
    width : auto;
    height : auto;
    line-height : 30px;;

p{
    padding : 10px 0;
}
`;
const Image = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    padding : 10px 0;
}
`;
const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 100%;
}

@media screen and (max-width : 768px){
   
}
`;

const Icon = styled(BsForwardFill)`
    font-size : 20px;
`;

const Description = () => {

    return <Fragment>
        <DescriptionSection>
            <Heading><h2>Description</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <p data-aos="fade-up"><Icon/> Auto Timesheets of Applications and Files Worked.</p>
                        <p data-aos="fade-up"><Icon/> Project and Activity Reports in real-time.</p>
                        <p data-aos="fade-up"><Icon/> Team Collaboration.</p>
                        <p data-aos="fade-up"><Icon/> Cataloging of files used and web traffic.</p>
                        <p data-aos="fade-up"><Icon/> Identify bad work habits.</p>
                        <p data-aos="fade-up"><Icon/> Actual working hours.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc1} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection>
            <Heading><h2>Monitor Time and Desk Activities</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv style={{padding : '0 20px'}}>
                        <p data-aos="fade-up"><Icon/>Auto recording of time spent on PC and Desk Activities and Works in invisible mode</p>
                        <p data-aos="fade-up"><Icon/>White List the apps to record productive hours moreover fix the time on apps which are less productive and get the time exceed reports</p>
                        <p data-aos="fade-up"><Icon/>Total time spent on internet, blacklist the URLs like facebook, youtube etc. and record the unproductive hours</p>
                        <p data-aos="fade-up"><Icon/>Track Gmails and time spent on communication on mails</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image>
            </Wrapper>
        </DescriptionSection>
        <DescriptionSection style={{paddingBottom : '50px'}}>
            <Heading><h2>Project Management</h2></Heading>
            <Wrapper>
                <Content>
                    <ContentDiv style={{padding : '0 20px'}}>
                        <p data-aos="fade-up"><Icon/>Manage the tracked files, folder, or applications as project or tasks by tagging thus identify the time spent on the project or activities.</p>
                        <p data-aos="fade-up"><Icon/>Auto logging of application (Xcode, studio, Autocad, phpstorm, Microsoft excel, keynote etc.) files and monitor the time spent.</p>
                        <p data-aos="fade-up"><Icon/>Detailed reports of the individual activities thus identify the in-efficient or bad work habits and turn to the efficient ones by pushing them via mentoring.</p>
                        <p data-aos="fade-up"><Icon/>Analyze the performance in a glance and do the appraisals by having the actual working reports of the individual</p>
                    </ContentDiv>
                </Content>
                {/* <Image>
                    <ImageDiv>
                        <img data-aos="zoom-out" src={ImageSrc2} />
                    </ImageDiv>
                </Image> */}
            </Wrapper>
        </DescriptionSection>
    </Fragment>
}

export default Description;