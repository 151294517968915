import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { DiGoogleAnalytics } from 'react-icons/di';
import { GiStairsGoal, GiArchiveResearch } from 'react-icons/gi';
import { HiUserCircle, HiOutlineDesktopComputer } from 'react-icons/hi';
import { RiGroupFill, RiComputerFill} from 'react-icons/ri';

const StrategyAndResearch = styled.div`
    width : 100%;
    height : auto;
    padding : 40px 0;
    box-sizing: border-box;

h1{
    text-align : center;
    padding : 20px 0;
}

@media screen and (max-width : 768px) {

    font-size : 0.9rem; 
}
`;

const StepsDiv = styled.div`    
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
`;



const StepsInner = styled.div`
    width : 80%;
    height : auto;
    display : flex;
    flex-wrap : wrap;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px) {
    flex-direction : column;
}
`;

const StepDiv = styled.div`
    width : 250px;
    height : 125px;
    border : 1px solid black;
    border-radius : 10px;
    margin :50px;
    box-shadow : 0 0 5px rgba(0, 0, 0, 0.5);
    position : relative;


@media screen and (max-width : 768px) {
    padding : 10px 0;
}
`
const IconDiv = styled.div`
    width : 100px;
    height : 100px;
    position : absolute;
    left : -30px;
    top : -30px;
    display : flex;
    justify-content : center;
    align-items : center;
    border-radius : 50%;
    background :  #b6c9f0;
    box-shadow : 0 0 10px rgba(0, 0, 0, 0.5);

svg{
    width : 50px;
    height : 50px;
    fill :  #6717cd;
}
`;


const StepsContent = styled.div`  
    width : 100%;
    height : auto;
    display : flex;
    text-align : center;    
    flex-direction : column;    

h1{
    width : 100%;
    font-size : 3rem;
    padding :10px;
    font-family : "Merriweather"
}

p{
    width : 100%;
    font-size : 1rem;
    font-weight : bold;
    padding : 0 10px;
}
`;

const Steps = () => {

    return (<Fragment>
        <StrategyAndResearch>
            <h1 data-aos="fade-up">STRATEGY & RESEARCH</h1>
            <StepsDiv>
                <StepsInner >
                    <StepDiv data-aos="fade-up" data-aos-delay="50">
                        <IconDiv><DiGoogleAnalytics /></IconDiv>
                        <StepsContent>
                            <h1> 01</h1>
                            <p>Requirement Analysis</p>
                        </StepsContent>
                    </StepDiv>
                    <StepDiv data-aos="fade-up" data-aos-delay="100" >
                        <IconDiv><GiStairsGoal /></IconDiv>
                        <StepsContent>
                            <h1> 02</h1>
                            <p>Project Goals</p>
                        </StepsContent>
                    </StepDiv>
                    <StepDiv  data-aos="fade-up" data-aos-delay="150">
                        <IconDiv><HiUserCircle /> </IconDiv>
                        <StepsContent>
                            <h1>03</h1>
                            <p>User Needs</p>
                        </StepsContent>
                    </StepDiv>
                    <StepDiv  data-aos="fade-up" data-aos-delay="200">
                        <IconDiv><RiGroupFill /></IconDiv>
                        <StepsContent>
                            <h1>04</h1>
                            <p>Focus Groups</p>
                        </StepsContent>
                    </StepDiv>
                    <StepDiv data-aos="fade-up" data-aos-delay="250" >
                        <IconDiv><GiArchiveResearch/></IconDiv>
                        <StepsContent>
                            <h1> 05</h1>
                            <p>Market Research</p>
                        </StepsContent>
                    </StepDiv>
                    <StepDiv  data-aos="fade-up" data-aos-delay="300">
                        <IconDiv><RiComputerFill /></IconDiv>
                        <StepsContent>
                            <h1>06</h1>
                            <p>Computer Analysis</p>
                        </StepsContent>
                    </StepDiv>
                    {/* <StepDiv data-aos="fade-up" data-aos-delay="350" >
                        <StepsContent>
                            <h1>07</h1>
                            <p>Computer Analysis</p>
                        </StepsContent>
                    </StepDiv> */}
                </StepsInner>
            </StepsDiv>
        </StrategyAndResearch>
    </Fragment>)
}

export default Steps;