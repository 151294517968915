import React,  { Fragment }  from 'react';
import styled from 'styled-components';
import PayrollLogo from './Svg';
import ImageSrc from '../../../Images/SlidersPage/Payroll/Payroll.png';
import { BsForwardFill} from 'react-icons/bs';
import { Images } from '../../Data/ServicesData';

const HeroSection = styled.section`
    width : 100%;
    height : 90vh;
    margin-top : 50px;

`;
const Wrapper = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    flex-direction : column;
}
`;
const Content = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;
`;
const ContentDiv = styled.div`
    width : 100%;
    display : flex ;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    line-height : 30px;
    text-align : center;

h2{
    padding : 15px;
}

p{
    padding : 15px;
}

@media screen and (max-width : 768px){
    text-align : center;


h2{
    padding : 5px;
}

p{
    padding : 5px;
}

}
`;


const Image = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

@media screen and (max-width : 768px){
    width : 100%;
}
`;

const ImageDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;

img{
    width : 80%;
}
`;

const Hero = () => {

    return <Fragment>
        <HeroSection>
            <Wrapper>
                <Content>
                    <ContentDiv>
                        <h2 data-aos="zoom-in" data-aos-delay="2000">Payroll Software</h2>
                        <p data-aos="zoom-in" data-aos-delay="2000">Payroll software, familiar as the payroll management system or payroll management software, is a solution that is used to organize the process of employee payments. Payroll Software helps the HR & Accounting Teams to manage and schedule payroll operations without human error and saves time.</p>
                    </ContentDiv>
                </Content>
                <Image>
                    <ImageDiv>
                        <img src={ImageSrc}/>
                    </ImageDiv>
                </Image>
            </Wrapper>
        </HeroSection>
    </Fragment>
}

export default Hero;